import React, { useState, useEffect } from "react";
import classes from "./WorkLocation.module.css";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../Store/Actions/index";
import * as actionTypes from "../../../Store/Actions/ActionTypes";
import Toast from "../../../common/Toast/Toast";
import Loader from "../../../common/Loader/Loader";
import ErrorAlert from "../../../common/ErrorAlert/ErrorAlert";

const WorkLocation = ({
  setNextPage,
  setPreviousPage,
  workLocation,
  handleWorkLocation,
}) => {
  let dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [error, setError] = useState("");

  const {
    refJobId,
    errorMsg,
    workLocationSubmit,
    masterCountryList,
    masterStateList,
    masterCityList,
  } = useSelector((state) => ({
    errorMsg: state.CreateJobReducer.errorMsg,
    refJobId: state.CreateJobReducer.refJobId,
    workLocationSubmit: state.CreateJobReducer.workLocationSubmit,
    masterCountryList: state.MasterReducer.masterCountryList,
    masterStateList: state.MasterReducer.masterStateList,
    masterCityList: state.MasterReducer.masterCityList,
  }));

  useEffect(()=>{
    window.scrollTo(0,0);
  },[])

  const submitWorkLocation = (e) => {
    e.preventDefault();
    if (
      parseInt(workLocation.zipcode) < 10000 ||
      parseInt(workLocation.zipcode) > 99999
    ) {
      setError("Zipcode must be of 5 digit");
    } else {
      setError("");
      dispatch({ type: actionTypes.CLEAR });
      setIsLoading(true);
      dispatch(
        actions.submitWorkLocation({ ...workLocation, refJobId: refJobId })
      );
    }
  };

  useEffect(() => {
    if (workLocationSubmit) {
      setIsLoading(false);
      dispatch({ type: actionTypes.CLEAR });
      setNextPage("onBoarding");
    } else if (errorMsg) {
      setIsLoading(false);
      setAlert(true)
      // setToast(
      //   true,
      //   setTimeout(() => {
      //     setToast(false);
      //   }, 3000)
      // );
    }
  }, [errorMsg, workLocationSubmit]);

  const confirm = () => {
    setAlert(false);
  };

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <React.Fragment>
        <form onSubmit={submitWorkLocation}>
          <div className={classes.workLocation}>
          {
            alert && errorMsg && (
              <ErrorAlert show={true} msg={errorMsg} confirm={confirm} />
            )
            // <Toast display={toast} type="danger" message={errorMsg} />
          }
            <div className={classes.workLocationItem}>
              <div>Address</div>
              <div>
                <textarea
                  name="address"
                  required
                  minlength="10"
                  autoComplete='off'
                  value={workLocation ? workLocation.address : ""}
                  onChange={handleWorkLocation}
                />
              </div>
            </div>
            <div className={classes.workLocationItem}>
              <div>Country</div>
              <div>
                <select
                  name="countryId"
                  required
                  value={workLocation ? workLocation.countryId : ""}
                  onChange={handleWorkLocation}
                >
                  <option selected disabled value="">
                    Select Country
                  </option>
                  {masterCountryList &&
                    masterCountryList.map((item) => {
                      return <option value={item._id}>{item.name}</option>;
                    })}
                </select>
              </div>
            </div>
            <div className={classes.workLocationItem}>
              <div>State</div>
              <div>
                <select
                  name="stateId"
                  required
                  value={workLocation ? workLocation.stateId : ""}
                  onChange={handleWorkLocation}
                >
                  <option selected disabled value="">
                    Select State
                  </option>
                  {masterStateList && 
                    masterStateList.map((item) => {
                      if(workLocation && item.countryId === workLocation.countryId){
                        return <option value={item._id}>{item.name}</option>;
                      }
                    })}
                </select>
              </div>
            </div>
            <div className={classes.workLocationItem}>
              <div>City</div>
              <div>
                <select
                  name="cityId"
                  required
                  value={workLocation ? workLocation.cityId : ""}
                  onChange={handleWorkLocation}
                >
                  <option selected disabled value="">
                    Select City
                  </option>
                  {masterCityList && 
                    masterCityList.map((item) => {
                      if(workLocation && item.stateId === workLocation.stateId){
                        return <option value={item._id}>{item.name}</option>;
                      }
                      
                    })}
                </select>
              </div>
            </div>
            <div className={classes.workLocationItem}>
              <div>ZipCode</div>
              <div>
                <input
                  name="zipcode"
                  type="text"
                  minLength="5"
                  maxLength="5"
                  pattern="^[0-9]*$"
                  title="Only numbers are allowed"
                  autoComplete='off'
                  required
                  defaultValue={workLocation ? workLocation.zipcode : ""}
                  onChange={handleWorkLocation}
                />
              </div>
            </div>
            <div className={classes.workLocationItem}>
              <div></div>
              <div className={classes.error}>{error}</div>
            </div>
          </div>
          <div className={classes.skillFooter}>
            <div onClick={() => setPreviousPage("interviewProcess")}>Back</div>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <button>Next</button>
          </div>
        </form>
      </React.Fragment>
    );
  }
};

export default WorkLocation;
