import React from "react";
import classes from "./Personal.module.css";
import PersonalCustome from "./PersonalCustom";

const PersonalAddCustom = ({
  personalQuestions,
  personalCustomeQuestions,
  addToCustome,
  editCustomeQuestion,
  removeCustomeQuestion,
}) => {
  return (
    <div className={classes.personal}>
      <div className={classes.head}>
        <div className={classes.index}>S.No.</div>
        <div>Question</div>
        <div>Action</div>
      </div>
      {personalCustomeQuestions.map((item, index) => {
        return (
          <PersonalCustome
            customeIndex={1 + index}
            customeQuestion={item}
            addToCustome={addToCustome}
            editCustomeQuestion={editCustomeQuestion}
            removeCustomeQuestion={removeCustomeQuestion}
          />
        );
      })}
    </div>
  );
};

export default PersonalAddCustom;
