import React, { useState, useEffect } from "react";
import classes from "./SkillMatrix.module.css";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../Store/Actions/index";
import * as actionTypes from "../../../Store/Actions/ActionTypes";
import SkillStrip from "./SkillStrip";
import SkillStriEdit from "./SkillMatrixEdit";
import Toast from "../../../common/Toast/Toast";
import Loader from "../../../common/Loader/Loader";
import Utility from "../../../Utility/Utility";
import ErrorAlert from "../../../common/ErrorAlert/ErrorAlert";

const SkillMatrix = ({
  skillMatrix,
  setEdit,
  saveSkillMatrix,
  addSkillMatrix,
  handleAvailableMatrix,
  handleNewMatrix,
  newMatrix,
  deleteMatrix,
  addNewValue,
  setNextPage,
  setPreviousPage,
  extractSkillFromJd,
}) => {
  let dispatch = useDispatch();

  const [skillError, setSkillError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState(false);

  const { masterSkill, refJobId, skillMatrixSubmitted, errorMsg } = useSelector(
    (state) => ({
      masterSkill: state.MasterReducer.masterSkill,
      skillMatrixSubmitted: state.CreateJobReducer.skillMatrixSubmitted,
      errorMsg: state.CreateJobReducer.errorMsg,
      refJobId: state.CreateJobReducer.refJobId,
    })
  );

  useEffect(() => {
    window.scrollTo(0,0);
  },[])

  useEffect(() => {
    if (skillMatrixSubmitted) {
      setIsLoading(false);
      dispatch({ type: actionTypes.CLEAR });
      setNextPage("question");
    } else if (errorMsg) {
      setIsLoading(false);
      setAlert(true);
      // setToast(
      //   true,
      //   setTimeout(() => {
      //     setToast(false);
      //   }, 3000)
      // );
    }
  }, [errorMsg, skillMatrixSubmitted]);

  const submitSkillMatrix = () => {
    if (skillMatrix.length === 0) {
      setSkillError("Atleast one skill is required");
    } else {
      let skillToSubmit = skillMatrix.map((item) => {
        delete item.isEdit;
        return item;
      });
      dispatch({ type: actionTypes.CLEAR });
      setIsLoading(true);
      dispatch(
        actions.submitSkillsMatrix({ jobId: refJobId, skill: skillToSubmit })
      );
    }
  };

  const clearError = () => {
    setSkillError("");
  };

  useEffect(()=>{
    if(skillMatrix){
      setSkillError("");
    }
  },[skillMatrix])

  const addToNewSkillMatrix = (e) => {
    e.preventDefault();
    if (
      e.target[0].value.match(/^[a-zA-Z ]+$/) !== null ||
      e.target[0].value.match(/^[ A-Za-z.#+ ]*$/) !== null
    ) {
      addSkillMatrix(e)
    } else {
      setSkillError("Invalid skill");
    }
  };

  const saveAvailableSkillMatrix = (e,index) => {
    e.preventDefault();
    if (
      e.target[0].value.match(/^[a-zA-Z ]+$/) !== null ||
      e.target[0].value.match(/^[A-Za-z.#+ ]*$/) !== null
    ) {
      saveSkillMatrix(e,index)
    } else {
      setSkillError("Invalid skill");
    }
  }

  const confirm = () => {
    setAlert(false);
  };

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <React.Fragment>
        <div className={classes.skillMatrix}>
        {
            alert && errorMsg && (
              <ErrorAlert show={true} msg={errorMsg} confirm={confirm} />
            )
            // <Toast display={toast} type="danger" message={errorMsg} />
          }
          <div className={classes.skillMatrixTop}>
            <div>Specify Skills</div>
            <div onClick={extractSkillFromJd}>Extract from job description</div>
          </div>
          <div className={classes.skillMatrixHeader}>
            <div>S.No.</div>
            <div>Skill</div>
            <div>Preference</div>
            <div>Experience</div>
            <div>Action</div>
          </div>
          {skillMatrix.map((item, index) => {
            return (
              <SkillStriEdit
                matrixData={item}
                index={index}
                setEdit={setEdit}
                saveSkillMatrix={saveSkillMatrix}
                handleAvailableMatrix={handleAvailableMatrix}
                deleteMatrix={deleteMatrix}
                addNewValue={addNewValue}
                masterSkill={masterSkill}
                clearError={clearError}
              />
            );
          })}
          <SkillStrip
            lastIndex={skillMatrix.length + 1}
            addSkillMatrix={addSkillMatrix}
            handleNewMatrix={handleNewMatrix}
            newMatrix={newMatrix}
            addNewValue={addNewValue}
            masterSkill={masterSkill}
            clearError={clearError}
          />
          <div className={classes.error}>{skillError}</div>
        </div>
        <div className={classes.skillFooter}>
          <button onClick={() => setPreviousPage("jobDetail")}>Back</button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <button onClick={submitSkillMatrix}>Next</button>
        </div>
      </React.Fragment>
    );
  }
};

export default SkillMatrix;
