import * as actionTypes from "../Actions/ActionTypes";

const initialState = {};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.API_FAIL:
      return {
        ...state,
        loader: false,
        errorMsg: action.payload,
        emailSent: false,
      };
    case actionTypes.API_INITIATE:
      return {
        ...state,
        loader: true,
        errorMsg: undefined,
        emailSent: undefined,
        signUpSuccessMsg: undefined,
        msg: undefined,
      };
    case actionTypes.CLEAR:
      return {
        ...state,
        errorMsg: undefined,
        emailSent: undefined,
        signUpSuccessMsg: undefined,
        msg: undefined,
        emailVerified: undefined,
        loginStatus: undefined,
        forgetPasswordFailMsg: undefined,
        forgetPassword: undefined,
        resetPassword:undefined,
        forgetPasswordFailMsg:undefined,
      };
    case actionTypes.SIGNIN_SUCCESS:
      return {
        ...state,
        loader: false,
        loginStatus: true,
      };
    case actionTypes.SIGNIN_FAIL:
      return {
        ...state,
        loader: false,
        error: true,
        errorMsg: action.errorMsg,
      };
    case actionTypes.SIGNUP_SUCCESS:
      return {
        ...state,
        loader: false,
        signUpSuccessMsg: action.payload,
        msg: action.msg,
        emailSent: action.emailSent,
      };
    case actionTypes.SIGNUP_FAIL:
      return {
        ...state,
        loader: false,
        errorMsg: action.errorMsg,
        emailSent: false,
      };
    case actionTypes.VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        loader: false,
        emailVerified: true,
      };
    case actionTypes.VERIFY_EMAIL_FAIL:
      return {
        ...state,
        loader: false,
        errorMsg: action.payload,
      };
    case actionTypes.FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        forgetPassword: true,
      };
    case actionTypes.FORGET_PASSWORD_FAIL:
      return {
        ...state,
        forgetPasswordFailMsg: action.payload,
      };
    case actionTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPassword: true,
        msg: "Password changed successfully",
      };
    case actionTypes.RESET_PASSWORD_FAIL:
      return {
        ...state,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default AuthReducer;
