// for api failure
export const API_FAIL = 'API_FAIL';
export const API_INITIATE = 'API_INITIATE';
export const SUB_API_INITIATE = 'SUB_API_INITIATE';
export const CLEAR = 'CLEAR';

// for login 
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_FAIL = 'SIGNIN_FAIL';

// for signup
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';

// verify email
export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_FAIL = 'VERIFY_EMAIL_FAIL';

// Employer Dashboard
export const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS';
export const GET_DASHBOARD_FAIL = 'GET_DASHBOARD_FAIL';

// Drafted Jobs
export const GET_DRAFTED_JOB_SUCCESS = 'GET_DRAFTED_JOB_SUCCESS';
export const GET_DRAFTED_JOB_FAIL = 'GET_DRAFTED_JOB_FAIL';

// deleted jobs
export const GET_DELETED_JOB_SUCCESS = 'GET_DELETED_JOB_SUCCESS';
export const GET_DELETED_JOB_FAIL = 'GET_DELETED_JOB_FAIL';

// repost job
export const REPOST_JOB_SUCCESS = 'REPOST_JOB_SUCCESS';
export const REPOST_JOB_FAIL = 'REPOST_JOB_FAIL';

export const REPOSTED_JOB_SUCCESS = 'REPOSTED_JOB_SUCCESS';
export const REPOSTED_JOB_FAIL = 'REPOSTED_JOB_FAIL';

// export get posted jobs
export const GET_POSTEDJOBS_SUCCESS = 'GET_POSTEDJOBS_SUCCESS';
export const GET_POSTEDJOBS_FAIL = 'GET_POSTEDJOBS_FAIL';

// close job
export const CLOSE_JOB_SUCCESS = 'CLOSE_JOB_SUCCESS';
export const CLOSE_JOB_FAIL = 'CLOSE_JOB_FAIL';

// get application interviews
export const GET_APPLICATION_SUCCESS = 'GET_APPLICATION_SUCCESS';
export const GET_APPLICATION_FAIL = 'GET_APPLICATION_FAIL';

// get application by id
export const GET_APPLICATION_BY_ID_SUCCESS = 'GET_APPLICATION_BY_ID_SUCCESS';
export const GET_APPLICATION_BY_ID_FAIL = 'GET_APPLICATION_BY_ID_FAIL';

// reject application
export const REJECT_JOB_APPLICATION_SUCCESS = 'REJECT_JOB_APPLICATION_SUCCESS';
export const REJECT_JOB_APPLICATION_FAIL = 'REJECT_JOB_APPLICATION_FAIL';

// get company sector list
export const MASTER_COMPANY_SECTOR_LIST_SUCCESS = 'MASTER_COMPANY_SECTOR_LIST_SUCCESS';
export const MASTER_COMPANY_SECTOR_LIST_FAIL = 'MASTER_COMPANY_SECTOR_LIST_FAIL';

// get country master
export const MASTER_COUNTRY_LIST_SUCCESS = 'MASTER_COUNTRY_LIST_SUCCESS';
export const MASTER_COUNTRY_LIST_FAIL = 'MASTER_COUNTRY_LIST_FAIL';

//get state master
export const MASTER_STATE_LIST_SUCCESS = 'MASTER_STATE_LIST_SUCCESS';
export const MASTER_STATE_LIST_FAIL = 'MASTER_STATE_LIST_FAIL';

// get city master
export const MASTER_CITY_LIST_SUCCESS = 'MASTER_CITY_LIST_SUCCESS';
export const MASTER_CITY_LIST_FAIL = 'MASTER_CITY_LIST_FAIL';

// get master education
export const MASTER_EDUCATION_LIST_SUCCESS = 'MASTER_EDUCATION_LIST_SUCCESS';
export const MASTER_EDUCATION_LIST_FAIL = 'MASTER_EDUCATION_LIST_FAIL';

// get master boarding requirements
export const MASTER_BOARDING_REQUIREMENT_LIST_SUCCESS = 'MASTER_BOARDING_REQUIREMENT_LIST_SUCCESS';
export const MASTER_BOARDING_REQUIREMENT_LIST_FAIL = 'MASTER_BOARDING_REQUIREMENT_LIST_FAIL';

// get master communication list 
export const MASTER_COMMUNICATION_MODE_LIST_SUCCESS = 'MASTER_COMMUNICATION_MODE_LIST_SUCCESS';
export const MASTER_COMMUNICATION_MODE_LIST_FAIL = 'MASTER_COMMUNICATION_MODE_LIST_FAIL';

// get master offered benefits 
export const MASTER_OFFERED_BENIFITS_LIST_SUCCESS = 'MASTER_OFFERED_BENIFITS_LIST_SUCCESS';
export const MASTER_OFFERED_BENIFITS_LIST_FAIL = 'MASTER_OFFERED_BENIFITS_LIST_FAIL';

// get master relocation benifits
export const MASTER_RELOCATION_BENIFITS_LIST_SUCCESS = 'MASTER_RELOCATION_BENIFITS_LIST_SUCCESS';
export const MASTER_RELOCATION_BENIFITS_LIST_FAIL = 'MASTER_RELOCATION_BENIFITS_LIST_FAIL';

// employer profile action types
export const UPLOAD_COMPANY_LOGO_SUCCESS = 'UPLOAD_COMPANY_LOGO_SUCCESS';
export const UPLOAD_COMPANY_LOGO_FAIL = 'UPLOAD_COMPANY_LOGO_FAIL';

// submit profile
export const SUBMIT_PROFILE_SUCCESS = 'SUBMIT_PROFILE_SUCCESS';
export const SUBMIT_PROFILE_FAIL = 'SUBMIT_PROFILE_FAIL';

// get employer profile
export const GET_EMPLOYER_PROFILE = 'GET_EMPLOYER_PROFILE';
export const GET_EMPLOYER_PROFILE_FAIL = 'GET_EMPLOYER_PROFILE_FAIL';

// update profile
export const UPDATE_EMPLOYER_PROFILE_SUCCESS = 'UPDATE_EMPLOYER_PROFILE_SUCCESS';
export const UPDATE_EMPLOYER_PROFILE_FAIL = 'UPDATE_EMPLOYER_PROFILE_FAIL';

// schedule interview 
export const SCHEDULE_INTERVIEW_SUCCESS = 'SCHEDULE_INTERVIEW_SUCCESS';
export const SCHEDULE_INTERVIEW_FAIL = 'SCHEDULE_INTERVIEW_FAIL'; 

// submit job detail
export const SUBMIT_JOB_DETAIL_SUCCESS = 'SUBMIT_JOB_DETAIL_SUCCESS';
export const SUBMIT_JOB_DETAIL_FAIL = 'SUBMIT_JOB_DETAIL_FAIL';

// check profile
export const CHECK_PROFILE_SUCCESS = 'CHECK_PROFILE_SUCCESS';

// master skill 
export const GET_MASTER_SKILLS = 'GET_MASTER_SKILLS';
export const GET_MASTER_SKILLS_FAIL = 'GET_MASTER_SKILLS_FAIL';

// submit skill matrix
export const SUBMIT_SKILLS_MATRIX_SUCCESS = 'SUBMIT_SKILLS_MATRIX_SUCCESS';
export const SUBMIT_SKILLS_MATRIX_FAIL = 'SUBMIT_SKILLS_MATRIX_FAIL';

// forget password
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_FAIL = 'FORGET_PASSWORD_FAIL';

// reset password
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';

// get interview questions
export const GET_INTERVIEW_QUESTIONS = 'GET_INTERVIEW_QUESTIONS';
export const GET_INTERVIEW_QUESTIONS_FAIL = 'GET_INTERVIEW_QUESTIONS_FAIL';

// submit qualifying questions
export const SUBMIT_QALIFYING_QUESTION = 'SUBMIT_QALIFYING_QUESTION';
export  const SUBMIT_QALIFYING_QUESTION_FAIL = 'SUBMIT_QALIFYING_QUESTION_FAIL';

// submit education
export const SUBMIT_EDUCATION_SUCCESS = 'SUBMIT_EDUCATION_SUCCESS';
export const SUBMIT_EDUCATION_FAIL = 'SUBMIT_EDUCATION_FAIL';

// submit interview process
export const SUBMIT_INTERVIEW_SUCCESS = 'SUBMIT_INTERVIEW_SUCCESS';
export const SUBMIT_INTERVIEW_FAIL = 'SUBMIT_INTERVIEW_FAIL';

// submit misc info
export const SUBMIT_MISC_INFO_SUCCESS = 'SUBMIT_MISC_INFO_SUCCESS';
export const SUBMIT_MISC_INFO_FAIL = 'SUBMIT_MISC_INFO_FAIL';

// submit work location
export const WORK_LOCATION_SUBMIT_SUCCESS = 'WORK_LOCATION_SUBMIT_SUCCESS';
export const WORK_LOCATION_SUBMIT_FAIL = 'WORK_LOCATION_SUBMIT_FAIL';

// submit on boarding
export const ONBOARDING_SUBMIT_SUCCESS = 'ONBOARDING_SUBMIT_SUCCESS';
export const ONBOARDING_SUBMIT_FAIL = 'ONBOARDING_SUBMIT_FAIL';

// submit personal message
export const SUBMIT_PERSONAL_MESSAGE = 'SUBMIT_PERSONAL_MESSAGE';
export const SUBMIT_PERSONAL_MESSAGE_FAIL = 'SUBMIT_PERSONAL_MESSAGE_FAIL';

// get create job preview
export const GET_CREATE_JOB_PREVIEW = 'GET_CREATE_JOB_PREVIEW';
export const GET_CREATE_JOB_PREVIEW_FAIL = 'GET_CREATE_JOB_PREVIEW_FAIL';

// save as draft
export const SAVE_AS_DRAFT_SUCCESS = 'SAVE_AS_DRAFT_SUCCESS';
export const SAVE_AS_DRAFT_FAIL = 'SAVE_AS_DRAFT_FAIL';

// publish job
export const PUBLISH_JOB_SUCCESS = 'PUBLISH_JOB_SUCCESS';
export const PUBLISH_JOB_FAIL = 'PUBLISH_JOB_FAIL'; 

export const TRASH = 'TRASH';

// delete job
export const DELETE_JOB_SUCCESS = 'DELETE_JOB_SUCCESS'; 
export const DELETE_JOB_FAIL = 'DELETE_JOB_FAIL';

// filter
export const ALL_JOB_FILTER = 'ALL_JOB_FILTER';
export const CLOSE_JOB_FILTER = 'CLOSE_JOB_FILTER';
export const OPEN_JOB_FILTER = 'OPEN_JOB_FILTER';

// update job
export const UPDATE_JOB = 'UPDATE_JOB';

// candidate pprofile
export const GET_CANDIDATE_PROFILE = 'GET_CANDIDATE_PROFILE';
export const GET_CANDIDATE_PROFILE_FAIL = 'GET_CANDIDATE_PROFILE_FAIL';

// submit profile
export const UPLOAD_CANDIDATE_FILE = 'UPLOAD_CANDIDATE_FILE';
export const UPLOAD_CANDIDATE_FILE_FAIL = 'UPLOAD_CANDIDATE_FILE_FAIL';

//apply job upload file
export const APPLY_JOB_UPLOAD_FILE = 'APPLY_JOB_UPLOAD_FILE';
export const APPLY_JOB_UPLOAD_FILE_FAIL = 'APPLY_JOB_UPLOAD_FILE_FAIL';

export const APPLY_JOB = 'APPLY_JOB';
export const APPLY_JOB_FAIL = 'APPLY_JOB_FAIL';

export const CHNAGE_STATE = 'CHNAGE_STATE';

// candidate applied jobs
export const CANDIDATE_APPLIED_JOBS = 'CANDIDATE_APPLIED_JOBS';
export const CANDIDATE_APPLIED_JOBS_FAIL = 'CANDIDATE_APPLIED_JOBS_FAIL';

// get my scheduled interviews candidate
export const GET_MY_SCHEDULED_INTERVIEWS = 'GET_MY_SCHEDULED_INTERVIEWS';
export const GET_MY_SCHEDULED_INTERVIEWS_FAIL = 'GET_MY_SCHEDULED_INTERVIEWS_FAIL';

//candidate application detail
export const GET_CANDIDATE_APPLICATION_DETAIL = 'GET_CANDIDATE_APPLICATION_DETAIL';
export const GET_CANDIDATE_APPLICATION_DETAIL_FAIL = 'GET_CANDIDATE_APPLICATION_DETAIL_FAIL';

// get questions for interview
export const GET_QUESTIONS_FOR_INTERVIEW = 'GET_QUESTIONS_FOR_INTERVIEW';
export const GET_QUESTIONS_FOR_INTERVIEW_FAIL = 'GET_QUESTIONS_FOR_INTERVIEW_FAIL';

// submit answer video
export const SUBMIT_ANSWER_VIDEO = 'SUBMIT_ANSWER_VIDEO';
export const SUBMIT_ANSWER_VIDEO_FAIL = 'SUBMIT_ANSWER_VIDEO_FAIL';

// submit candidate interview 
export const SUBMIT_CANDIDATE_INTERVIEW = 'SUBMIT_CANDIDATE_INTERVIEW';
export const SUBMIT_CANDIDATE_INTERVIEW_FAIL = 'SUBMIT_CANDIDATE_INTERVIEW_FAIL';

export const TEST = 'TEST';
export const UPDATE_APPLICATION = 'UPDATE_APPLICATION';

// candidate feedback
export const GET_CANDIDATE_FEEDBACK_SUCCESS = 'GET_CANDIDATE_FEEDBACK_SUCCESS';
export const GET_CANDIDATE_FEEDBACK_FAIL = 'GET_CANDIDATE_FEEDBACK_FAIL';

// candidate answer audio upload;
export const AUDIO_UPLOADED = 'AUDIO_UPLOADED';
export const AUDIO_UPLOADED_FAIL = 'AUDIO_UPLOADED_FAIL';

// get completed jobs
export const GET_COMPLETED_JOBS = 'GET_COMPLETED_JOBS';
export const GET_COMPLETED_JOBS_FAIL = 'GET_COMPLETED_JOBS_FAIL';

// get rejected jobs
export const GET_REJECTED_JOBS = 'GET_REJECTED_JOBS';
export const GET_REJECTED_JOBS_FAIL = 'GET_REJECTED_JOBS_FAIL';


// candidate filters
export const CANDIDATE_JOB_FILTER = 'CANDIDATE_JOB_FILTER';
export const CANDIDATE_JOB_FILTER_CLEAR = 'CANDIDATE_JOB_FILTER_CLEAR';

// candidate detail profile
export const CANDIDATE_DETAIL_PROFILE = 'CANDIDATE_DETAIL_PROFILE';
export const CANDIDATE_DETAIL_PROFILE_FAIL = 'CANDIDATE_DETAIL_PROFILE_FAIL';