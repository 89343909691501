import React from "react";
import classes from "./CandidateJobsCard.module.css";
import brifcase from "../../Assets/Images/brifcase.png";

const CandidateJobsCard = ({ textToShow, count, event }) => {
  return (
    <div
      className={classes.candidateJobsCard}
      onClick={() =>
        event(textToShow === "Applied Jobs" ? "/applied-jobs" : "/open-jobs")
      }
    >
      <div className={classes.count}>
        <div>{count}</div>
        <div>
          <img src={brifcase} />
        </div>
      </div>
      <div className={classes.textToShow}>{textToShow}</div>
    </div>
  );
};

export default CandidateJobsCard;
