import React, { useRef, useEffect } from "react";
import classes from "./Skill.module.css";

const SkillInitial = ({
  lastIndex,
  masterSkill,
  masterSkillBasedQuestions,
  addNewSkillQuestion,
}) => {
  const answerRef = useRef();
  const skillRef = useRef();
  const questionRef = useRef();

  const questionChange = (e) => {
    masterSkillBasedQuestions.map((item) => {
      if (e.target.value === item._id) {
        answerRef.current.value = item.answer;
      }
    });
  };

  const clearRef = () => {
    answerRef.current.value = "";
    skillRef.current.value = "";
    questionRef.current.value = "";
  };
  const cleanInputSkill = () => {
    document.getElementById("inputAnswer").value = "";
    document.getElementById("inputSelectQuestion").selected = true;
    document.getElementById("inputSelectSkill").selected = true;
  };

  return (
    <form
      onSubmit={(e) => {
        addNewSkillQuestion(e);
        clearRef();
      }}
      autocomplete="off"
    >
      <div className={classes.skillStrip} style={{ borderBottom: "none" }}>
        <div className={classes.index}>{lastIndex + 1}</div>
        <div className={classes.select}>
          <select required ref={skillRef}>
            <option disabled selected id="inputSelectSkill" value="">
              Select Skill
            </option>
            {masterSkill &&
              masterSkill.map((item) => {
                return <option value={item._id}>{item.value}</option>;
              })}
          </select>
        </div>
        <div className={classes.select}>
          <select
            required
            onChange={(e) => questionChange(e)}
            ref={questionRef}
          >
            <option disabled selected id="inputSelectQuestion" value="">
              Select Question
            </option>
            {masterSkillBasedQuestions &&
              masterSkillBasedQuestions.map((item) => {
                return <option value={item._id}>{item.name}</option>;
              })}
          </select>
        </div>
        <div className={classes.input}>
          <input
            type="text"
            placeholder="Enter answer"
            ref={answerRef}
            id="inputAnswer"
            minLength="5"
            // pattern="^[ A-Za-z.,']*$"
            // title="only alphabets and . ' , allowed"
            required
          />
        </div>
        <div className={classes.btnSave}>
          <span>
            <button>Add</button>&nbsp;&nbsp;&nbsp;
            <i
              class="fa fa-window-close"
              title="Clear Question"
              onClick={cleanInputSkill}
              style={{
                color: "#9675e4",
                cursor: "pointer",
                fontSize: "20px",
                verticalAlign: "middle",
              }}
              aria-hidden="true"
            ></i>
          </span>
        </div>
      </div>
    </form>
  );
};

export default SkillInitial;
