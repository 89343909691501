import React, { useEffect, useState, useMemo, useRef } from "react";
import classes from "./CandidateStartInterview.module.css";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../../common/Loader/Loader";
import RecordRTC from "recordrtc";
import { withRouter } from "react-router-dom";
import * as actions from "../../../Store/Actions/index";
import * as actionTypes from "../../../Store/Actions/ActionTypes";
import useUnsavedChanges from './useUnsavedChanges';
import Instruction from './Instruction';
import TestAudio from './TestAudio';
import InterviewComp from './InterviewComp';

var audioAnswer = [];

const CandidateStartInterview = ({ history }) => {

  const [activeComp,setActiveComp] = useState('instruction')
  const [Prompt,setDirty,setCompleted] = useUnsavedChanges();

  // useEffect(()=>{
  //   setDirty()
  // },[])


  const changeActiveComp = (compName) => {
    setActiveComp(compName)
  } 

  return (
      <div className={classes.startInterview}>
        {Prompt}
        {
          activeComp === 'instruction' &&
          <Instruction changeActiveComp={changeActiveComp}/>
        }
        
        {
          activeComp === 'test' &&
          <TestAudio changeActiveComp={changeActiveComp}/>
        }

        {
          activeComp === 'interview' &&
          <InterviewComp />
        }

      </div>
  );
};

export default withRouter(CandidateStartInterview);