import React from 'react';
import classes from './Loader.module.css';


const Loader = () => {
    return(
        <div className={classes.loader} style={{borderRadius:!localStorage.getItem("Token") && '0 0 0 0',minHeight:!localStorage.getItem("Token") && '100vh'}}>Please Wait...</div>
    )
}

export default Loader;