import React from "react";
import classes from "./Mcq.module.css";
import McqCustom from "./McqCustome";

const McqAddCustom = ({
  masterSkill,
  masterSkillBasedMcqQuestion,
  skillMcqQuestion,
  skillMcqCustomeQuestion,
  saveCustomeMcqQuestion,
  editCustomeMcqQuestion,
  removeCustomeMcqQuestion,
}) => {
  return (
    <div className={classes.mcq}>
      <div className={classes.head}>
        <div className={classes.index}>S.No.</div>
        <div>Skill</div>
        <div>Question</div>
        <div>Select Correct Answer</div>
        <div>Action</div>
      </div>
      
      {skillMcqCustomeQuestion &&
        skillMcqCustomeQuestion.length > 0 &&
        skillMcqCustomeQuestion.map((item, index) => {
          return (
            <McqCustom
              list={item}
              index={masterSkillBasedMcqQuestion.length != 0 ? index + 1 : index + 1}
              masterSkill={masterSkill}
              saveCustomeMcqQuestion={saveCustomeMcqQuestion}
              editCustomeMcqQuestion={editCustomeMcqQuestion}
              removeCustomeMcqQuestion={removeCustomeMcqQuestion}
            />
          );
        })}
      
    </div>
  );
};

export default McqAddCustom;
