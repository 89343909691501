import React from "react";
import classes from "./Skill.module.css";
import pencil from "../../../../Assets/Images/pencil.png";
import trash from "../../../../Assets/Images/trash.png";

const SkillEdit = ({
  index,
  isEdit,
  editSkillQuestion,
  removeSkillQuestion,
  data,
  masterSkill,
  masterSkillBasedQuestions,
  saveSkillQuestion,
  handleSkillQuestion,
}) => {
  const getSkill = (skillId) => {
    let skillName = "";
    masterSkill.map((skill) => {
      if (skill._id === skillId) {
        skillName = skill.value;
      }
    });

    if (skillName != "") {
      return skillName;
    } else {
      // return skillId;
      return ""
    }
  };

  const getQuestion = (questionId) => {
    let questionName = "";
    masterSkillBasedQuestions.map((question) => {
      if (question._id === questionId) {
        questionName = question.name;
      }
    });
    return questionName;
  };

  const textFormatter = (str) => {
    if (str.length > 50) {
      return `${str.substring(0, 50)}...`;
    } else {
      return str;
    }
  };
  const cleanInputSkill=()=>{
    document.getElementById("inputAnswer").value="";
    document.getElementById("inputSelectQuestion").selected=true;
    document.getElementById("inputSelectSkill").selected=true;
   }
  return (
    <form onSubmit={(e) => saveSkillQuestion(e, index)} autocomplete="off">
      <div className={classes.skillStrip}>
        <div className={classes.index}>{index + 1}</div>
        <div className={classes.select}>
          {data.isEdit && (
            <select
              name="skillId"
              required
              value={data.skillId}
              onChange={(e) => handleSkillQuestion(e, index)}
            >
              <option disabled selected id="inputSelectSkill" value="">
                Select Skill
              </option>
              {masterSkill.map((item) => {
                return <option value={item._id}>{item.value}</option>;
              })}
            </select>
          )}
          {!data.isEdit && (
            <span className={classes.text}>{getSkill(data.skillId)}</span>
          )}
        </div>
        <div className={classes.select}>
          {data.isEdit && (
            <select
              name="questionId"
              required
              value={data.questionId}
              onChange={(e) => handleSkillQuestion(e, index)}
            >
              <option disabled selected id="inputSelectQuestion" value="">
                Select Question
              </option>
              {masterSkillBasedQuestions.map((item) => {
                return (
                  <option value={item._id}>{item.name}</option>
                );
              })}
            </select>
          )}
          {!data.isEdit && (
            <span className={classes.text}>{getQuestion(data.questionId)}</span>
          )}
        </div>
        <div className={classes.input}>
          {data.isEdit && (
            <input
              name="answer"
              onChange={(e) => handleSkillQuestion(e, index)}
              type="text"
              id="inputAnswer"
              placeholder="Enter answer"
              minLength='1'
              // pattern="^[ A-Za-z.,']*$"
              // title="only alphabets and . ' , allowed"
              value={data.answer}
              required
            />
          )}

          {!data.isEdit && (
            <span className={classes.text}>{data.answer}</span>
          )}
        </div>
        <div>
          {data.isEdit && (
            <div className={classes.btnSave}>
            <span><button>Save</button>&nbsp;&nbsp;&nbsp;<i class="fa fa-window-close"
            title="Clear Question"
            onClick={cleanInputSkill}
            style={{color: "#9675e4",cursor:"pointer",fontSize: "20px",verticalAlign: "middle"}} aria-hidden="true"></i></span>
          
            </div>
          )}
          {!data.isEdit && (
            <div className={classes.edit}>
              <img src={pencil} onClick={() => editSkillQuestion(index)} />
              <img src={trash} onClick={() => removeSkillQuestion(index)} />
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

export default SkillEdit;
