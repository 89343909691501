import React, { useEffect, useState } from "react";
import classes from "./CandidateScheduleIntShort.module.css";
import TableTopStrip from "../TableTopStrip/TableTopStrip";
import Utility from "../../Utility/Utility";
import { withRouter } from "react-router-dom";
const CandidateScheduleIntShort = ({ data, event, history }) => {
  const [scheduleData, setScheduleData] = useState([]);
  const gotoInterView = () => {
    // history.push("")
  };
  useEffect(() => {
    if (data && data.length > 0) {
      let arr = data.sort(
        (a, b) =>
          new Date(b.interviewScheduleId.date).getTime() -
          new Date(a.interviewScheduleId.date).getTime()
      );
      setScheduleData(arr);
    }
  }, [data]);

  const redyForInterview = (date) => {
    let currentDate = new Date();
    let InterviewDate = new Date(date);
    if (Date.parse(date) <= Date.parse(currentDate)) {
      return true;
    } else {
      return false;
    }
  };
  const startInterview = (jobId, candidateId, applicationId) => {
    history.push("/start-interview", {
      JobId: jobId,
      CandidateId: candidateId,
      ApplicationId: applicationId,
    });
  };
  return (
    <div className={classes.candidateScheduleIntShort}>
      <TableTopStrip
        text="Upcoming Scheduled Interviews"
        clickEvent={console.log("clicked")}
      // clickEvent={() => event("/scheduled-interviews")}
      />
      {scheduleData && scheduleData.length === 0 && (
        <div className={classes.noData}>No Scheduled Interviews</div>
      )}
      {scheduleData && scheduleData.length > 0 && (
        <React.Fragment>
          <div className={classes.headStrip}>
            <div>Date</div>
            <div>Employer</div>
            <div>Position</div>
            <div>Action</div>
          </div>
          <div
            className={classes.dataBody}
            style={{ overflow: "auto", height: "185px" }}
          >


            {scheduleData.map((item) => {
              // For testing
              // item.interviewScheduleId.date = "2021-05-04"
              return (
                <div className={classes.bodyStrip}>
                  <div>{Utility.formatDate(item.interviewScheduleId.date)}</div>
                  <div>{item.employerId.userId.name}</div>
                  <div>{item.jobId ? item.jobId.role : "-"}</div>
                  <div>
                    {
                      // new Date(item.interviewScheduleId.date).toDateString() === new Date().toDateString() || new Date(item.interviewScheduleId.date) > new Date() ?
                      new Date(item.interviewScheduleId.date).toDateString() ===
                        new Date(
                          Date.now() - 3 * 24 * 60 * 60 * 1000
                        ).toDateString() ||
                        new Date(item.interviewScheduleId.date) >
                        new Date(Date.now() - 3 * 24 * 60 * 60 * 1000) ? (
                        <button
                          className={
                            redyForInterview(
                              Utility.formatDate(item.interviewScheduleId.date)
                            ) && item.applicationStatus === "SCHEDULED"
                              ? classes.enable
                              : classes.disable
                          }
                          onClick={() =>
                            redyForInterview(
                              Utility.formatDate(item.interviewScheduleId.date)
                            ) &&
                            item.applicationStatus === "SCHEDULED" &&
                            startInterview(
                              item.interviewScheduleId.appliedJobId,
                              item.candidateId,
                              item._id
                            )
                          }
                        >
                          Start
                        </button>
                      ) : // new Date(item.interviewScheduleId.date) < new Date() ? <span>Expired</span> : null
                        new Date(item.interviewScheduleId.date) <
                          new Date(Date.now() - 3 * 24 * 60 * 60 * 1000) ? (
                          <span>Expired</span>
                        ) : null
                    }
                  </div>
                </div>
              );
            })}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default withRouter(CandidateScheduleIntShort);
