import React from "react";
import classes from "./ProgressBar.module.css";

const ProgressBar = ({ percentage = 0, text }) => {
  return (
    <div
      className={classes.progressBar}
      style={{
        background: `conic-gradient(#fff calc(${percentage}*1%), rgba(255,255,255,0.5) 0%)`,
      }}
    >
      <div className={classes.progressText}>
        <span>{Math.round(percentage)}%</span>
        <span>{text}</span>
      </div>
    </div>
  );
};

export default ProgressBar;
