import React, { useEffect, useState } from "react";
import classes from "./CandidateDashboard.module.css";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../Store/Actions/index";
import * as actionTypes from "../../../Store/Actions/ActionTypes";
import CandidateJobsCard from "../../../components/CandidateJobsCard/CandidateJobsCard";
import CandidateScheduleIntShort from "../../../components/CandidateScheduleIntShort/CandidateScheduleIntShort";
import Loader from "../../../common/Loader/Loader";

const CandidateDashboard = ({ history }) => {
  let dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [toast, setToast] = useState(false);

  const { isProfileAvailable, errorMsg, dasboardDetail, loader } = useSelector(
    (state) => ({
      isProfileAvailable: state.CandidateReducer.isProfileAvailable,
      errorMsg: state.CandidateReducer.errorMsg,
      dasboardDetail: state.CommonReducer.dasboardDetail,
      loader: state.CommonReducer.loader,
    })
  );

  // const sendTo = () => {
  //   history.push("/scheduled-interviews");
  // };

  useEffect(() => {
    setIsLoading(true)
    window.scrollTo(0,0);
    dispatch(actions.getCandidateProfile());
  }, []);

  useEffect(() => {
    if (isProfileAvailable) {
      dispatch({ type: actionTypes.CLEAR });
      dispatch(actions.getDashboard());
    } else if (isProfileAvailable === false) {
      history.push("/candidate-profile");
    }
  }, [isProfileAvailable]);

  useEffect(() => {
    if (dasboardDetail) {
      setIsLoading(false);
    }
  }, [dasboardDetail]);

  const sendTo = (pageName) => {
    history.push(pageName);
  };

  useEffect(() => {
    return ()=>{
      dispatch({ type: actionTypes.TRASH });
    }
  },[])

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <div className={classes.candidateDashboard}>
        <div className={classes.wrapper}>
          <div className={classes.left}>
            
            <CandidateJobsCard
              count={dasboardDetail && dasboardDetail.openJobs}
              textToShow="Total Open Jobs"
              event={sendTo}
            />
            <CandidateJobsCard
              count={dasboardDetail && dasboardDetail.appliedjobs}
              textToShow="Applied Jobs"
              event={sendTo}
            />
          </div>
          <div className={classes.right}>
            <CandidateScheduleIntShort data={dasboardDetail && dasboardDetail.scheduledJobsList} event={sendTo} />
          </div>
        </div>
      </div>
    );
  }
};

export default CandidateDashboard;
