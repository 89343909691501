import React from "react";
import classes from "./AboutUs.module.css";
import heroImage from "../../Assets/Images/heroImage.png";
import { withRouter } from "react-router-dom";
import playBtn from "../../Assets/Images/playbtn.png";
import postIcon from "../../Assets/Images/postIcon.png";
import post from "../../Assets/Images/post.png";
import one from "../../Assets/Images/1.png";
import two from "../../Assets/Images/2.png";
import three from "../../Assets/Images/3.png";
import british from "../../Assets/Images/british.png";
import domino from "../../Assets/Images/domino.png";
import dunkin from "../../Assets/Images/dounkin.png";
import oyo from "../../Assets/Images/oyo.png";
import pay from "../../Assets/Images/pay.png";
import pinlab from "../../Assets/Images/pinlab.png";
import mediatek from "../../Assets/Images/media.png";
import summer from "../../Assets/Images/summer.png";

const LandingPage = (props) => {
  return (
    <>
      <div className={classes.hero}>
        <div className={classes.left}>
          <p>
            ConectD is a hiring intelligence platform which automates the job posting, talent assessment and interviewing process.  ConectD helps improve how companies find, engage and assess talent remotely through video interviews and artificial intelligence.
          </p>
          {/* <p>Get connected with candidates who are committed to your process</p>
          <button onClick={() => props.history.push("/login")}>
            Request Demo
          </button> */}
        </div>
        <div className={classes.right}>
          <img src={heroImage} alt="hero-img" />
          <img src={playBtn} alt="play-btn" />
        </div>
      </div>
    </>
  );
};

export default withRouter(LandingPage);
