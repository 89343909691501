import React from "react";
import { withRouter } from "react-router-dom";
// import PropTypes from 'prop-types';

/**
 * A replacement component for the react-router `Prompt`.
 * Allows for more flexible dialogs.
 *
 * @example
 * <NavigationPrompt when={this.props.isDirty}>
 *   {(isOpen, onConfirm, onCancel) => (
 *     <Modal show={isOpen}>
 *       <div>
 *         <p>Do you really want to leave?</p>
 *         <button onClick={onCancel}>Cancel</button>
 *         <button onClick={onConfirm}>Ok</button>
 *       </div>
 *     </Modal>
 *   )}
 * </NavigationPrompt>
 */
class NavigationPrompt extends React.Component {
  constructor(props) {
    super(props);
    this.state = { nextLocation: null, openModal: false, isLeave: false };
    this.onCancel = this.onCancel.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
  }

  componentDidMount() {
    window.addEventListener("blur", () => {
      if (this.props.when) {
        this.props.pauseInterview();
        this.setState({
          openModal: true,
          isLeave: true,
          nextLocation: { pathname: "/candidate-dashboard" },
        });
      }
    });
    this.unblock = this.props.history.block((nextLocation) => {
      this.props.pauseInterview();
      if (this.props.when) {
        this.setState({
          openModal: true,
          nextLocation: nextLocation,
        });
      }
      return !this.props.when;
    });
  }

  componentWillUnmount() {
    this.unblock();
  }
  componentWillUpdate() {
    if (this.state.openModal === true) {
      localStorage.setItem("pauseInterviewVal", true);
      this.props.pauseInterview();
    } else {
      localStorage.setItem("pauseInterviewVal", false);
    }
  }

  onCancel() {
    localStorage.setItem("pauseInterviewVal", false);
    this.setState({ nextLocation: null, openModal: false });
    this.props.pauseInterview();
  }

  async onConfirm() {
    await this.props.submit();
    this.navigateToNextLocation();
  }

  navigateToNextLocation() {
    this.unblock();
    this.props.history.push(this.state.nextLocation.pathname);
    window.location.reload();
  }

  render() {
    return (
      <div>
        {this.props.children(
          this.state.openModal,
          this.onConfirm,
          this.onCancel,
          this.state.isLeave
        )}
      </div>
    );
  }
}

// NavigationPrompt.propTypes = {
//   when: PropTypes.bool.isRequired,
//   children: PropTypes.func.isRequired,
// };

export default withRouter(NavigationPrompt);
