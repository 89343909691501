import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import HighOrderComponent from "./common/HOC/HighOrderComponent";
import { loadReCaptcha } from "react-recaptcha-google";
import Utility from "./Utility/Utility";
import { Route, Redirect, Switch } from "react-router-dom";
import { withRouter } from "react-router-dom";
// all common pages
import LandingPage from "./Pages/landingPage/landingPage";
import Login from "./Pages/Login/Login";
import Register from "./Pages/Register/Register";
import VerifyEmail from "./Pages/VerifyEmail/VerifyEmail";
import ResetPassword from "./Pages/ResetPassword/ResetPassword";
// importing all employer pages
import EmployerDashboard from "./Pages/Employer/EmployerDashboard/EmployerDashboard";
import EmployerProfile from "./Pages/Employer/EmployerProfile/EmployerProfile";
import Jobs from "./Pages/Employer/Jobs/Jobs";
import DraftedJobs from "./Pages/Employer/DraftedJobs/DraftedJobs";
import DeletedJobs from "./Pages/Employer/DeletedJobs/DeletedJobs";
import ApplicationReceived from "./Pages/Employer/ApplicationReceived/ApplicationReceived";
import InterviewScheduled from "./Pages/Employer/InterviewScheduled/InterviewScheduled";
import CreateJob from "./Pages/Employer/CreateJob/CreateJob";
import JobDescription from "./components/JobDescriptionCard/MobileView/JobDescription";
import UpdateJob from "./Pages/Employer/UpdateJob/UpdateJob";
import CompleatedJobs from "./Pages/Employer/CompleatedJobs/CompleatedJobs";
import CandidateDetailProfile from "./Pages/Employer/CandidateDetailProfile/CandidateDetailProfile";
// importing all candidate pages
import CandidateDashboard from "./Pages/Candidate/CandidateDashboard/CandidateDashboard";
import CandidateProfile from "./Pages/Candidate/CandidateProfile/CandidateProfile";
import OpenJobs from "./Pages/Candidate/OpenJobs/OpenJobs";
import AppliedJobs from "./Pages/Candidate/AppliedJobs/AppliedJobs";
import ScheduledInterviews from "./Pages/Candidate/ScheduledInterviews/ScheduledInterviews";
import Feedback from "./Pages/Candidate/Feedback/Feedback";
import StartInterview from "./Pages/Candidate/StartInterview/StartInterview";
import CandidateStartInterview from "./Pages/Candidate/CandidateStartInterview/CandidateStartInterview";
import AboutUs from "./Pages/AboutUs/AboutUs";
import ContactUs from "./Pages/ContactUs/ContactUs";
import Support from "./Pages/Support/Support"

const App = ({ history }) => {
  const [allPreviousRoute, setAllPrevioueRoute] = useState([]);
  // let allPreviousRoute1=[]
  const history1 = useHistory();
  const parseJwt = Utility.parseJwt;
  let route = "";


  if (
    localStorage.getItem("Token") &&
    parseJwt(localStorage.getItem("Token")).roleType === "employer"
  ) {
    route = (
      <Switch>
        <Route path="/employer-dashboard" exact component={EmployerDashboard} />
        <Route path="/employer-profile" exact component={EmployerProfile} />
        <Route path="/employer-jobs" exact component={Jobs} />
        <Route
          path="/interview-scheduled"
          exact
          component={InterviewScheduled}
        />
        <Route
          path="/application-received"
          exact
          component={ApplicationReceived}
        />
        <Route path="/deleted-jobs" exact component={DeletedJobs} />
        <Route path="/drafted-jobs" exact component={DraftedJobs} />
        <Route path="/create-job" exact component={CreateJob} />
        <Route path="/job-description" exact component={JobDescription} />
        <Route path="/update-job" exact component={UpdateJob} />
        <Route path="/feedback" component={Feedback} />
        <Route path="/completed-interviews" component={CompleatedJobs} />
        <Route
          path="/candidateDetailProfile"
          component={CandidateDetailProfile}
        />
        <Redirect to="/employer-dashboard" />
      </Switch>
    );
  } else if (
    localStorage.getItem("Token") &&
    parseJwt(localStorage.getItem("Token")).roleType === "candidate"
  ) {
    route = (
      <Switch>
        <Route
          path="/candidate-dashboard"
          exact
          component={CandidateDashboard}
        />
        <Route path="/candidate-profile" exact component={CandidateProfile} />
        <Route path="/open-jobs" exact component={OpenJobs} />
        <Route path="/job-description" exact component={JobDescription} />
        <Route path="/applied-jobs" component={AppliedJobs} />
        <Route path="/scheduled-interviews" component={ScheduledInterviews} />
        <Route path="/start-interview" component={StartInterview} />
        <Route path="/feedback" component={Feedback} />
        <Redirect to="/candidate-dashboard" />
      </Switch>
    );
  } else {
    route = (
      <Switch>
        <Route path="/" exact component={LandingPage} />
        <Route path="/AboutUs" exact component={AboutUs} />
        <Route path="/ContactUs" exact component={ContactUs} />
        <Route path="/Support" exact component={Support} />
        <Route path="/login" exact component={Login} />
        <Route path="/register" exact component={Register} />
        <Route path="/verifyEmail" component={VerifyEmail} />
        <Route path="/resetPassword" component={ResetPassword} />
        <Route path="/feedback" component={Feedback} />
        <Redirect to="/" />
      </Switch>
    );
  }

  useEffect(() => {
    loadReCaptcha();
  }, []);
  return (
    <>
      <HighOrderComponent>{route}</HighOrderComponent>
    </>
  );
};

export default withRouter(App);
