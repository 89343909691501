import React, { useState } from "react";
import classes from "./SkillMatrix.module.css";
import Utility from "../../../Utility/DataGenerator";
import SearchSuggestions from "./SearchSuggestion";

const SkillStrip = ({
  lastIndex,
  addSkillMatrix,
  handleNewMatrix,
  newMatrix,
  addNewValue,
  masterSkill,
  clearError
}) => {
  const [suggestion, setSuggestion] = useState([]);

  const filteredSuggestion = (e) => {
    if (masterSkill) {
      if (e.target.value !== "") {
        setSuggestion(
          masterSkill.filter((item) => {
            if (
              item.value.toLowerCase().includes(e.target.value.toLowerCase())
            ) {
              return item;
            }
          })
        );
      } else {
        setSuggestion([]);
      }
    }
  };
  const cleanInputSkill=(e)=>{
    document.getElementById("inputSkill").value="";
    document.getElementById("inputMinExp").value="0 month";   
    document.getElementById("skillReq1").checked=false
    document.getElementById("skillReq2").checked=false
}
  return (
    <form onSubmit={(e) => addSkillMatrix(e)}>
      <div className={classes.skillStrip} style={{borderBottom:'none'}}>
        <div className={classes.index}>{lastIndex}</div>
        <div style={{ position: "relative" }}>
          <input
            type="text"
            name="value"
            autoComplete="off"
            value={newMatrix.value ? newMatrix.value : ""}
            onChange={(e) => {
              handleNewMatrix(e);
              filteredSuggestion(e);
              clearError();
            }}
            pattern="^[ A-Za-z.#+2]*$"
            title="Only allowed alphabets and . # + 2"
            placeholder="Type your skill"
            required
            id="inputSkill"
          />
          {suggestion.length > 0 && (
            <SearchSuggestions suggestion={suggestion} addNewValue={addNewValue} clearSuggestion={()=>setSuggestion([])}/>
          )}
        </div>
        <div  className={classes.radio}>
        <input
            type="radio"
            name="preference"
            required
            checked={newMatrix.preference === "Required" && true}
            onChange={(e) => handleNewMatrix(e)}
            value="Required"
            id="skillReq1"
          />
          Required&nbsp;
          <input
            type="radio"
            name="preference"
            required
            checked={newMatrix.preference === "Preferred" && true}
            onChange={(e) => handleNewMatrix(e)}
            value="Preferred"
            id="skillReq2"
          />
          Preferred 
        </div>
        <div className={classes.select}>
          <div>
            <select
              name="minExperience"
              required
              onChange={(e) => handleNewMatrix(e)}
              value={newMatrix.minExperience ? newMatrix.minExperience : ""}
              id="inputMinExp"
            >
              {/* <option selected value="0">
                0
              </option> */}
              <option value='0 month'>0 Month</option>
              <option value='6 months'>6 Months</option>
              <option value='1 Year'>1 Year</option>
              <option value='2 Years'>2 Years</option>
              <option value='3 Years'>3 Years</option>
              <option value='4 Years'>4 Years</option>
              <option value='5 Years'>5 Years</option>
              <option value='6 Years'>6 Years</option>
              <option value='7 Years'>7 Years</option>
              <option value='8 Years'>8 Years</option>
              <option value='9 Years'>9 Years</option>
              <option value='10 Years & above'>10 Years & Above</option>
              {/* {Utility.yearOptions.map((item,index) => {
                  return <option value={item.value}>{item.name}</option>;
              })} */}
            </select>
          </div>
          {/* &nbsp; */}
          {/* <div style={{color:'#fff'}}>to</div> */}
          {/* &nbsp; */}
          {/* <div> */}
            {/* <select
              required
              name="maxExperience"
              onChange={(e) => handleNewMatrix(e)}
              value={newMatrix.maxExperience ? newMatrix.maxExperience : ""}
            >
              <option selected value="0">
                0
              </option>
              {Utility.mothOptions.map((item,index) => {
                  return <option value={item.value}>{item.name}</option>;
              })}
            </select> */}
          {/* </div> */}
        </div>
        <div >
          <button type="submit" style={{width:"70px",marginRight:"15px"}}>Add</button>
         
            <i class="fa fa-window-close"
              title="Clear Question"
              onClick={cleanInputSkill}
              style={{color: "#9675e4",cursor:"pointer",fontSize: "20px",verticalAlign: "middle"}} aria-hidden="true"></i>
            
        </div>
      </div>
    </form>
  );
};

export default SkillStrip;
