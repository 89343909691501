import React from "react";
import classes from "./JobProgressMobile.module.css";
import miscInfo from "../../../Assets/Images/miscInfo.png";
import interviewProcess from "../../../Assets/Images/interviewProcess.png";
import workLocation from "../../../Assets/Images/workLocation.png";
import onBoarding from "../../../Assets/Images/onboarding.png";
import personalMessage from "../../../Assets/Images/personalMessage.png";

const SectionTwo = ({activeIndex = 0}) => {
  return (
    <div className={classes.progressMobileSecTwo}>
      <div className={`${classes.secTwoElement} ${activeIndex >= 5 && classes.bar}`}>
        <div className={`${classes.elementImg} ${activeIndex >= 4 && classes.active}`}>
          <img src={miscInfo} />
        </div>
        <div className={classes.elementText}>Miscellaneous Information</div>
      </div>
      <div className={`${classes.secTwoElement} ${activeIndex >= 6 && classes.bar}`}>
        <div className={`${classes.elementImg} ${activeIndex >= 5 && classes.active}`}>
          <img src={interviewProcess} />
        </div>
        <div className={classes.elementText}>Interview Process</div>
      </div>
      <div className={`${classes.secTwoElement} ${activeIndex >= 7 && classes.bar}`}>
        <div className={`${classes.elementImg} ${activeIndex >= 6 && classes.active}`}>
          <img src={workLocation} />
        </div>
        <div className={classes.elementText}>Work Location</div>
      </div>
      <div className={`${classes.secTwoElement} ${activeIndex >= 8 && classes.bar}`}>
        <div className={`${classes.elementImg} ${activeIndex >= 7 && classes.active}`}>
          <img src={onBoarding} />
        </div>
        <div className={classes.elementText}>Onboarding</div>
      </div>
      <div className={`${classes.secTwoElement} ${activeIndex >= 9 && classes.bar}`}>
        <div className={`${classes.elementImg} ${activeIndex >= 8 && classes.active}`}>
          <img src={personalMessage} />
        </div>
        <div className={classes.elementText}>Personal Message</div>
      </div>
    </div>
  );
};

export default SectionTwo;
