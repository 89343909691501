import React, { useState, useEffect } from "react";
import classes from "./VerifyEmail.module.css";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import * as actions from "../../Store/Actions/index";
import * as actionTypes from "../../Store/Actions/ActionTypes";
import Toast from "../../common/Toast/Toast";

const VerifyEmail = (props) => {
  const [isloading, setIsLoading] = useState(false);

  const { errorMsg, emailVerified } = useSelector((state) => ({
    errorMsg: state.AuthReducer.errorMsg,
    emailVerified: state.AuthReducer.emailVerified,
  }));

  let [toast, setToast] = useState(false);

  const dispatch = useDispatch();

  const urlToken = props.history.location.search.split("=")[1];

  useEffect(() => {
    setIsLoading(true)
    dispatch(actions.verifyEmail(urlToken));
  }, []);

  useEffect(() =>{
    if(emailVerified){
      setIsLoading(false)
    }
  },[emailVerified])

  useEffect(() => {
    if (errorMsg) {
      setIsLoading(false)
      setToast(
        true,
        setTimeout(() => {
          setToast(false);
        }, 3000)
      );
    }
  }, [errorMsg]);

  const goToLogin = () => {
    dispatch({ type: actionTypes.CLEAR });
    props.history.push("/login");
  };

  const goToSignUp = () => {
    dispatch({ type: actionTypes.CLEAR });
    props.history.push("/register");
  };

  if(!urlToken){
    return <Redirect to='/register' />
  }else{
    return (
      <div className={classes.verify}>
        <div className={classes.verify}>
          {!isloading && emailVerified && (
            <div className={classes.verifiedSuccess}>
              <div className={classes.text}>
                Welcome to ConectD. Your account has been activated. Click below
                Button to Login.
              </div>
              <div className={classes.btn}>
                <button onClick={goToLogin}>Go to login</button>
              </div>
            </div>
          )}
          {isloading && "Please Wait..."}
          {!isloading && errorMsg && (
            <div className={classes.verifiedSuccess}>
              <div className={classes.text}>{errorMsg}</div>
              <div className={classes.btn}>
                <button onClick={goToSignUp}>Go to Signup</button>
              </div>
            </div>
          )}
          <Toast display={toast} type="danger" message={errorMsg} />
        </div>
      </div>
    );
  }
  
};

export default withRouter(VerifyEmail);
