import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../Store/Actions/index";
import * as actionTypes from "../../../Store/Actions/ActionTypes";
import classes from "./Questions.module.css";
import Personal from "./Personal/Personal";
import Skill from "./Skill/Skill";
import Toast from "../../../common/Toast/Toast";
import PersonalAddCustom from "../Questions/Personal/PersonalAddCustom";
import SkillAddPersonal from "../Questions/Skill/SkillAddCustom";
import ErrorAlert from "../../../common/ErrorAlert/ErrorAlert";
import Mcq from "./MCQ/Mcq";
import McqAddCustom from "./MCQ/McqAddCustom";
import NormalAlert from "../../../common/NormalAlert/NormalAlert";

const Questions = ({
  questionActiveTab,
  handleTabChange,
  personalQuestions,
  personalCustomeQuestions,
  skillQuestion,
  skillCustomeQuestion,
  removePersonal,
  editPersonal,
  savePersonal,
  handlePersonalChange,
  addNewPersonal,
  addToCustome,
  addCustomizedQuestion,
  editCustomeQuestion,
  removeCustomeQuestion,
  skillMatrix,
  addNewSkillQuestion,
  removeSkillQuestion,
  editSkillQuestion,
  saveSkillQuestion,
  handleSkillQuestion,
  addCustomeSkillQuestion,
  editCustomeSkillQuestion,
  removeCustomeSkillQuestion,
  setNextPage,
  setPreviousPage,
  modifyCustomQuestions,
  addNewMcqQuestion,
  skillMcqQuestion,
  editSkillMcqQuestion,
  removeSkillMcqQuestion,
  submitEditMcq,
  skillMcqCustomeQuestion,
  saveCustomeMcqQuestion,
  editCustomeMcqQuestion,
  removeCustomeMcqQuestion,
}) => {
  let dispatch = useDispatch();

  const {
    masterPersonalQuestions,
    masterSkillBasedQuestions,
    qualifyingQuestionSubmited,
    masterSkillBasedMcqQuestion,
    errorMsg,
    refJobId,
  } = useSelector((state) => ({
    masterPersonalQuestions: state.MasterReducer.masterPersonalQuestions,
    masterSkillBasedQuestions: state.MasterReducer.masterSkillBasedQuestions,
    masterSkillBasedMcqQuestion:
      state.MasterReducer.masterSkillBasedMcqQuestion,
    qualifyingQuestionSubmited:
      state.CreateJobReducer.qualifyingQuestionSubmited,
    errorMsg: state.CreateJobReducer.errorMsg,
    refJobId: state.CreateJobReducer.refJobId,
  }));

  const [skill, setSkill] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [subLoading, setSubLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [questionError, setQuestionError] = useState("");
  const [isTab, setIsTab] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (skillMatrix && skillMatrix.length > 0) {
      let copy = [...skillMatrix].map((item) => {
        return { ...item };
      });
      let skillToSet = copy.map((skill) => {
        delete skill.preference;
        delete skill.minExperience;
        delete skill.maxExperience;
        return skill;
      });
      setSkill(skillToSet);
    }
  }, []);

  useMemo(() => {
    // console.log('skillMatrix1',skillMatrix)
    if (skill.length > 0) {
      let url = "";
      skill.map((item, i) => {
        if (i === 0) {
          url = url + `skill${i}=${item._id}`;
        } else {
          url = url + "&&" + `skill${i}=${item._id}`;
        }
      });
      setIsLoading(true);
      dispatch(actions.getInterviewQuestions(url));
    }
  }, [skill]);

  useEffect(() => {
    // console.log('skillMatrix2',skillMatrix)
    if (
      masterSkillBasedQuestions &&
      masterPersonalQuestions &&
      masterSkillBasedMcqQuestion
    ) {
      setIsLoading(false);
    }
  }, [
    masterPersonalQuestions,
    masterSkillBasedQuestions,
    masterSkillBasedMcqQuestion,
  ]);

  const submitQualifyingQuestion = () => {
    dispatch({ type: actionTypes.CLEAR });
    setQuestionError("");

    if (
      personalQuestions.length === 0 &&
      personalCustomeQuestions.length === 0
    ) {
      setQuestionError("Atleast one personal question is required");
      setIsTab("personal");
      setShowAlert(true);
    } else if (
      skillQuestion.length === 0 &&
      skillCustomeQuestion.filter((item) => {
        if (item.question !== "") {
          return item;
        }
      }).length === 0
    ) {
      // handleTabChange("skill");
      //  if (questionActiveTab === "skill") {
      setQuestionError("Atleast one skill question is required");
      setIsTab("skill");
      setShowAlert(true);
      //}
    } else if (
      skillMcqQuestion.length === 0 &&
      skillMcqCustomeQuestion.length === 0
    ) {
      setQuestionError("Atleast one MCQ question is required");
      setIsTab("mcq");
      setShowAlert(true);
    } else {
      let personal;
      let personalCustome;
      if (personalQuestions) {
        personal = personalQuestions.map((item) => {
          delete item.isEdit;
          return item;
        });
      }
      if (personalCustomeQuestions) {
        personalCustome = personalCustomeQuestions.map((item) => {
          delete item.isEdit;
          return item;
        });
        personalCustome = personalCustome.filter((item) => {
          if (item.question !== "") {
            return item;
          }
        });
      }

      let skill = skillQuestion.map((item) => {
        delete item.isEdit;
        return item;
      });
      let skillCustome = skillCustomeQuestion.map((item) => {
        delete item.isEdit;
        return item;
      });

      skillCustome = skillCustome.filter((item) => {
        if (item.question !== "") {
          return item;
        }
      });

      let skilMcq = skillMcqQuestion.map((item) => {
        delete item.isEdit;
        return item;
      });

      let skillMcqCustome = skillMcqCustomeQuestion.map((item) => {
        delete item.isEdit;
        return item;
      });

      skillMcqCustome = skillMcqCustome.filter((item) => {
        if (item.question !== "") {
          return item;
        }
      });

      modifyCustomQuestions(personalCustome, skillCustome, skillMcqCustome);
      setSubLoading(true);
      // console.log('checking initial data',skillMcqCustomeQuestion);
      // console.log('after edit',skillMcqCustome);
      dispatch(
        actions.submitQualifyingQuestions({
          refJobId: refJobId,
          personalQuestions: personal,
          personalCustomeQuestions: personalCustome,
          skillBasedQuestion: skill,
          skillBasedCustomeQuestion: skillCustome,
          skilMcq: skilMcq,
          skillMcqCustome: skillMcqCustome,
        })
      );
    }
  };
  const confirmAlert = () => {
    setShowAlert(false);
    handleTabChange(isTab);
  };

  useEffect(() => {
    if (qualifyingQuestionSubmited) {
      setSubLoading(false);
      dispatch({ type: actionTypes.CLEAR });
      setNextPage("education");
    } else if (errorMsg) {
      setAlert(true);
      setSubLoading(false);
      // setToast(
      //   true,
      //   setTimeout(() => {
      //     setToast(false);
      //   }, 3000)
      // );
    }
  }, [errorMsg, qualifyingQuestionSubmited]);

  const clearError = () => {
    setQuestionError("");
  };

  const addPersonalCustomeQuestion = (e, index) => {
    e.preventDefault();
    if (
      e.target[0].value.match(/^[a-zA-Z]+$/) !== null ||
      e.target[0].value.match(/^[ A-Za-z?]*$/) !== null
    ) {
      setQuestionError("");
      addToCustome(e, index);
    } else {
      setQuestionError("Invalid question");
    }
  };

  const addSkillCustomeQuestion = (e, index) => {
    e.preventDefault();
    if (e.target[1].value.match(/^[ A-Za-z?]*$/) === null) {
      setQuestionError("Invalid question");
    } else if (e.target[2].value.match(/^[ A-Za-z.]*$/) === null) {
      setQuestionError("Invalid answer");
    } else {
      setQuestionError("");
      addCustomeSkillQuestion(e, index);
    }
  };

  const addToNewSkillQuestion = (e) => {
    e.preventDefault();
    if (e.target[2].value.match(/^[ A-Za-z.]*$/) !== null) {
      setQuestionError("");
      addNewSkillQuestion(e);
    } else {
      setQuestionError("Invalid answer");
    }
  };

  const saveToSkillQuestion = (e, index) => {
    e.preventDefault();
    if (e.target[2].value.match(/^[ A-Za-z.]*$/) !== null) {
      setQuestionError("");
      saveSkillQuestion(e, index);
    } else {
      setQuestionError("Invalid answer");
    }
  };

  const confirm = () => {
    setAlert(false);
  };

  if (isLoading) {
    return (
      <div className={classes.isLoading}>
        Please wait while we are picking questions...
      </div>
    );
  } else if (subLoading) {
    return <div className={classes.isLoading}>Please wait...</div>;
  } else {
    return (
      <React.Fragment>
        <div className={classes.note}>
          <span>Note:- </span>Please don't forget to add your questions before
          clicking on the next button.
        </div>
        <div className={classes.question}>
          {
            alert && errorMsg && (
              <ErrorAlert show={true} msg={errorMsg} confirm={confirm} />
            )
            // <Toast display={toast} type="danger" message={errorMsg} />
          }

          {showAlert == true ? (
            <NormalAlert
              show={true}
              msg={questionError}
              confirm={confirmAlert}
            />
          ) : null}

          <div className={classes.questionTop}>
            <div>Qualifying Questions</div>
            {/* <div onClick={addCustomizedQuestion}>Add Customized Question</div> */}
          </div>
          <div className={classes.questionTab}>
            <div
              className={classes.questionTabOne}
              onClick={() => handleTabChange("personal")}
            >
              Personal
              {questionActiveTab === "personal" && <div></div>}
            </div>
            <div
              className={classes.questionTabTwo}
              onClick={() => handleTabChange("skill")}
            >
              Skills
              {questionActiveTab === "skill" && <div></div>}
            </div>
            <div
              className={classes.questionTabTwo}
              onClick={() => handleTabChange("mcq")}
            >
              MCQ
              {questionActiveTab === "mcq" && <div></div>}
            </div>
          </div>
          <div className={classes.divider}></div>
          <div className={classes.lebelText}>
            Select a question from our list
          </div>
          {questionActiveTab === "personal" && (
            <Personal
              personalQuestions={personalQuestions}
              personalCustomeQuestions={personalCustomeQuestions}
              editPersonal={editPersonal}
              removePersonal={removePersonal}
              savePersonal={savePersonal}
              handlePersonalChange={handlePersonalChange}
              addNewPersonal={addNewPersonal}
              addToCustome={addToCustome}
              addCustomizedQuestion={addCustomizedQuestion}
              editCustomeQuestion={editCustomeQuestion}
              removeCustomeQuestion={removeCustomeQuestion}
              masterPersonalQuestions={masterPersonalQuestions}
              clearError={clearError}
            />
          )}
          {questionActiveTab === "skill" && (
            <Skill
              skillQuestion={skillQuestion}
              skillCustomeQuestion={skillCustomeQuestion}
              masterSkill={skill}
              masterSkillBasedQuestions={masterSkillBasedQuestions}
              addNewSkillQuestion={addNewSkillQuestion}
              removeSkillQuestion={removeSkillQuestion}
              editSkillQuestion={editSkillQuestion}
              saveSkillQuestion={saveSkillQuestion}
              handleSkillQuestion={handleSkillQuestion}
              addCustomeSkillQuestion={addCustomeSkillQuestion}
              editCustomeSkillQuestion={editCustomeSkillQuestion}
              removeCustomeSkillQuestion={removeCustomeSkillQuestion}
            />
          )}
          {questionActiveTab === "mcq" && (
            <Mcq
              masterSkill={skill}
              masterSkillBasedMcqQuestion={masterSkillBasedMcqQuestion}
              addNewMcqQuestion={addNewMcqQuestion}
              skillMcqQuestion={skillMcqQuestion}
              editSkillMcqQuestion={editSkillMcqQuestion}
              removeSkillMcqQuestion={removeSkillMcqQuestion}
              submitEditMcq={submitEditMcq}
              skillMcqCustomeQuestion={skillMcqCustomeQuestion}
              saveCustomeMcqQuestion={saveCustomeMcqQuestion}
              editCustomeMcqQuestion={editCustomeMcqQuestion}
              removeCustomeMcqQuestion={removeCustomeMcqQuestion}
            />
          )}

          {/* <div className={classes.error}>{questionError}</div> */}
          <div
            style={{
              width: "100%",
              borderTop: "2px solid lightgrey",
              paddingTop: "7px",
            }}
          >
            <div className={classes.AddCostomQuestion}>
              <div>Custom Question</div>
              {/* {questionActiveTab === "mcq" ? */}
              <div onClick={addCustomizedQuestion}>Add Item</div>
              {/* :null} */}
            </div>

            {questionActiveTab === "personal" && (
              <PersonalAddCustom
                personalQuestions={personalQuestions}
                personalCustomeQuestions={personalCustomeQuestions}
                editPersonal={editPersonal}
                removePersonal={removePersonal}
                savePersonal={savePersonal}
                handlePersonalChange={handlePersonalChange}
                addNewPersonal={addNewPersonal}
                addToCustome={addToCustome}
                addCustomizedQuestion={addCustomizedQuestion}
                editCustomeQuestion={editCustomeQuestion}
                removeCustomeQuestion={removeCustomeQuestion}
                masterPersonalQuestions={masterPersonalQuestions}
                clearError={clearError}
              />
            )}

            {questionActiveTab === "skill" && (
              <SkillAddPersonal
                skillQuestion={skillQuestion}
                skillCustomeQuestion={skillCustomeQuestion}
                masterSkill={skill}
                masterSkillBasedQuestions={masterSkillBasedQuestions}
                addNewSkillQuestion={addNewSkillQuestion}
                removeSkillQuestion={removeSkillQuestion}
                editSkillQuestion={editSkillQuestion}
                saveSkillQuestion={saveSkillQuestion}
                handleSkillQuestion={handleSkillQuestion}
                addCustomeSkillQuestion={addCustomeSkillQuestion}
                editCustomeSkillQuestion={editCustomeSkillQuestion}
                removeCustomeSkillQuestion={removeCustomeSkillQuestion}
              />
            )}

            {questionActiveTab === "mcq" && (
              <McqAddCustom
                masterSkill={skill}
                masterSkillBasedMcqQuestion={masterSkillBasedMcqQuestion}
                addNewMcqQuestion={addNewMcqQuestion}
                skillMcqQuestion={skillMcqQuestion}
                editSkillMcqQuestion={editSkillMcqQuestion}
                removeSkillMcqQuestion={removeSkillMcqQuestion}
                submitEditMcq={submitEditMcq}
                skillMcqCustomeQuestion={skillMcqCustomeQuestion}
                saveCustomeMcqQuestion={saveCustomeMcqQuestion}
                editCustomeMcqQuestion={editCustomeMcqQuestion}
                removeCustomeMcqQuestion={removeCustomeMcqQuestion}
              />
            )}
          </div>
        </div>
        <div className={classes.skillFooter}>
          {questionActiveTab === "personal" && (
            <>
              <button onClick={() => setPreviousPage("skillMatrix")}>
                Back
              </button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <button onClick={() => handleTabChange("skill")}>Next</button>
            </>
          )}

          {questionActiveTab === "skill" && (
            <>
              <button onClick={() => handleTabChange("personal")}>Back</button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <button onClick={() => handleTabChange("mcq")}>Next</button>
            </>
          )}

          {questionActiveTab === "mcq" && (
            <>
              <button onClick={() => handleTabChange("skill")}>Back</button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <button onClick={submitQualifyingQuestion}>Next</button>
            </>
          )}
        </div>
      </React.Fragment>
    );
  }
};

export default Questions;
