import React from 'react';
import classes from './ModalWrapper.module.css';


const ModalWrapper = ({children}) => {
    return (
        <div className={classes.modalWrapper}>
            {children}
        </div>
    )
}

export default ModalWrapper;