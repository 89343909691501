import React from "react";
import classes from "./landingPage.module.css";
import heroImage from "../../Assets/Images/heroImage.png";
import { withRouter } from "react-router-dom";
import playBtn from "../../Assets/Images/playbtn.png";
import postIcon from "../../Assets/Images/postIcon.png";
import post from "../../Assets/Images/post.png";
import one from "../../Assets/Images/1.png";
import two from "../../Assets/Images/2.png";
import three from "../../Assets/Images/3.png";
import british from "../../Assets/Images/british.png";
import domino from "../../Assets/Images/domino.png";
import dunkin from "../../Assets/Images/dounkin.png";
import oyo from "../../Assets/Images/oyo.png";
import pay from "../../Assets/Images/pay.png";
import pinlab from "../../Assets/Images/pinlab.png";
import mediatek from "../../Assets/Images/media.png";
import summer from "../../Assets/Images/summer.png";

const LandingPage = (props) => {
  return (
    <>
      <div className={classes.hero}>
        <div className={classes.left}>
          <p>The Future of Hiring Top Talent in Less Time</p>
          <p>
            ConectD is a hiring intelligence platform which automates the job
            posting, talent assessment and interviewing process. ConectD helps
            improve how companies find, engage and assess talent remotely
            through video interviews and artificial intelligence.{" "}
          </p>
          <p>
            Hiring managers determine the interview questions and ConectD
            captures, transcribes and analyzes the candidate’s responses –
            providing a consistent and measurable interview experience which
            facilitates the decision-making process and improves productivity.{" "}
          </p>
          <button
            onClick={() => props.history.push("/login")}
            style={{ display: "none" }}
          >
            Request Demo
          </button>
        </div>
        <div className={classes.right}>
          <img src={heroImage} alt="hero-img" />
          <img src={playBtn} alt="play-btn" />
        </div>
      </div>
      <div className={classes.provide}>
        <div className={classes.provideTitle}>
          What We Provide? &nbsp;&nbsp; <div></div>
        </div>
        <div className={classes.provideBox}>
          <div className={classes.provideBoxItem}>
            <img src={post} className={classes.withoutHover} />
            <img src={postIcon} className={classes.withHover} />
            <p>Post A Job</p>
            <p>Hire Skilled people, best of them</p>
            <p>Most efficient and transparent way to find a great talent</p>
            <button onClick={() => props.history.push("/login")}>
              Let's Get Started
            </button>
          </div>
          <div className={classes.provideBoxItem}>
            <img src={post} className={classes.withoutHover} />
            <img src={postIcon} className={classes.withHover} />
            <p>Find A Job</p>
            <p>Easiest way to find your job</p>
            <p>Smartest way to find your dream job</p>
            <button onClick={() => props.history.push("/login")}>
              Let's Get Started
            </button>
          </div>
        </div>
      </div>
      <div className={classes.choose}>
        <div className={classes.chooseTitle}>
          Why Choose Us? &nbsp;&nbsp;<div></div>
        </div>
        <div className={classes.item}>
          <div>
            <img src={one} />
            <p>Record Responses</p>
            <p>
              Allow candidate to reply back at their own convenience from device
              of their choice
            </p>
          </div>
          <div>
            <img src={two} />
            <p>Review & Share</p>
            <p>
              Collaborate with others for evaluating candidates and share
              recordings for review
            </p>
          </div>
          <div>
            <img src={three} />
            <p>Evaluate Candidate</p>
            <p>Structure candidate evaluation with configurable templates</p>
          </div>
        </div>
      </div>
      {/* <div className={classes.trusted}>
        <div className={classes.trustTitle}>
          <div></div>&nbsp;&nbsp;&nbsp;
          <div>Trusted by esteemed businesses</div>&nbsp;&nbsp;&nbsp;
          <div></div>
        </div>
        <div className={classes.companies}>
          <div>
            <img src={british} />
          </div>
          <div>
            <img src={mediatek} />
          </div>
          <div>
            <img src={domino} />
          </div>
          <div>
            <img src={oyo} />
          </div>
          <div>
            <img src={pay} />
          </div>
          <div>
            <img src={pinlab} />
          </div>
          <div>
            <img src={dunkin} />
          </div>
          <div>
            <img src={summer} />
          </div>
        </div>
      </div> */}
    </>
  );
};

export default withRouter(LandingPage);
