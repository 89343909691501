import React,{useRef} from "react";
import classes from "./Personal.module.css";


const PersonalInitial = ({ lastIndex, addNewPersonal,masterPersonalQuestion,clearError }) => {

  const questionRef = useRef()

  const clearValue = () => {
    questionRef.current.value = ''
  }
  const clearQuestion=()=>{
    document.getElementById("selectQ").selected=true
  }
  return (
    <form onSubmit={(e) => {addNewPersonal(e);clearValue();clearError()}} autocomplete="off">
      <div className={classes.strip} style={{borderBottom:'none'}}>
        <div className={classes.index}>{lastIndex + 1}</div>
        <div className={classes.select}>
          <select name='question' required ref={questionRef}>
            <option selected disabled id="selectQ" value="">
              Select Questions
            </option>
            {masterPersonalQuestion && masterPersonalQuestion.map((item) => {
              return <option value={item._id}>{item.name}</option>;
            })}
          </select>
        </div>
        <div className={classes.btnSave}>
          <span><button>Add</button>&nbsp;&nbsp;&nbsp;<i class="fa fa-window-close"
          title="Clear Question"
          onClick={clearQuestion}
          style={{color: "#9675e4",cursor:"pointer",fontSize: "20px",verticalAlign: "middle"}} aria-hidden="true"></i></span>
        </div>
      </div>
    </form>
  );
};

export default PersonalInitial;
