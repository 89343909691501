import React, { useRef, useState, useEffect } from "react";
import classes from "./InterviewProcess.module.css";
import * as actions from "../../../Store/Actions/index";
import * as actionTypes from "../../../Store/Actions/ActionTypes";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../../common/Loader/Loader";
import Toast from "../../../common/Toast/Toast";
import ErrorAlert from "../../../common/ErrorAlert/ErrorAlert";

const InterviewProcess = ({
  handleInterviewProcess,
  interviewProcess,
  setNextPage,
  setPreviousPage,
  setCommunicationMode,
  clearCommunicationMode,
  modeofCommunication,
  handleCandidateTip,
  candidateTip,
}) => {
  const checkBoxRef = useRef();

  let dispatch = useDispatch();

  const {
    masterCommunicationModeList,
    refJobId,
    interviewProcessSubmitted,
    errorMsg,
  } = useSelector((state) => ({
    masterCommunicationModeList:
      state.MasterReducer.masterCommunicationModeList,
    refJobId: state.CreateJobReducer.refJobId,
    interviewProcessSubmitted: state.CreateJobReducer.interviewProcessSubmitted,
    errorMsg: state.CreateJobReducer.errorMsg,
  }));
  // if(modeofCommunication){
  modeofCommunication.First = "AI Interview";
  // }

  const [otherActive, setOtherActive] = useState({
    First: false,
    Second: false,
    Third: false,
    Fourth: false,
    Fifth: false,
    Sixth: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState(false);

  // useEffect(() => {
  //   window.scrollTo(0,0);
  //   if (interviewProcess.otherCommunicationMode) {
  //     checkBoxRef.current.checked = true;
  //     setOtherActive(true);
  //   } else {
  //     checkBoxRef.current.checked = false;
  //     setOtherActive(false);
  //   }
  // }, []);

  const handleCheckBox = (e) => {
    // if (checkBoxRef.current.checked) {
    //   clearCommunicationMode(true);
    //   setOtherActive(true);
    // } else if(!checkBoxRef.current.checked){
    //   clearCommunicationMode(false);
    //   setOtherActive(false);
    // }
    // else {
    //   setOtherActive(false);
    // }
    clearCommunicationMode(e);
    setOtherActive({
      ...otherActive,
      [e.target.name]: !otherActive[e.target.name],
    });
  };

  const submitInterviewProcess = (e) => {
    e.preventDefault();
    dispatch({ type: actionTypes.CLEAR });
    let finalCommunicationMode = masterCommunicationModeList.filter((item) => {
      if (item.name === interviewProcess.communicationMode) {
        return item;
      }
    });
    setIsLoading(true);
    Object.assign({ First: "AI Interview" }, modeofCommunication);
    // modeofCommunication.First = "AI Interview"
    dispatch(
      actions.submitInterview({
        jobId: refJobId,
        communicationMode: modeofCommunication,
        // otherCommunicationMode: checkBoxRef.current.checked
        //   ? interviewProcess.otherCommunicationMode
        //   : "",
        rounds: interviewProcess.rounds,
        tips: candidateTip,
        // tipText: interviewProcess.tipText,
      })
    );
  };

  useEffect(() => {
    if (interviewProcessSubmitted) {
      setIsLoading(false);
      dispatch({ type: actionTypes.CLEAR });
      if (localStorage.getItem("wfhRef") !== "selected") {
        setNextPage("workLocation");
      } else {
        setNextPage("onBoarding");
      }
    } else if (errorMsg) {
      setIsLoading(false);
      setAlert(true);
      // setToast(
      //   true,
      //   setTimeout(() => {
      //     setToast(false);
      //   }, 3000)
      // );
    }
  }, [interviewProcessSubmitted, errorMsg]);

  const confirm = () => {
    setAlert(false);
  };

  let roundName = ["First", "Second", "Third", "Fourth", "Fifth", "Sixth"];
  let roundCount = ["a", "b", "c", "d", "e", "f"];
  let list = [];
  for (let i = 0; i < interviewProcess.rounds; i++) {
    if (i !== 0) {
      list.push(
        <div style={{ padding: "10px 17px" }}>
          <div style={{ fontWeight: 600 }}>Round {i + 1} : </div>
          <div className={classes.item}>
            {masterCommunicationModeList &&
              masterCommunicationModeList.map((item) => {
                return (
                  <div>
                    <input
                      type="radio"
                      name={roundName[i]}
                      value={item.name}
                      required={
                        // checkBoxRef.current && checkBoxRef.current.checked
                        document.getElementById(roundName[i]) &&
                        document.getElementById(roundName[i]).checked
                          ? false
                          : true
                      }
                      checked={
                        Object.keys(modeofCommunication).length != 0 &&
                        modeofCommunication[roundName[i]] == item.name
                      }
                      onChange={
                        // checkBoxRef.current &&
                        // !checkBoxRef.current.checked &&
                        document.getElementById(roundName[i]) == null
                          ? setCommunicationMode
                          : !document.getElementById(roundName[i]).checked &&
                            setCommunicationMode
                      }
                    />
                    {item.name}
                  </div>
                );
              })}
          </div>
          <div className={classes.otherMode}>
            <div>
              <input
                type="checkbox"
                name={roundName[i]}
                ref={checkBoxRef}
                id={roundName[i]}
                checked={otherActive[roundName[i]]}
                onChange={handleCheckBox}
              />
              Others (Specify here)
            </div>
            {otherActive[roundName[i]] && (
              <div>
                <input
                  type="text"
                  name={roundName[i]}
                  placeholder="Type mode of communication"
                  autoComplete="off"
                  minLength="1"
                  pattern="^[a-zA-Z ]+$"
                  title="Only alphabets allowed"
                  required={
                    // checkBoxRef.current &&
                    // checkBoxRef.current.checked &&
                    document.getElementById(roundName[i]) &&
                    document.getElementById(roundName[i]).checked &&
                    true
                  }
                  defaultValue={modeofCommunication[roundName[i]]}
                  onChange={setCommunicationMode}
                />
              </div>
            )}
          </div>
        </div>
      );
    }
  }

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <div className={classes.interviewProcess}>
        <form onSubmit={submitInterviewProcess}>
          <div className={classes.note}>
            {
              alert && errorMsg && (
                <ErrorAlert show={true} msg={errorMsg} confirm={confirm} />
              )
              // <Toast display={toast} type="danger" message={errorMsg} />
            }
            <span>Note:- </span>Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore dolore.
          </div>
          <div className={classes.InterviewProcessBox}>
            <div className={classes.rounds}>
              <div>1. How many rounds of interview?</div>
              <div className={classes.roundItem}>
                <div>
                  <input
                    type="radio"
                    name="rounds"
                    value="1"
                    required
                    defaultChecked={interviewProcess.rounds === "1" && true}
                    onChange={handleInterviewProcess}
                  />
                  1
                </div>
                <div>
                  <input
                    type="radio"
                    name="rounds"
                    value="2"
                    required
                    defaultChecked={interviewProcess.rounds === "2" && true}
                    onChange={handleInterviewProcess}
                  />
                  2
                </div>
                <div>
                  <input
                    type="radio"
                    name="rounds"
                    value="3"
                    required
                    defaultChecked={interviewProcess.rounds === "3" && true}
                    onChange={handleInterviewProcess}
                  />
                  3
                </div>
                <div>
                  <input
                    type="radio"
                    name="rounds"
                    value="4"
                    required
                    defaultChecked={interviewProcess.rounds === "4" && true}
                    onChange={handleInterviewProcess}
                  />
                  4
                </div>
                <div>
                  <input
                    type="radio"
                    name="rounds"
                    value="5"
                    required
                    defaultChecked={interviewProcess.rounds === "5" && true}
                    onChange={handleInterviewProcess}
                  />
                  5
                </div>
                <div>
                  <input
                    type="radio"
                    name="rounds"
                    value="6"
                    required
                    defaultChecked={interviewProcess.rounds === "6" && true}
                    onChange={handleInterviewProcess}
                  />
                  6
                </div>
              </div>
            </div>
            <div className={classes.boxItem}>
              <div className={classes.title}>
                2. Select a mode of communication for candidate’s interview
              </div>
              <div
                style={{
                  paddingLeft: "16px",
                  paddingTop: "8px",
                  fontWeight: 600,
                }}
              >
                Round 1 :{" "}
                <input
                  type="radio"
                  name={roundName[0]}
                  value="AI Interview"
                  required={true}
                  checked={true}
                />
                <span style={{ fontWeight: "100" }}>AI Interview</span>
              </div>

              {list.map((ind) => ind)}
            </div>

            <div className={classes.tip}>
              <div className={classes.tipTitle}>
                <div>
                  3. Would you like to share any tips for candidates to be
                  successful in the interview process?
                </div>
                <div className={classes.tipInput}>
                  <div>
                    <input
                      type="radio"
                      name="tip"
                      value="true"
                      required
                      checked={candidateTip.tip === "true" && true}
                      onChange={handleCandidateTip}
                    />
                    Yes
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="tip"
                      value="false"
                      required
                      checked={candidateTip.tip === "false" && true}
                      onChange={handleCandidateTip}
                    />
                    No
                  </div>
                </div>
              </div>
              {candidateTip.tip === "true" && (
                <div className={classes.inputBox}>
                  <textarea
                    required={candidateTip.tip === "true" && true}
                    name="tipText"
                    value={candidateTip.tipText}
                    onChange={handleCandidateTip}
                  />
                </div>
              )}
            </div>
          </div>
          <div className={classes.skillFooter}>
            <div onClick={() => setPreviousPage("miscInfo")}>Back</div>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <button>Next</button>
          </div>
        </form>
      </div>
    );
  }
};

export default InterviewProcess;
