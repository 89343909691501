import React, { useState, useEffect } from "react";
import classes from "./CandidateDetailProfile.module.css";
import { withRouter } from "react-router-dom";
import Loader from "../../../common/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../Store/Actions/index";

const CandidateDetailProfile = ({ history }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const { candidateDetailProfile } = useSelector((state) => ({
    candidateDetailProfile: state.CandidateReducer.candidateDetailProfile,
  }));

  useEffect(() => {
    if (history.location.search) {
      setIsLoading(true);
      dispatch(
        actions.candidateDetailProfile(history.location.search.split("=")[1])
      );
    }
  }, []);

  useEffect(() => {
    if (candidateDetailProfile) {
      setIsLoading(false);
    }
  }, [candidateDetailProfile]);

  if (isLoading) {
    return <Loader />;
  } else if (
    candidateDetailProfile &&
    Object.keys(candidateDetailProfile).length > 0
  ) {
    return (
      <div className={classes.candidateProfile}>
        <div className={classes.candidateTop}>
          <div className={classes.candidateTitle}>Candidate Profile</div>
          <div className={classes.profileData}>
            <div className={classes.title}>Name</div>
            <div>{candidateDetailProfile.candidateName}</div>
          </div>
          <div className={classes.profileData}>
            <div className={classes.title}>Contact No.</div>
            <div>{candidateDetailProfile.number}</div>
          </div>
          <div className={classes.profileData}>
            <div className={classes.title}>EmailId</div>
            <div>{candidateDetailProfile.email}</div>
          </div>
          <div className={classes.profileData}>
            <div className={classes.title}>Address</div>
            <div>{candidateDetailProfile.address}</div>
          </div>
          <div className={classes.profileData}>
            <div className={classes.title}>Education</div>
            <div>{candidateDetailProfile.education.name}</div>
          </div>
          <div className={classes.profileData}>
            <div className={classes.title}>Skill</div>
            <div>
              {candidateDetailProfile.skill.map((item) => {
                return <div>{item.name}</div>;
              })}
            </div>
          </div>
          <div className={classes.profileData}>
            <div className={classes.title}>Experience</div>
            <div>{candidateDetailProfile.experience}</div>
          </div>
          <div className={classes.profileData}>
            <div className={classes.title}>Linkedin Profile</div>
            <div>
              {candidateDetailProfile.linkedinProfile != "" &&
                candidateDetailProfile.linkedinProfile != null && (
                  <a
                    href={candidateDetailProfile.linkedinProfile}
                    target="_blank"
                  >
                    View Profile
                  </a>
                )}
              {(candidateDetailProfile.linkedinProfile === "" ||
                candidateDetailProfile.linkedinProfile === null) &&
                "Profile not provided"}
            </div>
          </div>
          <div className={classes.profileData}>
            <div className={classes.title}>Portfolio</div>
            <div>
              {candidateDetailProfile.portfolio != "" &&
                candidateDetailProfile.portfolio != null && (
                  <a href={candidateDetailProfile.portfolio} target="_blank">
                    View Profile
                  </a>
                )}
              {(candidateDetailProfile.portfolio === "" ||
                candidateDetailProfile.portfolio === null) &&
                "Portfolio not provided"}
            </div>
          </div>
          <div className={classes.profileData}>
            <div className={classes.title}>Resume</div>
            <div>
              <a href={candidateDetailProfile.resume} target="_blank">
                View Resume
              </a>
            </div>
          </div>
          <div className={classes.profileData}>
            <div className={classes.title}>Cover Letter</div>
            <div>
              {candidateDetailProfile.coverLetter && (
                <a href={candidateDetailProfile.coverLetter} target="_blank">
                  View Cover Letter
                </a>
              )}
              {!candidateDetailProfile.coverLetter &&
                "Cover letter not provided"}
            </div>
          </div>
          {/* <div className={classes.profileData}>
            <div className={classes.title}>Certificate</div>
            <div>
              {
                candidateDetailProfile.certification &&
                <a href={candidateDetailProfile.certification} target="_blank">
                  View Certificate
                </a>
              }
              {
                !candidateDetailProfile.certification &&
                "Certificate not provided"
              }
            </div>
          </div> */}
        </div>
      </div>
    );
  } else {
    // return <div className={classes.errMsgShow}>Somthing Went Worng</div>;
    return <Loader />;
  }
};

export default withRouter(CandidateDetailProfile);
