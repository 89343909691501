import React from "react";
import classes from "./Footer.module.css";
import { NavLink } from "react-router-dom";
import facebook from "../../Assets/Images/facebook.png";
import twitter from "../../Assets/Images/twitter.png";
import googlePlus from "../../Assets/Images/googlePlus.png";
import linkedIn from "../../Assets/Images/linkedIn.png";
import logo from "../../Assets/Images/logo.png";

const Footer = (props) => {
  return (
    <div className={classes.footer}>
      <div className={classes.wrapper}>
        <div className={classes.left}>
          <img src={logo} alt="logo" />
          <p>
            ConectD is a hiring intelligence platform which automates the job
            posting, talent assessment and interviewing process. ConectD helps
            improve how companies find, engage and assess talent remotely
            through video interviews and artificial intelligence.
          </p>
        </div>
        <div className={classes.right}>
          <div>
            <NavLink
              exact
              to="/"
              activeStyle={{
                padding: "5px",
                borderBottom: "2px solid #fff",
                color: "#fff",
                outline: "none",
                cursor: "pointer",
                width: "120px",
                fontSize: "14px",
              }}
            >
              Home
            </NavLink>
          </div>
          <div>
            <NavLink
              exact
              to="/AboutUs"
              activeStyle={{
                padding: "5px",
                borderBottom: "2px solid #fff",
                color: "#fff",
                outline: "none",
                cursor: "pointer",
                width: "75px",
                fontSize: "14px",
              }}
            >
              About Us
            </NavLink>
          </div>
          <div>Terms & Conditions</div>
          <div>Policy</div>
          <div>Sitemap</div>
        </div>
      </div>
      {/* <div className={classes.socialMedia}>
        <img src={twitter} alt="twitter" />
        <img src={facebook} alt="facebook" />
        <img src={linkedIn} alt="linkedIn" />
        <img src={googlePlus} alt="googlePlus" />
      </div> */}
      {/* <div className={classes.divider}></div>
      <div className={classes.copyright}>conectD © 2017</div> */}
    </div>
  );
};

export default Footer;
