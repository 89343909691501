import React, { useEffect, useState, useRef } from "react";
import classes from "../StartInterview.module.css";
import useSpeechToText from "react-hook-speech-to-text";
import { useSpeechSynthesis } from "react-speech-kit";

const SkillBased = ({ skillQuestion, changeHandler, isLast }) => {
  const [audioText, setAudioText] = useState([]);
  const { speak } = useSpeechSynthesis();
  const [counter, setCounter] = React.useState(120);
  const [countDown1, setCountDown1] = useState(null);
  const [disable, setDisable] = useState(false)
  const [firstQuesRead, setFirstQuesRead] = useState(false);

  useEffect(() => {
    !firstQuesRead && speak({ text: skillQuestion.name });
    if (counter == 0) {
      nextHandler();
    }
    setTimeout(() => {
      setFirstQuesRead(true);
    }, 2000);
    const countDown =
      counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    setCountDown1(countDown);
  }, [counter]);

  const {
    // error,
    interimResult,
    isRecording,
    results,
    startSpeechToText,
    stopSpeechToText,
  } = useSpeechToText({
    continuous: true,
    crossBrowser: true,
    googleApiKey: "AIzaSyAdcVWVfzB8YS9XDJFW2BIOR5WWef9wfaA",
    speechRecognitionProperties: { interimResults: true },
    useLegacyResults: false,
  });


  useEffect(() => {
    setAudioText([]);
    speak({ text: skillQuestion.name });
  }, []);

  useEffect(() => {
    speak({ text: skillQuestion.name });
  }, [skillQuestion]);


  const nextHandler = () => {
    setDisable(true)
    setTimeout(() => {
      if (isRecording) {
        speechToTextHandler();
      }
      const answer = Array.from(document.querySelectorAll(".testing li"))
        .map((el) => el.innerHTML)
        .join("");

      const endResult = {
        question: skillQuestion.name,
        answer,
      };
      changeHandler(endResult);
      if (isLast) {
        // stopVideoRecording();
      }
      setDisable(false)
    }, 2000);
  };

  console.log({ disable })
  const speechToTextHandler = () => {
    if (isRecording) {
      stopSpeechToText();
    } else {
      startSpeechToText();
    }
  };

  return (
    <>
      <div
        className={classes.maxTimer}
        style={{
          color: counter < 10 && "red",
          border: counter < 10 && "2px solid red",
        }}
      >
        <div>
          <i
            class="fa fa-clock-o"
            aria-hidden="true"
            style={{ color: "yellow", fontSize: "2em" }}
          ></i>
        </div>
        <div> Time Remaining</div>
        <div>{counter}</div>
      </div>

      <div className={classes.videoShow}>
        <div className={classes.testInterviewWrapper}>
          <div className={classes.question}>{skillQuestion.name}</div>

          <div
            // className={classes.submitBtn}
            style={{
              marginBottom: "5vh",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button className={classes.submitBtn2} onClick={speechToTextHandler}>
              <span>{isRecording ? "Stop Recording" : "Start Recording"}</span>
            </button>
            <ul className="testing" style={{ position: "absolute", color: "white", zIndex: "-1" }} >
              {results.map((result) => (
                <li key={result.timestamp}>{result.transcript}</li>
              ))}
            </ul>
            <button className={disable ? classes.submitBtn1 : classes.submitBtn2} disabled={disable} onClick={nextHandler}>Next</button>
            {/* */}
            {/* {error ?
              <span>Please stop the recording first, then move to the next question.</span>
              : ""} */}
            <br />
          </div>
        </div>
      </div>
    </>
  );
};

export default SkillBased;
