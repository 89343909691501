import React, { useState, useEffect, useRef, useMemo } from "react";
import classes from "./CandidateProfile.module.css";
import upload from "../../../Assets/Images/upload.png";
import cv from "../../../Assets/Images/cv.png";
import coverLetterImg from "../../../Assets/Images/certificate.png";
import DataGenerator from "../../../Utility/DataGenerator";
import * as actions from "../../../Store/Actions/index";
import * as actionTypes from "../../../Store/Actions/ActionTypes";
import { useSelector, useDispatch } from "react-redux";
import SearchSuggestion from "../../../components/CreateJobComp/SkillMatrix/SearchSuggestion";
import Loader from "../../../common/Loader/Loader";
import Toast from "../../../common/Toast/Toast";
import SuccessAlert from "../../../common/SuccessAlert/SuccessAlert";
import ErrorAlert from "../../../common/ErrorAlert/ErrorAlert";
import NormalAlert from "../../../common/NormalAlert/NormalAlert";
import $ from "jquery";

const CandidateProfile = ({ history }) => {
  let dispatch = useDispatch();
  const resumeRef = useRef();
  const logoRef = useRef();
  const coverLetterRef = useRef();
  const certificateRef = useRef();
  const skillRef = useRef();
  const [alert, setAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [candidateResume, setCandidateResume] = useState();
  const [candidateResumeObject, setCandidateResumeObject] = useState();
  const [profilePic, setProfilePic] = useState();
  const [profilePicObject, setProfilePicObject] = useState();
  const [coverLetter, setCoverLetter] = useState();
  const [coverLetterObject, setCoverLetterObject] = useState();
  const [certificate, setCertificate] = useState();
  const [certificateObject, setCertificateObject] = useState();
  const [formData, setFormData] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [chipData, setChipData] = useState([]);
  const [suggestion, setSuggestion] = useState([]);
  const [error, setError] = useState({});
  const [isShowCertificate, setIsShowCertificate] = useState(1);
  const [allCertificate, setAllCertificate] = useState([]);
  const [certificateShow, setCertificateShow] = useState([]);
  const [validMsg, setValidMsg] = useState("");

  const addressRef = useRef("");
  const countryRef = useRef("");
  const stateRef = useRef("");
  const cityRef = useRef("");
  const zipcodeRef = useRef("");
  const educationRef = useRef("");
  const expYRRef = useRef("");
  const expMonRef = useRef("");
  const termCheckRef = useRef("");

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const {
    masterCountryList,
    masterStateList,
    masterCityList,
    masterSkill,
    masterEducationList,
    candidateProfile,
    isProfileAvailable,
    fileUploaded,
    fileUrl,
    errorMsg,
    profileSubmitted,
    msg,
  } = useSelector((state) => ({
    masterCountryList: state.MasterReducer.masterCountryList,
    masterStateList: state.MasterReducer.masterStateList,
    masterCityList: state.MasterReducer.masterCityList,
    masterSkill: state.MasterReducer.masterSkill,
    masterEducationList: state.MasterReducer.masterEducationList,
    candidateProfile: state.CandidateReducer.candidateProfile,
    isProfileAvailable: state.CandidateReducer.isProfileAvailable,
    fileUploaded: state.CandidateReducer.fileUploaded,
    fileUrl: state.CandidateReducer.fileUrl,
    errorMsg: state.CandidateReducer.errorMsg,
    profileSubmitted: state.CandidateReducer.profileSubmitted,
    msg: state.CandidateReducer.msg,
  }));

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsLoading(true);
    dispatch(actions.getMasterCountryList());
    dispatch(actions.getMasterStateList());
    dispatch(actions.getMasterCityList());
    dispatch(actions.getMasterSkills());
    dispatch(actions.getMasterEducationList());
    dispatch(actions.getCandidateProfile());
  }, []);

  useEffect(() => {
    return () => {
      dispatch({ type: actionTypes.TRASH });
    };
  }, []);

  useMemo(() => {
    if (candidateProfile && !isProfileAvailable) {
      setIsLoading(false);
      setFormData({ ...candidateProfile.profileDetail });
    } else if (candidateProfile && isProfileAvailable) {
      setProfilePicObject("");
      setCandidateResumeObject("");
      setCoverLetterObject("");
      setCertificateObject("");
      setIsLoading(false);
      let detail = candidateProfile.profileDetail;
      let url = candidateProfile.filesUrl;
      setFormData({
        ...candidateProfile.profileDetail,
        countryId: detail.countryId._id,
        cityId: detail.cityId._id,
        stateId: detail.stateId._id,
        educationId: detail.educationId._id,
      });
      setIsUpdate(true);
      setChipData([...detail.skill]);
      setProfilePic(url[0]);
      setCandidateResume(url[1]);
      if (url[2]) {
        setCoverLetter(url[2]);
      } else {
        setCoverLetter("");
      }
      if (url[3]) {
        setCertificate(url[3]);
      } else {
        setCertificate("");
      }
    }
  }, [candidateProfile]);

  const handleResume = (e) => {
    if (e.target.files[0].size > 1024 * 1024 * 5) {
      alert("Size must be less than or equal to 5MB");
      resumeRef.current.value = "";
    }
    if (
      e.target.files[0].name.includes(".doc") &&
      !e.target.files[0].name.includes(".docx")
    ) {
      // window.alert("Only Pdf, Word file, Plain text, Docx file allowed");
      // window.alert("Only Pdf, Docx file allowed");
      setValidMsg("Only Pdf, Docx file are allowed");
    } else if (
      e.target.files[0].type === "application/pdf" ||
      e.target.files[0].type === "application/msword" ||
      e.target.files[0].type === "text/plain" ||
      e.target.files[0].type === ""
    ) {
      setCandidateResumeObject(e.target.files[0]);
      setCandidateResume(e.target.files[0]);
    } else if (e.target.files[0].name.includes(".docx")) {
      setCandidateResumeObject(e.target.files[0]);
      setCandidateResume(e.target.files[0]);
    } else {
      // window.alert("Only Pdf, Docx file allowed");
      setValidMsg("Only Pdf, Docx file are allowed");
    }
  };

  const handleBlur = () => {
    skillRef.current.value = "";
  };

  const handleLogoUpload = (e) => {
    if (e.target.files[0].size > 1024 * 1024 * 5) {
      alert("Size must be less than or equal to 5MB");
      logoRef.current.value = "";
    } else {
      let reader = new FileReader();
      setProfilePicObject(e.target.files[0]);
      reader.onload = (e) => {
        setProfilePic(e.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleCoverLetterUpload = (e) => {
    if (e.target.files[0].size > 1024 * 1024 * 5) {
      alert("Size must be less than or equal to 5MB");
      // logoRef.current.value = "";
      coverLetterRef.current.value = "";
    } else if (
      e.target.files[0].type != "application/pdf" &&
      !e.target.files[0].name.includes(".docx")
    ) {
      // logoRef.current.value = "";
      coverLetterRef.current.value = "";
      setCoverLetterObject("");
      setCoverLetter("");
      window.alert("Only Pdf, Docx file are allowed");
    } else {
      let reader = new FileReader();
      setCoverLetterObject(e.target.files[0]);
      reader.onload = (e) => {
        setCoverLetter(e.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const handleCertificateUpload = (e) => {
    if (e.target.files[0].size > 1024 * 1024 * 5) {
      alert("Size must be less than or equal to 5MB");
      logoRef.current.value = "";
    } else if (
      e.target.files[0].type === "image/png" ||
      e.target.files[0].type === "image/jpg" ||
      e.target.files[0].type === "image/jpeg"
    ) {
      let reader = new FileReader();
      setCertificateObject(e.target.files[0]);
      reader.onload = (e) => {
        setCertificate(e.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    } else {
      window.alert("Only png, jpg, jpeg files allowed");
    }
  };
  function useOutsideAlerter(ref) {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        if (skillRef.current.value !== "") {
          let name = skillRef.current.value;
          let arr = [...chipData];
          arr.push({ id: Math.random().toString(36).substring(7), name: name });
          setChipData(arr);
        }
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // }, [ref]);
  }
  const enterPressed = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      if (skillRef.current.value !== "") {
        let name = skillRef.current.value;
        let arr = [...chipData];
        arr.push({ id: Math.random().toString(36).substring(7), name: name });
        setChipData(arr);
      }
      skillRef.current.value = "";
    }
  };

  const onFileChange = (e) => {
    if (certificateShow.length <= 10 && allCertificate.length <= 10) {
      let img = e.target.files;
      if (
        img[0].type === "image/png" ||
        img[0].type === "image/jpg" ||
        img[0].type === "image/jpeg"
      ) {
        let reader = new FileReader();
        setAllCertificate([...allCertificate, ...img]);
        reader.onload = (e) => {
          setCertificateShow([...certificateShow, e.target.result]);
        };
        reader.readAsDataURL(img[0]);
        // setAllCertificate([...allCertificate,...img])
      } else if (img[0].size > 1024 * 1024 * 5) {
        window.alert("Size must be less than or equal to 5MB");
      } else {
        setAllCertificate([...allCertificate, ""]);
        window.alert("Only png, jpg, jpeg files allowed");
      }
    } else {
      window.alert("You can upload maximum 10 certificates");
    }
  };

  const removeResume = () => {
    setCandidateResume("");
    if (resumeRef.current) {
      resumeRef.current.value = "";
    }
  };

  const clearImage = () => {
    if (logoRef.current) {
      logoRef.current.value = "";
    }
    setProfilePic("");
    setProfilePicObject("");
  };

  const removeCoverLetter = () => {
    if (coverLetterRef.current) {
      coverLetterRef.current.value = "";
    }
    setCoverLetter("");
    setCoverLetterObject("");
  };

  const removeCertificate = () => {
    if (certificateRef.current) {
      certificateRef.current.value = "";
    }
    setCertificate("");
    setCertificateObject("");
  };

  const removeCert = (index) => {
    let arr = allCertificate;
    let arr2 = certificateShow;
    arr.splice(index, 1);
    arr2.splice(index, 1);
    setAllCertificate([...allCertificate, arr]);
    setCertificateShow([...certificateShow, arr2]);
  };

  let chips = chipData.map((item, i) => {
    return (
      <div className={classes.chips} key={i}>
        <span>{item.name}</span>
        <i className="fa fa-times" onClick={() => removeChip(i)}></i>
      </div>
    );
  });

  const onKeyDownEvent = (e) => {
    if (e.which === 8 && !e.target.value) {
      setChipData(
        chipData.filter((item, i) => {
          if (i != chipData.length - 1) {
            return item;
          }
        })
      );
    }
  };

  const removeChip = (index) => {
    setChipData(
      chipData.filter((item, i) => {
        if (i != index) {
          return item;
        }
      })
    );
  };

  const filteredSuggestion = (e) => {
    if (masterSkill) {
      if (e.target.value !== "") {
        setSuggestion(
          masterSkill.filter((item) => {
            if (
              item.value.toLowerCase().includes(e.target.value.toLowerCase())
            ) {
              return item;
            }
          })
        );
      } else {
        setSuggestion([]);
      }
    } else {
      console.log("Else");
    }
  };

  const addNewValue = (e, index, value, id) => {
    if (skillRef.current) {
      skillRef.current.value = "";
    }
    setChipData([...chipData, { id: id, name: value }]);
  };

  const handleFieldChanges = (e) => {
    if (e.target.name === "countryId") {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        stateId: "",
        cityId: "",
      });
    } else if (e.target.name === "stateId") {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        cityId: "",
      });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  // functions for making images in formdata format
  const getImages = () => {
    let profilePic = new FormData();
    let resume = new FormData();
    let coverLetter = new FormData();
    let certificate = new FormData();
    profilePic.append("productImage", profilePicObject);
    resume.append("productImage", candidateResume);
    coverLetter.append("productImage", coverLetterObject);
    certificate.append("productImage", certificateObject);
    return {
      profilePic,
      resume,
      coverLetter,
      certificate,
    };
  };
  const confirmAlert = () => {
    setValidMsg("");
  };

  const submitCandidateProfile = (e) => {
    e.preventDefault();
    if (validate()) {
      setIsLoading(true);
      let docs = [];
      let profilePic;
      let resume;
      let coverLetter;
      let certificate;
      if (profilePicObject) {
        profilePic = new FormData();
        profilePic.append("productImage", profilePicObject);
        docs.push({ profilePic: profilePic });
      }
      if (candidateResumeObject) {
        resume = new FormData();
        resume.append("productImage", candidateResumeObject);
        docs.push({ resume: resume });
      }
      if (coverLetterObject) {
        coverLetter = new FormData();
        coverLetter.append("productImage", coverLetterObject);
        docs.push({ coverLetter: coverLetter });
      }
      if (certificateObject) {
        certificate = new FormData();
        certificate.append("productImage", certificateObject);
        docs.push({ certificate: certificate });
      }
      setIsLoading(true);
      dispatch(actions.uploadDocs(docs));
    } else {
      window.scrollTo(0, 1);
    }
  };

  const validate = () => {
    let { zipCode, name, mobile } = formData;
    let status = true;
    let errorLocal = {};
    if (!profilePic) {
      status = false;
      errorLocal = {
        ...errorLocal,
        ["profileImg"]: "Profile image cannot be empty",
      };
    }
    if (!candidateResume) {
      status = false;
      errorLocal = { ...errorLocal, ["resume"]: "Resume cannot be empty" };
    }
    if (name === "") {
      status = false;
      errorLocal = { ...errorLocal, ["name"]: "Name cannot be empty" };
    }
    if (mobile === "") {
      status = false;
      errorLocal = { ...errorLocal, ["mobile"]: "Contact No cannot be empty" };
    }
    if (isNaN(mobile)) {
      status = false;
      errorLocal = { ...errorLocal, ["mobile"]: "Invalid contact no" };
    }

    if (addressRef.current.value === "") {
      status = false;
      errorLocal = {
        ...errorLocal,
        ["fullAddress"]: "Address cannot be empty",
      };
    }

    if (countryRef.current.value === "") {
      status = false;
      errorLocal = { ...errorLocal, ["countryId"]: "Please select a country" };
    }

    if (stateRef.current.value === "") {
      status = false;
      errorLocal = { ...errorLocal, ["stateId"]: "Please select a state" };
    }

    if (cityRef.current.value === "") {
      status = false;
      errorLocal = { ...errorLocal, ["cityId"]: "Please select a City" };
    }

    if (zipcodeRef.current.value === "") {
      status = false;
      errorLocal = { ...errorLocal, ["zipCode"]: "Zipcode cannot be empty" };
    }

    if (educationRef.current.value === "") {
      status = false;
      errorLocal = {
        ...errorLocal,
        ["educationId"]: "Please select education",
      };
    }
    if (chipData.length === 0) {
      status = false;
      errorLocal = { ...errorLocal, ["skill"]: "Skill cannot be empty" };
    }

    if (expYRRef.current.value === "") {
      status = false;
      errorLocal = { ...errorLocal, ["expYRRef"]: "Please select year" };
    }

    if (expMonRef.current.value === "") {
      status = false;
      errorLocal = { ...errorLocal, ["expMonRef"]: "Please select month" };
    }
    if (parseInt(zipCode) < 10000 || parseInt(zipCode) > 99999) {
      status = false;
      errorLocal = { ...errorLocal, ["zipCode"]: "Zipcode must be of 5 digit" };
    }
    if (!isUpdate) {
      if (!termCheckRef.current.checked) {
        status = false;
        errorLocal = {
          ...errorLocal,
          ["termCheckRef"]: "Please check the terms & conditions",
        };
      }
    }

    // if (logoRef.current.value === "") {
    //   status = false;
    //   errorLocal = { ...errorLocal, ["profileImg"]: "Profile image cannot be empty" };
    // }
    // if (resumeRef.current.value === "") {
    //   status = false;
    //   errorLocal = { ...errorLocal, ["resume"]: "Resume cannot be empty" };
    // }
    // if(fullAddress === ""){
    //   status = false;
    //   errorLocal = { ...errorLocal, ["fullAddress"]: "Address cannot be empty" };
    // }
    // if(countryId === ""){
    //   status = false;
    //   errorLocal = { ...errorLocal, ["countryId"]: "Country cannot be empty" };
    // }
    // if(stateId === ""){
    //   status = false;
    //   errorLocal = { ...errorLocal, ["stateId"]: "State cannot be empty" };
    // }
    // if(cityId === ""){
    //   status = false;
    //   errorLocal = { ...errorLocal, ["cityId"]: "City cannot be empty" };
    // }
    // if (zipCode === "") {
    //   status = false;
    //   errorLocal = { ...errorLocal, ["zipCode"]: "Zipcode cannot be empty" };
    // }
    // if(educationId === ""){
    //   status = false;
    //   errorLocal = { ...errorLocal, ["educationId"]: "Education cannot be empty" };
    // }

    // if(experienceInYear === ""){
    //   status = false;
    //   errorLocal = { ...errorLocal, ["experienceInYear"]: "Invalid experienceInYear" };
    // }
    // if(experienceInMonth === ""){
    //   status = false;
    //   errorLocal = { ...errorLocal, ["experienceInMonth"]: "Invalid experienceInMonth" };
    // }

    setError(errorLocal);

    return status;
  };

  useEffect(() => {
    if (fileUploaded && fileUrl) {
      if (isUpdate) {
        let coverLetter;
        let certificate;
        fileUrl.map((item) => {
          if (item.candiadateCoverLetter) {
            coverLetter = item.candiadateCoverLetter;
          }
          if (item.candiadateCertificate) {
            certificate = item.candiadateCertificate;
          }
        });
        dispatch(
          actions.updateProfile({
            ...formData,
            skills: chipData,
            coverLetterPath: coverLetter,
            certification: certificate,
          })
        );
      } else {
        dispatch({ type: actionTypes.CLEAR });
        let resume;
        let coverLetter;
        let certificate;
        fileUrl.map((item) => {
          if (item.candiadateResume) {
            resume = item.candiadateResume;
          }
          if (item.candiadateCoverLetter) {
            coverLetter = item.candiadateCoverLetter;
          }
          if (item.candiadateCertificate) {
            certificate = item.candiadateCertificate;
          }
        });
        dispatch(
          actions.submitProfile(
            {
              ...formData,
              skills: chipData,
              resumePath: resume,
              coverLetterPath: coverLetter,
              certification: certificate,
            },
            "candidate"
          )
        );
      }
    }
  }, [fileUploaded]);

  useEffect(() => {
    if (profileSubmitted || errorMsg) {
      setIsLoading(false);
      setAlert(true);
      // setToast(
      //   true,
      //   setTimeout(() => {
      //     setToast(false);
      //     dispatch({ type: actionTypes.CLEAR });
      //     if (msg === "Profile submitted successfully") {
      //       dispatch({ type: actionTypes.TRASH });
      //       history.push("/candidate-dashboard");
      //     }
      //   }, 3000)
      // );
    }
  }, [profileSubmitted, errorMsg]);

  const updateProfile = (e) => {
    e.preventDefault();

    if (validate()) {
      if (
        !profilePicObject &&
        !candidateResumeObject &&
        !coverLetterObject &&
        !certificateObject
      ) {
        setIsLoading(true);
        dispatch(
          actions.updateProfile(
            {
              ...formData,
              skills: chipData,
            },
            "candidate"
          )
        );
      } else {
        let docs = [];
        let profilePic;
        let resume;
        let coverLetter;
        let certificate;
        if (profilePicObject) {
          profilePic = new FormData();
          profilePic.append("productImage", profilePicObject);
          docs.push({ profilePic: profilePic });
        }
        if (candidateResumeObject) {
          resume = new FormData();
          resume.append("productImage", candidateResumeObject);
          docs.push({ resume: resume });
        }
        if (coverLetterObject) {
          coverLetter = new FormData();
          coverLetter.append("productImage", coverLetterObject);
          docs.push({ coverLetter: coverLetter });
        }
        if (certificateObject) {
          certificate = new FormData();
          certificate.append("productImage", certificateObject);
          docs.push({ certificate: certificate });
        }
        setIsLoading(true);
        dispatch(actions.uploadDocs(docs));
      }
    } else {
      window.scrollTo(0, 1);
    }
  };

  const confirm = () => {
    dispatch({ type: actionTypes.CLEAR });
    if (
      profileSubmitted &&
      msg === "Your profile has been created successfully."
    ) {
      setAlert(false);
      dispatch({ type: actionTypes.TRASH });
      history.push("/candidate-dashboard");
    } else if (
      profileSubmitted &&
      msg != "Your profile has been created successfully."
    ) {
      setAlert(false);
    }
    if (errorMsg) {
      setAlert(false);
    }
  };

  const addCertificate = () => {
    let count = isShowCertificate + 1;
    setIsShowCertificate(count);
  };
  const downloadResume = () => {
    let url = formData.resumeUrlPath;
    window.open(url, "_blank").focus();
  };
  const downloadCoverLetter = () => {
    let url = formData.coverLetterUrlPath;
    window.open(url, "_blank").focus();
  };
  const openFileBox = () => {
    $("#fileLoader").click();
  };
  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <div className={classes.candidateProfile}>
        {isUpdate ? (
          <div className={classes.candidateProfileHeading}>Profile</div>
        ) : (
          <div className={classes.candidateProfileHeading}>
            Welcome to conectd. Please build your profile.
          </div>
        )}
        {profileSubmitted && (
          <SuccessAlert show={true} msg={msg} confirm={confirm} />
          // <Toast display={toast} type="success" message={msg} />
        )}
        {
          errorMsg && (
            <ErrorAlert show={true} msg={errorMsg} confirm={confirm} />
          )
          // <Toast display={toast} type="danger" message={errorMsg} />
        }
        {/* <form> */}
        {/* // onSubmit={!isUpdate ? submitCandidateProfile : updateProfile} */}

        {validMsg ? (
          <NormalAlert show={true} msg={validMsg} confirm={confirmAlert} />
        ) : null}

        <div className={classes.candidateTop}>
          <div className={classes.left}>
            <div className={classes.logo}>
              <div className={classes.logoImg}>
                <div className={classes.img}>
                  {profilePic && (
                    <>
                      {/* <i className="fa fa-times" onClick={clearImage}></i>{" "} */}
                      <img src={profilePic} alt="image" />
                    </>
                  )}
                  {!profilePic && "Image"}
                </div>
              </div>
              <div
                className={classes.logoUploadBtn}
                // style={{ pointerEvents: profilePic ? "none" : "" }}
              >
                <button>Upload your Profile picture</button>
                <input
                  type="file"
                  // required={!isUpdate ? true : profilePic ? false : true}
                  accept="image/*"
                  ref={logoRef}
                  onChange={(e) => {
                    handleLogoUpload(e);
                    e.preventDefault();
                  }}
                />
              </div>
            </div>
            <div className={classes.errorMsg} style={{ fontSize: "0.7em" }}>
              <div>{error["profileImg"]}</div>
            </div>
            <div className={classes.inputBox}>
              <div>
                Name<span className={classes.reqFields}>*</span>
              </div>
              <div>
                <input
                  type="text"
                  required
                  name="name"
                  maxLength="50"
                  pattern="^[a-zA-Z ]*$"
                  title="Please enter a valid name"
                  autoComplete="off"
                  onChange={(e) => handleFieldChanges(e)}
                  defaultValue={formData.name}
                />
                <div className={classes.errorMsg}>
                  <div className={classes.error}>{error["name"]}</div>
                </div>
              </div>
            </div>

            <div className={classes.inputBox}>
              <div>
                Contact No<span className={classes.reqFields}>*</span>
              </div>
              <div>
                <input
                  type="tel"
                  required
                  name="mobile"
                  autoComplete="off"
                  maxLength="10"
                  minLength="10"
                  pattern="^[0-9]*$"
                  title="Only numbers are allowed"
                  onChange={(e) => handleFieldChanges(e)}
                  defaultValue={formData.mobile}
                />
                <div className={classes.errorMsg}>
                  <div className={classes.error}>{error["mobile"]}</div>
                </div>
              </div>
            </div>

            <div className={classes.inputBox}>
              <div>Email ID</div>
              <div>
                <input
                  type="email"
                  disabled
                  required
                  name="email"
                  autoComplete="off"
                  defaultValue={formData.email}
                />
              </div>
            </div>
          </div>
          <div className={classes.right}>
            {/* <div style={{fontSize:"13px",textAlign:"center"}}>Only Pdf or Docx file allowed</div> */}
            <div className={classes.resumeBox}>
              {!candidateResume && (
                <div className={classes.withoutUpload}>
                  <div>
                    <img src={upload} />
                  </div>
                  <div className={classes.uploadText}>
                    Drag & Drop your Resume here Or Browse.
                    <br />
                    <span style={{ color: "#9675e4" }}>
                      Only Pdf or Docx file allowed.
                    </span>
                  </div>
                  {/* <div className={classes.uploadText}>Or</div> */}
                  {/* <span className={classes.browseText}>Browse</span> */}
                  <input
                    type="file"
                    ref={resumeRef}
                    required={!isUpdate ? true : candidateResume ? false : true}
                    accept="application/pdf,application/msword,text/plain,.doc, .docx"
                    onChange={(e) => {
                      handleResume(e);
                      e.stopPropagation();
                    }}
                  />
                </div>
              )}
              {candidateResume && (
                <div className={classes.withUpload}>
                  <i
                    class="fa fa-times"
                    aria-hidden="true"
                    onClick={removeResume}
                  ></i>
                  <img src={cv} />
                  {isUpdate ? (
                    <button
                      type="button"
                      onClick={downloadResume}
                      style={{ marginTop: "10px" }}
                    >
                      Download
                    </button>
                  ) : null}
                </div>
              )}
            </div>
            <div className={classes.errorMsg} style={{ textAlign: "center" }}>
              <div></div>
              <div className={classes.error}>{error["resume"]}</div>
            </div>
          </div>
        </div>
        <div className={classes.block}>
          <div className={classes.blockInput}>
            <div>
              Address<span className={classes.reqFields}>*</span>
            </div>
            <div>
              <textarea
                required
                name="fullAddress"
                autoComplete="off"
                minLength="20"
                defaultValue={formData.fullAddress}
                onChange={(e) => handleFieldChanges(e)}
                ref={addressRef}
              />
              <div className={classes.errorMsg} style={{ fontSize: "0.7em" }}>
                <div>{error["fullAddress"]}</div>
              </div>
            </div>
          </div>

          <div className={classes.blockInput}>
            <div>
              Country<span className={classes.reqFields}>*</span>
            </div>
            <div>
              <select
                required
                name="countryId"
                value={formData.countryId}
                onChange={(e) => handleFieldChanges(e)}
                ref={countryRef}
              >
                <option value="" selected disabled>
                  Select Country
                </option>
                {!masterCountryList && (
                  <option value="" disabled>
                    Please wait...
                  </option>
                )}
                {masterCountryList && masterCountryList.length === 0 && (
                  <option value="" disabled>
                    Sorry no list found
                  </option>
                )}
                {masterCountryList &&
                  masterCountryList.length > 0 &&
                  masterCountryList.map((item) => {
                    return <option value={item._id}>{item.name}</option>;
                  })}
              </select>
              <div className={classes.errorMsg} style={{ fontSize: "0.7em" }}>
                <div>{error["countryId"]}</div>
              </div>
            </div>
          </div>

          <div className={classes.blockInput}>
            <div>
              State<span className={classes.reqFields}>*</span>
            </div>
            <div>
              <select
                required
                name="stateId"
                value={formData.stateId}
                onChange={(e) => handleFieldChanges(e)}
                ref={stateRef}
              >
                <option value="" selected disabled>
                  Select State
                </option>
                {!masterStateList && (
                  <option value="" disabled>
                    Please wait...
                  </option>
                )}
                {masterStateList && masterStateList.length === 0 && (
                  <option value="" disabled>
                    Sorry no list found
                  </option>
                )}
                {masterStateList &&
                  masterStateList.length > 0 &&
                  masterStateList.map((item) => {
                    if (formData.countryId === item.countryId) {
                      return <option value={item._id}>{item.name}</option>;
                    }
                  })}
              </select>
              <div className={classes.errorMsg} style={{ fontSize: "0.7em" }}>
                <div>{error["stateId"]}</div>
              </div>
            </div>
          </div>

          <div className={classes.blockInput}>
            <div>
              City<span className={classes.reqFields}>*</span>
            </div>
            <div>
              <select
                required
                name="cityId"
                value={formData.cityId}
                onChange={(e) => handleFieldChanges(e)}
                ref={cityRef}
              >
                <option value="" selected disabled>
                  Select City
                </option>
                {!masterCityList && (
                  <option value="" disabled>
                    Please wait...
                  </option>
                )}
                {masterCityList && masterCityList.length === 0 && (
                  <option value="" disabled>
                    Sorry no list found
                  </option>
                )}
                {masterCityList &&
                  masterCityList.length > 0 &&
                  masterCityList.map((item) => {
                    if (formData.stateId === item.stateId) {
                      return <option value={item._id}>{item.name}</option>;
                    }
                  })}
              </select>
              <div className={classes.errorMsg} style={{ fontSize: "0.7em" }}>
                <div>{error["cityId"]}</div>
              </div>
            </div>
          </div>

          <div className={classes.blockInput}>
            <div>
              Zipcode<span className={classes.reqFields}>*</span>
            </div>
            <div>
              <input
                type="text"
                maxLength="5"
                minLength="5"
                required
                pattern="^[0-9]*$"
                title="Only numbers are allowed"
                name="zipCode"
                autoComplete="off"
                defaultValue={formData.zipCode}
                onChange={(e) => handleFieldChanges(e)}
                ref={zipcodeRef}
              />
            </div>
          </div>
          <div className={classes.blockInput}>
            <div></div>
            <div className={classes.error}>{error["zipCode"]}</div>
          </div>
        </div>
        <div className={classes.block}>
          <div className={classes.blockInput}>
            <div>
              Education<span className={classes.reqFields}>*</span>
            </div>
            <div>
              <select
                required
                name="educationId"
                defaultValue={formData.educationId}
                onChange={(e) => handleFieldChanges(e)}
                ref={educationRef}
              >
                <option value="" selected disabled>
                  Select Education
                </option>
                {!masterEducationList && (
                  <option value="" disabled>
                    Please wait...
                  </option>
                )}
                {masterEducationList && masterEducationList.length === 0 && (
                  <option value="" disabled>
                    Sorry no list found
                  </option>
                )}
                {masterEducationList &&
                  masterEducationList.length > 0 &&
                  masterEducationList.map((item) => {
                    return <option value={item._id}>{item.name}</option>;
                  })}
              </select>
              <div className={classes.errorMsg} style={{ fontSize: "0.7em" }}>
                <div>{error["educationId"]}</div>
              </div>
            </div>
          </div>

          <div className={classes.skillBlockInput}>
            <div>
              Skills<span className={classes.reqFields}>*</span>
            </div>
            <div className={classes.skillInput} ref={wrapperRef}>
              {chips}
              <input
                type="text"
                ref={skillRef}
                required={chipData.length === 0 && true}
                placeholder="Type your skill here"
                onChange={(e) => filteredSuggestion(e)}
                onKeyDown={(e) => {
                  onKeyDownEvent(e);
                  e.stopPropagation();
                }}
                onBlur={handleBlur}
                onKeyPress={(e) => enterPressed(e)}
              />

              {suggestion.length > 0 && (
                <SearchSuggestion
                  suggestion={suggestion}
                  addNewValue={addNewValue}
                  clearSuggestion={() => setSuggestion([])}
                />
              )}
            </div>
          </div>
          <div
            className={classes.errorMsg}
            style={{ fontSize: "0.7em", paddingLeft: "162px" }}
          >
            <div>{error["skill"]}</div>
          </div>

          <div className={classes.blockInput}>
            <div>
              Experience<span className={classes.reqFields}>*</span>
            </div>
            <div className={classes.selectBox}>
              <div>
                <select
                  required
                  name="experienceInYear"
                  defaultValue={formData.experienceInYear}
                  onChange={(e) => handleFieldChanges(e)}
                  ref={expYRRef}
                >
                  <option value="" selected disabled>
                    Years
                  </option>
                  {DataGenerator.yearOptions.map((item) => {
                    return <option value={item.value}>{item.name}</option>;
                  })}
                </select>
                <div className={classes.errorMsg} style={{ fontSize: "0.7em" }}>
                  <div>{error["expYRRef"]}</div>
                </div>
                &nbsp;
              </div>

              <div>
                <select
                  required
                  name="experienceInMonth"
                  defaultValue={formData.experienceInMonth}
                  onChange={(e) => handleFieldChanges(e)}
                  ref={expMonRef}
                >
                  <option value="" selected disabled>
                    Months
                  </option>
                  {formData.experienceInYear !== "0" ? (
                    <option value="0">0 Month</option>
                  ) : null}
                  {DataGenerator.mothOptions.map((item) => {
                    return <option value={item.value}>{item.name}</option>;
                  })}
                </select>
                <div className={classes.errorMsg} style={{ fontSize: "0.7em" }}>
                  <div>{error["expMonRef"]}</div>
                </div>
                &nbsp;
              </div>
            </div>
          </div>
        </div>
        <div className={classes.block}>
          <div className={classes.blockInput}>
            <div>Linkedin Profile Link</div>
            <div>
              <input
                type="text"
                // maxLength="5"
                // minLength="5"
                // required
                // pattern="^[0-9]*$"
                // title="Only numbers are allowed"
                name="linkedinProfile"
                autoComplete="off"
                defaultValue={formData.linkedinProfile}
                onChange={(e) => handleFieldChanges(e)}
              />
            </div>
          </div>
          <div className={classes.blockInput}>
            <div>Portfolio Link</div>
            <div>
              <input
                type="text"
                // maxLength="5"
                // minLength="5"
                // required
                // pattern="^[0-9]*$"
                // title="Only numbers are allowed"
                name="portfolio"
                autoComplete="off"
                defaultValue={formData.portfolio}
                onChange={(e) => handleFieldChanges(e)}
              />
            </div>
          </div>
          <div className={classes.fileUploadBlock}>
            <div>Cover Letter</div>
            <div className={classes.fileBox}>
              {!coverLetter && (
                <div className={classes.fileUpload}>
                  <div>
                    <img src={upload} />
                  </div>
                  <div className={classes.fileText}>
                    Drag & Drop your cover letter here Or Browse.
                    <br />
                    <span style={{ color: "#9675e4" }}>
                      Only Pdf or Docx file allowed.
                    </span>
                  </div>
                  {/* <div className={classes.fileText}>Or</div> */}
                  {/* <div className={classes.file}>
                      <span className={classes.browseText}>Browse</span> */}
                  <input
                    type="file"
                    ref={coverLetterRef}
                    // required={!isUpdate ? true : coverLetter ? false : true}
                    accept="application/pdf"
                    onChange={(e) => {
                      handleCoverLetterUpload(e);
                      e.stopPropagation();
                    }}
                  />
                  {/* </div> */}
                </div>
              )}
              {coverLetter && (
                <div className={classes.fileWithUpload}>
                  <i
                    class="fa fa-times"
                    aria-hidden="true"
                    onClick={removeCoverLetter}
                  ></i>
                  <img src={coverLetterImg} />
                  {isUpdate ? (
                    <button
                      type="button"
                      onClick={downloadCoverLetter}
                      style={{ marginTop: "10px" }}
                    >
                      Download
                    </button>
                  ) : null}
                </div>
              )}
            </div>
          </div>
          {/* <div className={classes.fileUploadBlock}>
              <div>Certificate</div>
              <div className={classes.fileBox}>
                {!certificate && (
                  <div className={classes.fileUpload}>
                    <div>
                      <img src={upload} />
                    </div>
                    <div className={classes.fileText}>
                      Drag & Drop your certificate here Or Browse.
                    </div>
                    <input
                      type="file"
                      ref={certificateRef}
                      // required={!isUpdate ? true : certificate ? false : true}
                      accept="image/*"
                      onChange={(e) => {
                        handleCertificateUpload(e);
                        e.stopPropagation();
                      }}
                    />
                  </div>
                )}
                {certificate && (
                  <div className={classes.fileWithUpload}>
                    <i
                      class="fa fa-times"
                      aria-hidden="true"
                      onClick={removeCertificate}
                    ></i>
                    <img src={certificate} />
                  </div>
                )}
              </div>
              
            </div> */}

          <div className={classes.fileUploadBlock}>
            {/* <div>
              <div>Certificates</div>
              <div><a onClick={openFileBox}>Add</a></div>
              <div className={classes.fileBoxCertificate}>
                <input type='file' name='certificate' multiple accept="image/*" id="fileLoader" style={{ display: "none" }}
                  onChange={(e) => { onFileChange(e) }} />

                {certificateShow.length > 0 ?
                  certificateShow.map((item, index) => (
                    <div className={classes.fileWithUpload}>
                      {item ? <>
                        <i
                          class="fa fa-times"
                          aria-hidden="true"
                          onClick={() => removeCert(index)}
                        ></i>
                        <img src={item}  />
                      </> : null}
                    </div>
                  )) : null}

              </div>
              </div> */}

            {/* {certificate && (
                  <div className={classes.fileWithUpload}>
                    <i
                      class="fa fa-times"
                      aria-hidden="true"
                      onClick={removeCertificate}
                    ></i>
                    <img src={certificate} />
                  </div>
                )}  */}
            {/* </div> */}
          </div>
        </div>
        {!isUpdate && (
          <div className={classes.terms}>
            <input type="checkbox" ref={termCheckRef} required />
            &nbsp;&nbsp; <span>I agree to </span>
            <span>Terms & Conditions</span>
          </div>
        )}
        <div
          className={classes.errorMsg}
          style={{ fontSize: "0.7em", textAlign: "center" }}
        >
          <div>{error["termCheckRef"]}</div>
        </div>

        {/* <div className={classes.submit}>
            <button>{isUpdate ? "Update" : "Submit"}</button>
          </div> */}

        <div className={classes.submit}>
          {isUpdate ? (
            <button type="button" onClick={updateProfile}>
              Update
            </button>
          ) : (
            <button type="button" onClick={submitCandidateProfile}>
              Submit
            </button>
          )}
        </div>

        {/* </form> */}
      </div>
    );
  }
};

export default CandidateProfile;
