import * as actionTypes from "../Actions/ActionTypes";

const initialState = {};

const CreateJobReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CLEAR:
      return {
        ...state,
        jobDetailSubmitted: undefined,
        skillMatrixSubmitted: undefined,
        qualifyingQuestionSubmited: undefined,
        educationSubmitted: undefined,
        interviewProcessSubmitted: undefined,
        miscInfoSubmitted: undefined,
        workLocationSubmit: undefined,
        onBoardingSubmitted: undefined,
        personalMessageSubmitted: undefined,
        savedAsDraft: undefined,
        publishJob: undefined,
        errorMsg: undefined,
        jobUpdated: undefined,
        msg:undefined
      };
    case actionTypes.TRASH:
      return{
        ...state,
        jobPreview:undefined,
        refJobId:undefined,
      }  
    case actionTypes.SUBMIT_JOB_DETAIL_SUCCESS:
      return {
        ...state,
        refJobId: action.payload.refJobId,
        jobDetailSubmitted: true,
      };
    case actionTypes.SUBMIT_JOB_DETAIL_FAIL:
      return {
        ...state,
        errorMsg: action.payload,
      };
    case actionTypes.SUBMIT_SKILLS_MATRIX_SUCCESS:
      return {
        ...state,
        skillMatrixSubmitted: true,
      };
    case actionTypes.SUBMIT_SKILLS_MATRIX_FAIL:
      return {
        ...state,
        errorMsg: action.payload,
      };
    case actionTypes.SUBMIT_QALIFYING_QUESTION:
      return {
        ...state,
        qualifyingQuestionSubmited: true,
      };
    case actionTypes.SUBMIT_QALIFYING_QUESTION_FAIL:
      return {
        ...state,
        errorMsg: action.payload,
      };
    case actionTypes.SUBMIT_EDUCATION_SUCCESS:
      return {
        ...state,
        educationSubmitted: true,
      };
    case actionTypes.SUBMIT_EDUCATION_FAIL:
      return {
        ...state,
        errorMsg: action.payload,
      };
    case actionTypes.SUBMIT_INTERVIEW_SUCCESS:
      return {
        ...state,
        interviewProcessSubmitted: true,
      };
    case actionTypes.SUBMIT_INTERVIEW_FAIL:
      return {
        ...state,
        errorMsg: action.payload,
      };
    case actionTypes.SUBMIT_MISC_INFO_SUCCESS:
      return {
        ...state,
        miscInfoSubmitted: true,
      };
    case actionTypes.SUBMIT_MISC_INFO_FAIL:
      return {
        ...state,
        errorMsg: action.payload,
      };
    case actionTypes.WORK_LOCATION_SUBMIT_SUCCESS:
      return {
        ...state,
        workLocationSubmit: true,
      };
    case actionTypes.WORK_LOCATION_SUBMIT_FAIL:
      return {
        ...state,
        errorMsg: action.payload,
      };
    case actionTypes.ONBOARDING_SUBMIT_SUCCESS:
      return {
        ...state,
        onBoardingSubmitted: true,
      };
    case actionTypes.ONBOARDING_SUBMIT_FAIL:
      return {
        ...state,
        errorMsg: action.payload,
      };
    case actionTypes.GET_CREATE_JOB_PREVIEW:
      return {
        ...state,
        jobPreview: action.payload,
        refJobId:action.refJobId
      };
    case actionTypes.GET_CREATE_JOB_PREVIEW_FAIL:
      return {
        ...state,
        errorMsg: action.payload,
      };
    case actionTypes.SUBMIT_PERSONAL_MESSAGE:
      return {
        ...state,
        personalMessageSubmitted: true,
        jobPreview:undefined
      };
    case actionTypes.SUBMIT_PERSONAL_MESSAGE_FAIL:
      return {
        ...state,
        errorMsg: action.payload,
      };
    case actionTypes.SAVE_AS_DRAFT_SUCCESS:
      return {
        ...state,
        savedAsDraft: true,
        msg: "Job Saved as draft successfully.",
      };
    case actionTypes.SAVE_AS_DRAFT_FAIL:
      return {
        ...state,
        errorMsg: action.payload,
      };
    case actionTypes.PUBLISH_JOB_SUCCESS:
      return {
        ...state,
        publishJob: true,
        msg: "Job published successfully.",
      };
    case actionTypes.PUBLISH_JOB_FAIL:
      return {
        ...state,
        errorMsg: action.payload,
      };
    case actionTypes.UPDATE_JOB:
      return{
        ...state,
        jobUpdated:true,
        msg:'Job Updated successfully.',
      }
      case actionTypes.CANDIDATE_JOB_FILTER:
        return{
          ...state,
          jobPreview:undefined
        };
      case actionTypes.CANDIDATE_JOB_FILTER_CLEAR:
        return {
          ...state,
          jobPreview:undefined
        };     
    default:
      return state;
  }
};

export default CreateJobReducer;
