import * as actionTypes from "../Actions/ActionTypes";

const initialState = {};

const CandidateReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.API_INITIATE:
      return {
        ...state,
        loader: true,
      };
    case actionTypes.CLEAR:
      return {
        ...state,
        fileUploaded: undefined,
        profileSubmitted: undefined,
        errorMsg: undefined,
        msg: undefined,
        videoSubmittedUrl: undefined,
        jobApplied: undefined,
        jobId: undefined,
        applicationDetail: undefined,
      };
    case actionTypes.TRASH:
      return {
        candidateProfile: undefined,
        isProfileAvailable: undefined,
        appliedJobs:undefined,
        myScheduledInterviews:undefined,
      };
    case actionTypes.GET_CANDIDATE_PROFILE:
      return {
        ...state,
        loader: false,
        candidateProfile: action.payload,
        isProfileAvailable: action.isCandidateProfileAvailable,
      };
    case actionTypes.GET_CANDIDATE_PROFILE_FAIL:
      return {
        ...state,
        loader: false,
        errorMsg: action.payload,
      };
    case actionTypes.UPLOAD_CANDIDATE_FILE:
      return {
        ...state,
        fileUploaded: true,
        fileUrl: action.payload,
      };
    case actionTypes.UPLOAD_CANDIDATE_FILE_FAIL:
      return {
        ...state,
        errorMsg: action.payload,
      };
    case actionTypes.SUBMIT_PROFILE_SUCCESS:
      return {
        ...state,
        loader: false,
        profileSubmitted: true,
        msg: "Your profile has been created successfully.",
      };
    case actionTypes.SUBMIT_PROFILE_FAIL:
      return {
        ...state,
        loader: false,
        errorMsg: action.payload,
      };
    case actionTypes.UPDATE_EMPLOYER_PROFILE_SUCCESS:
      return {
        ...state,
        loader: false,
        profileSubmitted: true,
        msg: "Your profile has been updated successfully.",
      };
    case actionTypes.UPDATE_EMPLOYER_PROFILE_FAIL:
      return {
        ...state,
        loader: false,
        errorMsg: action.payload,
      };
    case actionTypes.APPLY_JOB_UPLOAD_FILE:
      return {
        ...state,
        videoSubmittedUrl: action.payload,
      };
    case actionTypes.APPLY_JOB_UPLOAD_FILE_FAIL:
      return {
        ...state,
        errorMsg: action.payload,
      };
    case actionTypes.APPLY_JOB:
      return {
        ...state,
        jobApplied: true,
        jobId: action.payload,
      };
    case actionTypes.APPLY_JOB_FAIL:
      return {
        ...state,
        errorMsg: action.payload,
      };
    case actionTypes.CANDIDATE_APPLIED_JOBS:
      return {
        ...state,
        appliedJobs :action.payload,
      } 
      case actionTypes.CANDIDATE_APPLIED_JOBS_FAIL:
        return {
          ...state,
          appliedJobs :[],
          errorMsg:action.payload
        }
      case actionTypes.GET_MY_SCHEDULED_INTERVIEWS:
        return{
          ...state,
          myScheduledInterviews:action.payload
        }
      case actionTypes.GET_MY_SCHEDULED_INTERVIEWS_FAIL:
        return{
          ...state,
          myScheduledInterviews:[],
          errorMsg:action.payload
        }
      case actionTypes.GET_CANDIDATE_APPLICATION_DETAIL:
        return{
          ...state,
          applicationDetail: action.payload
        }
      case actionTypes.GET_CANDIDATE_APPLICATION_DETAIL_FAIL:
        return{
          ...state,
          applicationDetail:[],
          errorMsg: action.payload
        }
      case  actionTypes.CANDIDATE_DETAIL_PROFILE:
        return{
          ...state,
          candidateDetailProfile: action.payload
        } 
      case  actionTypes.CANDIDATE_DETAIL_PROFILE_FAIL:
        return{
          ...state,
          candidateDetailProfile: {},
          errorMsg:action.payload
        }              
    default:
      return state;
  }
};

export default CandidateReducer;
