import React from "react";
import classes from "./Mcq.module.css";
import McqInitial from "./McqInitial";
import McqEdit from "./McqEdit";
import McqCustom from "./McqCustome";

const Mcq = ({
  masterSkill,
  masterSkillBasedMcqQuestion,
  addNewMcqQuestion,
  skillMcqQuestion,
  editSkillMcqQuestion,
  removeSkillMcqQuestion,
  submitEditMcq,
  skillMcqCustomeQuestion,
  saveCustomeMcqQuestion,
  editCustomeMcqQuestion,
  removeCustomeMcqQuestion,
}) => {

  return (
    <div className={classes.mcq}>
      <div className={classes.head}>
        <div className={classes.index}>S.No.</div>
        <div>Skill</div>
        <div>Question</div>
        <div>Select Correct Answer</div>
        <div>Action</div>
      </div>
      {skillMcqQuestion &&
        skillMcqQuestion.length > 0 &&
        skillMcqQuestion.map((item, index) => {
          return (
            <McqEdit
              list={item}
              index={index}
              masterSkill={masterSkill}
              masterSkillBasedMcqQuestion={masterSkillBasedMcqQuestion}
              editSkillMcqQuestion={editSkillMcqQuestion}
              removeSkillMcqQuestion={removeSkillMcqQuestion}
              submitEditMcq={submitEditMcq}
            />
          );
        })}
        {masterSkillBasedMcqQuestion.length != 0 && (
        <McqInitial
          index={skillMcqQuestion.length}
          masterSkill={masterSkill}
          masterSkillBasedMcqQuestion={masterSkillBasedMcqQuestion}
          addNewMcqQuestion={addNewMcqQuestion}
        />
      )}
     
      
      {skillMcqQuestion.length === 0 &&
        // skillMcqCustomeQuestion.length === 1 &&
        masterSkillBasedMcqQuestion.length === 0 && (
          <div style={{ textAlign: "center" }}>
            Sorry no master Mcq questions found
          </div>
        )}
    </div>
  );
};

export default Mcq;
