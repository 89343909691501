import React, { useEffect, useState } from "react";
import classes from "./Education.module.css";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../Store/Actions/index";
import * as actionTypes from "../../../Store/Actions/ActionTypes";
import Toast from "../../../common/Toast/Toast";
import Loader from "../../../common/Loader/Loader";
import ErrorAlert from '../../../common/ErrorAlert/ErrorAlert';

const Education = ({
  handleEducation,
  education,
  setNextPage,
  setPreviousPage,
}) => {
  let dispatch = useDispatch();
  const [educationError, setEducatioError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState(false);

  const {
    masterEducationList,
    educationSubmitted,
    errorMsg,
    next,
    refJobId,
  } = useSelector((state) => ({
    masterEducationList: state.MasterReducer.masterEducationList,
    educationSubmitted: state.CreateJobReducer.educationSubmitted,
    errorMsg: state.CreateJobReducer.errorMsg,
    refJobId: state.CreateJobReducer.refJobId,
  }));

  useEffect(() => {
    window.scrollTo(0,0);
    if (!masterEducationList) {
      dispatch(actions.getMasterEducationList());
    }
  }, []);

  useEffect(() => {
    if (educationSubmitted) {
      setIsLoading(false);
      dispatch({ type: actionTypes.CLEAR });
      setNextPage("miscInfo");
    } else if (errorMsg) {
      setIsLoading(false);
      setAlert(true)
      // setToast(
      //   true,
      //   setTimeout(() => {
      //     setToast(false);
      //   }, 3000)
      // );
    }
  }, [educationSubmitted, errorMsg]);

  const submitEducation = () => {
    setEducatioError("");
    dispatch({ type: actionTypes.CLEAR });
    if (!education) {
      setIsLoading(true);
      // setEducatioError("Please select minimum qualification");
      dispatch(actions.submitEducation({ refJobId: refJobId, education_id: masterEducationList[0]._id }))
    } else {
      setIsLoading(true);
      dispatch(
        actions.submitEducation({ refJobId: refJobId, education_id: education })
      );
    }
  };
  
  const confirm = () => {
    setAlert(false);
  }

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <React.Fragment>
        <div className={classes.education}>
        {
          alert && errorMsg && 
          <ErrorAlert show={true} msg={errorMsg} confirm={confirm}/>
          // <Toast display={toast} type="danger" message={errorMsg} />
        }
          <div className={classes.text}>1. Minimum qualification required for the job:</div>
          <div>
            <select
              required
              onChange={(e)=>{handleEducation(e);setEducatioError('')}}
              value={education}
            >
              {masterEducationList &&
                masterEducationList.map((educationList,index) => {
                  return (
                    <option value={educationList._id}>
                      {educationList.name}
                    </option>
                  );
                })}
            </select>
          </div>
          <div></div>
          <div className={classes.error}>{educationError}</div>
        </div>
        <div className={classes.skillFooter}>
          <button onClick={()=>setPreviousPage('question')}>Back</button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <button onClick={submitEducation}>Next</button>
        </div>
      </React.Fragment>
    );
  }
};

export default Education;
