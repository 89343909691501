import React, { useState, useEffect } from "react";
import classes from "./InterviewScheduled.module.css";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../../common/Loader/Loader";
import Toast from "../../../common/Toast/Toast";
import JobCard from "../../../components/JobCard/JobCard";
import * as actions from "../../../Store/Actions/index";
import * as actionTypes from "../../../Store/Actions/ActionTypes";
import Utility from "../../../Utility/Utility";
import TopCountShowStrip from "../../../common/TopShowCountStrip/TopCountShowStrip";
import TableWrapper from "../../../common/TableWrapper/TableWrapper";
import Slider from "react-slick";
import {withRouter} from 'react-router-dom';
import ErrorAlert from '../../../common/ErrorAlert/ErrorAlert';
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';

const InterviewScheduled = ({history}) => {
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    className: `${classes.carousel}`,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
    ],
  };

  const [activeIndex, setActiveIndex] = useState(0);
  const [alert, setAlert] = useState(false);
  const {
    loader,
    application,
    error,
    errorMsg,
    smallLoader,
    applicationDetail,
  } = useSelector((state) => ({
    loader: state.EmployerReducer.loader,
    application: state.EmployerReducer.application,
    error: state.EmployerReducer.error,
    errorMsg: state.EmployerReducer.errorMsg,
    smallLoader: state.EmployerReducer.smallLoader,
    applicationDetail: state.EmployerReducer.applicationDetail,
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0,0);
    dispatch({ type: actionTypes.CLEAR });
    dispatch(actions.getApplications("SCHEDULED"));
  }, []);

  useEffect(() => {
    if (
      application &&
      application.length > 0 &&
      activeIndex === 0 &&
      !applicationDetail
    ) {
      dispatch(
        actions.getApplicationByJobId(application[0].jobId, "SCHEDULED")
      );
    }
  }, [application]);

  useEffect(() => {
    if (errorMsg) {
      setAlert(true)
      // setToast(
      //   true,
      //   setTimeout(() => {
      //     setToast(false);
      //   }, 3000)
      // );
    }
  }, [errorMsg]);

  const getApplicationDetail = (jobId, index) => {
    setActiveIndex(index);
    dispatch(actions.getApplicationByJobId(jobId, "SCHEDULED"));
  };

  const getTotalReceivedApplication = (application) => {
    let count = 0;
    application.map((item) => {
      count = count + parseInt(item.applicationReceived);
    });
    return count;
  };

  const getFeedback = (id) => {
    history.push('/feedback', {id: id})
  };

  const confirm = () => {
    setAlert(false);
  }
  const PrevBtn = (props) => {
    const { className, onClick, currentSlide } = props
    return (
      <div className={className} onClick={onClick} style={{color: "#9675E4"}}>
       {currentSlide !== 0 ? <ArrowBackIos  /> : null}
      </div>
    )
  }
  const NextBtn = (props) => {
    const { className, onClick } = props
    return (
      <div className={className} onClick={onClick} style={{color: "#9675E4"}}>
       <ArrowForwardIos />
      </div>
    )
  }

  if (loader) {
    return <Loader />;
  } else if (application) {
    return (
      <div className={classes.interviewScheduled}>
        <TopCountShowStrip
          text="Total Interviews Scheduled"
          count={
            application.length !== 0
              ? getTotalReceivedApplication(application)
              : 0
          }
        />

        {application.length === 0 && (
          <div className={classes.noData}>There are no interview scheduled till now.</div>
        )}

        {application.length !== 0 && (
          <Slider {...settings}
          prevArrow={<PrevBtn />}
          nextArrow={<NextBtn />}>
            {application.map((application, index) => {
              return (
                <JobCard
                  width={
                    window.innerWidth < 800
                      ? window.innerWidth < 600
                        ? ""
                        : "17.8em"
                      : "22em"
                  }
                  indexing={true}
                  activeIndex={activeIndex}
                  index={index}
                  jobId={application.jobId}
                  jobTitle={application.role}
                  jobType={application.type}
                  postedDate={application.publishedDate}
                  totalCount={application.applicationReceived}
                  getApplicationDetail={getApplicationDetail}
                />
              );
            })}
          </Slider>
        )}

        <div className={classes.bottom}>
          {smallLoader && (
            <div className={classes.noSubData}>Please wait...</div>
          )}
          {application.length !== 0 && !smallLoader && !applicationDetail && (
            <div className={classes.noSubData}>
              Sorry No Interview Scheduled For this Job.
            </div>
          )}
          {!smallLoader && applicationDetail && (
            <TableWrapper
              text={application[activeIndex].role}
              count={application[activeIndex].applicationReceived}
            >
              <div className={classes.applicationDetailHead}>
                <div>S.No.</div>
                <div>Date of Interview</div>
                {/* <div>Time</div> */}
                <div>Applicant</div>
                <div>Email ID</div>
                <div>Contact No.</div>
                <div>Criteria Matches</div>
                {/* <div>Date of Application</div> */}
              </div>
              <div className={classes.scrollBar} style={{overflow:"auto",height:"200px"}}>
              {applicationDetail.map((detail, index) => {
                return (
                  <div className={classes.applicationDetailData}>
                    <div className={classes.index}>{index + 1}</div>
                    <div className={classes.mob}>
                      <div className={classes.item}>Date of Interview</div>
                      <div>{Utility.formatDate(detail.dateOfInterview)}</div>
                    </div>
                    {/* <div className={classes.mob}>
                      <div className={classes.item}>Time</div>
                      <div>{Utility.formatTime(detail.interviewTime)}</div>
                    </div> */}
                    <div className={classes.mob}>
                      <div className={classes.item}>Applicant</div>
                      <div>{detail.candidateName}</div>
                    </div>
                    <div className={classes.mob}>
                      <div className={classes.item}>Email ID</div>
                      <div>{detail.candidateEmail}</div>
                    </div>
                    <div className={classes.mob}>
                      <div className={classes.item}>Contact No.</div>
                      <div>{detail.candidateNumber}</div>
                    </div>
                    <div className={classes.mob}>
                      <div className={classes.item}>criteria Matches</div>
                      <div>{detail.iSensePercent.includes("503 Service Unavailable") ||  detail.iSensePercent.includes("error") || detail.iSensePercent == "0" ? "-" : parseInt(detail.iSensePercent=="NaN"? 0:detail.iSensePercent).toFixed(2)+"%"}</div>
                    </div>
                    {/* <div className={classes.mob}>
                      <div className={classes.item}>Date of Application</div>
                      <div>{Utility.formatDate(detail.appliedDate)}</div>
                    </div> */}
                  </div>
                );
              })}
              </div>
            </TableWrapper>
          )}
        </div>
        {
          alert && 
          <ErrorAlert show={true} msg={errorMsg} confirm={confirm}/>
        }
        
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default withRouter(InterviewScheduled);
