import React from "react";
import classes from "./JobDetails.module.css";

const Tab = ({ type, setJobType }) => {
  return (
    <div className={classes.jobDetailsTab}>
      <div className={classes.ele} onClick={(e)=>setJobType(e,'Contract')}>
        Contract
        {type === "Contract" && <div></div>}
      </div>
      <div className={classes.ele} onClick={(e)=>setJobType(e,'CTH')}>
        CTH
        {type === "CTH" && <div></div>}
      </div>
      <div className={classes.ele} onClick={(e)=>setJobType(e,'Direct Hire')}>
        Direct Hire{type === "Direct Hire" && <div></div>}
      </div>
    </div>
  );
};

export default Tab;
