import React, { useEffect, useState } from "react";
import classes from "./EmployerDashboard.module.css";
import TotalJobsCard from "../../../components/TotalJobsCard/TotalJobsCard";
import DeleteDraftCard from "../../../components/DeleteDraftCard/DeleteDraftCard";
import UpcomingInterviews from "../../../components/UpcomingInterviews/UpcomingInterviews";
import NewApplications from "../../../components/NewApplications/NewApplications";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actions from "../../../Store/Actions/index";
import * as actionTypes from "../../../Store/Actions/ActionTypes";
import Loader from "../../../common/Loader/Loader";
import Toast from "../../../common/Toast/Toast";
import ErrorAlert from "../../../common/ErrorAlert/ErrorAlert";
import Utility from "../../../Utility/Utility";

const EmployerDashboard = ({ history }) => {
  let dispatch = useDispatch();
  const [alert, setAlert] = useState(false);
  const [scheduledIntervieList, setScheduledIntervieList] = useState([]);
  const {
    loader,
    dasboardDetail,
    error,
    errorMsg,
    profile,
    completedJobs,
    rejectedJobs,
  } = useSelector((state) => ({
    loader: state.CommonReducer.loader,
    dasboardDetail: state.CommonReducer.dasboardDetail,
    error: state.CommonReducer.error,
    errorMsg: state.CommonReducer.errorMsg,
    profile: state.CommonReducer.profile,
    completedJobs: state.EmployerReducer.completedJobs,
    rejectedJobs: state.EmployerReducer.rejectedJobs,
  }));
  useEffect(() => {
    dispatch(actions.checkForProfile());
    dispatch(actions.getCompletedInterviews());
    dispatch(actions.getRejectedInterviews());
  }, []);

  useEffect(() => {
    if (completedJobs && completedJobs.length > 0) {
      completedJobs.sort(
        (a, b) =>
          new Date(b.interviewScheduleId.date).getTime() -
          new Date(a.interviewScheduleId.date).getTime()
      );
    }

    if (dasboardDetail && dasboardDetail.scheduledIntervieList.length > 0) {
      let scheduledIntervieList = dasboardDetail.scheduledIntervieList.sort(
        (c, d) =>
          new Date(d.interviewScheduleId.date).getTime() -
          new Date(c.interviewScheduleId.date).getTime()
      );
      setScheduledIntervieList(scheduledIntervieList);
    }
  }, [completedJobs, dasboardDetail]);

  useEffect(() => {
    if (profile && profile.length === 0) {
      history.push("/employer-profile");
    } else if (profile && profile.length !== 0) {
      dispatch(actions.getDashboard());
    }
  }, [profile]);

  useEffect(() => {
    if (errorMsg) {
      setAlert(true);
      // setToast(
      //   true,
      //   setTimeout(() => {
      //     setToast(false);
      //   }, 3000)
      // );
    }
  }, [errorMsg]);

  const sendToCompletedInterview = () => {
    history.push("/completed-interviews");
  };

  const getFeedback = (id) => {
    history.push(`/feedback?id=${id}`);
  };

  const confirm = () => {
    setAlert(false);
  };

  if (loader) {
    return <Loader />;
  } else if (dasboardDetail) {
    return (
      <div className={classes.employerDashboard}>
        <div className={classes.dashboardCards}>
          <TotalJobsCard
            totalJobs={dasboardDetail.totalJobCount}
            openJobs={dasboardDetail.openJobs}
            closeJobs={dasboardDetail.closeJobs}
          />
          <DeleteDraftCard
            deletedJobs={dasboardDetail.deletedJobsCount}
            draftedJobs={dasboardDetail.draftJobCount}
          />
        </div>
        <div className={classes.interviewList}>
          <UpcomingInterviews upcomingInterviews={scheduledIntervieList} />
          <NewApplications
            newApplicants={dasboardDetail.receivedApplicationList}
          />
        </div>
        {/* Completed Interview Section */}
        <div className={classes.completedJobs}>
          <div className={classes.completedJobsCard}>
            <div className={classes.top}>
              <span>Completed Interview</span>
              <span onClick={sendToCompletedInterview}>See All</span>
            </div>
            {completedJobs && completedJobs.length === 0 && (
              <div className={classes.noData}>
                There are no completed interviews till now.
              </div>
            )}
            {completedJobs && completedJobs.length > 0 && (
              <React.Fragment>
                <div className={classes.applicationDetailHead}>
                  <div>S.No.</div>
                  <div>Date of Interview</div>
                  <div>Position Title</div>
                  <div>Job ID</div>
                  <div>Applicant</div>
                  {/* <div>Email ID</div> */}
                  <div>Interview Results</div>
                  <div>Candidate Status</div>
                </div>
                <div
                  className={classes.scrollBar}
                  style={{ overflow: "auto", height: "200px" }}
                >
                  {completedJobs.map((item, index) => {
                    return (
                      <div className={classes.applicationDetailData}>
                        <div className={classes.index}>{index + 1}</div>
                        <div className={classes.mob}>
                          <div className={classes.item}>Date of Interview</div>
                          <div>
                            {item.interviewScheduleId !== null
                              ? Utility.formatDate(
                                  item.interviewScheduleId.date
                                )
                              : "-"}
                          </div>
                        </div>
                        <div className={classes.mob}>
                          <div className={classes.item}>Position Title</div>
                          <div>{item.jobId.role ? item.jobId.role : ""}</div>
                        </div>
                        <div className={classes.mob}>
                          <div className={classes.item}>Job ID</div>
                          <div>
                            {item.jobId.alternateJobId
                              ? item.jobId.alternateJobId
                              : ""}
                          </div>
                        </div>
                        <div className={classes.mob}>
                          <div className={classes.item}>Applicant</div>
                          <div>{item.candidateId.userId.name}</div>
                        </div>
                        <div className={classes.mob}>
                          <div className={classes.item}>Review</div>
                          <div>
                            {item.applicationStatus === "COMPLETED" ? (
                              <span
                                onClick={() => getFeedback(item._id)}
                                className={classes.feedback}
                              >
                                Review
                              </span>
                            ) : (
                              "-"
                            )}
                          </div>
                        </div>
                        {/* <div className={classes.mob}>
                        <div className={classes.item}>Review</div>
                        <div>{item.candidateId.userId.email}</div>
                      </div> */}
                        <div className={classes.mob}>
                          <div className={classes.item}>Candidate Status</div>
                          <div>Review Pending</div>
                        </div>
                        {/* <div className={classes.mob}>
                      <div className={classes.item}>Date of Application</div>
                      <div>{Utility.formatDate(detail.appliedDate)}</div>
                    </div> */}
                        {/* <div className={classes.mob}>
                        <div className={classes.item}>Feedback</div>
                        <div>
                          {item.applicationStatus === "COMPLETED" ? (
                            <span
                              onClick={() => getFeedback(item._id)}
                              className={classes.feedback}
                            >
                              Feedback
                            </span>
                          ) : (
                            "-"
                          )}
                        </div>
                      </div> */}
                      </div>
                    );
                  })}
                </div>
              </React.Fragment>
            )}
          </div>
        </div>

        {/* Declined Interview Section */}

        <div className={classes.completedJobs}>
          <div className={classes.completedJobsCard}>
            <div className={classes.top}>
              <span>Declined Interview</span>
            </div>
            {rejectedJobs && rejectedJobs.length === 0 && (
              <div className={classes.noData}>
                There are no Declined interviews till now.
              </div>
            )}
            {rejectedJobs && rejectedJobs.length > 0 && (
              <React.Fragment>
                <div className={classes.applicationDetailHeadRejected}>
                  <div>S.No.</div>
                  <div>Date of Application</div>
                  <div>Position Title</div>
                  <div>Job ID</div>
                  <div>Applicant</div>
                  <div>Profile</div>
                  <div>criteria Matches</div>
                  {/* <div>Email ID</div>
                  <div>Contact No.</div> */}
                </div>
                <div
                  className={classes.scrollBar}
                  style={{ overflow: "auto", height: "200px" }}
                >
                  {rejectedJobs.map((item, index) => {
                    return (
                      <div className={classes.applicationDetailDataRejected}>
                        <div className={classes.index}>{index + 1}</div>
                        <div className={classes.mob}>
                          <div className={classes.item}>
                            Date of Application
                          </div>
                          <div>{Utility.formatDate(item.appliedDate)}</div>
                        </div>
                        <div className={classes.mob}>
                          <div className={classes.item}>Position Title</div>
                          <div>{item.jobId.role}</div>
                        </div>
                        <div className={classes.mob}>
                          <div className={classes.item}>Job ID</div>
                          <div>{item.jobId.alternateJobId}</div>
                        </div>
                        <div className={classes.mob}>
                          <div className={classes.item}>Applicant</div>
                          <div>{item.candidateId.userId.name}</div>
                        </div>
                        <div className={classes.mob}>
                          <div className={classes.item}>Profile</div>
                          <div
                            onClick={() =>
                              history.push(
                                `/candidateDetailProfile?id=${item.candidateId._id}`
                              )
                            }
                          >
                            View Profile
                          </div>
                        </div>
                        <div className={classes.mob}>
                          <div className={classes.item}>criteria Matches</div>
                          <div>
                            {" "}
                            {parseInt(
                              item.isSensePercent == "NaN"
                                ? 0
                                : item.isSensePercent
                            ).toFixed(2)}
                            %
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </React.Fragment>
            )}
          </div>
        </div>

        {
          alert && errorMsg && (
            <ErrorAlert show={true} msg={errorMsg} confirm={confirm} />
          )
          // <Toast display={toast} type="danger" message={errorMsg} />
        }
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default withRouter(EmployerDashboard);
