import * as actionTypes from "./ActionTypes";
import { getRequest, postRequest } from "../../Helper/apiHit";

export const uploadProfilePicture = (file) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.API_INITIATE });
    postRequest("users/uploadProfilePicture", file)
      .then((res) => {
        if (res.data.responseStatus.statusCode === 0) {
          dispatch({
            type: actionTypes.UPLOAD_COMPANY_LOGO_SUCCESS,
          });
        } else {
          dispatch({
            type: actionTypes.UPLOAD_COMPANY_LOGO_FAIL,
            payload: "Somthing went wrong",
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.UPLOAD_COMPANY_LOGO_FAIL,
          payload: "Somthing went wrong",
        });
      });
  };
};

export const submitProfile = (data,type) => {
  return (dispatch) => {
    postRequest("employer/submitProfile", data)
      .then((res) => {
        if (res.data.responseStatus.statusCode === 0) {
          if(type === 'candidate'){
            localStorage.setItem("User",data.name)
          }
          dispatch({
            type: actionTypes.SUBMIT_PROFILE_SUCCESS,
          });
        } else {
          dispatch({
            type: actionTypes.SUBMIT_PROFILE_FAIL,
            payload: "Somthing went wrong",
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.SUBMIT_PROFILE_FAIL,
          payload: "Somthing went wrong",
        });
      });
  };
};

export const getEmployerDetails = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.API_INITIATE });
    getRequest("employer/getEmployerProfile")
      .then((res) => {
        if (res.data.responseStatus.statusCode === 0) {
          console.log('success')
          let image;
          if (res.data.responseData.userDetails) {
            new Promise((resolve, reject) => {
              getRequest(`users/getAvatar`)
                .then((res) => {
                  image = res.data.responseData;
                  resolve(1);
                })
                .catch((err) => {});
            }).then((re) => {
              dispatch({
                type: actionTypes.GET_EMPLOYER_PROFILE,
                payload: {
                  addresses: { ...res.data.responseData.addresses },
                  companySector: { ...res.data.responseData.companySector },
                  userDetails: {
                    ...res.data.responseData.userDetails,
                    avatarPath: image,
                  },
                },
              });
            });
          } else {
            dispatch({
              type: actionTypes.GET_EMPLOYER_PROFILE,
              payload: res.data.responseData,
            });
          }
        } else {
          dispatch({
            type: actionTypes.GET_EMPLOYER_PROFILE_FAIL,
            payload: 'Somthing went wrong',
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_EMPLOYER_PROFILE_FAIL,
          payload: 'Somthing went wrong',
        });
      });
  };
};

export const updateProfile = (data,type) => {
  return dispatch => {
      dispatch({ type: actionTypes.API_INITIATE })
      postRequest('employer/updateProfile', data).then(res => {
          if (res.data.responseStatus.statusCode === 0) {
            if(type === 'candidate'){
              localStorage.setItem("User",data.name)
            }
            
              dispatch({
                  type: actionTypes.UPDATE_EMPLOYER_PROFILE_SUCCESS,
              })
          } else {
              dispatch({
                  type: actionTypes.UPDATE_EMPLOYER_PROFILE_FAIL,
                  payload: 'Somthing went wrong'
              })
          }
      }).catch(e => {
          dispatch({
              type: actionTypes.UPDATE_EMPLOYER_PROFILE_FAIL,
              payload: 'Somthing went wrong'
          })
      })
  }
}

export const draftedJobs = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.API_INITIATE });
    getRequest("jobs/draftedJobs")
      .then((res) => {
        if (res.data.responseStatus.statusCode === 0) {
          dispatch({
            type: actionTypes.GET_DRAFTED_JOB_SUCCESS,
            payload: res.data.responseData,
          });
        } else {
          dispatch({
            type: actionTypes.GET_DRAFTED_JOB_FAIL,
            payload: "Somthing went wrong",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_DRAFTED_JOB_FAIL,
          payload: "Somthing went wrong",
        });
      });
  };
};

export const deletedJobs = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.API_INITIATE });
    getRequest("jobs/deletedJobs")
      .then((res) => {
        if (res.data.responseStatus.statusCode === 0) {
          dispatch({
            type: actionTypes.GET_DELETED_JOB_SUCCESS,
            payload: res.data.responseData,
          });
        } else {
          dispatch({
            type: actionTypes.GET_DELETED_JOB_FAIL,
            payload: "Somthing went wrong",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_DELETED_JOB_FAIL,
          payload: "Somthing went wrong",
        });
      });
  };
};

export const repostJob = (body) => {
  return (dispatch) => {
    postRequest("jobs/repostJob", body)
      .then((res) => {
        if (res.data.responseStatus.statusCode === 0) {
          dispatch({
            type: actionTypes.REPOST_JOB_SUCCESS,
            payload: body.refJobId,
          });
        } else {
          dispatch({
            type: actionTypes.REPOST_JOB_FAIL,
            payload: "Somthing went wrong",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.REPOST_JOB_FAIL,
          payload: "Somthing went wrong",
        });
      });
  };
};



export const getApplications = (applicationType) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.API_INITIATE });
    getRequest(
      `interview/getReceivedApplications?applicationType=${applicationType}`
    )
      .then((res) => {
        if (res.data.responseStatus.statusCode === 0) {
          dispatch({
            type: actionTypes.GET_APPLICATION_SUCCESS,
            payload: res.data.responseData,
          });
        } else {
          dispatch({
            type: actionTypes.GET_APPLICATION_FAIL,
            payload: "Somthing went wrong",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_APPLICATION_FAIL,
          payload: "Somthing went wrong",
        });
      });
  };
};

export const getApplicationByJobId = (jobId, applicationType) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.SUB_API_INITIATE });
    getRequest(
      `interview/getApplicationByJobId?jobId=${jobId}&applicationType=${applicationType}`
    )
      .then((res) => {
        if (res.data.responseStatus.statusCode === 0) {
          dispatch({
            type: actionTypes.GET_APPLICATION_BY_ID_SUCCESS,
            payload: res.data.responseData,
          });
        } else {
          dispatch({
            type: actionTypes.GET_APPLICATION_BY_ID_FAIL,
            payload: "Somthing went wrong",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_APPLICATION_BY_ID_FAIL,
          payload: "Somthing went wrong",
        });
      });
  };
};

export const rejectApplication = (data) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.SUB_API_INITIATE });
    postRequest("interview/rejectJobApplication", data)
      .then((res) => {
        if (res.data.responseStatus.statusCode === 0) {
          dispatch({
            type: actionTypes.REJECT_JOB_APPLICATION_SUCCESS,
            payload: data.applicationId,
          });
        } else {
          dispatch({
            type: actionTypes.REJECT_JOB_APPLICATION_FAIL,
            payload: "Somthing went wrong",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.REJECT_JOB_APPLICATION_FAIL,
          payload: "Somthing went wrong",
        });
      });
  };
};

export const scheduleInterview = (data) => {
  return dispatch => {
      dispatch({ type: actionTypes.SUB_API_INITIATE })
      postRequest('interview/scheduleInterview', data).then(res => {
          if (res.data.responseStatus.statusCode === 0) {
              dispatch({
                  type: actionTypes.SCHEDULE_INTERVIEW_SUCCESS,
                  payload: data.applicationId
              })
          } else {
              dispatch({
                  type: actionTypes.SCHEDULE_INTERVIEW_FAIL,
                  payload: 'Somthing went wrong'
              })
          }
      }).catch(err => {
          dispatch({
              type: actionTypes.SCHEDULE_INTERVIEW_FAIL,
              payload: 'Somthing went wrong'
          })
      })
  }
}

export const getCompletedInterviews = () => {
  return (dispatch) => {
    getRequest('employer/getCompletedJobs').then(res=>{
      if(res.data.responseStatus.statusCode === 0){
        dispatch({
          type: actionTypes.GET_COMPLETED_JOBS,
          payload: res.data.responseData,
        })
      }else{
        dispatch({
          type: actionTypes.GET_COMPLETED_JOBS_FAIL,
          payload: 'Somthing went wrong',
        })
      }
    }).catch(err=>{
      dispatch({
        type: actionTypes.GET_COMPLETED_JOBS_FAIL,
        payload: 'Somthing went wrong',
      })
    })
  }
}

export const getRejectedInterviews = () => {
  return (dispatch) => {
    getRequest('employer/getRejectedJobs').then(res=>{
      if(res.data.responseStatus.statusCode === 0){
        dispatch({
          type: actionTypes.GET_REJECTED_JOBS,
          payload: res.data.responseData,
        })
      }else{
        dispatch({
          type: actionTypes.GET_REJECTED_JOBS_FAIL,
          payload: 'Somthing went wrong',
        })
      }
    }).catch(err=>{
      dispatch({
        type: actionTypes.GET_REJECTED_JOBS_FAIL,
        payload: 'Somthing went wrong',
      })
    })
  }
}