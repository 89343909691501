import React, { useEffect, useState, useRef } from "react";
import classes from "./StartInterview.module.css";
import { useHistory } from "react-router";
import RecordRTC from "recordrtc";
import * as actions from "../../../Store/Actions/index";
import * as actionTypes from "../../../Store/Actions/ActionTypes";
import { useSelector, useDispatch } from "react-redux";
import ErrorAlert from "../../../common/ErrorAlert/ErrorAlert";
import Loader from "../../../common/Loader/Loader";
import { withRouter } from "react-router-dom";
import Mcq from "./McqComp";
import Hark from "hark";
import SuccessAlert from "../../../common/SuccessAlert/SuccessAlert";
import MediaStreamRecorder from "msr";
import useUnsavedChanges from "./useUnsavedChanges";
import NavigationPrompt from "./NavigationPrompt";
import Modal from "react-modal";
import { useSpeechSynthesis } from "react-speech-kit";
import useSpeechToText from "react-hook-speech-to-text";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
var recorder;
var tts = window.speechSynthesis;
var toSpeak;
var audioAnswer = "";
var audioRecorder;
var timeInterval;
var maxTimeInterval;
var submittedMcq = [];
var myAnswer = [];
let finalAnswer = [];
var speachEvent;
var mcqQuestionNumber = 0;
var totalQuestionNumber = 0;
var mcqCompleted = false;
var mediaRecorder;
// var SpeechRecognition = window.webkitSpeechRecognition;
// var recognition = new SpeechRecognition();
// var hello
var recognition;
let platform = window.navigator.platform;
if (
  platform === "Macintosh" ||
  platform === "MacIntel" ||
  platform === "MacPPC" ||
  platform === "Mac68K" ||
  platform === "iPhone" ||
  platform === "iPad" ||
  platform === "iPod"
) {
  recognition = window.webkitSpeechRecognition;
} else {
  SpeechRecognition = window.webkitSpeechRecognition;
  recognition = new SpeechRecognition();
}
var SpeechRecognition;

var isPause = false;

const InterviewComp = ({ history }) => {
  const dispatch = useDispatch();

  // const history = useHistory()
  // history.go(0)
  const [isLoading, setIsLoading] = useState(false);
  const [warningAlert, setWarningAlert] = useState(false);
  const [interviewStarted, setInterviewStarted] = useState(false);
  const [interviewCompleated, setInterviewCompleted] = useState(false);
  const { speak } = useSpeechSynthesis();
  const [questionAnswer, setQuestionAnswer] = useState([]);

  const [pause, setPause] = useState(false);
  //   const [mcqCompleted, setMcqCompleted] = useState(false);
  const [question, setQuestion] = useState();
  let [timer, setTimer] = useState(10);
  let [maxTime, setMaxTime] = useState(120);
  let [questionNumber, setQuestionNumber] = useState(0);
  const [isMaxTimeReached, setIsMaxTimeReached] = useState(false);
  const [activeComp, setActiveComp] = useState("mcq");
  //   const [mcqQuestionNumber, setMcqQuestionNumber] = useState(0);
  const [recordingVideo, setRecordingVideo] = useState();
  const [totalQuestion, setTotalQuestion] = useState([]);
  const [finalAnswer, setFinalAnswer] = useState([]);
  //   let [totalQuestionNumber, setTotalQuestionNumber] = useState(0);
  const [answer, setAnswer] = useState("");
  const [alert, setAlert] = useState(false);
  let [isRecognizationEnded, setIsRecognizationEnded] = useState(false);
  const videoRef = useRef();
  const radioRefOne = useRef();
  const radioRefTwo = useRef();
  const radioRefThree = useRef();
  const radioRefFour = useRef();
  const [Prompt, setDirty, setCompleted, isDirty] = useUnsavedChanges();

  const {
    // error,
    interimResult,
    isRecording,
    results,
    startSpeechToText,
    stopSpeechToText,
  } = useSpeechToText({
    continuous: true,
    crossBrowser: true,
    googleApiKey: "AIzaSyAdcVWVfzB8YS9XDJFW2BIOR5WWef9wfaA",
    speechRecognitionProperties: { interimResults: true },
    useLegacyResults: false,
  });

  // useEffect(()=>{
  //   if(interviewStarted){
  //     setDirty()
  //   }
  // },[interviewStarted])

  let {
    errorMsg,
    questionsForInterview,
    interviewSubmited,
    msg,
    audioUploaded,
    audioPath,
    interviewVideoSubmitted,
  } = useSelector((state) => ({
    errorMsg: state.AiInterviewReducer.errorMsg,
    questionsForInterview: state.AiInterviewReducer.questionsForInterview,
    interviewSubmited: state.AiInterviewReducer.interviewSubmited,
    msg: state.AiInterviewReducer.msg,
    audioUploaded: state.AiInterviewReducer.audioUploaded,
    audioPath: state.AiInterviewReducer.audioPath,
    interviewVideoSubmitted: state.AiInterviewReducer.interviewVideoSubmitted,
  }));

  console.log("interviewVideoSubmitted", interviewVideoSubmitted);
  console.log("applicationId", history.location.state.ApplicationId);
  const [candidateInterview, setCandidateInterview] = useState(
    interviewVideoSubmitted
  );
  useEffect(() => {
    setIsLoading(true);
    dispatch(actions.getQuestionsForInterview(history.location.state.JobId));
  }, []);

  useEffect(() => {
    if (questionsForInterview || interviewSubmited || errorMsg) {
      mergeQuestion();
      setIsLoading(false);
      if (errorMsg || interviewSubmited) {
        setAlert(true);
      }
    }
  }, [questionsForInterview, errorMsg, interviewSubmited]);

  const confirmAlert = () => {
    dispatch({ type: actionTypes.CLEAR });
    setAlert(false);

    if (interviewSubmited) {
      history.push("/scheduled-interviews");
    }
    window.location.reload();
    localStorage.setItem("reloadmodal", false);
  };

  const mergeQuestion = () => {
    setTotalQuestion([
      ...questionsForInterview.skillBasedQuestions,
      ...questionsForInterview.personalQuestions,
      ...questionsForInterview.mcqQuestion,
    ]);
  };

  const getVoices = () => {
    // if(pause === false){
    if (localStorage.getItem("pauseInterviewVal") == "false") {
      let voices = [];
      voices = tts.getVoices();
      voices = voices.filter((item, index) => {
        if (index === 4) {
          return item;
        }
      });
      return voices;
    }
  };

  const checkMcq = () => {
    if (questionsForInterview.mcqQuestion.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const startInterview = () => {
    localStorage.setItem("userStartedInterview", true);
    if (checkMcq()) {
      setInterviewStarted(true);
      setActiveComp("mcq");
      startMaxTimer("start interview");
    } else {
      setIsLoading(true);
      gettingVideoReady()
        .then((res) => {
          setActiveComp("video");
          mcqCompleted = true;
          //   setMcqCompleted(true);
          setInterviewStarted(true);
          setIsLoading(false);
          startVideoRecording(res);
          startListening(res);
          startAudioRecording();
          handleQuestion(formatInterviewQuestions(), questionNumber);
        })
        .catch((err) => {
          // setWarningAlert(true);
          if (history.location.pathname === "/start-interview") {
            setWarningAlert(true);
          }
        });
      // gettingAudioReady()
      //   .then((res) => {
      //     startAudioRecording(res);
      //   })
      //   .catch((err) => {
      //     setWarningAlert(true);
      //   });
    }
  };

  const gettingVideoReady = () => {
    // if(pause === false){
    if (localStorage.getItem("pauseInterviewVal") == "false") {
      if (window.innerWidth < 800) {
        return navigator.mediaDevices.getUserMedia({
          audio: true,
          video: { width: 400, height: 250 },
        });
      } else if (window.innerWidth < 600) {
        return navigator.mediaDevices.getUserMedia({
          audio: true,
          video: { width: 400, height: 200 },
        });
      } else if (window.innerWidth < 500) {
        return navigator.mediaDevices.getUserMedia({
          audio: true,
          video: { width: 250, height: 200 },
        });
      } else {
        return navigator.mediaDevices.getUserMedia({
          audio: true,
          video: { width: 600, height: 250 },
        });
      }
    }
  };
  // const gettingAudioReady = () => {
  //   return navigator.mediaDevices.getUserMedia({
  //     audio: true,
  //     mimeType: "audio/wav;codecs=pcm",
  //     recorderType: "StereoAudioRecorder",
  //   });
  // };

  // starting video recording
  const startVideoRecording = (camera) => {
    // if(pause === false){
    if (localStorage.getItem("pauseInterviewVal") == "false") {
      if (videoRef.current !== null) {
        videoRef.current.srcObject = camera;
        videoRef.current.setAttribute("muted", true);
        videoRef.current.setAttribute("autoplay", false);
        videoRef.current.setAttribute("controls", false);
      }

      recorder = RecordRTC(camera, {
        type: "video",
      });

      // recognition.start()
      recorder.startRecording();
      recorder.camera = camera;
    }
  };

  const stopVideoRecordingCallback = () => {
    if (videoRef.current !== null) {
      videoRef.current.src = URL.createObjectURL(recorder.getBlob());
      videoRef.current.srcObject = null;
      videoRef.current.setAttribute("muted", true);
      videoRef.current.setAttribute("autoplay", true);
      videoRef.current.setAttribute("controls", true);
    }
    let file = new File([recorder.getBlob()], "video.mp4", {
      type: "video/mp4",
    });
    console.log("testinggggggg", { recorder, file });
    setRecordingVideo(file);
    setInterviewCompleted(true);
    setInterviewStarted(false);
    // setAnswerText(answerText);
    clearInterval(timeInterval);
    clearInterval(maxTimeInterval);
    recorder.camera.stop();
    recorder.destroy();
    speachEvent.stop();

    recorder = null;
  };

  const stopVideoRecording = () => {
    recorder.stopRecording(stopVideoRecordingCallback);
  };

  // start audio recording
  // const startAudioRecording = (audio) => {
  //   audioRecorder = RecordRTC(audio, {
  //     type: "audio/wav;codecs=pcm",
  //   });
  //   audioRecorder.startRecording();
  // };

  // const stopAudioRecorderCallback = () => {
  //   let file = new File(
  //     [audioRecorder.getBlob()],
  //     `answer${questionNumber}.wav`,
  //     {
  //       type: "audio/wav",
  //     }
  //   );
  //   console.log("file", audioRecorder.getBlob());
  //   audioAnswer.push(file);
  //   // console.log("file",answerAudio)
  //   // setAnswerAudio([...answerAudio, `answer${questionNumber}.mp3`]);
  //   // console.log("checking", file);
  //   audioRecorder = null;
  // };
  // const stopAudioReording = () => {
  //   if (audioRecorder) {
  //     audioRecorder.stopRecording(stopAudioRecorderCallback);
  //   }
  // };
  const startAudioRecording = () => {
    if (localStorage.getItem("pauseInterviewVal") == "false") {
      recognition.start();
      recognition.onstart = () => {
        console.log("onstart", recognition);
      };
      recognition.onend = () => {
        // console.log('onend')
        // console.log({ isRecognizationEnded, recognition })
        // if (!isRecognizationEnded) {
        //   setIsRecognizationEnded(!isRecognizationEnded);
        // }
      };
      recognition.onresult = (event) => {
        console.log("onresult");
        const transcript = event.results[0][0].transcript;
        audioAnswer = audioAnswer + transcript;
      };
      startSpeechToText();
    }
  };

  const stopAudioRecording = () => {
    if (recognition) {
      console.log("stop2");
      recognition.stop();
    }
    recognition = undefined;
    if (
      platform === "Macintosh" ||
      platform === "MacIntel" ||
      platform === "MacPPC" ||
      platform === "Mac68K" ||
      platform === "iPhone" ||
      platform === "iPad" ||
      platform === "iPod"
    ) {
      recognition = window.webkitSpeechRecognition;
    } else {
      SpeechRecognition = window.webkitSpeechRecognition;
      recognition = new SpeechRecognition();
    }
  };

  const speak1 = (voice, dataToSpeak) => {
    console.log("speak1");
    // if(pause === false){
    if (localStorage.getItem("pauseInterviewVal") == "false") {
      console.log("speak2");
      setQuestion(dataToSpeak);
      toSpeak = speak({ text: dataToSpeak });
      if (toSpeak) {
        toSpeak.pitch = 0.5;
        toSpeak.rate = 1;
        toSpeak.voice = voice;
        toSpeak.onstart = (e) => {
          if (audioAnswer !== "") {
            audioAnswer = audioAnswer + "+";
          }
        };
        toSpeak.onend = (e) => {
          if (recorder.getState() !== "stopped") {
            if (timeInterval || maxTimeInterval) {
              clearInterval(timeInterval);
              clearInterval(maxTimeInterval);
            }
            startTimer("speak end");
            startMaxTimer("speak end");
          }
        };
        tts.speak(toSpeak);
      }
    }
  };

  const startTimer = (msg) => {
    // if(pause === false){
    if (localStorage.getItem("pauseInterviewVal") == "false") {
      if (history.location.pathname === "/start-interview") {
        timeInterval = setInterval(() => {
          // if(pause){
          setTimer(--timer);
          // }
          if (timer === 0) {
            stopAudioRecording();
            moveToNext("minitimer");
          }
        }, 1000);
      } else {
        console.log("speak11");
        if (toSpeak) {
          toSpeak.volume = 0;
        }
        if (recorder) {
          // stopAudioRecording();
          stopVideoRecording();
        }
      }
    }
  };

  const startMaxTimer = (msg) => {
    // if(pause === false){
    if (localStorage.getItem("pauseInterviewVal") == "false") {
      if (msg === "pause") {
        clearInterval(maxTimeInterval);
      }
      maxTimeInterval = setInterval(() => {
        // if(pause){
        setMaxTime(--maxTime);
        // }

        if (maxTime === 0) {
          setIsMaxTimeReached(true);
          clearInterval(maxTimeInterval);
          setMaxTime(120);
          maxTime = 120;
          setTimeout(() => {
            if (mcqCompleted) {
              stopAudioRecording();
              setIsMaxTimeReached(false);
              moveToNext("maxtimer");
            } else {
              submitAnswer(
                questionsForInterview.mcqQuestion[mcqQuestionNumber].name,
                answer
              );
            }
          }, 2000);
        }
      }, 1000);
    }
  };

  const moveToNext = (a) => {
    // if(pause === false){
    if (localStorage.getItem("pauseInterviewVal") == "false") {
      setTimer(10);
      timer = 10;
      clearInterval(timeInterval);
      setMaxTime(120);
      maxTime = 120;
      clearInterval(maxTimeInterval);
      questionNumber = questionNumber + 1;
      setQuestionNumber(questionNumber);
      totalQuestionNumber = totalQuestionNumber + 1;
      // setTotalQuestionNumber(totalQuestionNumber + 1);
      mergeQuestion();
      // recognition.start()
      // { !interviewCompleated && startAudioRecording() };
      handleQuestion(formatInterviewQuestions(), questionNumber);
    }
  };

  const handleQuestion = (questions, questionNumber) => {
    if (questions.length - 1 >= questionNumber) {
      let question = questionNumber;
      let voice = getVoices();
      console.log("speak12");
      speak1(voice[0], questions[question]);
      // gettingAudioReady().then((res) => {
      //   startAudioRecording(res);
      //   let question = questionNumber;
      //   let voice = getVoices();
      //   speak(voice[0], questions[question]);
      // });
    } else {
      if (recorder) {
        stopVideoRecording();
        clearInterval(timeInterval);
        clearInterval(maxTimeInterval);
        tts.cancel();
      }
    }
  };

  const formatInterviewQuestions = () => {
    if (questionsForInterview) {
      let combineedQuestion = [];
      if (
        questionsForInterview.skillBasedQuestions &&
        questionsForInterview.skillBasedQuestions.length > 0
      ) {
        combineedQuestion = [
          ...combineedQuestion,
          ...questionsForInterview.skillBasedQuestions.map((item) => {
            return item.name;
          }),
        ];
      }
      if (
        questionsForInterview.personalQuestions &&
        questionsForInterview.personalQuestions.length > 0
      ) {
        combineedQuestion = [
          ...combineedQuestion,
          ...questionsForInterview.personalQuestions.map((item) => {
            return item.name;
          }),
        ];
      }
      return combineedQuestion;
    }
  };

  const startListening = (camera) => {
    // if(pause === false){
    if (localStorage.getItem("pauseInterviewVal") == "false") {
      speachEvent = Hark(camera, {});

      speachEvent.on("speaking", () => {
        clearInterval(timeInterval);
        setTimer(10);
        timer = 10;
      });
      speachEvent.on("stopped_speaking", () => {
        if (timeInterval) {
          clearInterval(timeInterval);
          setTimer(10);
          timer = 10;
        }
        startTimer("start listening");
      });
    }
  };

  const submitAnswer = (name, answer) => {
    submittedMcq.push({ question: name, answer: answer });
    clearInterval(maxTimeInterval);
    setMaxTime(120);
    maxTime = 120;
    radioRefOne.current.checked = false;
    radioRefTwo.current.checked = false;
    radioRefThree.current.checked = false;
    radioRefFour.current.checked = false;

    if (mcqQuestionNumber === questionsForInterview.mcqQuestion.length - 1) {
      setIsLoading(true);
      setActiveComp("video");
      mcqCompleted = true;
      //   setMcqCompleted(true);
      gettingVideoReady()
        .then((res) => {
          setInterviewStarted(true);
          setIsLoading(false);
          startVideoRecording(res);
          startListening(res);
          totalQuestionNumber = totalQuestionNumber + 1;
          startAudioRecording();
          //   setTotalQuestionNumber(totalQuestionNumber + 1);
          mergeQuestion();
          handleQuestion(formatInterviewQuestions(), questionNumber);
        })
        .catch((err) => {
          if (history.location.pathname === "/start-interview") {
            setWarningAlert(true);
          }
        });
      // gettingAudioReady()
      //   .then((res) => {
      //     setIsLoading(false);
      //     startAudioRecording(res);
      //   })
      //   .catch((err) => {
      //     setWarningAlert(true);
      //   });
    } else if (
      mcqQuestionNumber <
      questionsForInterview.mcqQuestion.length - 1
    ) {
      if (history.location.pathname === "/start-interview") {
        startMaxTimer("mcq");
      }
      totalQuestionNumber = totalQuestionNumber + 1;
      //   setTotalQuestionNumber(totalQuestionNumber + 1);
      mergeQuestion();

      mcqQuestionNumber = mcqQuestionNumber + 1;

      //   setMcqQuestionNumber(mcqQuestionNumber + 1);
    }
  };

  const skipQuestion = () => {
    setTimeout(() => {
      // <ul>
      //   {results.map((result) => (
      //     <li key={result.timestamp}>{result.transcript}</li>
      //   ))}
      //   {interimResult && <li>{interimResult}</li>}
      // </ul>
      let textaudio = "";
      results.map((result) => {
        textaudio += result.transcript;
      });
      console.log("sadhvi1", results);
      const abc = [...questionAnswer, textaudio];
      console.log("sadhvi", abc);
      setQuestionAnswer(abc);

      myAnswer.push(audioAnswer);
      textaudio = "";
      // stopAudioRecording();
      moveToNext();
    }, 200);
  };

  const submitData = async () => {
    clearInterval(timeInterval);
    clearInterval(maxTimeInterval);
    tts.cancel();
    let formData = new FormData();
    formData.append("file", recordingVideo);
    setIsLoading(true);
    dispatch(
      actions.submitAnswerVideo(formData, history.location.state.ApplicationId)
    );
  };

  const submitData1 = () => {
    setTimeout(() => {
      let textaudio = "";
      results.map((result) => {
        textaudio += result.transcript;
      });
      console.log("sadhvi1", results);
      const abc = [...questionAnswer, textaudio];
      console.log("sadhvi", abc);
      setQuestionAnswer(abc);

      myAnswer.push(audioAnswer);
      textaudio = "";
      // stopAudioRecording();

      console.log("formatInterviewQuestions", questionsForInterview);
      let question = formatInterviewQuestions();
      let answers = audioAnswer.split("+");
      stopSpeechToText();

      let finalAnswer = [];
      console.log("rahul", abc);
      for (let i = 1; i <= abc.length - 1; i++) {
        let test = abc[i];
        test = test.replace(abc[i - 1], "");

        finalAnswer.push(test.trim());
      }
      finalAnswer.unshift(abc[0]);

      console.log("akangcha", finalAnswer);
      finalAnswer = finalAnswer.filter((answer) => answer !== "");

      console.log("myAnswer", myAnswer, audioAnswer);
      let dataToUpload = question.map((item, i) => {
        // let audioFile = new FormData();
        // audioFile.append("audio", item);
        return {
          question: item,
          // answer: myAnswer[i] ? myAnswer[i] : '',
          answer: finalAnswer[i],
        };
      });
      console.log(" final answer", dataToUpload);
      let questionsWithAnswer = [...dataToUpload, ...submittedMcq];
      console.log("checking final answer", questionsWithAnswer);

      // dispatch(actions.candidateAnswerAudio(dataToUpload));
      // let questionsWithAnswer = question.map((item, i) => {
      //   return {
      //     question: item,
      //     answer: audioAnswer[i],
      //   };
      // });

      // dispatch(
      //   actions.submitCandidateInterview({
      //     answers: questionsWithAnswer,
      //     applicationId: history.location.state.ApplicationId,
      //   })
      // );

      let x = JSON.parse(localStorage.getItem("InterviewCompleted"));
      x.push(history.location.state.ApplicationId);
      localStorage.setItem("InterviewCompleted", JSON.stringify(x));
    }, 200);
  };

  useEffect(() => {
    if (interviewVideoSubmitted) {
      submitData1();
    }
  }, [interviewVideoSubmitted]);

  useEffect(() => {
    if (audioUploaded) {
      let question = formatInterviewQuestions();
      let questionsWithAnswer = question.map((item, i) => {
        return {
          question: item,
          answer: audioPath[i],
        };
      });
      questionsWithAnswer = [...questionsWithAnswer, ...submittedMcq];
      console.log("useeffect", questionsWithAnswer);
      dispatch(
        actions.submitCandidateInterview({
          answers: questionsWithAnswer,
          applicationId: history.location.state.ApplicationId,
        })
      );
    }
  }, [audioUploaded]);

  const pauseInterview = () => {
    console.log("pause", pause);
    setPause(!pause);
    if (!pause) {
      clearInterval(timeInterval);
      clearInterval(maxTimeInterval);
    } else {
      if (history.location.pathname === "/start-interview") {
        if (activeComp === "video") {
          startTimer("pause");
          startMaxTimer("pause");
        } else {
          startMaxTimer("pause");
        }
      }
    }
    console.log("pause2", pause);
  };

  useEffect(() => {
    return () => {
      tts.cancel();
      // clearInterval(timeInterval);
      // clearInterval(maxTimeInterval);
    };
  }, []);

  //

  window.onbeforeunload = (event) => {
    localStorage.setItem("pageReloaded", true);
    submitData();
  };

  let indexQuestion = (
    <React.Fragment>
      {totalQuestion.map((item, index) => {
        return (
          <div
            className={classes.index}
            style={{
              background: index <= totalQuestionNumber && "#6a68d2",
              color: index <= totalQuestionNumber && "#fff",
            }}
          >
            {index + 1}
          </div>
        );
      })}
    </React.Fragment>
  );

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <div className={classes.left}>
        <div className={classes.title}>
          Welcome {localStorage.getItem("User")}
        </div>
        
        <NavigationPrompt
          when={interviewStarted}
          submit={submitData}
          pauseInterview={pauseInterview}
        >
          {(isOpen, onConfirm, onCancel, isLeave) => (
            <React.Fragment>
              <Modal isOpen={isOpen} style={customStyles}>
                <div>
                  <p>
                    {isLeave
                      ? "Suspicious activity detected. Your interview will be submitted. "
                      : "Switching tabs is not permitted."}
                  </p>
                  {isLeave && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        borderRadius: "10px",
                        width: "80%",
                        margin: "auto",
                      }}
                    >
                      <button
                        onClick={onConfirm}
                        style={{
                          background: "#5f5dbd",
                          borderRadius: "50px",
                          border: "none",
                          padding: "10px 20px",
                          color: "#fff",
                          cursor: "pointer",
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  )}
                  {!isLeave && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderRadius: "10px",
                        width: "80%",
                        margin: "auto",
                      }}
                    >
                      <button
                        onClick={onCancel}
                        style={{
                          background: "#5f5dbd",
                          borderRadius: "50px",
                          border: "none",
                          padding: "10px",
                          color: "#fff",
                          cursor: "pointer",
                        }}
                      >
                        Ok
                      </button>
                      <button
                        onClick={onConfirm}
                        style={{
                          background: "#5f5dbd",
                          borderRadius: "50px",
                          border: "none",
                          padding: "10px 20px",
                          color: "#fff",
                          cursor: "pointer",
                        }}
                      >
                        leave
                      </button>
                    </div>
                  )}
                </div>
              </Modal>
            </React.Fragment>
          )}
        </NavigationPrompt>
        {alert && interviewSubmited && (
          <SuccessAlert show={true} msg={msg} confirm={confirmAlert} />
        )}

        {alert && errorMsg && (
          <ErrorAlert show={true} msg={errorMsg} confirm={confirmAlert} />
        )}

        {warningAlert && (
          <ErrorAlert
            show={true}
            msg={"Please Enable Microphone & Camera Permissions"}
            confirm={() => setWarningAlert(false)}
          />
        )}
        {interviewStarted && !interviewCompleated && (
          <div
            className={classes.maxTimerOuter}
            style={{
              color: maxTime < 10 && "red",
              border: maxTime < 10 && "2px solid red",
            }}
          >
            <div>
              <i
                class="fa fa-clock-o"
                aria-hidden="true"
                style={{ color: "yellow", fontSize: "2em" }}
              ></i>
            </div>
            <div> Time Remaining</div>
            <div>{maxTime}</div>
          </div>
        )}

        <div className={classes.extraWrapper}>
          {(interviewStarted || interviewCompleated) && (
            <div>
              <div className={classes.questionIndexs}>
                {!interviewCompleated ? (
                  <div>
                    <div className={classes.questionText}>Questions:</div>
                    <div className={classes.questionIndex}>{indexQuestion}</div>
                  </div>
                ) : (
                  <div className={classes.questionText}>Preview Video</div>
                )}
              </div>

              {activeComp === "mcq" && interviewStarted && (
                <div className={classes.left}>
                  <div className={classes.testInterviewWrapper}>
                    <div className={classes.question}>
                      {
                        questionsForInterview.mcqQuestion[mcqQuestionNumber]
                          .name
                      }
                    </div>
                    <div className={classes.options}>
                      <div className={classes.option}>
                        <input
                          ref={radioRefOne}
                          type="radio"
                          name="option"
                          value={
                            questionsForInterview.mcqQuestion[mcqQuestionNumber]
                              .options[0]
                          }
                          onChange={() =>
                            setAnswer(
                              questionsForInterview.mcqQuestion[
                                mcqQuestionNumber
                              ].options[0]
                            )
                          }
                        />{" "}
                        &nbsp;{" "}
                        {
                          questionsForInterview.mcqQuestion[mcqQuestionNumber]
                            .options[0]
                        }
                      </div>
                      <div className={classes.option}>
                        <input
                          ref={radioRefTwo}
                          type="radio"
                          name="option"
                          value={
                            questionsForInterview.mcqQuestion[mcqQuestionNumber]
                              .options[1]
                          }
                          onChange={() =>
                            setAnswer(
                              questionsForInterview.mcqQuestion[
                                mcqQuestionNumber
                              ].options[1]
                            )
                          }
                        />{" "}
                        &nbsp;{" "}
                        {
                          questionsForInterview.mcqQuestion[mcqQuestionNumber]
                            .options[1]
                        }
                      </div>
                      <div className={classes.option}>
                        <input
                          ref={radioRefThree}
                          type="radio"
                          name="option"
                          value={
                            questionsForInterview.mcqQuestion[mcqQuestionNumber]
                              .options[2]
                          }
                          onChange={() =>
                            setAnswer(
                              questionsForInterview.mcqQuestion[
                                mcqQuestionNumber
                              ].options[2]
                            )
                          }
                        />{" "}
                        &nbsp;{" "}
                        {
                          questionsForInterview.mcqQuestion[mcqQuestionNumber]
                            .options[2]
                        }
                      </div>
                      <div className={classes.option}>
                        <input
                          ref={radioRefFour}
                          type="radio"
                          name="option"
                          value={
                            questionsForInterview.mcqQuestion[mcqQuestionNumber]
                              .options[3]
                          }
                          onChange={() =>
                            setAnswer(
                              questionsForInterview.mcqQuestion[
                                mcqQuestionNumber
                              ].options[3]
                            )
                          }
                        />{" "}
                        &nbsp;{" "}
                        {
                          questionsForInterview.mcqQuestion[mcqQuestionNumber]
                            .options[3]
                        }
                      </div>
                    </div>
                    <div
                      className={classes.submitBtn}
                      style={{ marginTop: "5vh", marginBottom: "5vh" }}
                    >
                      <button
                        onClick={() =>
                          submitAnswer(
                            questionsForInterview.mcqQuestion[mcqQuestionNumber]
                              .name,
                            answer
                          )
                        }
                      >
                        Next
                      </button>
                      <br />
                      {/* <div>Note : Please review your response and edit if needed before proceeding to the next question.
                            <br />  You will not be able to return to a previous question.</div> */}
                    </div>
                  </div>
                </div>
                // <Mcq
                //   questions={
                //     questionsForInterview &&
                //     questionsForInterview.mcqQuestion[mcqQuestionNumber]
                //   }
                //   submitMcq={submitMcq}
                // />
              )}
              {(interviewStarted || interviewCompleated) &&
                mcqCompleted &&
                activeComp === "video" && (
                  <div className={classes.videoShow}>
                    <div className={classes.testInterviewWrapper}>
                      {interviewStarted && !interviewCompleated && (
                        <div className={classes.question}>{question}</div>
                      )}
                      {/* {interviewStarted && !interviewCompleated && (
                  <div className={classes.timer}>{timer}</div>
                )} */}
                      <div className={classes.videoBox}>
                        <video
                          className={classes.video}
                          ref={videoRef}
                          muted
                          playsinline
                        ></video>
                      </div>
                      {interviewCompleated && (
                        <div
                          className={classes.submitBtn}
                          style={{ marginBottom: "5vh" }}
                        >
                          <button onClick={submitData}>Submit</button>
                        </div>
                      )}
                      {interviewStarted && mcqCompleted && (
                        <div
                          className={classes.submitBtn}
                          style={{ marginBottom: "5vh" }}
                        >
                          <button
                            onClick={skipQuestion}
                            // style={{ pointerEvents: timer > 7 && "none" }}
                          >
                            Next
                          </button>
                          <br />
                          {/* <div>Note : Please review your response and edit if needed before proceeding to the next question.
                            <br />  You will not be able to return to a previous question.</div> */}
                        </div>
                      )}
                    </div>
                  </div>
                )}
            </div>
          )}
          {interviewStarted && !interviewCompleated && (
            <div
              className={classes.maxTimer}
              style={{
                color: maxTime < 10 && "red",
                border: maxTime < 10 && "2px solid red",
              }}
            >
              <div>
                <i
                  class="fa fa-clock-o"
                  aria-hidden="true"
                  style={{ color: "yellow", fontSize: "2em" }}
                ></i>
              </div>
              <div> Time Remaining</div>
              <div>{maxTime}</div>
            </div>
          )}
        </div>

        {/* {interviewStarted && mcqCompleted && !interviewCompleated && (
          <div
            className={classes.maxTimer}
            style={{
              color: maxTime < 10 && "red",
              border: maxTime < 10 && "2px solid red",
            }}
          >
            <div> Time Remaining</div>
            <div>{maxTime}</div>
          </div>
        )} */}

        {!interviewStarted && !interviewCompleated && (
          <div className={classes.instruction}>
            To Start the Interview Please click on below button
          </div>
        )}

        {!interviewStarted && !interviewCompleated && (
          <div className={classes.submitBtn} style={{ marginTop: "20vh" }}>
            <button onClick={startInterview}>Start Interview</button>
          </div>
        )}
      </div>
    );
  }
};

export default withRouter(InterviewComp);
