import React, { useState, useEffect } from "react";
import classes from "./Jobs.module.css";
import JobsInfoCard from "../../../components/JobInfoCard/JobInfoCard";
import JobDescriptionCard from "../../../components/JobDescriptionCard/JobDescriptionCard";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../Store/Actions/index";
import * as actionTypes from "../../../Store/Actions/ActionTypes";
import Loader from "../../../common/Loader/Loader";
import Toast from "../../../common/Toast/Toast";
import Utility from "../../../Utility/Utility";
import { withRouter } from "react-router-dom";
import SuccessAlert from "../../../common/SuccessAlert/SuccessAlert";
import ErrorAlert from "../../../common/ErrorAlert/ErrorAlert";
import WarningAlert from "../../../common/WarningAlert/WarningAlert";

const Jobs = ({ history }) => {
  const roleType = Utility.parseJwt(localStorage.getItem("Token")).roleType;
  let dispatch = useDispatch();
  const [alert, setAlert] = useState(false);
  const [inLoader,setInLoader] = useState(false);
  const [activeIndex, setIsActiveIndex] = useState(0);
  const [isClosedAvailable, setIsClosedAvailable] = useState(false);
  const [closeJobCount, setCloseJobCount] = useState(0);
  const [openJobCount, setOpenJobCount] = useState(0);
  const [warnAlert, setWarnAlert] = useState(false);
  const [warningMessage, setWarningMessage] = useState(false);
  const [actionId,setActionId] = useState(false);

  const {
    postedJobs,
    error,
    errorMsg,
    masterPostedJobs,
    jobClosed,
    msg,
    jobPreview,
    jobReposted,
    jobDeleted,
    loader,
  } = useSelector((state) => ({
    loader: state.CommonReducer.loader,
    postedJobs: state.CommonReducer.postedJobs,
    masterPostedJobs: state.CommonReducer.masterPostedJobs,
    error: state.CommonReducer.error,
    errorMsg: state.CommonReducer.errorMsg,
    msg: state.CommonReducer.msg,
    jobClosed: state.CommonReducer.jobClosed,
    jobPreview: state.CreateJobReducer.jobPreview,
    jobReposted: state.CommonReducer.jobReposted,
    jobDeleted: state.CommonReducer.jobDeleted,
  }));

  useEffect(() => {
    window.scrollTo(0,0);
    dispatch(actions.getPostedJobs(roleType));
    dispatch(actions.getMasterSkills());
    dispatch(actions.getMasterCommunicationModeList());
    dispatch(actions.getMasterOfferedBenefitsList());
    dispatch(actions.getMasterRelocationBenefitsList());
    dispatch(actions.getMasterCountryList());
    dispatch(actions.getMasterStateList());
    dispatch(actions.getMasterCityList());
    dispatch(actions.getEmployerDetails());
    dispatch(actions.getMasterBoardingRequirementsList());
    dispatch(actions.getInterviewQuestions());
  }, []);

  useEffect(() => {
    if (postedJobs && postedJobs.length >= 0 && window.innerWidth > 800) {
      if (activeIndex === 0 && postedJobs.length > 0) {
        dispatch(actions.getCreateJobPreview(postedJobs[0]._id));
      }
    }
  }, [postedJobs]);

  // useEffect(() => {
  //   if (jobDeleted) {
  //     dispatch({ type: actionTypes.TRASH });
  //     dispatch(actions.getPostedJobs(roleType));
  //   }
  // }, [jobDeleted]);

  useEffect(() => {
    if (errorMsg || jobClosed || jobReposted || jobDeleted) {
      setInLoader(false)
      setActionId('');
      setWarningMessage('');
      setAlert(true)
      // setToast(
      //   true,
      //   setTimeout(() => {
      //     dispatch({ type: actionTypes.CLEAR });
      //     setToast(false);
      //   }, 3000)
      // );
    }
  }, [errorMsg, jobClosed, jobReposted, jobDeleted]);

  const sendToCreateJob = () => {
    history.push("/create-job");
  };

  const getJobDetails = (index, jobId) => {
    if (window.innerWidth > 800) {
      dispatch({ type: actionTypes.TRASH });
      dispatch(actions.getCreateJobPreview(jobId));
      setIsActiveIndex(index);
    } else {
      history.push("/job-description", { jobId: jobId });
    }
  };

  const deleteJob = (id) => {
    setWarningMessage('Are you sure you want to delete this job?')
    setActionId(id)
    setWarnAlert(true)
  };
  const closeJob = (id) => {
    setWarningMessage('Are you sure you want to close this job?')
    setActionId(id)
    setWarnAlert(true)
  };
  const repostJob = (id) => {
    setWarningMessage('Are you sure you want to repost this job?')
    setActionId(id)
    setWarnAlert(true)
    
  };

  const sendToUpdateJob = (id) => {
    history.push("/update-job", { jobId: id });
  };

  useEffect(() => {
    if (masterPostedJobs) {
      let closeCount = 0;
      let openCount = 0;
      masterPostedJobs.map((item) => {
        if (item.isActive === false) {
          closeCount = closeCount + 1;
        }
      });
      masterPostedJobs.map((item) => {
        if (item.isActive === true) {
          openCount = openCount + 1;
        }
      });
      setCloseJobCount(closeCount);
      setOpenJobCount(openCount);
    }
  }, [masterPostedJobs]);

  useEffect(() => {
    return () => {
      dispatch({type:actionTypes.TRASH})
    }
  },[])


  const filterJobs = (e) => {
    if (e.target.value === "allJobs") {
      dispatch({ type: actionTypes.ALL_JOB_FILTER });
    } else if (e.target.value === "closeJob") {
      dispatch({ type: actionTypes.CLOSE_JOB_FILTER });
    } else if (e.target.value === "openJob") {
      dispatch({ type: actionTypes.OPEN_JOB_FILTER });
    }
  };


  const warnConfirm = () => {
    setWarnAlert(false)
    if(warningMessage === 'Are you sure you want to close this job?'){
      dispatch(actions.closeJob({ refJobId: actionId }));
    }else if(warningMessage === 'Are you sure you want to repost this job?'){
      dispatch(actions.repostedJob({ refJobId: actionId }));
    }else if(warningMessage === 'Are you sure you want to delete this job?'){
      setInLoader(true)
      dispatch(actions.deleteJob({ jobId: actionId }));
    }
  }
  const cancel = () => {
    setWarnAlert(false)
  }

  const confirm = () => {
    setAlert(false);
    dispatch({ type: actionTypes.CLEAR });
    if(jobDeleted){
      dispatch({ type: actionTypes.TRASH });
      dispatch(actions.getPostedJobs(roleType));
    }
  }

  if (loader) {
    return <Loader />;
  } else if (postedJobs) {
    return (
      <div className={classes.jobs}>
        
        {postedJobs.length === 0 && (
          <div className={classes.noData}>
            There are no jobs posted till now.
            <span onClick={sendToCreateJob}>Create One</span>
          </div>
        )}
        {postedJobs.length !== 0 && (
          <div className={classes.allJobs}>
            <div className={classes.jobCard}>
              <div>
                <div className={classes.filter}>
                  <span>Total Jobs</span>&nbsp;&nbsp;
                  <span>{postedJobs.length}</span>
                  <select onChange={filterJobs}>
                    <option selected disabled>
                      Filter By
                    </option>
                    <option value="allJobs">All</option>
                    {closeJobCount > 0 && (
                      <option value="closeJob">Closed Jobs</option>
                    )}
                    {openJobCount > 0 && (
                      <option value="openJob">Open Jobs</option>
                    )}
                  </select>
                </div>
              </div>
              <div className={classes.card}>
                {postedJobs.map((job, index) => {
                  return (
                    <JobsInfoCard
                      key={index}
                      roleType="employer"
                      id={job._id}
                      index={index}
                      jobTitle={job.role}
                      isActive={job.isActive}
                      postedDate={job.publishedDate}
                      deleteJob={deleteJob}
                      closeJob={closeJob}
                      repostJob={repostJob}
                      getJobDetails={getJobDetails}
                      cardActive={activeIndex === index ? true : false}
                      sendToUpdateJob={sendToUpdateJob}
                    />
                  );
                })}
              </div>
            </div>
            <div className={classes.jobDescription}>
              <JobDescriptionCard jobDetail={jobPreview} roleType="employer" />
            </div>
          </div>
        )}
        {
          warnAlert &&
          <WarningAlert show={true} msg={warningMessage} confirm={warnConfirm} cancel={cancel} />
        }
        {alert && error && errorMsg && (
          <ErrorAlert show={true} msg={errorMsg} confirm={confirm}/>
          // <Toast display={toast} type="danger" message={errorMsg} />
        )}
        {alert && (jobClosed || jobReposted || jobDeleted) && (
          <SuccessAlert show={true} msg={msg} confirm={confirm}/>
          // <Toast display={toast} type="success" message={msg} />
        )}
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default withRouter(Jobs);
