import React, { useState } from "react";
import classes from "./Header.module.css";
import Menu from "./Menu";
import Utility from "../../Utility/Utility";
import logo from "../../Assets/Images/logo.png";
import menu from "../../Assets/Images/menu.png";
import { NavLink, withRouter } from "react-router-dom";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";

const Header = (props) => {
  const [isActive, setActive] = useState(false);
  const [isConfirmActive, setIsConfirmActive] = useState(false);

  const go_to = (page_name) => {
    setActive(false)
    props.history.push(`/${page_name}`);
  };

  const toogle = () => {
    setActive(false)
  }

  const logout = () => {
    setActive(false)
    setIsConfirmActive(!isConfirmActive);
  };

  const confirmLogout = () => {
    localStorage.removeItem("Token");
    localStorage.removeItem("User");
    localStorage.removeItem("candidateProfielAvailabe");
    localStorage.clear();
    window.location.replace("/login");
  };

  return (
    <React.Fragment>
      {!Utility.isMobile() && (
        <div className={classes.header}>
          <div className={classes.left}>
            <img src={logo} alt="logo" onClick={()=>props.history.push('/')}/>
          </div>
          <div className={classes.middle}>
            <Menu />
          </div>
          <div className={classes.right}>
            {!localStorage.getItem("Token") && (
              <div className={classes.buttons}>
                <button
                  className={classes.login}
                  onClick={() => go_to("login")}
                >
                  Login
                </button>
                <button
                  className={classes.register}
                  onClick={() => go_to("register")}
                >
                  Register
                </button>
              </div>
            )}
            {localStorage.getItem("Token") && (
              <div className={classes.afterLogin}>
                <div className={classes.name}>{`Welcome ${localStorage.getItem(
                  "User"
                )}`}</div>
                <div className={classes.logout}>
                  <button className={classes.register} onClick={logout}>
                    Log Out
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {Utility.isMobile() && (
        <div className={classes.mobileHeader}>
          <div className={classes.top}>
            <div className={classes.menuBtn}>
              <img src={menu} alt="logo" onClick={() => setActive(!isActive)} />
            </div>
            <div className={classes.logo}>
              <img src={logo} alt="logo" onClick={()=>props.history.push('/')}/>
            </div>
          </div>
          <div
            className={
              isActive ? classes.mobileMiddle : classes.mobileMiddleDisabled
            }
          >
            <Menu toogle={toogle}/>
            {!localStorage.getItem("Token") && (
              <div className={classes.mobileBtn}>
                <button
                  className={classes.mobileLogin}
                  onClick={() => go_to("login")}
                >
                  Login
                </button>
                <button
                  className={classes.mobileRegister}
                  onClick={() => go_to("register")}
                >
                  Register
                </button>
              </div>
            )}
            {localStorage.getItem("Token") && (
              <div className={classes.mobileAfterLogin}>
                <div className={classes.name}>{`Welcome ${localStorage.getItem(
                  "User"
                )}`}</div>
                <div className={classes.logout}>
                  <button className={classes.mobileRegister} onClick={logout}>
                    Log Out
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {isConfirmActive && (
        <ConfirmModal
          text="Are you sure you want to logout?"
          onCancel={logout}
          onConfirm={confirmLogout}
        />
      )}
    </React.Fragment>
  );
};

export default withRouter(Header);
