import React from "react";
import classes from "./MiscInformation.module.css";
import Utility from "../../../Utility/Utility";
import { useSelector, useDispatch } from "react-redux";

const MiscInformation = ({ miscInfo, roleType }) => {
  const { masterOfferedBenifitsList, masterRelocationBenifitsList } =
    useSelector((state) => ({
      masterOfferedBenifitsList: state.MasterReducer.masterOfferedBenifitsList,
      masterRelocationBenifitsList:
        state.MasterReducer.masterRelocationBenifitsList,
    }));

  const forEmployerRender = () => {
    return (
      <div className={classes.miscInformation}>
        <div className={classes.titleOne}>Misc Information</div>
        <div className={classes.title}>
          <div>Misc. Information:</div>
          {roleType !== "candidate" && <div>Display To Candidates</div>}
        </div>

        <div
          className={roleType !== "candidate" ? classes.data : classes.dataOne}
        >
          <div>Hourly Rates:</div>
          <div className={classes.subText}>
            {miscInfo.hourlyRate ? miscInfo.hourlyRate : "0"}(USD)
          </div>
          {roleType !== "candidate" && (
            <div className={classes.last}>
              {miscInfo.displayHourTocandidate ? "Yes" : "No"}
            </div>
          )}
        </div>

        <div
          className={roleType !== "candidate" ? classes.data : classes.dataOne}
        >
          <div>Given position qualifies for the over time:</div>
          <div className={classes.subText}>
            {miscInfo.positionQualifiesForOvertime === "true" ? "Yes" : "No"}
          </div>
          {roleType !== "candidate" && (
            <div className={classes.last}>
              {miscInfo.displayPositionQualifiesForOvertime ? "Yes" : "No"}
            </div>
          )}
        </div>

        <div
          className={roleType !== "candidate" ? classes.data : classes.dataOne}
        >
          <div>Are you able to sponsor applicants who require work visas:</div>
          <div className={classes.subText}>
            {miscInfo.workVisaSponcer === "true" ? "Yes" : "No"}
          </div>
          {roleType !== "candidate" && (
            <div className={classes.last}>
              {miscInfo.displayWorkVisaSponcer ? "Yes" : "No"}
            </div>
          )}
        </div>

        <div
          className={roleType !== "candidate" ? classes.data : classes.dataOne}
        >
          <div>Benefits to be offered by Company:</div>
          <div>
            {miscInfo.offeredBenefits.length > 0 &&
              miscInfo.offeredBenefits.map((item, index) => {
                return (
                  <div className={classes.subText}>
                    <i class="fa fa-circle" aria-hidden="true"></i>{" "}
                    {Utility.getQuestionName(masterOfferedBenifitsList, item)}
                  </div>
                );
              })}
            {miscInfo.offeredBenefits.length === 0 && (
              <div className={classes.subText}>
                Offered Benefits not available
              </div>
            )}
          </div>
          {roleType !== "candidate" && (
            <div className={classes.last}>
              {miscInfo.displayOfferedBenifits ? "Yes" : "No"}
            </div>
          )}
        </div>

        <div
          className={roleType !== "candidate" ? classes.data : classes.dataOne}
        >
          <div>Is relocation applicable:</div>
          <div className={classes.subText}>
            {miscInfo.isRelocationApplicable === "true" ? "Yes" : "No"}
          </div>
          {roleType !== "candidate" && (
            <div className={classes.last}>
              {miscInfo.displayRelocationBenifits ? "Yes" : "No"}
            </div>
          )}
        </div>

        <div
          className={roleType !== "candidate" ? classes.data : classes.dataOne}
        >
          <div>Relocation benefits:</div>
          <div>
            {miscInfo.relocationBenefits.length > 0 &&
              miscInfo.relocationBenefits.map((item, index) => {
                return (
                  <div className={classes.subText}>
                    <i class="fa fa-circle" aria-hidden="true"></i>{" "}
                    {Utility.getQuestionName(
                      masterRelocationBenifitsList,
                      item
                    )}
                  </div>
                );
              })}
            {miscInfo.relocationBenefits.length === 0 && (
              <div className={classes.subText}>
                Relocation Benefits not available
              </div>
            )}
          </div>
          {roleType !== "candidate" && <div></div>}
        </div>

        <div
          className={roleType !== "candidate" ? classes.data : classes.dataOne}
        >
          <div>Travel applicable for the job:</div>
          <div className={classes.subText}>
            {miscInfo.isTravelApplicable === "true" ? "Yes" : "No"}
          </div>
          {roleType !== "candidate" && (
            <div className={classes.last}>
              {miscInfo.displayTravelApplicable ? "Yes" : "No"}
            </div>
          )}
        </div>

        <div
          className={roleType !== "candidate" ? classes.data : classes.dataOne}
        >
          <div>Travel Requirement Details:</div>
          <div className={classes.subText}>
            {miscInfo.travelRequirementsDetails
              ? miscInfo.travelRequirementsDetails
              : "No Requirement Detail"}
          </div>
          {roleType !== "candidate" && <div></div>}
        </div>

        <div
          className={roleType !== "candidate" ? classes.data : classes.dataOne}
        >
          <div>Work from home - applicable:</div>
          <div className={classes.subText}>
            {miscInfo.isWFHAvailable === "true" ? "Yes" : "No"}
          </div>
          {roleType !== "candidate" && (
            <div className={classes.last}>
              {miscInfo.displayWorkFromHome ? "Yes" : "No"}
            </div>
          )}
        </div>

        <div
          className={roleType !== "candidate" ? classes.data : classes.dataOne}
        >
          <div>Expected Start Date:</div>
          <div className={classes.subText}>
            {Utility.formatDate(miscInfo.startDate)}
          </div>
          {roleType !== "candidate" && <div></div>}
        </div>

        <div
          className={roleType !== "candidate" ? classes.data : classes.dataOne}
        >
          <div>Additional Notes you want to specify:</div>
          <div className={classes.subText}>
            {!miscInfo.additionalNotes
              ? "No additional notes"
              : miscInfo.additionalNotes}
          </div>
          {roleType !== "candidate" && (
            <div className={classes.last}>
              {miscInfo.displayAdditionNotes ? "Yes" : "No"}
            </div>
          )}
        </div>
      </div>
    );
  };

  const CheckAllValues = () => {
    if (
      (miscInfo.displayHourTocandidate === false ||
        miscInfo.displayHourTocandidate == null) &&
      (miscInfo.displayPositionQualifiesForOvertime === false ||
        miscInfo.displayPositionQualifiesForOvertime == null) &&
      (miscInfo.displayWorkVisaSponcer === false ||
        miscInfo.displayWorkVisaSponcer == null) &&
      (miscInfo.displayOfferedBenifits === false ||
        miscInfo.displayOfferedBenifits == null) &&
      (miscInfo.displayRelocationBenifits === false ||
        miscInfo.displayRelocationBenifits == null) &&
      (miscInfo.displayTravelApplicable === false ||
        miscInfo.displayTravelApplicable == null) &&
      (miscInfo.displayWorkFromHome === false ||
        miscInfo.displayWorkFromHome) &&
      (miscInfo.displayAdditionNotes === false ||
        miscInfo.displayAdditionNotes == null) &&
      (miscInfo.exStartDate ||
        miscInfo.exStartDate === false ||
        miscInfo.exStartDate == null)
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div>
      {/* For Candidate Render */}
      {roleType === "candidate" ? (
        <div>
          {CheckAllValues() ? (
            <div>
              <div className={classes.miscInformation}>
                <div className={classes.titleOne}>Misc Information</div>

                <div className={classes.title}>
                  <div>Misc. Information:</div>
                  {/* {roleType !== "candidate" && <div>Display To Candidates</div>} */}
                </div>

                {miscInfo.displayHourTocandidate ? (
                  <div
                    className={
                      roleType !== "candidate" ? classes.data : classes.dataOne
                    }
                  >
                    <div>Hourly Rates:</div>
                    <div className={classes.subText}>
                      {miscInfo.hourlyRate ? miscInfo.hourlyRate : "0"}(USD)
                    </div>
                    {/* {roleType !== "candidate" && (
                <div className={classes.last}>
                  {miscInfo.displayHourTocandidate ? "Yes" : "No"}
                </div>
              )} */}
                  </div>
                ) : null}

                {miscInfo.displayPositionQualifiesForOvertime ? (
                  <div
                    className={
                      roleType !== "candidate" ? classes.data : classes.dataOne
                    }
                  >
                    <div>Given position qualifies for the over time:</div>
                    <div className={classes.subText}>
                      {miscInfo.positionQualifiesForOvertime === "true"
                        ? "Yes"
                        : "No"}
                    </div>
                    {/* {roleType !== "candidate" && (
                <div className={classes.last}>
                  {miscInfo.displayPositionQualifiesForOvertime ? "Yes" : "No"}
                </div>
              )} */}
                  </div>
                ) : null}

                {miscInfo.displayWorkVisaSponcer ? (
                  <div
                    className={
                      roleType !== "candidate" ? classes.data : classes.dataOne
                    }
                  >
                    <div>
                      Are you able to sponsor applicants who require work visas:
                    </div>
                    <div className={classes.subText}>
                      {miscInfo.workVisaSponcer === "true" ? "Yes" : "No"}
                    </div>
                    {/* {roleType !== "candidate" && (
                <div className={classes.last}>
                  {miscInfo.displayWorkVisaSponcer ? "Yes" : "No"}
                </div>
              )} */}
                  </div>
                ) : null}

                {miscInfo.displayOfferedBenifits ? (
                  <div
                    className={
                      roleType !== "candidate" ? classes.data : classes.dataOne
                    }
                  >
                    <div>Benefits to be offered by Company:</div>
                    <div>
                      {miscInfo.offeredBenefits.length > 0 &&
                        miscInfo.offeredBenefits.map((item, index) => {
                          return (
                            <div className={classes.subText}>
                              <i class="fa fa-circle" aria-hidden="true"></i>{" "}
                              {Utility.getQuestionName(
                                masterOfferedBenifitsList,
                                item
                              )}
                            </div>
                          );
                        })}
                      {miscInfo.offeredBenefits.length === 0 && (
                        <div className={classes.subText}>
                          Offered Benefits not available
                        </div>
                      )}
                    </div>
                    {/* {roleType !== "candidate" && (
                <div className={classes.last}>
                  {miscInfo.displayOfferedBenifits ? "Yes" : "No"}
                </div>
              )} */}
                  </div>
                ) : null}

                {miscInfo.displayRelocationBenifits ? (
                  <div
                    className={
                      roleType !== "candidate" ? classes.data : classes.dataOne
                    }
                  >
                    <div>Is relocation applicable:</div>
                    <div className={classes.subText}>
                      {miscInfo.isRelocationApplicable === "true"
                        ? "Yes"
                        : "No"}
                    </div>
                    {/* {roleType !== "candidate" && (
                <div className={classes.last}>
                  {miscInfo.displayRelocationBenifits ? "Yes" : "No"}
                </div>
              )} */}
                  </div>
                ) : null}

                {miscInfo.displayRelocationBenifits ? (
                  <div
                    className={
                      roleType !== "candidate" ? classes.data : classes.dataOne
                    }
                  >
                    <div>Relocation benefits:</div>
                    <div>
                      {miscInfo.relocationBenefits.length > 0 &&
                        miscInfo.relocationBenefits.map((item, index) => {
                          return (
                            <div className={classes.subText}>
                              <i class="fa fa-circle" aria-hidden="true"></i>{" "}
                              {Utility.getQuestionName(
                                masterRelocationBenifitsList,
                                item
                              )}
                            </div>
                          );
                        })}
                      {miscInfo.relocationBenefits.length === 0 && (
                        <div className={classes.subText}>
                          Relocation Benefits not available
                        </div>
                      )}
                    </div>
                    {/* {roleType !== "candidate" && <div></div>} */}
                  </div>
                ) : null}

                {miscInfo.displayTravelApplicable ? (
                  <div
                    className={
                      roleType !== "candidate" ? classes.data : classes.dataOne
                    }
                  >
                    <div>Travel applicable for the job:</div>
                    <div className={classes.subText}>
                      {miscInfo.isTravelApplicable === "true" ? "Yes" : "No"}
                    </div>
                    {/* {roleType !== "candidate" && (
                <div className={classes.last}>
                  {miscInfo.displayTravelApplicable ? "Yes" : "No"}
                </div>
              )} */}
                  </div>
                ) : null}

                {miscInfo.displayTravelApplicable ? (
                  <div
                    className={
                      roleType !== "candidate" ? classes.data : classes.dataOne
                    }
                  >
                    <div>Travel Requirement Details:</div>
                    <div className={classes.subText}>
                      {miscInfo.travelRequirementsDetails
                        ? miscInfo.travelRequirementsDetails
                        : "No Requirement Detail"}
                    </div>
                    {/* {roleType !== "candidate" && <div></div>} */}
                  </div>
                ) : null}

                {miscInfo.displayWorkFromHome ? (
                  <div
                    className={
                      roleType !== "candidate" ? classes.data : classes.dataOne
                    }
                  >
                    <div>Work from home - applicable:</div>
                    <div className={classes.subText}>
                      {miscInfo.isWFHAvailable === "true" ? "Yes" : "No"}
                    </div>
                    {/* {roleType !== "candidate" && (
                <div className={classes.last}>
                  {miscInfo.displayWorkFromHome ? "Yes" : "No"}
                </div>
              )} */}
                  </div>
                ) : null}
                {miscInfo.exStartDate ? (
                  <div
                    className={
                      roleType !== "candidate" ? classes.data : classes.dataOne
                    }
                  >
                    <div>Expected Start Date:</div>
                    <div className={classes.subText}>
                      {Utility.formatDate(miscInfo.startDate)}
                    </div>
                    {/* {roleType !== "candidate" && <div></div>} */}
                  </div>
                ) : null}

                {miscInfo.displayAdditionNotes ? (
                  <div
                    className={
                      roleType !== "candidate" ? classes.data : classes.dataOne
                    }
                  >
                    <div>Additional Notes you want to specify:</div>
                    <div className={classes.subText}>
                      {!miscInfo.additionalNotes
                        ? "No additional notes"
                        : miscInfo.additionalNotes}
                    </div>
                    {/* {roleType !== "candidate" && (
                <div className={classes.last}>
                  {miscInfo.displayAdditionNotes ? "Yes" : "No"}
                </div>
              )} */}
                  </div>
                ) : null}
              </div>
            </div>
          ) : (
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              No information provided by recruiter
            </div>
          )}
        </div>
      ) : (
        <div>{forEmployerRender()}</div>
      )}
    </div>
  );
};

export default MiscInformation;
