import React, { useState } from "react";
import classes from "./SkillMatrix.module.css";
import Utility from "../../../Utility/DataGenerator";
import pencil from "../../../Assets/Images/pencil.png";
import trash from "../../../Assets/Images/trash.png";
import SearchSuggestions from "./SearchSuggestion";

const SkillStripEdit = ({
  matrixData,
  index,
  setEdit,
  saveSkillMatrix,
  handleAvailableMatrix,
  deleteMatrix,
  masterSkill,
  addNewValue,
  clearError,
}) => {
  const [suggestion, setSuggestion] = useState([]);

  const filteredSuggestion = (e) => {
    if (masterSkill) {
      if (e.target.value !== "") {
        setSuggestion(
          masterSkill.filter((item) => {
            if (
              item.value.toLowerCase().includes(e.target.value.toLowerCase())
            ) {
              return item;
            }
          })
        );
      } else {
        setSuggestion([]);
      }
    }
  };

  return (
    <form onSubmit={(e) => saveSkillMatrix(e, index)}>
      <div className={classes.skillStrip}>
        <div className={classes.index}>{index + 1}</div>
        <div style={{ position: "relative" }}>
          {matrixData && matrixData.isEdit && (
            <React.Fragment>
              <input
                type="text"
                name="value"
                autoComplete="off"
                pattern="^[ A-Za-z.#+2]*$"
                title="Only allowed alphabets and . # + 2"
                required
                value={matrixData.value}
                onChange={(e) => {
                  handleAvailableMatrix(e, index);
                  filteredSuggestion(e);
                  clearError();
                }}
                placeholder="Type your skill"
              />
              <SearchSuggestions
                suggestion={suggestion}
                addNewValue={addNewValue}
                index={index}
                clearSuggestion={() => setSuggestion([])}
              />
            </React.Fragment>
          )}

          {matrixData && !matrixData.isEdit && (
            <span className={classes.text}>{matrixData.value}</span>
          )}
        </div>
        <div className={classes.radio}>
          {matrixData && matrixData.isEdit && (
            <React.Fragment>
              <>
                <input
                  type="radio"
                  name="preference"
                  required
                  onChange={(e) => handleAvailableMatrix(e, index)}
                  checked={matrixData.preference === "Required" && true}
                  value="Required"
                />
                Required
              </>
              &nbsp;
              <>
                <input
                  type="radio"
                  name="preference"
                  required
                  onChange={(e) => handleAvailableMatrix(e, index)}
                  checked={matrixData.preference === "Preferred" && true}
                  value="Preferred"
                />
                Preferred
              </>
            </React.Fragment>
          )}
          {matrixData && !matrixData.isEdit && (
            <span className={classes.text}>{matrixData.preference}</span>
          )}
        </div>
        <div className={classes.select}>
          {matrixData && matrixData.isEdit && (
            <React.Fragment>
              <div>
                <select
                  name="minExperience"
                  onChange={(e) => handleAvailableMatrix(e, index)}
                  required
                  value={matrixData.minExperience}
                >
                  <option value='0 month'>0 Month</option>
                  <option value='6 months'>6 Months</option>
                  <option value='1 Year'>1 Year</option>
                  <option value='2 Years'>2 Years</option>
                  <option value='3 Years'>3 Years</option>
                  <option value='4 Years'>4 Years</option>
                  <option value='5 Years'>5 Years</option>
                  <option value='6 Years'>6 Years</option>
                  <option value='7 Years'>7 Years</option>
                  <option value='8 Years'>8 Years</option>
                  <option value='9 Years'>9 Years</option>
                  <option value='10 Years & above'>10 Years & Above</option>
                </select>
              </div>
              {/* &nbsp;
              <div style={{opacity:'0'}}>To</div>&nbsp;
              <div>
                <select
                  name="maxExperience"
                  onChange={(e) => handleAvailableMatrix(e, index)}
                  required
                  value={matrixData.maxExperience}
                >
                  <option selected disabled value="">
                    Month
                  </option>
                  {Utility.mothOptions.map((item) => {
                    return <option value={item.value}>{item.name}</option>;
                  })}
                </select>
              </div> */}
            </React.Fragment>
          )}
          {matrixData && !matrixData.isEdit && (
            <span className={classes.text}>
              {`${matrixData.minExperience}`}
              {/* &nbsp;&nbsp;&nbsp; */}
              {/* {`${matrixData.maxExperience} ${
                matrixData.maxExperience > 1 ? "months" : "month"
              }`} */}
            </span>
          )}
          {matrixData && !matrixData.isEdit && (
            <div className={classes.forMobile}>
              <img src={pencil} onClick={() => setEdit(index)} />
              &nbsp;&nbsp;
              <img src={trash} onClick={() => deleteMatrix(index)} />
            </div>
          )}
        </div>
        <div>
          {matrixData && matrixData.isEdit && (
            <button type="submit">Add</button>
          )}
          {matrixData && !matrixData.isEdit && (
            <div className={classes.forDesktop}>
              <img src={pencil} onClick={() => setEdit(index)} />
              &nbsp;&nbsp;
              <img src={trash} onClick={() => deleteMatrix(index)} />
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

export default SkillStripEdit;
