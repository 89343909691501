import React from "react";
import classes from "./OpenJobs.module.css";

const Suggestions = ({
  suggestion = [],
  addNewValue,
  clearSuggestion,
  index,
}) => {

  return (
    <div className={classes.searchSuggestion}>
      {suggestion.map((item) => {
        return (
          <div
            style={{borderBottom: '1px solid gray'}}
            key={Math.random}
            onClick={(e) => {
              addNewValue(e, index,item.name,item._id) ;
              clearSuggestion();
            }}
          >
            {item.name}
          </div>
        );
      })}
    </div>
  );
};

export default Suggestions;
