import * as actionTypes from "./ActionTypes";
import { getRequest, postRequest } from "../../Helper/apiHit";

export const getMasterCompanySectorList = () => {
  return (dispatch) => {
    getRequest("master/getMasterCompanySector")
      .then((res) => {
        if (res.data.responseStatus.statusCode === 0) {
          dispatch({
            type: actionTypes.MASTER_COMPANY_SECTOR_LIST_SUCCESS,
            payload: res.data.responseData,
          });
        } else {
          dispatch({
            type: actionTypes.MASTER_COMPANY_SECTOR_LIST_FAIL,
            payload: "Master company sector list failed",
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.MASTER_COMPANY_SECTOR_LIST_FAIL,
          payload: "Master company sector list failed",
        });
      });
  };
};

export const getMasterCountryList = () => {
  return (dispatch) => {
    getRequest("master/getMasterCountry")
      .then((res) => {
        if (res.data.responseStatus.statusCode === 0) {
          dispatch({
            type: actionTypes.MASTER_COUNTRY_LIST_SUCCESS,
            payload: res.data.responseData,
          });
        } else {
          dispatch({
            type: actionTypes.MASTER_COUNTRY_LIST_FAIL,
            payload: "Master country list failed",
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.MASTER_COUNTRY_LIST_FAIL,
          payload: "Master country list failed",
        });
      });
  };
};

export const getMasterStateList = () => {
  return (dispatch) => {
    getRequest("master/getMasterState")
      .then((res) => {
        if (res.data.responseStatus.statusCode === 0) {
          dispatch({
            type: actionTypes.MASTER_STATE_LIST_SUCCESS,
            payload: res.data.responseData,
          });
        } else {
          dispatch({
            type: actionTypes.MASTER_STATE_LIST_FAIL,
            payload: "Master state list failed",
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.MASTER_STATE_LIST_FAIL,
          payload: "Master state list failed",
        });
      });
  };
};

export const getMasterCityList = () => {
  return (dispatch) => {
    getRequest("master/getCityMasterData")
      .then((res) => {
        if (res.data.responseStatus.statusCode === 0) {
          dispatch({
            type: actionTypes.MASTER_CITY_LIST_SUCCESS,
            payload: res.data.responseData,
          });
        } else {
          dispatch({
            type: actionTypes.MASTER_CITY_LIST_FAIL,
            payload: "Master city list failed",
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.MASTER_CITY_LIST_FAIL,
          payload: "Master city list failed",
        });
      });
  };
};

export const getMasterEducationList = () => {
  return (dispatch) => {
    getRequest("master/getMasterEducation")
      .then((res) => {
        if (res.data.responseStatus.statusCode === 0) {
          dispatch({
            type: actionTypes.MASTER_EDUCATION_LIST_SUCCESS,
            payload: res.data.responseData,
          });
        } else {
          dispatch({
            type: actionTypes.MASTER_EDUCATION_LIST_FAIL,
            payload: "Master education list failed",
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.MASTER_EDUCATION_LIST_FAIL,
          payload: "Master education list failed",
        });
      });
  };
};

export const getMasterBoardingRequirementsList = () => {
  return (dispatch) => {
    getRequest("master/getMasterBoardingRequirements")
      .then((res) => {
        if (res.data.responseStatus.statusCode === 0) {
          dispatch({
            type: actionTypes.MASTER_BOARDING_REQUIREMENT_LIST_SUCCESS,
            payload: res.data.responseData,
          });
        } else {
          dispatch({
            type: actionTypes.MASTER_BOARDING_REQUIREMENT_LIST_FAIL,
            payload: "Master boarding requirement list failed",
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.MASTER_BOARDING_REQUIREMENT_LIST_FAIL,
          payload: "Master boarding requirement list failed",
        });
      });
  };
};

export const getMasterCommunicationModeList = () => {
  return (dispatch) => {
    getRequest("master/getMasterCommunicationMode")
      .then((res) => {
        if (res.data.responseStatus.statusCode === 0) {
          dispatch({
            type: actionTypes.MASTER_COMMUNICATION_MODE_LIST_SUCCESS,
            payload: res.data.responseData,
          });
        } else {
          dispatch({
            type: actionTypes.MASTER_COMMUNICATION_MODE_LIST_FAIL,
            payload: "Master Communication Mode list failed",
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.MASTER_COMMUNICATION_MODE_LIST_FAIL,
          payload: "Master Communication Mode list failed",
        });
      });
  };
};

export const getMasterOfferedBenefitsList = () => {
  return (dispatch) => {
    getRequest("master/getMasterOfferedBenefits")
      .then((res) => {
        if (res.data.responseStatus.statusCode === 0) {
          dispatch({
            type: actionTypes.MASTER_OFFERED_BENIFITS_LIST_SUCCESS,
            payload: res.data.responseData,
          });
        } else {
          dispatch({
            type: actionTypes.MASTER_OFFERED_BENIFITS_LIST_SUCCESS,
            payload: "Master offered benifits list failed",
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.MASTER_OFFERED_BENIFITS_LIST_SUCCESS,
          payload: "Master offered benifits list failed",
        });
      });
  };
};

export const getMasterRelocationBenefitsList = () => {
  return (dispatch) => {
    getRequest("master/getMasterRelocationBenefits")
      .then((res) => {
        if (res.data.responseStatus.statusCode === 0) {
          dispatch({
            type: actionTypes.MASTER_RELOCATION_BENIFITS_LIST_SUCCESS,
            payload: res.data.responseData,
          });
        } else {
          dispatch({
            type: actionTypes.MASTER_RELOCATION_BENIFITS_LIST_SUCCESS,
            payload: "Master relocation benifits list failed",
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.MASTER_RELOCATION_BENIFITS_LIST_SUCCESS,
          payload: "Master relocation benifits list failed",
        });
      });
  };
};

export const getMasterSkills = () => {
  return (dispatch) => {
    getRequest("skills/getSkills")
      .then((res) => {
        if (res.data.responseStatus.statusCode === 0) {
          dispatch({
            type: actionTypes.GET_MASTER_SKILLS,
            payload: res.data.responseData,
          });
        } else {
          dispatch({
            type: actionTypes.GET_MASTER_SKILLS_FAIL,
            payload: "Somthing went wrong",
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.GET_MASTER_SKILLS_FAIL,
          payload: "Somthing went wrong",
        });
      });
  };
};
export const getInterviewQuestions = (urlToAppend) => {
  return (dispatch) => {
    getRequest(`questions/getInterviewQuestions?${urlToAppend}`).then(res=>{
      if (res.data.responseStatus.statusCode === 0){
          dispatch({
            type:actionTypes.GET_INTERVIEW_QUESTIONS,
            masterPersonalQuestions:res.data.responseData.masterPersonalQuestions,
            masterSkillBasedQuestions:res.data.responseData.masterSkillBasedQuestion,
            masterSkillBasedMcqQuestion:res.data.responseData.masterSkillBasedMcqQuestion,
            questions:res.data.responseData.questions
          })
      }else{
        dispatch({
          type:actionTypes.GET_INTERVIEW_QUESTIONS_FAIL,
          payload:'Somthing went wrong'
        })
      }
    }).catch(err=>{
      dispatch({
        type:actionTypes.GET_INTERVIEW_QUESTIONS_FAIL,
        payload:'Somthing went wrong'
      })
    })
  }
}