import React, { useEffect, useState, useRef } from "react";
import classes from "./UpdateJob.module.css";
import Utility from "../../../Utility/Utility";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../Store/Actions/index";
import * as actionTypes from "../../../Store/Actions/ActionTypes";
import Loader from "../../../common/Loader/Loader";
import JobProggressDesktop from "../../../components/CreateJobProgress/Desktop/JobProggressDesktop";
import JobProgressMobile from "../../../components/CreateJobProgress/Mobile/JobProgressMobile";
import JobDetails from "../../../components/CreateJobComp/JobDetails/JobDetails";
import SkillMatrix from "../../../components/CreateJobComp/SkillMatrix/SkillMatrix";
import Questions from "../../../components/CreateJobComp/Questions/Questions";
import Education from "../../../components/CreateJobComp/Education/Education";
import MiscInformation from "../../../components/CreateJobComp/MiscInformation/MiscInfromation";
import InterviewProcess from "../../../components/CreateJobComp/InterviewProcess/InterviewProcess";
import WorkLocation from "../../../components/CreateJobComp/WorkLocation/WorkLocation";
import OnBoarding from "../../../components/CreateJobComp/OnBoarding/OnBoarding";
import PersonalMessage from "../../../components/CreateJobComp/PersonalMessage/PersonalMessage";
import Preview from "../../../components/CreateJobComp/Preview/Preview";

const UpdateJob = ({ history }) => {
  const dispatch = useDispatch();
  const descriptionRef = useRef();

  const {
    masterSkill,
    jobPreview,
    masterOfferedBenifitsList,
    masterSkillBasedQuestions,
    masterSkillBasedMcqQuestion,
  } = useSelector((state) => ({
    masterSkill: state.MasterReducer.masterSkill,
    jobPreview: state.CreateJobReducer.jobPreview,
    masterOfferedBenifitsList: state.MasterReducer.masterOfferedBenifitsList,
    masterSkillBasedQuestions: state.MasterReducer.masterSkillBasedQuestions,
    masterSkillBasedMcqQuestion:
      state.MasterReducer.masterSkillBasedMcqQuestion,
  }));

  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("preview");
  const [activeIndex, setActiveIndex] = useState(8);
  //job detail
  const [jobDetail, setJobdetail] = useState({ type: "Contract" });
  const [description, setDescription] = useState("");
  let descriptionText = "";
  // skill matrix
  const [skillMatrix, setSkillMatrix] = useState([]);
  const [newMatrix, setNewMatrix] = useState({});
  // qualifying question
  const [questionActiveTab, setQuestionActiveTab] = useState("personal");
  const [personalQuestions, setPersonalQuestions] = useState([]);
  const [personalCustomeQuestions, setPersonalCustomeQuestions] = useState([
    { question: "", isEdit: true },
  ]);
  const [skillQuestion, setSkillQuestion] = useState([]);
  const [skillCustomeQuestion, setSkillCustomeQuestion] = useState([
    { skillId: "", question: "", answer: "", isEdit: true },
  ]);
  const [skillMcqQuestion, setSkillMcqQuestion] = useState([]);
  const [skillMcqCustomeQuestion, setSkillMcqCustomeQuestion] = useState([
    {
      skillId: "",
      question: "",
      options: [],
      answer: "",
      isEdit: true,
    },
  ]);
  // education
  const [education, setEducation] = useState();
  // misc information
  const [miscInfo, setMiscInfo] = useState({});
  // interview process
  const [interviewProcess, setInterviewProcess] = useState({ rounds: "1" });
  const [modeofCommunication, setmodeofCommunication] = useState({});
  const [candidateTip, setTip] = useState({});
  //work location
  const [workLocation, setWorkLocation] = useState({});
  // on boarding
  const [onBoarding, setOnBoarding] = useState();
  // personal message
  const [personalMessageToSend, setPersonalMessageToSend] = useState();
  const [personalMessageUrl, setPersonalMessageUrl] = useState();

  //hitting initial apis
  useEffect(() => {
    window.scrollTo(0, 0);

    if (history.location.state && history.location.state.jobId) {
      dispatch({ type: actionTypes.CLEAR });
      setIsLoading(true);
      dispatch(actions.getCreateJobPreview(history.location.state.jobId));
      dispatch(actions.getMasterSkills());
      dispatch(actions.getMasterCommunicationModeList());
      dispatch(actions.getMasterOfferedBenefitsList());
      dispatch(actions.getMasterRelocationBenefitsList());
      dispatch(actions.getMasterCountryList());
      dispatch(actions.getMasterStateList());
      dispatch(actions.getMasterCityList());
      dispatch(actions.getMasterBoardingRequirementsList());
    }
  }, []);

  // updatig states after geting reponce
  useEffect(() => {
    if (jobPreview) {
      setIsLoading(false);
      setJobdetail({
        type: jobPreview.type,
        role: jobPreview.role,
        startDate: jobPreview.annualSalaryRange ? "" : jobPreview.startDate,
        endDate: jobPreview.annualSalaryRange ? "" : jobPreview.endDate,
        sectorId: jobPreview.sectorId._id,
        initialSalary: jobPreview.annualSalaryRange
          ? jobPreview.annualSalaryRange.split("-")[0]
          : "",
        finalSalary: jobPreview.annualSalaryRange
          ? jobPreview.annualSalaryRange.split("-")[1]
          : "",
      });
      setDescription(jobPreview.description);
      setSkillMatrix(jobPreview.skillMatrixId.skill);
      setPersonalQuestions(jobPreview.qualifyingQuestionId.personalQuestion);
      setSkillQuestion(jobPreview.qualifyingQuestionId.skillBasedQuestion);
      setSkillMcqQuestion(
        jobPreview.qualifyingQuestionId.skillBasedMcqQuestion
      );
      setEducation(jobPreview.educationId._id);
      setMiscInfo(jobPreview.miscInfoId);
      setInterviewProcess(
        jobPreview.interviewProcessId === null
          ? {}
          : {
              rounds: jobPreview.interviewProcessId.rounds,
            }
      );
      setmodeofCommunication(
        jobPreview.interviewProcessId === null
          ? {}
          : jobPreview.interviewProcessId.communicationMode
      );
      setTip(
        jobPreview.interviewProcessId === null
          ? {}
          : jobPreview.interviewProcessId.tips
      );
      setWorkLocation(jobPreview.workLocation);
      setOnBoarding(
        jobPreview.boardingId.map((item) => {
          return item._id;
        })
      );
      setPersonalMessageUrl(jobPreview.uploadedData[0]);
      if (jobPreview.skillMatrixId !== null) {
        let url = "";
        jobPreview.skillMatrixId.skill.map((item, i) => {
          if (i === 0) {
            url = url + `skill${i}=${item._id}`;
          } else {
            url = url + "&&" + `skill${i}=${item._id}`;
          }
        });
        dispatch(actions.getInterviewQuestions(url));
      }
    }
  }, [jobPreview]);

  // job detail
  const onChangeJobDetail = (e, type) => {
    if (type) {
      setJobdetail({
        ...jobDetail,
        type: type,
      });
    } else {
      setJobdetail({
        ...jobDetail,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleDescriptionBox = (content) => {
    descriptionText = content.trim();
  };
  const setToDescription = () => {
    setDescription(descriptionText);
    descriptionText = "";
  };

  const setTextFileToJd = (e) => {
    if (e.target.files) {
      let reader = new FileReader();
      reader.onload = () => {
        setDescription(reader.result);
      };
      reader.readAsText(e.target.files[0]);
    }
  };

  // skill matrix
  const setEdit = (outerIndex) => {
    setSkillMatrix(
      skillMatrix.map((item, index) => {
        let returnItem = { ...item };
        if (outerIndex === index) {
          returnItem.isEdit = true;
        }
        return returnItem;
      })
    );
  };

  const saveSkillMatrix = (e, outerIndex) => {
    e.preventDefault();
    setSkillMatrix(
      skillMatrix.map((item, index) => {
        let returnItem = { ...item };
        if (outerIndex === index) {
          returnItem.isEdit = false;
        }
        return returnItem;
      })
    );
  };

  const addSkillMatrix = (e) => {
    e.preventDefault();
    setSkillMatrix([
      ...skillMatrix,
      {
        ...newMatrix,
        minExperience: e.target[3].value,
        // maxExperience: e.target[4].value,
        isEdit: false,
      },
    ]);
    setNewMatrix({});
  };

  const handleAvailableMatrix = (e, outerIndex) => {
    let updatedMatrix = skillMatrix.map((item, index) => {
      if (outerIndex === index) {
        return { ...item, [e.target.name]: e.target.value };
      } else {
        return item;
      }
    });
    setSkillMatrix(updatedMatrix);
  };

  const handleNewMatrix = (e) => {
    let newMatrixToAdd = { ...newMatrix, [e.target.name]: e.target.value };
    setNewMatrix(newMatrixToAdd);
  };

  const deleteMatrix = (outerIndex) => {
    setSkillMatrix(
      skillMatrix.filter((item, index) => {
        if (outerIndex !== index) {
          return item;
        }
      })
    );
  };

  const addNewValue = (e, outerIndex, value, id) => {
    if (outerIndex === undefined) {
      setNewMatrix({ ...newMatrix, value: value, _id: id });
    } else {
      let updatedMatrix = skillMatrix.map((item, index) => {
        if (outerIndex === index) {
          return { ...item, value: value, _id: id };
        } else {
          return item;
        }
      });
      setSkillMatrix(updatedMatrix);
    }
  };

  const extractSkillFromJd = () => {
    let descriptionBoxInLowerCase = description.toLowerCase();
    let skillFromJd = masterSkill.filter((item) => {
      let returnItem = { ...item };
      let reg = new RegExp("\\b" + item.value.toLowerCase() + "\\b", "g");
      if (descriptionBoxInLowerCase.search(reg) !== -1) {
        return returnItem;
      }
    });
    skillFromJd = skillFromJd.map((item) => {
      let returnItem = { ...item };
      returnItem.preference = "Required";
      returnItem.minExperience = "0 months";
      // returnItem.maxExperience = "0";
      returnItem.isEdit = false;
      return returnItem;
    });
    let a = [...skillFromJd];
    if (skillMatrix.length > 0) {
      a = skillFromJd.filter((outer) => {
        return !skillMatrix.find((inner) => inner._id === outer._id);
      });
    }

    if (skillFromJd.length !== 0) {
      setSkillMatrix([...skillMatrix, ...a]);
    } else {
      setSkillMatrix([...skillMatrix]);
    }
  };

  // qualifying questions

  const handleTabChange = (tabName) => {
    setQuestionActiveTab(tabName);
  };

  const removePersonal = (index) => {
    setPersonalQuestions(
      personalQuestions.filter((item, i) => {
        if (index !== i) {
          return item;
        }
      })
    );
  };

  const editPersonal = (index) => {
    setPersonalQuestions(
      personalQuestions.map((item, i) => {
        if (index === i) {
          return { ...item, isEdit: true };
        } else {
          return item;
        }
      })
    );
  };

  const savePersonal = (index) => {
    setPersonalQuestions(
      personalQuestions.map((item, i) => {
        if (index === i) {
          return { ...item, isEdit: false };
        } else {
          return item;
        }
      })
    );
  };

  const handlePersonalChange = (e, index) => {
    setPersonalQuestions(
      personalQuestions.map((item, i) => {
        if (index === i) {
          return { ...item, questionId: e.target.value };
        } else {
          return item;
        }
      })
    );
  };

  const addNewPersonal = (e) => {
    e.preventDefault();
    setPersonalQuestions([
      ...personalQuestions,
      { questionId: e.target[0].value, isEdit: false },
    ]);
  };

  const addToCustome = (e, index) => {
    e.preventDefault();
    let newIndex = Math.abs(index - 1);
    let returnItem = null;

    let count = 0;
    let allowed = personalCustomeQuestions.map((item) => {
      if (item.isEdit === true) {
        count += 1;
      }
    });
    if (count <= 1) {
      personalCustomeQuestions.push({ question: "", isEdit: true });
    }
    setPersonalCustomeQuestions(
      personalCustomeQuestions.map((item, i) => {
        returnItem = { ...item };
        if (newIndex === i) {
          returnItem.question = e.target[0].value;
          returnItem.isEdit = false;
        }
        return returnItem;
      })
    );
  };

  const editCustomeQuestion = (index) => {
    let newIndex = Math.abs(index - 1);
    setPersonalCustomeQuestions(
      personalCustomeQuestions.map((item, i) => {
        let returnItem = { ...item };
        if (newIndex === i) {
          returnItem.isEdit = true;
        }
        return returnItem;
      })
    );
  };
  const removeCustomeQuestion = (index) => {
    let newIndex = Math.abs(index - 1);
    setPersonalCustomeQuestions(
      personalCustomeQuestions.filter((item, i) => {
        if (newIndex !== i) {
          return item;
        }
      })
    );
  };

  const addCustomizedQuestion = () => {
    if (questionActiveTab === "personal") {
      let count = 0;
      let allowed = personalCustomeQuestions.map((item) => {
        if (item.isEdit === true) {
          count += 1;
        }
      });
      if (count > 0) {
        alert("Please save last question");
      } else {
        setPersonalCustomeQuestions([
          ...personalCustomeQuestions,
          { question: "", isEdit: true },
        ]);
      }
    } else if (questionActiveTab === "skill") {
      let count = 0;
      skillCustomeQuestion.map((item) => {
        if (item.isEdit === true) {
          count += 1;
        }
      });
      if (count > 0) {
        alert("Please save last question");
      } else {
        setSkillCustomeQuestion([
          ...skillCustomeQuestion,
          {
            skillId: "",
            question: "",
            answer: "",
            isEdit: true,
          },
        ]);
      }
    } else {
      let count = 0;
      skillMcqCustomeQuestion.map((item) => {
        if (item.isEdit) {
          count += 1;
        }
      });
      if (count > 0) {
        alert("Please save last question");
      } else {
        setSkillMcqCustomeQuestion([
          ...skillMcqCustomeQuestion,
          {
            skillId: "",
            question: "",
            options: [],
            answer: "",
            isEdit: true,
          },
        ]);
      }
    }
  };

  const addNewSkillQuestion = (e) => {
    e.preventDefault();
    setSkillQuestion([
      ...skillQuestion,
      {
        skillId: e.target[0].value,
        questionId: e.target[1].value,
        answer: e.target[2].value,
        isEdit: false,
      },
    ]);
  };

  const removeSkillQuestion = (outerIndex) => {
    setSkillQuestion(
      skillQuestion.filter((item, index) => {
        if (outerIndex !== index) {
          return item;
        }
      })
    );
  };

  const editSkillQuestion = (outerIndex) => {
    setSkillQuestion(
      skillQuestion.map((item, index) => {
        let returnItem = { ...item };
        if (outerIndex === index) {
          returnItem.isEdit = true;
        }
        return returnItem;
      })
    );
  };

  const handleSkillQuestion = (e, outerIndex) => {
    setSkillQuestion(
      skillQuestion.map((item, index) => {
        if (outerIndex === index) {
          return { ...item, [e.target.name]: e.target.value };
        } else {
          return item;
        }
      })
    );
  };

  const saveSkillQuestion = (e, outerIndex) => {
    e.preventDefault();
    setSkillQuestion(
      skillQuestion.map((item, index) => {
        let returnItem = { ...item };
        if (outerIndex === index) {
          returnItem.isEdit = false;
        }
        return returnItem;
      })
    );
  };

  const addCustomeSkillQuestion = (e, outerIndex) => {
    e.preventDefault();
    let newIndex;
    if (masterSkillBasedQuestions.length != 0) {
      newIndex = Math.abs(outerIndex - 1);
    } else {
      newIndex = Math.abs(outerIndex - 1);
    }
    let count = 0;
    skillCustomeQuestion.map((item) => {
      if (item.isEdit === true) {
        count += 1;
      }
    });
    if (count <= 1) {
      skillCustomeQuestion.push({
        skillId: "",
        question: "",
        answer: "",
        isEdit: true,
      });
    }
    setSkillCustomeQuestion(
      skillCustomeQuestion.map((item, index) => {
        let returnItem = { ...item };
        if (newIndex === index) {
          returnItem.skillId = e.target[0].value;
          returnItem.question = e.target[1].value;
          returnItem.answer = e.target[2].value;
          returnItem.isEdit = false;
        }
        return returnItem;
      })
    );
  };

  const editCustomeSkillQuestion = (outerIndex) => {
    let newIndex;
    if (masterSkillBasedQuestions.length != 0) {
      newIndex = Math.abs(outerIndex - 1);
    } else {
      newIndex = Math.abs(outerIndex - 1);
    }
    setSkillCustomeQuestion(
      skillCustomeQuestion.map((item, index) => {
        let returnItem = { ...item };
        if (newIndex === index) {
          returnItem.isEdit = true;
        }
        return returnItem;
      })
    );
  };

  const removeCustomeSkillQuestion = (outerIndex) => {
    let newIndex;
    if (masterSkillBasedQuestions.length != 0) {
      newIndex = Math.abs(outerIndex - 1);
    } else {
      newIndex = Math.abs(outerIndex - 1);
    }
    setSkillCustomeQuestion(
      skillCustomeQuestion.filter((item, index) => {
        let returnItem = { ...item };
        if (newIndex !== index) {
          return returnItem;
        }
      })
    );
  };

  const modifyCustomQuestions = (
    personaCustome,
    skillCustome,
    skillMcqCustome
  ) => {
    setPersonalCustomeQuestions(personaCustome);
    setSkillCustomeQuestion(skillCustome);
    setSkillMcqCustomeQuestion(skillMcqCustome);
  };

  const addNewMcqQuestion = (e) => {
    e.preventDefault();
    let answer = "";
    if (e.target[2].checked) {
      answer = e.target[3].value;
    } else if (e.target[4].checked) {
      answer = e.target[5].value;
    } else if (e.target[6].checked) {
      answer = e.target[7].value;
    } else if (e.target[8].checked) {
      answer = e.target[9].value;
    }
    setSkillMcqQuestion([
      ...skillMcqQuestion,
      {
        skillId: e.target[0].value,
        questionId: e.target[1].value,
        options: [
          e.target[3].value,
          e.target[5].value,
          e.target[7].value,
          e.target[9].value,
        ],
        answer: answer,
        isEdit: false,
      },
    ]);
  };

  const submitEditMcq = (e, index) => {
    e.preventDefault();
    let answer = "";
    if (e.target[2].checked) {
      answer = e.target[3].value;
    } else if (e.target[4].checked) {
      answer = e.target[5].value;
    } else if (e.target[6].checked) {
      answer = e.target[7].value;
    } else if (e.target[8].checked) {
      answer = e.target[9].value;
    }

    setSkillMcqQuestion(
      skillMcqQuestion.map((item, i) => {
        let returnItem = { ...item };
        if (index === i) {
          returnItem.skillId = e.target[0].value;
          returnItem.questionId = e.target[1].value;
          returnItem.options = [
            e.target[3].value,
            e.target[5].value,
            e.target[7].value,
            e.target[9].value,
          ];
          returnItem.answer = answer;
          returnItem.isEdit = false;
        }
        return returnItem;
      })
    );
  };

  const editSkillMcqQuestion = (index) => {
    setSkillMcqQuestion(
      skillMcqQuestion.map((item, i) => {
        let returnItem = { ...item };
        if (i === index) {
          returnItem.isEdit = true;
        }
        return returnItem;
      })
    );
  };

  const removeSkillMcqQuestion = (index) => {
    setSkillMcqQuestion(
      skillMcqQuestion.filter((item, i) => {
        if (i != index) {
          return item;
        }
      })
    );
  };

  const saveCustomeMcqQuestion = (e, index) => {
    let ind;
    if (masterSkillBasedMcqQuestion.length != 0) {
      ind = Math.abs(index - 1);
    } else {
      ind = Math.abs(index - 1);
    }
    e.preventDefault();
    let answer = "";
    if (e.target[2].checked) {
      answer = e.target[3].value;
    } else if (e.target[4].checked) {
      answer = e.target[5].value;
    } else if (e.target[6].checked) {
      answer = e.target[7].value;
    } else if (e.target[8].checked) {
      answer = e.target[9].value;
    }

    let count = 0;
    skillMcqCustomeQuestion.map((item) => {
      if (item.isEdit) {
        count += 1;
      }
    });
    if (count <= 1) {
      skillMcqCustomeQuestion.push({
        skillId: "",
        question: "",
        options: [],
        answer: "",
        isEdit: true,
      });
    }
    setSkillMcqCustomeQuestion(
      skillMcqCustomeQuestion.map((item, i) => {
        let returnItem = { ...item };
        if (ind === i) {
          returnItem.skillId = e.target[0].value;
          returnItem.question = e.target[1].value;
          returnItem.options = [
            e.target[3].value,
            e.target[5].value,
            e.target[7].value,
            e.target[9].value,
          ];
          returnItem.answer = answer;
          returnItem.isEdit = false;
        }
        return returnItem;
      })
    );
  };

  const editCustomeMcqQuestion = (index) => {
    let ind;
    if (masterSkillBasedMcqQuestion.length != 0) {
      ind = Math.abs(index - 1);
    } else {
      ind = Math.abs(index - 1);
    }
    setSkillMcqCustomeQuestion(
      skillMcqCustomeQuestion.map((item, i) => {
        let returnItem = { ...item };
        if (ind === i) {
          returnItem.isEdit = true;
        }
        return returnItem;
      })
    );
  };

  const removeCustomeMcqQuestion = (index) => {
    let ind;
    if (masterSkillBasedMcqQuestion.length != 0) {
      ind = Math.abs(index - 1);
    } else {
      ind = Math.abs(index - 1);
    }
    setSkillMcqCustomeQuestion(
      skillMcqCustomeQuestion.filter((item, i) => {
        if (ind != i) {
          return item;
        }
      })
    );
  };

  // education
  const handleEducation = (e) => {
    setEducation(e.target.value);
  };

  // handle misc info
  const handleMiscInfo = (e) => {
    setMiscInfo({ ...miscInfo, [e.target.name]: e.target.value });
  };

  const handleDisplay = (e) => {
    if (e.target.name === "allDisplayToCandidate") {
      if (miscInfo["allDisplayToCandidate"]) {
        setMiscInfo({
          ...miscInfo,
          ["allDisplayToCandidate"]: false,
          ["displayHourTocandidate"]: false,
          ["displayPositionQualifiesForOvertime"]: false,
          ["displayWorkVisaSponcer"]: false,
          ["displayOfferedBenifits"]: false,
          ["displayRelocationBenifits"]: false,
          ["displayTravelApplicable"]: false,
          ["displayWorkFromHome"]: false,
          ["displayAdditionNotes"]: false,
          ["exStartDate"]: false,
        });
      } else {
        setMiscInfo({
          ...miscInfo,
          ["allDisplayToCandidate"]: true,
          ["displayHourTocandidate"]: true,
          ["displayPositionQualifiesForOvertime"]: true,
          ["displayWorkVisaSponcer"]: true,
          ["displayOfferedBenifits"]: true,
          ["displayRelocationBenifits"]: true,
          ["displayTravelApplicable"]: true,
          ["displayWorkFromHome"]: true,
          ["displayAdditionNotes"]: true,
          ["exStartDate"]: true,
        });
      }
    } else {
      if (miscInfo[e.target.name] !== undefined) {
        if (miscInfo[e.target.name] === true) {
          setMiscInfo({
            ...miscInfo,
            [e.target.name]: false,
            ["allDisplayToCandidate"]: false,
          });
        } else {
          setMiscInfo({
            ...miscInfo,
            [e.target.name]: true,
            ["allDisplayToCandidate"]: false,
          });
        }
      } else {
        setMiscInfo({
          ...miscInfo,
          [e.target.name]: true,
          ["allDisplayToCandidate"]: false,
        });
      }
    }
  };

  const handleFieldChanges = (e) => {
    if (e.target.name === "hourlyRate") {
      setMiscInfo({
        ...miscInfo,
        [e.target.name]: e.target.value,
        hourlyRateRangeStart: "",
        hourlyRateRangeEnd: "",
      });
    } else if (e.target.name === "hourlyRateRangeStart") {
      setMiscInfo({
        ...miscInfo,
        [e.target.name]: e.target.value,
        hourlyRate: "",
      });
    } else if (e.target.name === "offeredBenifitsApplicable") {
      if (e.target.value === "true") {
        setMiscInfo({ ...miscInfo, [e.target.name]: e.target.value });
      } else {
        setMiscInfo({
          ...miscInfo,
          [e.target.name]: e.target.value,
          offeredBenefits: [],
        });
      }
    } else if (e.target.name === "offeredBenefits") {
      if (!miscInfo.offeredBenefits || miscInfo.offeredBenefits.length === 0) {
        setMiscInfo({ ...miscInfo, offeredBenefits: [e.target.value] });
      } else {
        if (miscInfo.offeredBenefits.indexOf(e.target.value) === -1) {
          setMiscInfo({
            ...miscInfo,
            offeredBenefits: [...miscInfo.offeredBenefits, e.target.value],
          });
        } else {
          setMiscInfo({
            ...miscInfo,
            offeredBenefits: miscInfo.offeredBenefits.filter((item) => {
              if (item !== e.target.value) {
                return item;
              }
            }),
          });
        }
      }
    } else if (e.target.name === "relocationBenefits") {
      if (
        !miscInfo.relocationBenefits ||
        miscInfo.relocationBenefits.length === 0
      ) {
        setMiscInfo({ ...miscInfo, relocationBenefits: [e.target.value] });
      } else {
        if (miscInfo.relocationBenefits.indexOf(e.target.value) === -1) {
          setMiscInfo({
            ...miscInfo,
            relocationBenefits: [
              ...miscInfo.relocationBenefits,
              e.target.value,
            ],
          });
        } else {
          setMiscInfo({
            ...miscInfo,
            relocationBenefits: miscInfo.relocationBenefits.filter((item) => {
              if (item !== e.target.value) {
                return item;
              }
            }),
          });
        }
      }
    } else {
      setMiscInfo({ ...miscInfo, [e.target.name]: e.target.value });
    }
  };
  // handle interview process
  const handleInterviewProcess = (e) => {
    setInterviewProcess({
      [e.target.name]: e.target.value,
    });
  };
  const setCommunicationMode = (e) => {
    setmodeofCommunication({
      ...modeofCommunication,
      [e.target.name]: e.target.value,
    });
  };

  const clearCommunicationMode = (e) => {
    setmodeofCommunication({
      ...modeofCommunication,
      [e.target.name]: "",
    });
  };

  const handleCandidateTip = (e) => {
    setTip({
      ...candidateTip,
      [e.target.name]: e.target.value,
    });
  };

  // handle workLocation
  const handleWorkLocation = (e) => {
    if (e.target.name === "countryId") {
      setWorkLocation({
        ...workLocation,
        [e.target.name]: e.target.value,
        stateId: "",
        cityId: "",
      });
    } else if (e.target.name === "stateId") {
      setWorkLocation({
        ...workLocation,
        [e.target.name]: e.target.value,
        cityId: "",
      });
    } else {
      setWorkLocation({ ...workLocation, [e.target.name]: e.target.value });
    }
  };

  const allOnBoardingId = (arr) => {
    setOnBoarding(arr);
  };
  // handle onboarding
  const handleOnboarding = (e) => {
    if (!onBoarding || onBoarding.length === 0) {
      setOnBoarding([e.target.value]);
    } else {
      if (onBoarding.indexOf(e.target.value) === -1) {
        setOnBoarding([...onBoarding, e.target.value]);
      } else {
        setOnBoarding(
          onBoarding.filter((item) => {
            if (item !== e.target.value) {
              return item;
            }
          })
        );
      }
    }
  };
  const handleExpDate = (date) => {
    setMiscInfo({ ...miscInfo, startDate: date });
  };

  // handle personal message
  const handlePersonalMessage = (e) => {
    if (e.target.files) {
      if (
        e.target.files[0].type === "video/mp4" ||
        e.target.files[0].type === "video/quicktime" ||
        e.target.files[0].type === "video/x-matroska"
      ) {
        setPersonalMessageUrl(URL.createObjectURL(e.target.files[0]));
        setPersonalMessageToSend(e.target.files[0]);
      } else {
        alert("File format not supported");
      }
    }
  };

  const remove = () => {
    setPersonalMessageUrl("");
    setPersonalMessageToSend("");
  };

  const setNextPage = (pageName) => {
    setActiveIndex(activeIndex + 1);
    setActiveTab(pageName);
  };

  const setPreviousPage = (pageName) => {
    setActiveIndex(activeIndex - 1);
    setActiveTab(pageName);
  };

  const sendToPage = (pageName, index) => {
    setActiveTab(pageName);
    setActiveIndex(index);
  };

  const backToFlow = () => {
    setActiveTab("personalMessage");
  };

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <div className={classes.updateJob}>
        {!Utility.isMobile() && activeTab !== "preview" && (
          <JobProggressDesktop activeIndex={activeIndex} />
        )}
        {Utility.isMobile() && activeTab !== "preview" && (
          <JobProgressMobile activeIndex={activeIndex} />
        )}
        {activeTab === "preview" && (
          <Preview
            sendToPage={sendToPage}
            backToFlow={backToFlow}
            updateJob={true}
          />
        )}
        {activeTab === "jobDetail" && (
          <JobDetails
            jobDetail={jobDetail}
            setJobdetail={onChangeJobDetail}
            descriptionRef={descriptionRef}
            description={description}
            handleDescriptionBox={handleDescriptionBox}
            setTextFileToJd={setTextFileToJd}
            setToDescription={setToDescription}
            next={setNextPage}
          />
        )}
        {activeTab === "skillMatrix" && (
          <SkillMatrix
            skillMatrix={skillMatrix}
            setEdit={setEdit}
            saveSkillMatrix={saveSkillMatrix}
            addSkillMatrix={addSkillMatrix}
            handleAvailableMatrix={handleAvailableMatrix}
            handleNewMatrix={handleNewMatrix}
            newMatrix={newMatrix}
            deleteMatrix={deleteMatrix}
            addNewValue={addNewValue}
            setNextPage={setNextPage}
            setPreviousPage={setPreviousPage}
            extractSkillFromJd={extractSkillFromJd}
          />
        )}
        {activeTab === "question" && (
          <Questions
            questionActiveTab={questionActiveTab}
            handleTabChange={handleTabChange}
            personalQuestions={personalQuestions}
            skillQuestion={skillQuestion}
            personalCustomeQuestions={personalCustomeQuestions}
            skillCustomeQuestion={skillCustomeQuestion}
            removePersonal={removePersonal}
            editPersonal={editPersonal}
            savePersonal={savePersonal}
            handlePersonalChange={handlePersonalChange}
            addNewPersonal={addNewPersonal}
            addToCustome={addToCustome}
            addCustomizedQuestion={addCustomizedQuestion}
            editCustomeQuestion={editCustomeQuestion}
            removeCustomeQuestion={removeCustomeQuestion}
            skillMatrix={skillMatrix}
            addNewSkillQuestion={addNewSkillQuestion}
            removeSkillQuestion={removeSkillQuestion}
            editSkillQuestion={editSkillQuestion}
            saveSkillQuestion={saveSkillQuestion}
            handleSkillQuestion={handleSkillQuestion}
            addCustomeSkillQuestion={addCustomeSkillQuestion}
            editCustomeSkillQuestion={editCustomeSkillQuestion}
            removeCustomeSkillQuestion={removeCustomeSkillQuestion}
            setNextPage={setNextPage}
            setPreviousPage={setPreviousPage}
            modifyCustomQuestions={modifyCustomQuestions}
            addNewMcqQuestion={addNewMcqQuestion}
            skillMcqQuestion={skillMcqQuestion}
            editSkillMcqQuestion={editSkillMcqQuestion}
            removeSkillMcqQuestion={removeSkillMcqQuestion}
            submitEditMcq={submitEditMcq}
            skillMcqCustomeQuestion={skillMcqCustomeQuestion}
            saveCustomeMcqQuestion={saveCustomeMcqQuestion}
            editCustomeMcqQuestion={editCustomeMcqQuestion}
            removeCustomeMcqQuestion={removeCustomeMcqQuestion}
          />
        )}
        {activeTab === "education" && (
          <Education
            handleEducation={handleEducation}
            education={education}
            setNextPage={setNextPage}
            setPreviousPage={setPreviousPage}
          />
        )}
        {activeTab === "miscInfo" && (
          <MiscInformation
            miscInfo={miscInfo}
            handleMiscInfo={handleMiscInfo}
            handleDisplay={handleDisplay}
            handleFieldChanges={handleFieldChanges}
            handleExpDate={handleExpDate}
            setNextPage={setNextPage}
            setPreviousPage={setPreviousPage}
          />
        )}
        {activeTab === "interviewProcess" && (
          <InterviewProcess
            handleInterviewProcess={handleInterviewProcess}
            interviewProcess={interviewProcess}
            setNextPage={setNextPage}
            setPreviousPage={setPreviousPage}
            clearCommunicationMode={clearCommunicationMode}
            setCommunicationMode={setCommunicationMode}
            modeofCommunication={modeofCommunication}
            handleCandidateTip={handleCandidateTip}
            candidateTip={candidateTip}
          />
        )}
        {activeTab === "workLocation" && (
          <WorkLocation
            workLocation={workLocation}
            handleWorkLocation={handleWorkLocation}
            setNextPage={setNextPage}
            setPreviousPage={setPreviousPage}
          />
        )}
        {activeTab === "onBoarding" && (
          <OnBoarding
            onBoarding={onBoarding}
            handleOnboarding={handleOnboarding}
            setNextPage={setNextPage}
            setPreviousPage={setPreviousPage}
            allOnBoardingId={allOnBoardingId}
          />
        )}
        {activeTab === "personalMessage" && (
          <PersonalMessage
            personalMessageUrl={personalMessageUrl}
            handlePersonalMessage={handlePersonalMessage}
            personalMessageToSend={personalMessageToSend}
            remove={remove}
            setNextPage={setNextPage}
            setPreviousPage={setPreviousPage}
          />
        )}
      </div>
    );
  }
};

export default withRouter(UpdateJob);
