import React, { Component } from "react";
import SweetAlert from "react-bootstrap-sweetalert";

const ErrorAlert = ({ show, msg, confirm }) => {
  return (
    <React.Fragment>
      {show && (
        <SweetAlert 
        error
        style={{ width: "400px" }}
          confirmBtnStyle={{
            background: "#5f5dbd",
            outline: "none",
            borderRadius: "50px",
            width: "100px",
            border: "none",
            padding: "5px 10px",
            marginLeft: "10px",
            color:'#fff',
            textDecoration: "none"
          }}
          onConfirm={confirm}
        >
          <span style={{ fontWeight: "500" }}>{msg}</span>
        </SweetAlert>
      )}
    </React.Fragment>
  );
};

export default ErrorAlert;