import React from "react";
import classes from "./SkillMatrix.module.css";

const SearchSuggestion = ({
  suggestion = [],
  addNewValue,
  clearSuggestion,
  index,
}) => {
  return (
    <div className={classes.searchSuggestion}>
      {suggestion.map((item) => {
        return (
          <div
            style={{ borderBottom: "1px solid gray" }}
            key={Math.random}
            onClick={(e) => {
              addNewValue(e, index, item.value, item._id);
              clearSuggestion();
            }}
          >
            {item.value}
          </div>
        );
      })}
    </div>
  );
};

export default SearchSuggestion;
