import React, { useState, useRef } from "react";
import classes from "./Header.module.css";
import Utility from "../../Utility/Utility";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import down from "../../Assets/Images/down-arrow.png";
import useVisible from "./useVisible";

const Menu = ({ toogle, history }) => {
  const parseJwt = Utility.parseJwt;
  const { ref, isVisible, setIsVisible } = useVisible(false);

  const { employerDetail } = useSelector((state) => ({
    employerDetail: state.EmployerReducer.employerDetail,
  }));

  const goTo = (pageName) => {
    if ((pageName = "/create-job")) {
      localStorage.setItem("isUpdate", "NO");
    }

    history.push(`${pageName}`);
  };

  if (
    localStorage.getItem("Token") &&
    parseJwt(localStorage.getItem("Token")).roleType === "employer"
  ) {
    return (
      <div
        className={
          Utility.isMobile() ? classes.employerMobileMenu : classes.employerMenu
        }
        style={{
          pointerEvents:
            employerDetail && employerDetail.length === 0 ? "none" : "auto",
        }}
      >
        <NavLink to="/employer-dashboard" onClick={toogle}>
          Dashboard
        </NavLink>
        <div
          className={
            Utility.isMobile() ? classes.mobileDropdown : classes.dropdownMenu
          }
          onClick={setIsVisible}
        >
          Jobs&nbsp;
          <img src={down} />
          {isVisible && (
            <div
              ref={ref}
              className={
                Utility.isMobile() ? classes.mobileSubMenu : classes.subMenu
              }
            >
              <div
                onClick={(e) => {
                  Utility.isMobile() && toogle();
                  goTo("/create-job");
                  e.stopPropagation();
                  setIsVisible(!isVisible);
                }}
              >
                Post A Job
              </div>
              <div
                onClick={(e) => {
                  Utility.isMobile() && toogle();
                  history.push("/employer-jobs");
                  e.stopPropagation();
                  setIsVisible(!isVisible);
                }}
              >
                Total Jobs
              </div>
              <div
                onClick={(e) => {
                  Utility.isMobile() && toogle();
                  history.push("/deleted-jobs");
                  e.stopPropagation();
                  setIsVisible(!isVisible);
                }}
              >
                Deleted Jobs
              </div>
              <div
                onClick={(e) => {
                  Utility.isMobile() && toogle();
                  history.push("/drafted-jobs");
                  e.stopPropagation();
                  setIsVisible(!isVisible);
                }}
              >
                Draft Jobs
              </div>
              <div
                onClick={(e) => {
                  Utility.isMobile() && toogle();
                  history.push("/completed-interviews");
                  e.stopPropagation();
                  setIsVisible(!isVisible);
                }}
              >
                Completed Interviews
              </div>
            </div>
          )}
        </div>
        <NavLink to="/interview-scheduled" onClick={toogle}>
          Interviews
        </NavLink>
        <NavLink to="/application-received" onClick={toogle}>
          Applicants
        </NavLink>
        <NavLink to="/employer-profile" onClick={toogle}>
          Profile
        </NavLink>
      </div>
    );
  } else if (
    localStorage.getItem("Token") &&
    parseJwt(localStorage.getItem("Token")).roleType === "candidate"
  ) {
    return (
      <div
        className={Utility.isMobile() ? classes.mobileMenu : classes.menu}
        style={{
          pointerEvents:
            localStorage.getItem("candidateProfielAvailabe") === "true"
              ? "auto"
              : "none",
        }}
      >
        <NavLink to="/candidate-dashboard" onClick={toogle}>
          Dashboard
        </NavLink>
        <div
          className={
            Utility.isMobile() ? classes.mobileDropdown : classes.dropdownMenu
          }
          onClick={(e) => setIsVisible(!isVisible)}
        >
          Jobs&nbsp;
          <img src={down} />
          {isVisible && (
            <div
              ref={ref}
              className={
                Utility.isMobile() ? classes.mobileSubMenu : classes.subMenu
              }
            >
              <div
                onClick={(e) => {
                  Utility.isMobile() && toogle();
                  history.push("/open-jobs");
                  e.stopPropagation();
                  setIsVisible(!isVisible);
                }}
              >
                Open Jobs
              </div>
              <div
                onClick={(e) => {
                  Utility.isMobile() && toogle();
                  history.push("/applied-jobs");
                  e.stopPropagation();
                  setIsVisible(!isVisible);
                }}
              >
                Applied Jobs
              </div>
            </div>
          )}
        </div>
        <NavLink to="/scheduled-interviews" onClick={toogle}>
          Interviews
        </NavLink>
        <NavLink to="/candidate-profile" onClick={toogle}>
          Profile
        </NavLink>
      </div>
    );
  } else {
    return (
      <div className={Utility.isMobile() ? classes.mobileMenu : classes.menu}>
        <NavLink
          exact
          to="/"
          activeStyle={{
            padding: "5px",
            borderBottom: "2px solid #fff",
            color: "#fff",
            // borderRadius: "4px",
            outline: "none",
            cursor: "pointer",
            width: "85px",
            fontSize: "16px",
          }}
          onClick={toogle}
        >
          Company
        </NavLink>
        <NavLink
          exact
          to="/ContactUs"
          activeStyle={{
            padding: "5px",
            borderBottom: "2px solid #fff",
            color: "#fff",
            // borderRadius: "4px",
            outline: "none",
            cursor: "pointer",
            width: "85px",
            fontSize: "16px",
          }}
          onClick={toogle}
        >
          ContactUs
        </NavLink>
        <NavLink
          exact
          to="/AboutUs"
          activeStyle={{
            padding: "5px",
            borderBottom: "2px solid #fff",
            color: "#fff",
            // borderRadius: "4px",
            outline: "none",
            cursor: "pointer",
            width: "75px",
            fontSize: "16px",
          }}
          onClick={toogle}
        >
          About Us
        </NavLink>
        {/* <NavLink
          exact
          to="/support"
          activeStyle={{
            padding: "5px",
            borderBottom: "2px solid #fff",
            color: "#fff",
            // borderRadius: "4px",
            outline: "none",
            cursor: "pointer",
            width: "70px",
            fontSize: "16px",
          }}
          onClick={toogle}
        >
          Support
        </NavLink> */}
      </div>
    );
  }
};

export default withRouter(Menu);
