import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { withRouter } from "react-router-dom";

const HighOrderComponent = (props) => {
  return (
    <>
      {!localStorage.getItem("Token") &&
        props.history.location.pathname === "/feedback" &&
        props.children}
      {!localStorage.getItem("Token") &&
        props.history.location.pathname !== "/feedback" && (
          <>
            <Header />
            {props.children}

            <Footer />
          </>
        )}
      {localStorage.getItem("Token") && (
        <>
          <Header />
          {props.children}

          <Footer />
        </>
      )}
    </>
  );
};

export default withRouter(HighOrderComponent);
