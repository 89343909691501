import axios from 'axios';
import api from '../Constant/Constant';


export  function getRequest(apiUrl){
    const url = api.apiIpPort + api.apiBaseUrl + apiUrl ;
    const header ={
        "Access-Control-Allow-Origin": "*",
        "Authorization": localStorage.getItem('Token') ? 'Bearer'+' '+JSON.parse(localStorage.getItem('Token')) :''
    }
    return axios({
        method:'get',
        url:url,
        headers:header
    })
}


export function postRequest(apiUrl,data){
    const url = api.apiIpPort + api.apiBaseUrl+apiUrl
    const header = {
        "Access-Control-Allow-Origin": "*",
       "Authorization": localStorage.getItem('Token') ? 'Bearer'+' '+JSON.parse(localStorage.getItem('Token')) :''
    }
    return axios({
        method:"post",
        url:url,
        data:data,
        headers:header
    })
}