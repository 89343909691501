import React, { useState, useEffect } from "react";
import { Prompt } from "react-router-dom";

const useUnsavedChanges = (
  message = "Are you sure you want to leave the page?"
) => {
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    window.onbeforeunload = isDirty && (() => message);

    return () => {
      window.onbeforeunload = null;
    };
  }, [isDirty]);

  const onConfirm = () => {};

  const routerPrompt = (
    <Prompt when={isDirty} message={message} onConfirm={onConfirm} />
  );

  return [
    routerPrompt,
    () => setIsDirty(true),
    () => setIsDirty(false),
    isDirty,
  ];
};

export default useUnsavedChanges;
