import * as actionTypes from "./ActionTypes";
import { getRequest, postRequest } from "../../Helper/apiHit";

export const getQuestionsForInterview = (jobId) => {
  return (dispatch) => {
    getRequest(`interview/getQuestionsForInterview?jobId=${jobId}`)
      .then((res) => {
        if (res.data.responseStatus.statusCode === 0) {
          dispatch({
            type: actionTypes.GET_QUESTIONS_FOR_INTERVIEW,
            payload: res.data.responseData,
          });
        } else {
          dispatch({
            type: actionTypes.GET_QUESTIONS_FOR_INTERVIEW_FAIL,
            payload: "Something went wrong",
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.GET_QUESTIONS_FOR_INTERVIEW_FAIL,
          payload: "Something went wrong",
        });
      });
  };
};

export const submitAnswerVideo = (file, applicationId) => {
  return (dispatch) => {
    postRequest(
      `interview/submitAnswerVideo?applicationId=${applicationId}`,
      file
    )
      .then((res) => {
        if (res.data.responseStatus.statusCode === 0) {
          dispatch({
            type: actionTypes.SUBMIT_ANSWER_VIDEO,
          });
        } else {
          dispatch({
            type: actionTypes.SUBMIT_ANSWER_VIDEO_FAIL,
            payload: "Somthing went wrong",
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.SUBMIT_ANSWER_VIDEO_FAIL,
          payload: "Somthing went wrong",
        });
      });
  };
};

export const submitCandidateInterview = (data) => {
  return (dispatch) => {
    postRequest("interview/submitInterview", data)
      .then((res) => {
        if (res.data.responseStatus.statusCode === 0) {
          dispatch({ type: actionTypes.SUBMIT_CANDIDATE_INTERVIEW });
        } else {
          dispatch({
            type: actionTypes.SUBMIT_CANDIDATE_INTERVIEW_FAIL,
            payload: "Somthing went wrong",
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.SUBMIT_CANDIDATE_INTERVIEW_FAIL,
          payload: "Somthing went wrong",
        });
      });
  };
};

export const candidateAnswerAudio = (data) => {
  return (dispatch) => {
    let promise = [];
    let failed = [];
    let path = [];
    data.map((item) => {
      promise.push(
        postRequest(
          `candidate/candidateAnswerAudio?audioName=${item.name}`,
          item.file
        )
      );
    });

    Promise.allSettled(promise)
      .then((results) => {
        results.forEach((result) => {
          if (result.status === "fulfilled") {
            path.push(result.value.data.responseData);
          } else {
            failed.push(result.value.data.responseData);
          }
        });

        if (failed.length === 0) {
          dispatch({
            type: actionTypes.AUDIO_UPLOADED,
            payload: path,
          });
        } else {
          dispatch({
            type: actionTypes.AUDIO_UPLOADED_FAIL,
            payload: "Somthing went wrong",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.AUDIO_UPLOADED_FAIL,
          payload: "Somthing went wrong",
        });
      });
  };
};

export const getFeedback = (id) => {
  return (dispatch) => {
    getRequest(`interview/getFeedback?applicationId=${id}`)
      .then((res) => {
        if (res.data.responseStatus.statusCode === 0) {
          dispatch({
            type: actionTypes.GET_CANDIDATE_FEEDBACK_SUCCESS,
            payload: res.data.responseData,
          });
        } else {
          dispatch({
            type: actionTypes.GET_CANDIDATE_FEEDBACK_FAIL,
            payload: "Something went wrong",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_CANDIDATE_FEEDBACK_FAIL,
          payload: "Something went wrong",
        });
      });
  };
};
