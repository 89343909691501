import React, { useState, useRef, useEffect } from "react";
import classes from "./EmployerProfile.module.css";
import Dropdown from "../../../components/Dropdown/Dropdown";
import Toast from "../../../common/Toast/Toast";
import * as actions from "../../../Store/Actions/index";
import * as actionTypes from "../../../Store/Actions/ActionTypes";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import Loader from "../../../common/Loader/Loader";
import SuccessAlert from "../../../common/SuccessAlert/SuccessAlert";
import ErrorAlert from "../../../common/ErrorAlert/ErrorAlert";

const EmployerProfile = ({ history }) => {
  const logoRef = useRef();
  let dispatch = useDispatch();
  const [selectedImage, setSelectedImage] = useState();
  const [imgObject, setImgObject] = useState();
  const [data, setData] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [error, setError] = useState({});
  const [alert, setAlert] = useState(false);
  const {
    loader,
    masterCompanySectorList,
    masterCountryList,
    masterStateList,
    masterCityList,
    errorMsg,
    logoUploaded,
    profileSubmitted,
    employerDetail,
  } = useSelector((state) => ({
    loader: state.EmployerReducer.loader,
    masterCompanySectorList: state.MasterReducer.masterCompanySectorList,
    masterCountryList: state.MasterReducer.masterCountryList,
    masterStateList: state.MasterReducer.masterStateList,
    masterCityList: state.MasterReducer.masterCityList,
    errorMsg: state.EmployerReducer.errorMsg,
    logoUploaded: state.EmployerReducer.logoUploaded,
    profileSubmitted: state.EmployerReducer.profileSubmitted,
    employerDetail: state.EmployerReducer.employerDetail,
  }));

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(actions.getMasterCountryList());
    dispatch(actions.getMasterStateList());
    dispatch(actions.getMasterCityList());
    dispatch(actions.getMasterCompanySectorList());
    dispatch(actions.getEmployerDetails());
  }, []);

  const handleLogoUpload = (e) => {
    setError({});
    if (e.target.files[0]) {
      if (e.target.files[0].size > 1024 * 1024 * 5) {
        alert("Size must be less than or equal to 5MB");
        logoRef.current.value = "";
      } else {
        let reader = new FileReader();
        setImgObject(e.target.files[0]);
        reader.onload = (e) => {
          setSelectedImage(e.target.result);
        };
        reader.readAsDataURL(e.target.files[0]);
      }
    }
  };

  const clearImage = () => {
    if (logoRef.current.value) {
      logoRef.current.value = "";
      setSelectedImage("");
      setImgObject("");
    } else {
      setSelectedImage("");
    }
  };

  const inputFieldChanges = (e) => {
    setError({});
    if (isUpdate) {
      if (e.target.name === "countryId") {
        setData({
          ...data,
          [e.target.name]: e.target.value.trim(),
          stateId: "",
          cityId: "",
        });
      } else if (e.target.name === "stateId") {
        setData({
          ...data,
          [e.target.name]: e.target.value.trim(),
          cityId: "",
        });
      } else {
        setData({
          ...data,
          [e.target.name]: e.target.value.trim(),
        });
      }
    } else {
      setData({
        ...data,
        [e.target.name]: e.target.value.trim(),
      });
    }
  };

  const submitProfile = (e) => {
    e.preventDefault();
    if (validateFields()) {
      let formData = new FormData();
      formData.append("productImage", imgObject);
      dispatch(actions.uploadProfilePicture(formData));
    }
  };

  const validateFields = () => {
    let { zipCode } = data;
    let status = true;
    let errorLocal = {};
    if (parseInt(zipCode) < 10000 || parseInt(zipCode) > 99999) {
      status = false;
      errorLocal = { ...errorLocal, ["zipCode"]: "Zipcode must be of 5 digit" };
    }

    setError(errorLocal);

    return status;
  };

  useEffect(() => {
    if (logoUploaded) {
      if (isUpdate) {
        dispatch(actions.updateProfile(data));
      } else {
        dispatch(actions.submitProfile(data, "emp"));
      }
    }
  }, [logoUploaded]);

  useEffect(() => {
    if (
      profileSubmitted === "Your company profile has been created successfully."
    ) {
      setAlert(true);
      // setToast(
      //   true,
      //   setTimeout(() => {
      //     setToast(false);
      //     dispatch({type:actionTypes.TRASH})
      //     history.push("/employer-dashboard");
      //   }, 3000)
      // );
    } else if (
      profileSubmitted === "Your company profile has been updated successfully."
    ) {
      setAlert(true);
      // setToast(
      //   true,
      //   setTimeout(() => {
      //     setToast(false);
      //   }, 3000)
      // );
    }
  }, [profileSubmitted]);

  useEffect(() => {
    if (errorMsg) {
      setAlert(true);
      // setToast(
      //   true,
      //   setTimeout(() => {
      //     setToast(false);
      //   }, 3000)
      // );
    }
  }, [errorMsg]);

  const confirm = () => {
    window.scrollTo(0, 0);
    if (
      profileSubmitted === "Your company profile has been created successfully."
    ) {
      setAlert(false);
      dispatch({ type: actionTypes.TRASH });
      history.push("/employer-dashboard");
    } else if (
      profileSubmitted === "Your company profile has been updated successfully."
    ) {
      setAlert(false);
    }
    if (errorMsg) {
      setAlert(false);
    }
  };

  useEffect(() => {
    if (
      employerDetail &&
      employerDetail.addresses &&
      employerDetail.companySector &&
      employerDetail.userDetails
    ) {
      setData({
        fullAddress: employerDetail.addresses.fullAddress,
        sectorId: employerDetail.companySector._id,
        cityId: employerDetail.addresses.city._id,
        stateId: employerDetail.addresses.state._id,
        countryId: employerDetail.addresses.country._id,
        zipCode: employerDetail.addresses.zipCode,
      });
      // logoRef.current.value = URL.createObjectURL({value:'employerDetail.userDetails.avatarPath'})
      setSelectedImage(employerDetail.userDetails.avatarPath);
      setIsUpdate(true);
    }
  }, [employerDetail]);

  const updateProfile = (e) => {
    e.preventDefault();
    if (validateFields()) {
      if (!imgObject) {
        dispatch(actions.updateProfile(data, "emp"));
      } else {
        let formData = new FormData();
        formData.append("productImage", imgObject);
        dispatch(actions.uploadProfilePicture(formData));
      }
    }
  };

  if (loader) {
    return <Loader />;
  } else {
    return (
      <div className={classes.employerProfile}>
        {isUpdate ? (
          <div className={classes.employerProfileHeading}>Profile</div>
        ) : (
          <div className={classes.employerProfileHeading}>
            Welcome to conectd. Please build your profile.
          </div>
        )}
        {alert && profileSubmitted && (
          <SuccessAlert show={true} msg={profileSubmitted} confirm={confirm} />
          // <Toast display={toast} type="success" message={profileSubmitted} />
        )}
        {
          alert && errorMsg && (
            <ErrorAlert show={true} msg={errorMsg} confirm={confirm} />
          )
          // <Toast display={toast} type="danger" message={errorMsg} />
        }

        <form
          autocomplete="off"
          onSubmit={isUpdate ? updateProfile : submitProfile}
        >
          <div className={classes.logo}>
            <div className={classes.logoImg}>
              <div className={classes.img}>
                {selectedImage && (
                  <>
                    {/* <i className="fa fa-times" onClick={clearImage}></i>{" "} */}
                    <img src={selectedImage} alt="logo" />
                  </>
                )}
                {!selectedImage && "Logo"}
              </div>
            </div>
            <div
              className={classes.logoUploadBtn}
              // style={{ pointerEvents: selectedImage ? "none" : "" }}
            >
              <button>Upload your organization logo</button>
              <input
                type="file"
                required={!isUpdate ? true : selectedImage ? false : true}
                accept="image/*"
                ref={logoRef}
                onChange={(e) => {
                  handleLogoUpload(e);
                  e.preventDefault();
                }}
              />
            </div>
          </div>
          <div className={classes.companyType}>
            <div className={classes.dropdown}>
              <div className={classes.nametext}>Company Type</div>
              <div className={classes.selectBox}>
                <select
                  name="sectorId"
                  value={data.sectorId}
                  onChange={inputFieldChanges}
                  required
                >
                  <option disabled selected value="">
                    Select Company Type
                  </option>
                  {!masterCompanySectorList && (
                    <option value="">Please wait...</option>
                  )}
                  {masterCompanySectorList &&
                    masterCompanySectorList.length > 0 &&
                    masterCompanySectorList.map((item) => {
                      return <option value={item._id}>{item.name}</option>;
                    })}
                  {masterCompanySectorList &&
                    masterCompanySectorList.length === 0 && (
                      <option value="">{`Sorry no company type available`}</option>
                    )}
                </select>
              </div>
            </div>
          </div>
          <div className={classes.companyOtherDetails}>
            <div className={classes.address}>
              <div className={classes.nametext}>Address</div>
              <div className={classes.inputBox}>
                <textarea
                  defaultValue={data.fullAddress}
                  placeholder="Address"
                  name="fullAddress"
                  autocomplete="false"
                  onChange={inputFieldChanges}
                  required
                />
              </div>
            </div>
            <div className={classes.dropdown}>
              <div className={classes.nametext}>Country</div>
              <div className={classes.selectBox}>
                <select
                  value={data.countryId}
                  name="countryId"
                  id="countryId"
                  onChange={inputFieldChanges}
                  required
                >
                  <option disabled selected value="">
                    Select Country
                  </option>
                  {!masterCountryList && (
                    <option value="">Please wait...</option>
                  )}
                  {masterCountryList &&
                    masterCountryList.length > 0 &&
                    masterCountryList.map((item) => {
                      return <option value={item._id}>{item.name}</option>;
                    })}
                  {masterCountryList && masterCountryList.length === 0 && (
                    <option value="">{`Sorry no country available`}</option>
                  )}
                </select>
              </div>
            </div>
            <div className={classes.dropdown}>
              <div className={classes.nametext}>State</div>
              <div className={classes.selectBox}>
                <select
                  name="stateId"
                  value={data.stateId}
                  id="stateId"
                  onChange={inputFieldChanges}
                  required
                >
                  <option disabled selected value="">
                    Select State
                  </option>
                  {!masterStateList && <option value="">Please wait...</option>}
                  {masterStateList &&
                    masterStateList.length > 0 &&
                    masterStateList.map((item) => {
                      if (data.countryId === item.countryId) {
                        return <option value={item._id}>{item.name}</option>;
                      }
                    })}
                  {masterStateList && masterStateList.length === 0 && (
                    <option value="">{`Sorry no state available`}</option>
                  )}
                </select>
              </div>
            </div>
            <div className={classes.dropdown}>
              <div className={classes.nametext}>City</div>
              <div className={classes.selectBox}>
                <select
                  name="cityId"
                  value={data.cityId}
                  onChange={inputFieldChanges}
                  required
                >
                  <option disabled selected value="">
                    Select City
                  </option>
                  {!masterCityList && <option value="">Please wait...</option>}
                  {masterCityList &&
                    masterCityList.length > 0 &&
                    masterCityList.map((item) => {
                      if (data.stateId === item.stateId) {
                        return <option value={item._id}>{item.name}</option>;
                      }
                    })}
                  {masterCityList && masterCityList.length === 0 && (
                    <option value="">{`Sorry no city available`}</option>
                  )}
                </select>
              </div>
            </div>
            <div className={classes.zip}>
              <div className={classes.nametext}>Zipcode</div>
              <div className={classes.inputBox}>
                <input
                  defaultValue={data.zipCode}
                  type="text"
                  placeholder="Zipcode"
                  maxLength="5"
                  minLength="5"
                  name="zipCode"
                  autocomplete="false"
                  pattern="^[0-9]*$"
                  title="Only numbers are allowed"
                  onChange={inputFieldChanges}
                  required
                />
                <div>{error["zipCode"]}</div>
              </div>
            </div>
          </div>
          {!isUpdate && (
            <div className={classes.termsAndConditions}>
              <input
                required
                type="checkbox"
                title="You must agree our terms and conditions."
              />
              &nbsp;&nbsp;
              <span>I agree to</span>&nbsp;
              <span>Terms & Conditions</span>
              <span>*</span>
            </div>
          )}

          <div className={classes.submitBtn}>
            <button>{isUpdate ? "Update" : "Submit"}</button>
          </div>
        </form>
      </div>
    );
  }
};

export default withRouter(EmployerProfile);
