import React, { useRef } from "react";
import classes from "./Mcq.module.css";

const McqInitial = ({
  masterSkill,
  masterSkillBasedMcqQuestion,
  addNewMcqQuestion,
  index,
}) => {
  const skillRef = useRef();
  const questionRef = useRef();
  const optionOneRef = useRef();
  const optionTwoRef = useRef();
  const optionThreeRef = useRef();
  const optionFourRef = useRef();
  const answerOneRef = useRef();
  const answerTwoRef = useRef();
  const answerThreeRef = useRef();
  const answerFourRef = useRef();

  const clearRefs = () => {
    skillRef.current.value = "";
    questionRef.current.value = "";
    optionOneRef.current.value = "";
    optionTwoRef.current.value = "";
    optionThreeRef.current.value = "";
    optionFourRef.current.value = "";
    answerOneRef.current.checked = false;
    answerTwoRef.current.checked = false;
    answerThreeRef.current.checked = false;
    answerFourRef.current.checked = false;
  };

  const setAnswers = (e) => {
    masterSkillBasedMcqQuestion.map((item) => {
      if (e.target.value === item._id) {
        optionOneRef.current.value = item.options[0];
        optionTwoRef.current.value = item.options[1];
        optionThreeRef.current.value = item.options[2];
        optionFourRef.current.value = item.options[3];
        if(item.options.indexOf(item.answer) === 0){
          answerOneRef.current.checked = true;
        }else if(item.options.indexOf(item.answer) === 1){
          answerTwoRef.current.checked = true;
        }else if(item.options.indexOf(item.answer) === 2){
          answerThreeRef.current.checked = true;
        }else if(item.options.indexOf(item.answer) === 3){
          answerFourRef.current.checked = true;
        }
      }
    });
  };

  const cleanInputSkill=(e)=>{
    document.getElementById("inputSelect").value="";
    document.getElementById("inputQuestion").value="";
    document.getElementById("inputAnswer1").value="";
    document.getElementById("inputAnswer2").value="";
    document.getElementById("inputAnswer3").value="";
    document.getElementById("inputAnswer4").value="";

    document.getElementById("inputAnsRadio1").checked=false
    document.getElementById("inputAnsRadio2").checked=false
    document.getElementById("inputAnsRadio3").checked=false
    document.getElementById("inputAnsRadio4").checked=false
    

      }
  return (
    <form
      onSubmit={(e) => {
        addNewMcqQuestion(e);
        clearRefs();
      }}
      autocomplete="off"
    >
      <div className={classes.mcqStrip}>
        <div className={classes.index}>{index + 1}.</div>
        <div className={classes.select}>
          <select required ref={skillRef} id="inputSelect">
            <option disabled selected value="">
              Select Skill
            </option>
            {masterSkill &&
              masterSkill.map((item) => {
                return <option value={item._id}>{item.value}</option>;
              })}
          </select>
        </div>
        <div className={classes.select}>
          <select required ref={questionRef} onChange={(e) => setAnswers(e)} id="inputQuestion">
            <option disabled selected value="">
              Select Question
            </option>
            {masterSkillBasedMcqQuestion &&
              masterSkillBasedMcqQuestion.map((item) => {
                return <option value={item._id} >{item.name}</option>;
              })}
          </select>
        </div>
        <div className={classes.options}>
          <div className={classes.optionItem}>
            <input
              type="radio"
              ref={answerOneRef}
              required
              name="answer"
              id="inputAnsRadio1"
              // value={optionOneRef.current && optionOneRef.current.value}
            />
            &nbsp;
            <input type="text" required ref={optionOneRef}
             placeholder="Enter Answer" minLength='1' id="inputAnswer1" />
          </div>
          <div className={classes.optionItem}>
            <input
              type="radio"
              ref={answerTwoRef}
              required
              name="answer"
              id="inputAnsRadio2"
              // value={optionTwoRef.current && optionTwoRef.current.value}
            />
            &nbsp;
            <input type="text" required ref={optionTwoRef}
             placeholder="Enter Answer"  minLength='1' id="inputAnswer2"/>
          </div>
          <div className={classes.optionItem}>
            <input
              type="radio"
              ref={answerThreeRef}
              required
              name="answer"
              id="inputAnsRadio3"
              // value={optionThreeRef.current && optionThreeRef.current.value}
            />
            &nbsp;
            <input type="text" required ref={optionThreeRef}
             placeholder="Enter Answer"  minLength='1' id="inputAnswer3"/>
          </div>
          <div className={classes.optionItem}>
            <input
              type="radio"
              ref={answerFourRef}
              required
              name="answer"
              id="inputAnsRadio4"
              // value={optionFourRef.current && optionFourRef.current.value}
            />
            &nbsp;
            <input type="text" required ref={optionFourRef}
             placeholder="Enter Answer"  minLength='1' id="inputAnswer4"/>
          </div>
        </div>

        <div className={classes.btnSave}>
          <button>Add</button>&nbsp;&nbsp;&nbsp;<i class="fa fa-window-close"
              title="Clear Question"
              onClick={cleanInputSkill}
              style={{color: "#9675e4",cursor:"pointer",fontSize: "20px",verticalAlign: "middle"}} aria-hidden="true"></i>
        </div>
      </div>
    </form>
  );
};

export default McqInitial;
