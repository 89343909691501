import React from "react";
import classes from "./TableWrapper.module.css";

const TableWrapper = ({ text, count, children }) => {
  return (
    <div className={classes.tableWrapper}>
      <div className={classes.title}>
        <div>{text}</div>
        <div></div>
        <div>{count}</div>
      </div>
      {children}
    </div>
  );
};

export default TableWrapper;
