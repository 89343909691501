import React, { useState, useEffect, useRef } from "react";
import classes from "./CandidateStartInterview.module.css";
import RecordRTC from "recordrtc";
import ErrorAlert from "../../../common/ErrorAlert/ErrorAlert";

var recorder;
var tts = window.speechSynthesis;
var toSpeak;

const TestAudio = ({ changeActiveComp }) => {
  const [isTestStarted, setIsTestStarted] = useState(false);
  const [isTestCompleted, setIsTestCompleted] = useState(false);
  const [warningAlert, setWarningAlert] = useState(false);

  const videoRef = useRef();

  const getVoices = () => {
    let voices = [];
    voices = tts.getVoices();
    voices = voices.filter((item, index) => {
      if (index === 4) {
        return item;
      }
    });
    return voices;
  };

  const gettingRecoderReady = () => {
    return navigator.mediaDevices.getUserMedia({
      audio: true,
      video: { width: 800, height: 300 },
    });
  };

  const hasUserMedia = () => {
    if (!!navigator.getUserMedia) {
      return true;
    } else {
      return false;
    }
  };

  const startVideoRecording = (camera) => {
    if (videoRef.current !== null) {
      videoRef.current.srcObject = camera;
      videoRef.current.setAttribute("muted", true);
      videoRef.current.setAttribute("autoplay", false);
      videoRef.current.setAttribute("controls", false);
    }

    recorder = RecordRTC(camera, {
      type: "video",
    });

    // recognition.start()
    recorder.startRecording();
    recorder.camera = camera;
  };

  const handleQuestion = () => {
    let voice = getVoices();
    speak(voice[0], "How Are You ?");
  };

  const speak = (voice, dataToSpeak) => {
    toSpeak = new SpeechSynthesisUtterance(dataToSpeak);
    toSpeak.pitch = 0.5;
    toSpeak.rate = 1;
    toSpeak.voice = voice;
    toSpeak.onstart = (e) => {};
    toSpeak.onend = (e) => {};
    tts.speak(toSpeak);
  };

  const startTesting = () => {
    if (hasUserMedia()) {
      gettingRecoderReady()
        .then((res) => {
          setIsTestStarted(true);
          startVideoRecording(res);
          handleQuestion();
        })
        .catch((err) => {
          setWarningAlert(true);
        });
    } else {
      alert("getUserMedia() is not supported in your browser");
    }
  };

  const stopVideoRecordingCallback = () => {
    if (videoRef.current !== null) {
      videoRef.current.src = URL.createObjectURL(recorder.getBlob());
      videoRef.current.srcObject = null;
      videoRef.current.setAttribute("muted", true);
      videoRef.current.setAttribute("autoplay", true);
      videoRef.current.setAttribute("controls", true);
    }
    let file = new File([recorder.getBlob()], "video.mp4", {
      type: "video/mp4",
    });
    setIsTestCompleted(true);
    setIsTestStarted(false);
    // setAnswerText(answerText);
    recorder.camera.stop();
    recorder.destroy();
    // speachEvent.stop();

    recorder = null;
  };

  const stopVideoRecording = () => {
    recorder.stopRecording(stopVideoRecordingCallback);
  };

  const submitTest = () => {
    stopVideoRecording();
  };

  return (
    <div className={classes.left}>
      <div className={classes.title}> Test Your Audio and Video </div>
      {warningAlert && (
        <ErrorAlert
          show={true}
          msg={"Please Enable Microphone & Camera Permissions"}
          confirm={() => setWarningAlert(false)}
        />
      )}
      {(isTestStarted || isTestCompleted) && (
        <div className={classes.testInterviewWrapper}>
          {!isTestCompleted && (
            <div className={classes.question}>How Are You ?</div>
          )}

          <div className={classes.videoBox}>
            <video
              className={classes.video}
              ref={videoRef}
              muted
              playsinline
            ></video>
          </div>
        </div>
      )}

      {isTestStarted && (
        <div className={classes.submitBtn}>
          <button onClick={submitTest}>Submit</button>
        </div>
      )}

      {isTestCompleted && (
        <div className={classes.submitBtn}>
          <button onClick={() => changeActiveComp("interview")}>Proceed</button>
        </div>
      )}

      {!isTestStarted && !isTestCompleted && (
        <div className={classes.submitBtn}>
          <button onClick={startTesting}>
            Start Test Your Audio and Video
          </button>
        </div>
      )}
    </div>
  );
};

export default TestAudio;
