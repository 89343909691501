import React from "react";
import classes from "./JobProgressMobile.module.css";
import jobDetail from "../../../Assets/Images/jobDetail.png";
import skillMatrix from "../../../Assets/Images/skillMatrix.png";
import questions from "../../../Assets/Images/questionMark.png";
import education from "../../../Assets/Images/education.png";
import miscInfo from "../../../Assets/Images/miscInfo.png";

const SectionOne = ({activeIndex = 0}) => {
  return (
    <div className={classes.progressMobileSecOne}>
      <div className={`${classes.element}  ${activeIndex >= 1 && classes.bar}`}>
        <div
          className={`${classes.elementImg} ${activeIndex >= 0 && classes.active}`}
        >
          <img src={jobDetail} />
        </div>
        <div className={classes.elementText}>Job Details</div>
      </div>
      <div className={`${classes.element} ${activeIndex >= 2 && classes.bar}`}>
        <div
          className={`${classes.elementImg} ${activeIndex >= 1 && classes.active}`}
        >
          <img src={skillMatrix} />
        </div>
        <div className={classes.elementText}>Skill matrix</div>
      </div>
      <div className={`${classes.element} ${activeIndex >= 3 && classes.bar}`}>
        <div
          className={`${classes.elementImg} ${activeIndex >= 2 && classes.active}`}
        >
          <img src={questions} />
        </div>
        <div className={classes.elementText}>Questions</div>
      </div>
      <div className={`${classes.element} ${activeIndex >= 4 && classes.bar}`}>
        <div
          className={`${classes.elementImg} ${activeIndex >= 3 && classes.active}`}
        >
          <img src={education} />
        </div>
        <div className={classes.elementText}>Education</div>
      </div>
      <div className={`${classes.element} ${activeIndex >= 5 && classes.bar}`}>
        <div
          className={`${classes.elementImg} ${activeIndex >= 4 && classes.active}`}
        >
          <img src={miscInfo} />
        </div>
        <div className={classes.elementText}>Miscellaneous Information</div>
      </div>
    </div>
  );
};

export default SectionOne;
