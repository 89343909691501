import * as actionTypes from "./ActionTypes";
import { getRequest, postRequest } from "../../Helper/apiHit";

export const getCandidateProfile = () => {
  return (dispatch) => {
    getRequest("candidate/getCandidateProfile")
      .then((res) => {
        if (res.data.responseStatus.statusCode === 0) {
          if (res.data.responseData.isProfileAvailable) {
            let url = [];
            let request = [];
            let baseUrl = res.data.responseData.profileDetail;
            // let profilePic = getRequest("users/getAvatar");
            request.push(getRequest("users/getAvatar"));
            request.push(
              getRequest(`users/getAvatar?filename=${baseUrl.resume}`)
            );
            if (baseUrl.coverLetterPath) {
              request.push(
                getRequest(
                  `users/getAvatar?filename=${baseUrl.coverLetterPath}`
                )
              );
            }
            if (baseUrl.certification[0]) {
              request.push(
                getRequest(
                  `users/getAvatar?filename=${baseUrl.certification[0]}`
                )
              );
            }
            Promise.allSettled(request).then((result) => {
              result.forEach((result) => {
                if (result.status === "fulfilled") {
                  url.push(result.value.data.responseData);
                }
              });
              localStorage.setItem(
                "candidateProfielAvailabe",
                res.data.responseData.isProfileAvailable
              );
              dispatch({
                type: actionTypes.GET_CANDIDATE_PROFILE,
                payload: { ...res.data.responseData, filesUrl: url },
                isCandidateProfileAvailable:
                  res.data.responseData.isProfileAvailable,
              });
            });
          } else {
            localStorage.setItem(
              "candidateProfielAvailabe",
              res.data.responseData.isProfileAvailable
            );
            dispatch({
              type: actionTypes.GET_CANDIDATE_PROFILE,
              payload: res.data.responseData,
              isCandidateProfileAvailable:
                res.data.responseData.isProfileAvailable,
            });
          }
        } else {
          dispatch({
            type: actionTypes.GET_CANDIDATE_PROFILE_FAIL,
            payload: "Somthing went wrong",
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.GET_CANDIDATE_PROFILE_FAIL,
          payload: "Somthing went wrong",
        });
      });
  };
};

export const uploadDocs = (doc) => {
  return (dispatch) => {
    let successPath = [];
    let failedPath = [];
    let path = doc.map((item) => {
      if (item.profilePic) {
        return postRequest("candidate/candiadateAvatar", item.profilePic);
      }
      if (item.resume) {
        return postRequest("candidate/candiadateResume", item.resume);
      }
      if (item.coverLetter) {
        return postRequest("candidate/candiadateCoverLetter", item.coverLetter);
      }
      if (item.certificate) {
        return postRequest("candidate/candiadateCertificate", item.certificate);
      }
    });

    Promise.allSettled(path)
      .then((res) => {
        let setData = [];
        res.forEach((result) => {
          if (result.status === "fulfilled") {
            successPath.push(result.value.data.responseData);
            let urlArr = result.value.config.url.split("/");
            let uploadFileName = urlArr[urlArr.length - 1];
            setData.push({ [uploadFileName]: result.value.data.responseData });
          } else {
            failedPath.push(result.value.data.responseData);
          }
          if (failedPath.length === 0) {
            dispatch({
              type: actionTypes.UPLOAD_CANDIDATE_FILE,
              payload: setData,
            });
          } else {
            dispatch({
              type: actionTypes.UPLOAD_CANDIDATE_FILE_FAIL,
              payload: "Somthing went wrong.",
            });
          }
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.UPLOAD_CANDIDATE_FILE_FAIL,
          payload: "Somthing went wrong.",
        });
      });
  };
};

export const uploadApplyJobsData = ({ resume, video }) => {
  return (dispatch) => {
    let path = [];
    let failedPath = [];
    let promise1;
    let promise2;
    let promises = [];
    if (resume !== null) {
      promise1 = postRequest("candidate/candiadateResume", resume);
      promises.push(promise1);
    }
    if (video !== null) {
      promise2 = postRequest("candidate/candidatePitch", video);
      promises.push(promise2);
    }

    if (promises.length > 0) {
      Promise.allSettled(promises)
        .then((results) => {
          results.forEach((result) => {
            if (result.status === "fulfilled") {
              path.push(result.value.data.responseData);
            } else {
              failedPath.push(result.value.data.responseData);
            }
          });
          if (failedPath.length === 0) {
            dispatch({
              type: actionTypes.APPLY_JOB_UPLOAD_FILE,
              payload: path.length > 1 ? path[1] : path[0],
            });
          } else {
            dispatch({
              type: actionTypes.APPLY_JOB_UPLOAD_FILE_FAIL,
              payload: "Somthing went wrong",
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: actionTypes.APPLY_JOB_UPLOAD_FILE_FAIL,
            payload: "Somthing went wrong",
          });
        });
    } else {
      dispatch({
        type: actionTypes.APPLY_JOB_UPLOAD_FILE,
        payload: "",
      });
    }
  };
};

export const sendCandidateApplication = (data) => {
  return (dispatch) => {
    postRequest("interview/sendCandidateApplication", data)
      .then((res) => {
        if (res.data.responseStatus.statusCode === 0) {
          dispatch({
            type: actionTypes.APPLY_JOB,
            payload: data.jobId,
          });
        } else {
          dispatch({
            type: actionTypes.APPLY_JOB_FAIL,
            payload: "Somthing went wrong",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.APPLY_JOB_FAIL,
          payload: "Somthing went wrong",
        });
      });
  };
};

export const getCandidateApplications = () => {
  return (dispatch) => {
    getRequest("interview/getCandidateApplications")
      .then((res) => {
        if (res.data.responseStatus.statusCode === 0) {
          dispatch({
            type: actionTypes.CANDIDATE_APPLIED_JOBS,
            payload: res.data.responseData,
          });
        } else {
          dispatch({
            type: actionTypes.CANDIDATE_APPLIED_JOBS_FAIL,
            payload: "Somthing went wrong",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.CANDIDATE_APPLIED_JOBS_FAIL,
          payload: "Somthing went wrong",
        });
      });
  };
};

export const getMyScheduleInterviews = () => {
  return (dispatch) => {
    getRequest("interview/getScheduleInterviews")
      .then((res) => {
        if (res.data.responseStatus.statusCode === 0) {
          dispatch({
            type: actionTypes.GET_MY_SCHEDULED_INTERVIEWS,
            payload: res.data.responseData,
          });
        } else {
          dispatch({
            type: actionTypes.GET_MY_SCHEDULED_INTERVIEWS_FAIL,
            payload: "Somthing went wrong",
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.GET_MY_SCHEDULED_INTERVIEWS_FAIL,
          payload: "Somthing went wrong",
        });
      });
  };
};

export const getApplicationDetailsCandidate = (id) => {
  return (dispatch) => {
    getRequest(`interview/getApplicationDetailsCandidate?applicationId=${id}`)
      .then((res) => {
        if (res.data.responseStatus.statusCode === 0) {
          dispatch({
            type: actionTypes.GET_CANDIDATE_APPLICATION_DETAIL,
            payload: res.data.responseData,
          });
        } else {
          dispatch({
            type: actionTypes.GET_CANDIDATE_APPLICATION_DETAIL_FAIL,
            payload: "Somthing went wrong",
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.GET_CANDIDATE_APPLICATION_DETAIL_FAIL,
          payload: "Somthing went wrong",
        });
      });
  };
};

export const uploadDocOnUpdate = (doc) => {
  return (dispatch) => {
    let successPath = [];
    let failedPath = [];

    let path = doc.map((item) => {
      if (item.profilePic) {
        return postRequest("candidate/candiadateAvatar", item.profilePic);
      }
      if (item.resume) {
        return postRequest("candidate/candiadateResume", item.resume);
      }
      if (item.coverLetter) {
        return postRequest("candidate/candiadateCoverLetter", item.coverLetter);
      }
      if (item.certificate) {
        return postRequest("candidate/candiadateCertificate", item.certificate);
      }
    });

    Promise.allSettled(path)
      .then((res) => {
        res.forEach((result) => {
          if (result.status === "fulfilled") {
            successPath.push(result.value.data.responseData);
          } else {
            failedPath.push(result.value.data.responseData);
          }
          if (failedPath.length === 0) {
            dispatch({
              type: actionTypes.UPLOAD_CANDIDATE_FILE,
              payload: path,
            });
          } else {
            dispatch({
              type: actionTypes.UPLOAD_CANDIDATE_FILE_FAIL,
              payload: "Somthing went wrong.",
            });
          }
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.UPLOAD_CANDIDATE_FILE_FAIL,
          payload: "Somthing went wrong.",
        });
      });
  };
};

export const candidateDetailProfile = (id) => {
  return (dispatch) => {
    getRequest(`candidate/candidateDetailProfile?candidateId=${id}`)
      .then((res) => {
        if (res.data.responseStatus.statusCode === 0) {
          dispatch({
            type: actionTypes.CANDIDATE_DETAIL_PROFILE,
            payload: res.data.responseData,
          });
        } else {
          dispatch({
            type: actionTypes.CANDIDATE_DETAIL_PROFILE_FAIL,
            payload: "Somthing went wrong",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.CANDIDATE_DETAIL_PROFILE_FAIL,
          payload: "Somthing went wrong",
        });
      });
  };
};
