import React from "react";
import classes from "./StartInterview.module.css";

const Instruction = ({ changeActiveComp }) => {
  localStorage.setItem("reloadmodal", true);
  localStorage.setItem("pageReloaded", false);
  return (
    <div className={classes.left}>
      <div className={classes.title}> Instructions </div>
      <div className={classes.instructions}>
        <div>1.</div>
        <div>ConectD requires a stable internet connection.</div>
      </div>
      <div className={classes.instructions}>
        <div>2.</div>
        <div>We recommend using Chrome or Firefox browsers.</div>
      </div>
      <div className={classes.instructions}>
        <div>3.</div>
        <div>
          Please close all other browsers, browser tabs, and applications with
          sensitive or personal information before starting the assessment.
        </div>
      </div>
      <div className={classes.instructions}>
        <div>4.</div>
        <div>
          Please close all programs that upload or download files in the
          background (eg. Dropbox, Google Drive, etc.)
        </div>
      </div>
      <div className={classes.instructions}>
        <div>5.</div>
        <div>
          To avoid any distractions, you should close all chat windows,
          screen-savers etc. before starting the interview.
        </div>
      </div>
      <div className={classes.instructions}>
        <div>6.</div>
        <div>
          Be sure to click on the{" "}
          <span
            style={{
              textDecoration: "underline",
              color: "black",
              fontWeight: "bold",
            }}
          >
            Start Recording
          </span>{" "}
          button when you are ready to answer each question.
        </div>
      </div>
      <div className={classes.instructions}>
        <div>7.</div>
        <div>
          Answer each question fully before moving to the next question. You
          cannot go back.
        </div>
      </div>
      <div className={classes.instructions}>
        <div>8.</div>
        <div>
          If you run out of time, all the attempted questions will be
          auto-submitted.
        </div>
      </div>
      <div className={classes.instructions}>
        <div>9.</div>
        <div>
          Your attempted responses will be auto-saved if the test is
          disconnected for any reason. You can resume the test from the last
          attempted question.
        </div>
      </div>
      <div className={classes.instructions}>
        <div>10.</div>
        <div>
          Please do not wear earphones or headphones while attempting the test.
        </div>
      </div>
      <div className={classes.instructions}>
        <div>11.</div>
        <div>Please do not converse while attempting the test.</div>
      </div>
      <div className={classes.submitBtn} style={{ marginTop: "10vh" }}>
        <button onClick={() => changeActiveComp("test")}>Next</button>
      </div>
    </div>
  );
};

export default Instruction;
