import * as actionTypes from "../Actions/ActionTypes";

const initialState = {};

const CommonReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.API_INITIATE:
      return {
        ...state,
        loader: true,
        error: undefined,
        errorMsg: undefined,
        dasboardDetail: undefined,
      };
    case actionTypes.TRASH:
      return {
        ...state,
        dasboardDetail: undefined,
        profile:undefined,
      };
    case actionTypes.CLEAR:
      return {
        ...state,
        jobClosed: undefined,
        jobReposted: undefined,
        jobDeleted: undefined,
        errorMsg: undefined,
      };
    case actionTypes.CHECK_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.payload,
      };
    case actionTypes.GET_DASHBOARD_SUCCESS:
      return {
        ...state,
        loader: false,
        dasboardDetail: action.payload,
      };
    case actionTypes.GET_DASHBOARD_FAIL:
      return {
        ...state,
        loader: false,
        dasboardDetail: {},
        error: true,
        errorMsg: action.payload,
      };
    case actionTypes.GET_POSTEDJOBS_SUCCESS:
      return {
        ...state,
        loader: false,
        postedJobs: action.payload,
        masterPostedJobs: action.payload,
        images: action.images,
        jobDeleted: undefined,
      };
    case actionTypes.GET_POSTEDJOBS_FAIL:
      return {
        ...state,
        loader: false,
        error: true,
        postedJobs: [],
        errorMsg: action.payload,
      };
    case actionTypes.CLOSE_JOB_SUCCESS:
      return {
        ...state,
        loader: false,
        jobClosed: true,
        msg: "Job closed successfully.",
        postedJobs: state.postedJobs.map((item) => {
          let returnItem = { ...item };
          if (item._id === action.jobId) {
            returnItem.isActive = false;
          }
          return returnItem;
        }),
        masterPostedJobs: state.masterPostedJobs.map((item) => {
          let returnItem = { ...item };
          if (item._id === action.jobId) {
            returnItem.isActive = false;
          }
          return returnItem;
        }),
      };
    case actionTypes.CLOSE_JOB_FAIL:
      return {
        ...state,
        loader: false,
        errorMsg: action.payload,
      };
    case actionTypes.REPOSTED_JOB_SUCCESS:
      return {
        ...state,
        loader: false,
        jobReposted: true,
        msg: "Job reposted successfully.",
        postedJobs: state.postedJobs.map((item) => {
          let returnItem = { ...item };
          if (item._id === action.jobId) {
            returnItem.isActive = true;
          }
          return returnItem;
        }),
        masterPostedJobs: state.masterPostedJobs.map((item) => {
          let returnItem = { ...item };
          if (item._id === action.jobId) {
            returnItem.isActive = true;
          }
          return returnItem;
        }),
      };
    case actionTypes.REPOSTED_JOB_FAIL:
      return {
        ...state,
        loader: false,
        errorMsg: action.payload,
      };
    case actionTypes.DELETE_JOB_SUCCESS:
      return {
        ...state,
        loader: false,
        // postedJobs: undefined,
        jobDeleted: true,
        msg: "Job deleted successfully.",
      };
    case actionTypes.DELETE_JOB_FAIL:
      return {
        ...state,
        loader: false,
        errorMsg: action.payload,
      };
    case actionTypes.ALL_JOB_FILTER:
      return {
        ...state,
        postedJobs: state.masterPostedJobs,
      };
    case actionTypes.CLOSE_JOB_FILTER:
      return {
        ...state,
        postedJobs: state.masterPostedJobs.filter((item) => {
          if (item.isActive === false) {
            return item;
          }
        }),
      };
    case actionTypes.OPEN_JOB_FILTER:
      return {
        ...state,
        postedJobs: state.masterPostedJobs.filter((item) => {
          if (item.isActive === true) {
            return item;
          }
        }),
      };
    case actionTypes.CHNAGE_STATE:
      return {
        ...state,
        postedJobs: state.postedJobs.map((item) => {
          let returnItem = { ...item };
          if (item._id === action.payload) {
            returnItem.isApplied = true;
          }
          return returnItem;
        }),
      };
    case actionTypes.CANDIDATE_JOB_FILTER:
      return{
        ...state,
        postedJobs:action.payload
      };
    case actionTypes.CANDIDATE_JOB_FILTER_CLEAR:
      return {
        ...state,
        postedJobs:action.payload
      };    
    default:
      return state;
  }
};

export default CommonReducer;
