import React, { useState, useEffect, useRef } from "react";
import classes from "./ResetPassword.module.css";
import { withRouter, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../Store/Actions/index";
import * as actionTypes from "../../Store/Actions/ActionTypes";
import Toast from "../../common/Toast/Toast";

const ResetPassword = ({ history }) => {
  const newPasswordRef = useRef();
  const confirmPasswordRef = useRef();
  const [newPasswordShow, setNewPasswordShow] = useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
  const [isLoadingset, setIsLoadingset] = useState(false);
  const [toast, setToast] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const token = history.location.search.split("=")[1];

  const { resetPassword, msg, errorMsg } = useSelector((state) => ({
    resetPassword: state.AuthReducer.resetPassword,
    msg: state.AuthReducer.msg,
    errorMsg: state.AuthReducer.errorMsg,
  }));

  useEffect(() => {
    if (resetPassword || errorMsg) {
      setToast(true);
      setIsLoadingset(false);
      setTimeout(() => {
        setToast(false);
        dispatch({ type: actionTypes.CLEAR });
        if (resetPassword) {
          history.push("/login");
        }
      }, 3000);
    }
  }, [resetPassword, errorMsg]);

  const submitNewPassword = (e) => {
    e.preventDefault();
    if (newPasswordRef.current.value !== confirmPasswordRef.current.value) {
      setError(true);
    } else {
      let password = newPasswordRef.current.value;
      setIsLoadingset(true);
      dispatch(actions.resetPassword({ password, token }));
    }
  };

  if (!token) {
    return <Redirect to='/login' />
  } else {
    return (
      <div className={classes.reset}>
        {resetPassword && (
          <Toast display={toast} type="success" message={msg} />
        )}
        {errorMsg && <Toast display={toast} type="danger" message={errorMsg} />}

        <div className={classes.resetPasswordBox}>
          <form onSubmit={submitNewPassword}>
            <div>Change Password</div>
            <div>
              <input
                type={newPasswordShow ? "text" : "password"}
                ref={newPasswordRef}
                placeholder="New Password"
                pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{7,}$"
                title="Password must contain minimum 7 characters, atleast 1 letter, 1 number and 1 special character"
                required
              />
              {newPasswordShow && (
                <i
                  class="fa fa-eye"
                  onClick={() => setNewPasswordShow(!newPasswordShow)}
                ></i>
              )}
              {!newPasswordShow && (
                <i
                  class="fa fa-eye-slash"
                  onClick={() => setNewPasswordShow(!newPasswordShow)}
                ></i>
              )}
            </div>
            <div style={{ border: error && "1px solid red" }}>
              <input
                type={confirmPasswordShow ? "text" : "password"}
                ref={confirmPasswordRef}
                placeholder="Confirm New Password"
                pattern="^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{7,}$"
                title="Password must contain minimum 7 characters, atleast 1 letter, 1 number and 1 special character"
                required
              />
              {confirmPasswordShow && (
                <i
                  class="fa fa-eye"
                  onClick={() => setConfirmPasswordShow(!confirmPasswordShow)}
                ></i>
              )}
              {!confirmPasswordShow && (
                <i
                  class="fa fa-eye-slash"
                  onClick={() => setConfirmPasswordShow(!confirmPasswordShow)}
                ></i>
              )}
            </div>
            <div>
              <button
                className={isLoadingset ? classes.disable : classes.enable}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
};

export default withRouter(ResetPassword);
