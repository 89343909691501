import React from "react";
import classes from "./Personal.module.css";
import pencil from "../../../../Assets/Images/pencil.png";
import trash from "../../../../Assets/Images/trash.png";

const PersonalEdit = ({
  personalIndex,
  personalQuestions,
  removePersonal,
  editPersonal,
  savePersonal,
  handlePersonalChange,
  masterPersonalQuestion,
}) => {
  const getQuestionName = (id) => {
    let questionName =
      masterPersonalQuestion &&
      masterPersonalQuestion.filter((item) => {
        if (item._id === id) {
          return item;
        }
      });
    return questionName;
  };

  const textFormatter = (str) => {
    if (str.length > 80) {
      return `${str.substring(0, 80)}...`;
    } else {
      return str;
    }
  };

  return (
    <div className={classes.strip}>
      <div className={classes.index}>{personalIndex + 1}</div>
      <div className={classes.select}>
        {personalQuestions.isEdit && (
          <select
            required
            defaultValue={personalQuestions.questionId}
            onChange={(e) => handlePersonalChange(e, personalIndex)}
          >
            <option selected disabled value="">
              Select Questions
            </option>
            {masterPersonalQuestion.map((item) => {
              return <option value={item._id}>{item.name}</option>;
            })}
          </select>
        )}
        {!personalQuestions.isEdit && (
          <span className={classes.text}>
            {getQuestionName(personalQuestions.questionId)[0].name}
          </span>
        )}
      </div>
      <div>
        {personalQuestions.isEdit && (
          <div className={classes.btnSave}>
            <button onClick={() => savePersonal(personalIndex)}>Save</button>
          </div>
        )}
        {!personalQuestions.isEdit && (
          <div className={classes.edit}>
            <img src={pencil} onClick={() => editPersonal(personalIndex)} />
            <img src={trash} onClick={() => removePersonal(personalIndex)} />
          </div>
        )}
      </div>
    </div>
  );
};

export default PersonalEdit;
