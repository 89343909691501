import React from "react";
import classes from "./Skill.module.css";
import SkillEdit from "./SkillEdit";
import SkillCustome from "./SkillCustome";
import SkillInitial from "./SkillInitial";

const SkillAddCustom = ({
  skillQuestion,
  skillCustomeQuestion,
  masterSkill,
  masterSkillBasedQuestions,
  addCustomeSkillQuestion,
  editCustomeSkillQuestion,
  removeCustomeSkillQuestion,
}) => {

  return (
    <div className={classes.skill}>
      <div className={classes.head}>
        <div className={classes.index}>S.No.</div>
        <div>Skill</div>
        <div>Question</div>
        <div>Answer</div>
        <div>Action</div>
      </div>
     
      {skillCustomeQuestion.map((item, index) => {
        return (
          <SkillCustome
            customeIndex={masterSkillBasedQuestions.length !== 0 ?  index + 1 :  index + 1 }
            customeQuestion={item}
            masterSkill={masterSkill}
            addCustomeSkillQuestion={addCustomeSkillQuestion}
            editCustomeSkillQuestion={editCustomeSkillQuestion}
            removeCustomeSkillQuestion={removeCustomeSkillQuestion}
          />
        );
      })}
      {skillQuestion.length === 0 &&
        skillCustomeQuestion.length === 0 &&
        masterSkillBasedQuestions.length === 0 && (
          <div style={{ textAlign: "center" }}>
            Sorry no skill based question found
          </div>
        )}
    </div>
  );
};

export default SkillAddCustom;
