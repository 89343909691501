import React from "react";
import classes from "./ContactUs.module.css";
import heroImage from "../../Assets/Images/heroImage.png";
import { withRouter } from "react-router-dom";
import playBtn from "../../Assets/Images/playbtn.png";
import postIcon from "../../Assets/Images/postIcon.png";
import post from "../../Assets/Images/post.png";
import one from "../../Assets/Images/1.png";
import two from "../../Assets/Images/2.png";
import three from "../../Assets/Images/3.png";
import british from "../../Assets/Images/british.png";
import domino from "../../Assets/Images/domino.png";
import dunkin from "../../Assets/Images/dounkin.png";
import oyo from "../../Assets/Images/oyo.png";
import pay from "../../Assets/Images/pay.png";
import pinlab from "../../Assets/Images/pinlab.png";
import mediatek from "../../Assets/Images/media.png";
import envelop from "../../Assets/Images/envelop.png";
import phone from "../../Assets/Images/iphone.png";

const LandingPage = (props) => {
  return (
    <>
      <div className={classes.hero}>
        <div className={classes.left}>
              <p className={classes.phone_no}>Phone Number</p>
        <div className={classes.input}>
              <div>
                <img src={phone} />
              </div>
              <input
                type="number"
                name="number"
                placeholder="Phone number"
                autocomplete="off"
                title="Please enter a valid Phone number"
                required
                autoComplete="off"
              />
            </div>
              <p className={classes.email}>Email</p>
            <div className={classes.inputOne}>
              <div>
                <img src={envelop} />
              </div>
              <input
                 type="email"
                 name="email"
                 placeholder="Email"
                 autocomplete="off"
                 pattern="^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                 title="Please enter a valid Email Id"
                 required
                 autoComplete="off"
              />
              </div>

              <div className={classes.submit}>
              <button
                type="submit"
                className={classes.enabled}
              >
                Contact Us
              </button>
              </div>
            
        </div>
        <div className={classes.right}>
          <img src={heroImage} alt="hero-img" />
          <img src={playBtn} alt="play-btn" />
        </div>
      </div>
    </>
  );
};

export default withRouter(LandingPage);
