import React, { useEffect, useState, useMemo, useRef } from "react";
import classes from "./StartInterview.module.css";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../../common/Loader/Loader";
import RecordRTC from "recordrtc";
import { withRouter } from "react-router-dom";
import * as actions from "../../../Store/Actions/index";
import * as actionTypes from "../../../Store/Actions/ActionTypes";

import Instruction from "./Instruction";
import TestAudio from "./TestAudio";
import InterviewComp from "./InterviewComp";
import SuccessAlert from "../../../common/SuccessAlert/SuccessAlert";
import NormalAlert from "../../../common/NormalAlert/NormalAlert";
import MainInterview from "./mainInterview";

var audioAnswer = [];

const StartInterview = ({ history }) => {
  const [activeComp, setActiveComp] = useState("instruction");
  const [isPageReload, setIsPageReload] = useState(false);
  const [isInterviewSchedule, setisInterviewSchedule] = useState(true);
  let hiddenId = JSON.parse(localStorage.getItem("InterviewCompleted"));

  const changeActiveComp = (compName) => {
    setActiveComp(compName);
  };
  useEffect(() => {
    localStorage.setItem("pauseInterviewVal", false);
  }, []);

  useEffect(() => {
    // setIsPageReload(false)
    if (
      localStorage.getItem("userStartedInterview") == "true" &&
      localStorage.getItem("pageReloaded") == "true"
    ) {
      setIsPageReload(true);
    } else {
      setIsPageReload(false);
    }

    if (hiddenId.includes(history?.location?.state?.ApplicationId)) {
      setisInterviewSchedule(false);
    } else {
      setisInterviewSchedule(true);
    }
  }, []);
  const confirmAlert = () => {
    setIsPageReload(false);
    localStorage.removeItem("userStartedInterview");
    localStorage.removeItem("pageReloaded");
    history.push("/");
  };

  const confirmAlert1 = () => {
    history.push("/");
  };

  return (
    <div className={classes.startInterview}>
      {activeComp === "instruction" && isInterviewSchedule && (
        <Instruction changeActiveComp={changeActiveComp} />
      )}

      {activeComp === "test" && isInterviewSchedule && (
        <TestAudio changeActiveComp={changeActiveComp} />
      )}

      {activeComp === "interview" && isInterviewSchedule && (
        // <InterviewComp />
        <MainInterview />
      )}
      {isPageReload && localStorage.getItem("reloadmodal") && (
        <SuccessAlert
          show={true}
          msg="Your Interview is submitted due to page reload"
          confirm={confirmAlert}
        />
      )}

      {!isInterviewSchedule && (
        <NormalAlert
          show={true}
          msg="Candidate Interview already Submitted"
          confirm={confirmAlert1}
        />
      )}
    </div>
  );
};

export default withRouter(StartInterview);
