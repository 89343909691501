import React from "react";
import classes from "./Mcq.module.css";
import pencil from "../../../../Assets/Images/pencil.png";
import trash from "../../../../Assets/Images/trash.png";

const McqEdit = ({
  list,
  index,
  masterSkillBasedMcqQuestion,
  masterSkill,
  editSkillMcqQuestion,
  removeSkillMcqQuestion,
  submitEditMcq,
}) => {
  const getSkill = (skillId) => {
    let skillName = "";
    masterSkill.map((skill) => {
      if (skill._id === skillId) {
        skillName = skill.value;
      }
    });

    if (skillName != "") {
      return skillName;
    } else {
      return skillId;
    }
  };

  const getQuestion = (questionId) => {
    let questionName = "";
    masterSkillBasedMcqQuestion.map((question) => {
      if (question._id === questionId) {
        questionName = question.name;
      }
    });
    return questionName;
  };

  return (
    <form onSubmit={(e) => submitEditMcq(e, index)} autocomplete="off">
      <div className={classes.mcqStrip}>
        <div className={classes.index}>{index + 1}.</div>
        <div className={classes.select}>
          <div className={classes.mobView}>
            <div className={classes.mob}>Skill:</div>
            <div>
              {list.isEdit && (
                <select required defaultValue={list.skillId}>
                  <option disabled selected value="">
                    Select Skill
                  </option>
                  {masterSkill &&
                    masterSkill.map((item) => {
                      return <option value={item._id}>{item.value}</option>;
                    })}
                </select>
              )}
              {!list.isEdit && (
                <span className={classes.text}>{getSkill(list.skillId)}</span>
              )}
            </div>
          </div>
        </div>
        <div className={classes.select}>
          <div className={classes.mobView}>
            <div className={classes.mob}>Question:</div>
            <div>
              {list.isEdit && (
                <select required defaultValue={list.questionId}>
                  <option disabled selected value="">
                    Select Question
                  </option>
                  {masterSkillBasedMcqQuestion &&
                    masterSkillBasedMcqQuestion.map((item) => {
                      return <option value={item._id}>{item.name}</option>;
                    })}
                </select>
              )}
              {!list.isEdit && (
                <span className={classes.text}>
                  {getQuestion(list.questionId)}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className={classes.options}>
          <div className={classes.mobView}>
            <div className={classes.mob}>Options:</div>
            <div>
              {list.isEdit && (
                <React.Fragment>
                  <div className={classes.optionItem}>
                    <input
                      type="radio"
                      name="answer"
                      defaultChecked={list.answer === list.options[0]}
                    />
                    &nbsp;
                    <input
                      type="text"
                      required
                      defaultValue={list.options[0]}
                      minLength='1'
                      placeholder="Enter Answer"
                    />
                  </div>
                  <div className={classes.optionItem}>
                    <input
                      type="radio"
                      name="answer"
                      defaultChecked={list.answer === list.options[1]}
                    />
                    &nbsp;
                    <input
                      type="text"
                      required
                      defaultValue={list.options[1]}
                      minLength='1'
                      placeholder="Enter Answer"
                    />
                  </div>
                  <div className={classes.optionItem}>
                    <input
                      type="radio"
                      name="answer"
                      defaultChecked={list.answer === list.options[2]}
                    />
                    &nbsp;
                    <input
                      type="text"
                      required
                      defaultValue={list.options[2]}
                      minLength='1'
                      placeholder="Enter Answer"
                    />
                  </div>
                  <div className={classes.optionItem}>
                    <input
                      type="radio"
                      name="answer"
                      defaultChecked={list.answer === list.options[3]}
                    />
                    &nbsp;
                    <input
                      type="text"
                      required
                      defaultValue={list.options[3]}
                      minLength='1'
                      placeholder="Enter Answer"
                    />
                  </div>
                </React.Fragment>
              )}
              {!list.isEdit &&
                list.options.map((item) => {
                  return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span className={classes.text}>{item}</span>&nbsp;
                      <i
                        class="fa fa-check-circle-o"
                        aria-hidden="true"
                        style={{
                          color: "green",
                          opacity: list.answer === item ? "1" : "0",
                        }}
                      ></i>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <div>
          {list.isEdit && (
            <div className={classes.btnSave}>
              <button>Save</button>
            </div>
          )}
          {!list.isEdit && (
            <div className={classes.edit}>
              <img src={pencil} onClick={() => editSkillMcqQuestion(index)} />
              <img src={trash} onClick={() => removeSkillMcqQuestion(index)} />
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

export default McqEdit;
