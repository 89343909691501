import React, { useEffect, useState, useRef } from "react";

import classes from "../StartInterview.module.css";

const MCQ = ({ questions, submitted ,currentQuestionNumber}) => {
  const [currentMcqIndex, setCurrentMCQIndex] = useState(0);
  const [totalMcqCount, setTotalMCQCount] = useState(questions.length - 1);
  const [currentAnswer, setCurrentAnswer] = useState("");
  const [allAnswered, setAllAnswered] = useState([]);
  const [counter, setCounter] = React.useState(120);

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  useEffect(()=>{
   if(counter==0){
     submitAnswer();
   }
  },[counter])

  const submitAnswer = () => {
    if (totalMcqCount > currentMcqIndex) {
      const questionAnswered = {
        question: questions[currentMcqIndex]?.name,
        answer: currentAnswer,
      };

      const transformedAllAnswered = [...allAnswered, questionAnswered];
      setAllAnswered(transformedAllAnswered);
      const transformedCurrentMCQIndex = currentMcqIndex + 1;
      setCurrentMCQIndex(transformedCurrentMCQIndex);
      currentQuestionNumber(currentMcqIndex) 
    } else {
      const questionAnswered = {
        question: questions[currentMcqIndex]?.name,
        answer: currentAnswer,
      };

      const transformedAllAnswered = [...allAnswered, questionAnswered];
      setAllAnswered(transformedAllAnswered);
      submitted(transformedAllAnswered);
    }
    setCurrentAnswer("");
  };

  const answerHandler = (e) => {
    setCurrentAnswer(e.target.value);
  };

  return (
    <>
     <div
        className={classes.maxTimer}
        style={{
          color: counter < 10 && "red",
          border: counter < 10 && "2px solid red",
        }}
      >
        <div>
          <i
            class="fa fa-clock-o"
            aria-hidden="true"
            style={{ color: "yellow", fontSize: "2em" }}
          ></i>
        </div>
        <div> Time Remaining</div>
        <div>{counter}</div>
      </div>


      {(currentMcqIndex || currentMcqIndex === 0) && (
        <div className={classes.left}>
          <div className={classes.testInterviewWrapper}>
            <div className={classes.question}>
              {questions[currentMcqIndex].name}
            </div>
            <div className={classes.options}>
              <div className={classes.option}>
                <input
                  type="radio"
                  name="option"
                  value={questions[currentMcqIndex].options[0]}
                  onChange={answerHandler}
                  checked={
                    questions[currentMcqIndex].options[0] === currentAnswer
                      ? true
                      : false
                  }
                />
                &nbsp;
                {questions[currentMcqIndex].options[0]}
              </div>
              <div className={classes.option}>
                <input
                  type="radio"
                  name="option"
                  value={questions[currentMcqIndex].options[1]}
                  onChange={answerHandler}
                  checked={
                    questions[currentMcqIndex].options[1] === currentAnswer
                      ? true
                      : false
                  }
                />
                &nbsp;
                {questions[currentMcqIndex].options[1]}
              </div>
              <div className={classes.option}>
                <input
                  type="radio"
                  name="option"
                  value={questions[currentMcqIndex].options[2]}
                  onChange={answerHandler}
                  checked={
                    questions[currentMcqIndex].options[2] === currentAnswer
                      ? true
                      : false
                  }
                />
                &nbsp;
                {questions[currentMcqIndex].options[2]}
              </div>
              <div className={classes.option}>
                <input
                  type="radio"
                  name="option"
                  value={questions[currentMcqIndex].options[3]}
                  onChange={answerHandler}
                  checked={
                    questions[currentMcqIndex].options[3] === currentAnswer
                      ? true
                      : false
                  }
                />
                &nbsp;
                {questions[currentMcqIndex].options[3]}
              </div>
            </div>
            <div
              className={classes.submitBtn}
              style={{ marginTop: "5vh", marginBottom: "5vh" }}
            >
              <button onClick={submitAnswer}>Next</button>
              <br />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MCQ;
