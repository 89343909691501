import React, { useEffect, useState } from "react";
import classes from "./Description.module.css";
import parse from "html-react-parser";
import Loader from "../../../common/Loader/Loader";

const Description = ({
  id,
  type,
  description,
  education,
  interviewProcess,
  companyType,
}) => {
  const [comnMode, setComnMode] = useState("");

  useEffect(() => {
    if (interviewProcess) {
      let comnMode = getIterviewMode(interviewProcess.communicationMode);
      setComnMode(comnMode);
    }
  }, [interviewProcess]);

  const getIterviewMode = (mode) => {
    let returnMode = "";
    if (Object.keys(mode).length === 1) {
      returnMode = mode.First;
    }
    if (Object.keys(mode).length === 2) {
      returnMode = mode.First + "," + mode.Second;
    }
    if (Object.keys(mode).length === 3) {
      returnMode = mode.First + "," + mode.Second + "," + mode.Third;
    }
    if (Object.keys(mode).length === 4) {
      returnMode =
        mode.First + "," + mode.Second + "," + mode.Third + "," + mode.Fourth;
    }
    if (Object.keys(mode).length === 5) {
      returnMode =
        mode.First +
        "," +
        mode.Second +
        "," +
        mode.Third +
        "," +
        mode.Fourth +
        "," +
        mode.Fifth;
    }
    if (Object.keys(mode).length === 6) {
      returnMode =
        mode.First +
        "," +
        mode.Second +
        "," +
        mode.Third +
        "," +
        mode.Fourth +
        "," +
        mode.Fifth +
        "," +
        mode.Sixth;
    }
    return returnMode;
  };
  if (!interviewProcess) {
    return <Loader />;
  } else {
    return (
      <div className={classes.description}>
        <div className={classes.title}>Job Description</div>
        <div className={classes.des}>
          <span className={classes.titleText}>Job Id</span>
          <br />
          <span>{id}</span>
        </div>
        <div className={classes.des}>
          <span className={classes.titleText}>Job Type</span>
          <br />
          <span>{type}</span>
        </div>
        <div className={classes.des}>
          <span className={classes.titleText}>Job Description</span>
          <br />
          <span className={classes.opacity}>{parse(description)}</span>
        </div>
        <div className={classes.des}>
          <span className={classes.titleText}>Education</span>
          <br />
          <span>{education}</span>
        </div>
        <div className={classes.des}>
          <span className={classes.titleText}>Interview Process</span>
          <br />
          <span>
            <div className={classes.process}>
              <span
                className={classes.titleText}
                style={{ opacity: "unset !important" }}
              >
                Communication Mode:
              </span>
              &nbsp;&nbsp;<span>{comnMode}</span>
            </div>
            <div className={classes.process}>
              <span className={classes.titleText} style={{ opacity: "unset" }}>
                Rounds:
              </span>
              &nbsp;&nbsp; <span>{interviewProcess.rounds}</span>
            </div>
          </span>
        </div>
        <div className={classes.des}>
          <span className={classes.titleText}>Company Type</span>
          <br />
          <span>{companyType}</span>
        </div>
      </div>
    );
  }
};

export default Description;
