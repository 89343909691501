import React from 'react';
import classes from './TableTopStrip.module.css';


const TableTopStrip = ({text,clickEvent}) =>{
    return(
        <div className={classes.top}>
        <span>{text}</span>
        {/* <span onClick={clickEvent}>See All</span> */}
      </div>
    )
}

export default TableTopStrip;