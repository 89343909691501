import React, { useState, useEffect, useRef, useMemo } from "react";
import classes from "./Login.module.css";
import img from "../../Assets/Images/4.png";
import envelop from "../../Assets/Images/envelop.png";
import key from "../../Assets/Images/key.png";
import { withRouter, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../Store/Actions/index";
import * as actionTypes from "../../Store/Actions/ActionTypes";
import Toast from "../../common/Toast/Toast";
import ForgetPasswordModal from "../../common/ForgetPasswordModal/ForgetPasswordModal";
// import Utility from "../../Utility/Utility";
// import { Redirect } from "react-router-dom";

const Login = ({ history }) => {
  const forgetPasswordRef = useRef();
  const [formData, setFormData] = useState({});
  const [hide, setHide] = useState(false);
  const [toast, setToast] = useState(false);
  const [forgetPasswordError, setForgetPasswordError] = useState({});
  const [isForgetPasswordOpen, setIsForgetPasswordOpen] = useState(false);
  const [forgetPasswordLoading, setForgetPasswordLoading] = useState(false);
  const dispatch = useDispatch();
  const {
    loginStatus,
    forgetStatus,
    errorMsg,
    error,
    loader,
    forgetPassword,
    forgetPasswordFailMsg,
  } = useSelector((state) => ({
    loginStatus: state.AuthReducer.loginStatus,
    error: state.AuthReducer.error,
    errorMsg: state.AuthReducer.errorMsg,
    forgetStatus: state.AuthReducer.forgetStatus,
    loader: state.AuthReducer.loader,
    forgetPassword: state.AuthReducer.forgetPassword,
    forgetPasswordFailMsg: state.AuthReducer.forgetPasswordFailMsg,
  }));

  const handleFieldChanges = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const checkboxStatusHandler = (e) => {};

  const goToLogin = () => {
    dispatch({ type: actionTypes.CLEAR });
    setFormData({});
    setHide(false);
    history.push("/login");
  };

  useEffect(() => {
    if (errorMsg && errorMsg !== "Email is Not verified") {
      setToast(
        true,
        setTimeout(() => {
          setToast(false);
          dispatch({ type: actionTypes.CLEAR });
        }, 3000)
      );
    }
  }, [errorMsg]);

  useEffect(() => {
    if (loginStatus === true) {
      window.location.reload();
    }
  }, [loginStatus]);

  useEffect(() => {
    if (forgetPasswordFailMsg) {
      setForgetPasswordLoading(false);
      setForgetPasswordError({ msg: forgetPasswordFailMsg });
    } else if (forgetPassword) {
      setForgetPasswordLoading(false);
      setIsForgetPasswordOpen(false);
    }
  }, [forgetPasswordFailMsg, forgetPassword]);

  const sendResetLink = () => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    dispatch({type:actionTypes.CLEAR})
    if (forgetPasswordRef.current.value === "") {
      setForgetPasswordError({ msg: "please enter a valid email" });
    } else if (!re.test(forgetPasswordRef.current.value.toLowerCase())) {
      setForgetPasswordError({ msg: "please enter a valid email" });
    } else {
      setForgetPasswordLoading(true);
      setForgetPasswordError({});
      dispatch(
        actions.forgetPassword({ email: forgetPasswordRef.current.value })
      );
    }
  };

  const toogleForgetPasswordBox = () => {
    setForgetPasswordError({});
    setIsForgetPasswordOpen(!isForgetPasswordOpen);
  };

  const submitForm = (e) => {
    e.preventDefault();
    dispatch(actions.signin(formData));
  };

  if (error && errorMsg === "Email is Not verified") {
    return (
      <div className={classes.modal}>
        <i class="fa fa-paper-plane-o" aria-hidden="true"></i>
        <div>
          We have sent an email to {formData.email} . click on the verification
          link to activate your account.
        </div>
        <div className={classes.goToBtn} onClick={() => goToLogin()}>
          Go to login
        </div>
      </div>
    );
  } else if (forgetPassword) {
    return (
      <div className={classes.modal}>
        <i class="fa fa-paper-plane-o" aria-hidden="true"></i>
        <div></div>
        <div>We have sent a reset password link to your Email ID.</div>
        <div className={classes.goToBtn} onClick={() => goToLogin()}>
          Go to login
        </div>
      </div>
    );
  } else {
    return (
      <div className={classes.login}>
        {isForgetPasswordOpen && (
          <ForgetPasswordModal
            forgetFieldRef={forgetPasswordRef}
            sendResetLink={sendResetLink}
            error={forgetPasswordError}
            isLoading={forgetPasswordLoading}
            toogleForgetModal={toogleForgetPasswordBox}
          />
        )}
        <div className={classes.left}></div>

        <div className={classes.right}>
          <form onSubmit={submitForm}>
            <div className={classes.signText}>Sign In</div>
            <p>
              Join to our platform that promotes ethical hiring, transparency
              and full disclosure
            </p>
            <div className={classes.input}>
              <div>
                <img src={envelop} />
              </div>
              <input
                type="email"
                name="email"
                onChange={(e) => handleFieldChanges(e)}
                defaultValue={formData.email}
                placeholder="Email"
                autocomplete="off"
                pattern="^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                title="Please enter a valid Email Id"
                required
                autocomplete="off"
              />
            </div>
            <div className={classes.inputOne}>
              <div>
                <img src={key} />
              </div>
              <input
                type={hide ? "text" : "password"}
                name="password"
                onChange={(e) => handleFieldChanges(e)}
                defaultValue={formData.password}
                placeholder="Password"
                autocomplete="off"
                minLength="7"
                maxLength="15"
                required
                autoComplete="off"
              />
              <div className={classes.show} onClick={() => setHide(!hide)}>
                {hide && <i class="fa fa-eye"></i>}
                {!hide && <i class="fa fa-eye-slash"></i>}
              </div>
            </div>
            {/* <div className={classes.error}>{errorMsg}</div> */}
            <div className={classes.remember}>
              <div>
                <input
                  type="checkbox"
                  name="remember"
                  onChange={(e) => checkboxStatusHandler(e)}
                />{" "}
                Remember Me
              </div>
              <div onClick={toogleForgetPasswordBox}>Forgot password?</div>
            </div>
            <div className={classes.load}>{loader && "Please wait..."}</div>
            <div className={classes.submit}>
              <button
                type="submit"
                className={loader ? classes.disabled : classes.enabled}
              >
                Sign In
              </button>
            </div>
            <div className={classes.goto}>
              Not registered yet?{" "}
              <span>
                <NavLink to="/register">Sign Up</NavLink>
              </span>{" "}
              now
            </div>
          </form>
        </div>

        <Toast display={toast} type="danger" message={errorMsg} />
      </div>
    );
  }
};

export default withRouter(Login);
