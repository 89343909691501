import React,{useEffect,useState} from 'react';
import classes from './NewApplications.module.css';
// import applicants from '../../Assets/Images/applicants.png';
import userIcon from '../../Assets/Images/user-icon.png';
import info from '../../Assets/Images/information.png';
import { useSelector, useDispatch } from "react-redux";
import {postRequest,getRequest} from '../../Helper/apiHit';

const NewApplication = ({applicant,sendTo}) => {
    const [applicantImg,setApplicantImg] = useState()

    useEffect(()=>{
        getRequest(
            `users/getAvatar?avatarPath=${applicant.candidateId.userId.avatarPath}`
          ).then(res=>{
            setApplicantImg(res.data.responseData)
          })
    },[])

    return(
        <div className={classes.newApplication} onClick={sendTo}>
            <div className={classes.img}>
                <img src={applicantImg ? applicantImg : userIcon} />
            </div>
            <div className={classes.jobTitle}>
                <span>{applicant.candidateId.userId.name}</span><br/>
                <span>{applicant.jobId.role}</span>
            </div>
            {/* <div className={classes.info}>
                <img src={info}/>
            </div> */}
        </div>
    )
}

export default NewApplication;