import React, { useState, useEffect } from "react";
import classes from "./ScheduleInterviewModal.module.css";
import cross from "../../Assets/Images/cross.png";
// import calender from "../../Assets/Images/calender.png";
// import clock from "../../Assets/Images/clock.png";
// import DatePicker from "react-datepicker";
import Utility from "../../Utility/Utility";
import ModalWrapper from "../../common/ModalWrapper/ModalWrapper";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import $ from "jquery";

const ScheduleInterviewModal = ({
  toogleApplicationModal,
  sendInvite,
  role,
  applicationDetail,
}) => {
  const [selectedDate, setSelectedDate] = useState();
  const [selectedTime, setSelectedTime] = useState();

  const timeStampFormat=(strDate)=>{
    var newDate =new Date(strDate).getTime()
    setSelectedDate(newDate);
  }

  const dateChange = (date) => {
    setSelectedDate(date);
  };
  $(function () {
    var dtToday = new Date();

    var month = dtToday.getMonth() + 1;
    var day = dtToday.getDate();
    var year = dtToday.getFullYear();
    if (month < 10) month = "0" + month.toString();
    if (day < 10) day = "0" + day.toString();

    var maxDate = year + "-" + month + "-" + day;

    // or instead:
    // var maxDate = dtToday.toISOString().substr(0, 10);

    $("#txtDate").attr("min", maxDate);
  });

  return (
    <ModalWrapper>
      <div className={classes.modalBox}>
        <div className={classes.head}>
          <span>Schedule Interview</span>
          <span>
            <img src={cross} alt="close-btn" onClick={toogleApplicationModal} />
          </span>
        </div>
        <form onSubmit={(e) => sendInvite(e, selectedDate)}>
          <div className={classes.candidateData}>
            <div>Candidate Name</div>
            <div>{applicationDetail.candidateName}</div>
          </div>
          <div className={classes.candidateData}>
            <div>Email ID</div>
            <div>{applicationDetail.candidateEmail}</div>
          </div>
          <div className={classes.interviewDate}>
            <div style={{ fontWeight: "bold" }}>Interview Date</div>
            <div
              className={classes.input}
              style={{ position: "relative", display: "flex" }}
            >
              <input
                type="date"
                required
                onChange={(e) => timeStampFormat(e.target.value)}
                id="txtDate"
              />
              {/* <DatePicker selected={selectedDate} value={selectedDate} onChange={(date) => dateChange(date)}
                 minDate={new Date()}
                      dateFormat="dd/MM/yyyy" name="startDate" placeholderText="DD / MM / YYYY" className={classes.exp_date} />
                 <span className={classes.calanderIcon}><i class="fa fa-calendar" aria-hidden="true" ></i></span> */}
            </div>
          </div>
          {/* <div className={classes.candidateData}>
            <div>Interview Time</div>
            <div className={classes.input}>
              <input type="time" required onChange={(e)=>setSelectedTime(e.target.value)}/>
            </div>
          </div> */}
          <div className={classes.candidateData}>
            <div>Notes to Applicant</div>
            <div>
              I am setting up your interview as a part of Interview process for
              the position of <span>{role}</span>.
            </div>
          </div>
          <div className={classes.sendInviteBtn}>
            <button type="submit">Send Invite</button>
          </div>
        </form>
      </div>
    </ModalWrapper>
  );
};

export default ScheduleInterviewModal;
