import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";

const WarningAlert = ({ show, msg, confirm, cancel }) => {
  return (
    <React.Fragment>
      {show && (
        <SweetAlert
          warning
          showCancel
          style={{ width: "400px" }}
          cancelBtnStyle={{
            outline: "none",
            borderRadius: "50px",
            border: '1px solid #5f5dbd',
            width: "100px",
            textDecoration: "none",
            color: "#5f5dbd",
            padding: "3px 10px",
          }}
          confirmBtnStyle={{
            background: "#5f5dbd",
            outline: "none",
            borderRadius: "50px",
            width: "100px",
            border: "none",
            padding: "5px 10px",
            // marginLeft: "10px",
            color: "#fff",
            textDecoration: "none",
          }}
          onConfirm={confirm}
          onCancel={cancel}
        >
          <span style={{ fontWeight: "500" }}>{msg}</span>
        </SweetAlert>
      )}
    </React.Fragment>
  );
};

export default WarningAlert;
