import React, { useEffect, useState, useRef } from "react";
import classes from "../StartInterview.module.css";
import useSpeechToText from "react-hook-speech-to-text";
import { useSpeechSynthesis } from "react-speech-kit";
import RecordRTC from "recordrtc";

const VideoPreview = ({ submitApi, recordedVideo }) => {
  const submitHandler = () => {
    submitApi();
  };

  return (
    <>
      <div className={classes.videoShow}>
        <div className={classes.testInterviewWrapper}>
          {/* <div className={classes.question}>{skillQuestion.name}</div> */}

          <div className={classes.videoBox}>
            {
              <video
                className={classes.video}
                controls
                ref={recordedVideo}
              ></video>
            }
            {/* <video
              className={classes.video}
              width="320"
              height="240"
              controls
              src={URL.createObjectURL(recordedVideo.getBlob())}
            ></video> */}
          </div>
          <div
            className={classes.submitBtn}
            style={{
              marginBottom: "5vh",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button onClick={submitHandler}>Submit</button>
            <br />
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoPreview;
