import React from 'react';
import classes from './TopCountShowStrip.module.css';


const TopCountShowStrip = ({text,count}) => {
    return (
        <div className={classes.countShowStrip}>
          <div>{text}</div>
          <div>{count}</div>
        </div>
    )
}

export default TopCountShowStrip