import React, { useEffect, useState, useRef } from "react";
import SkillBased from "./skillBased";
import RecordRTC from "recordrtc";
import classes from "../StartInterview.module.css";

let recorder;

const SkillsBased = ({
  questions,
  submitted,
  currentQuestionNumber,
  recordedVideo,
  videoData,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [totalIndex, setTotalIndex] = useState(questions.length - 1);
  const [skillAnswers, setSkillAnwers] = useState([]);

  const videoRef = useRef();

  const changeQuestionIndexHandler = (result) => {
    // console.log("result", result.answer);
    if (totalIndex > currentIndex) {
      const transformedCurrentIndex = currentIndex + 1;
      setCurrentIndex(transformedCurrentIndex);

      const transformedSkillAnswers = [...skillAnswers, result];
      setSkillAnwers(transformedSkillAnswers);
      currentQuestionNumber(currentIndex);
    } else {
      const transformedSkillAnswers = [...skillAnswers, result];
      setSkillAnwers(transformedSkillAnswers);

      const finalArray = [];
      for (let i = 1; i < transformedSkillAnswers.length; i++) {
        let needToRemove = transformedSkillAnswers[i]["answer"];
        let removeableText = transformedSkillAnswers[i - 1]["answer"];
        let removed = needToRemove.replace(removeableText, "");
        finalArray.push({
          question: transformedSkillAnswers[i].question,
          answer: removed,
        });
        console.log({ finalArray })
      }

      // console.log(
      //   "Complete skills answer",
      //   [transformedSkillAnswers[0], ...finalArray].map((ans) => ans.answer)
      // );
      submitted([transformedSkillAnswers[0], ...finalArray]);
      currentQuestionNumber(currentIndex + 1);

      if (currentIndex == questions.length - 1) {
        stopVideoRecording();
      }
    }
  };

  useEffect(() => {
    gettingVideoReady().then((res) => {
      startVideoRecording(res);
    });
  }, []);

  const gettingVideoReady = () => {
    if (window.innerWidth < 800) {
      return navigator.mediaDevices.getUserMedia({
        audio: true,
        video: { width: 400, height: 250 },
      });
    } else if (window.innerWidth < 600) {
      return navigator.mediaDevices.getUserMedia({
        audio: true,
        video: { width: 400, height: 200 },
      });
    } else if (window.innerWidth < 500) {
      return navigator.mediaDevices.getUserMedia({
        audio: true,
        video: { width: 250, height: 200 },
      });
    } else {
      return navigator.mediaDevices.getUserMedia({
        audio: true,
        video: { width: 600, height: 250 },
      });
    }
  };
  const startVideoRecording = (camera) => {
    if (camera) {
      if (videoRef.current !== null) {
        videoRef.current.srcObject = camera;
        videoRef.current.setAttribute("muted", true);
        videoRef.current.setAttribute("autoplay", false);
        videoRef.current.setAttribute("controls", false);
      }

      recorder = RecordRTC(camera, {
        type: "video",
      });

      recorder.startRecording();
      recorder.camera = camera;
    }
  };

  const stopVideoRecording = () => {
    recorder.stopRecording(function () {
      videoData(recorder);
    });
  };

  return (
    <>
      {(currentIndex || currentIndex === 0) && (
        <SkillBased
          skillQuestion={questions[currentIndex]}
          changeHandler={changeQuestionIndexHandler}
          isLast={currentIndex == questions.length - 1}
          videoData={videoData}
        />
      )}
      <div className={classes.a_video}>
        <video
          className={classes.videoSkill}
          ref={videoRef}
          muted
          playsinline
        ></video>
      </div>
    </>
  );
};

export default SkillsBased;
