import React from "react";
import classes from "./Questions.modules.css";
import Question from "./Question";

const Questions = ({ questions }) => {
  return (
    <div className={classes.questions}>
      <div className={classes.title} style={{fontWeight:"bold"}}>Questions:</div>
      <div className={classes.title} style={{fontWeight:"bold"}}>Personal Questions:</div>
      <div className={classes.data}>
        {questions.personalQuestion.length > 0 &&
          questions.personalQuestion.map((item, index) => {
            return <Question index={index} question={item} />;
          })}
        {questions.personalQuestion.length === 0 && (
          <div
            className={classes.question}
            style={{ fontSize: ".9em" }}
          >
            No Personal Question
          </div>
        )}
      </div>
      <div className={classes.title} style={{fontWeight:"bold"}}>Skill Based Questions:</div>
      <div className={classes.data}>
        {questions.skillBasedQuestion.length > 0 &&
          questions.skillBasedQuestion.map((item, index) => {
            return <Question index={index} question={item} />;
          })}
        {questions.skillBasedQuestion.length === 0 && (
          <div
            className={classes.question}
            style={{  fontSize: ".9em" }}
          >
            No Personal Question
          </div>
        )}
      </div>
      <div className={classes.title} style={{fontWeight:"bold"}}>MCQ Questions:</div>
      {questions.skillBasedMcqQuestion.length > 0 &&
        questions.skillBasedMcqQuestion.map((item, index) => {
          return <Question index={index} question={item} />;
        })}
      {questions.skillBasedMcqQuestion.length === 0 && (
        <div
          className={classes.question}
          style={{  fontSize: ".9em" }}
        >
          No MCQ Question
        </div>
      )}
    </div>
  );
};

export default Questions;
