import * as actionTypes from "./ActionTypes";
import { getRequest, postRequest } from "../../Helper/apiHit";

export const signin = (inputData) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.API_INITIATE });
    postRequest("auth/signin", inputData)
      .then((res) => {
        if (res.data.responseStatus.statusCode === 0) {
          if (res.data.responseData.token !== undefined) {
            localStorage.setItem(
              "Token",
              JSON.stringify(res.data.responseData.token)
            );
            localStorage.setItem("Role",res.data.responseData.userRole);
            localStorage.setItem("User", res.data.responseData.user.name);
            var names = [];
            localStorage.setItem("InterviewCompleted", JSON.stringify(names));
            dispatch({
              type: actionTypes.SIGNIN_SUCCESS,
            });
          } else {
            dispatch({
              type: actionTypes.SIGNIN_FAIL,
              errorMsg: res.data.responseData.message,
            });
          }
        } else if (res.data.responseStatus.statusCode === 2) {
          dispatch({
            type: actionTypes.SIGNIN_FAIL,
            errorMsg: res.data.responseStatus.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.API_FAIL,
          payload: err.message,
        });
      });
  };
};

export const signup = (body) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.API_INITIATE });
    postRequest("users/signup", body)
      .then((res) => {
        if (res.data.responseStatus.statusCode === 0) {
          dispatch({
            type: actionTypes.SIGNUP_SUCCESS,
            payload: "newSignup",
          });
        } else if (res.data.responseStatus.statusCode === 1) {
          dispatch({
            type: actionTypes.SIGNUP_SUCCESS,
            payload: "emailAlreadyExists",
            msg: res.data.responseStatus.message,
            emailSent: res.data.responseData.emailSent,
          });
        } else if (res.data.responseStatus.statusCode === 2) {
          dispatch({
            type: actionTypes.SIGNUP_FAIL,
            errorMsg: res.data.responseStatus.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.API_FAIL,
          payload: err.message,
        });
      });
  };
};

export const verifyEmail = (token) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.API_INITIATE });
    getRequest(`auth/verifyEmail?token=${token}`)
      .then((res) => {
        if (res.data.responseStatus.statusCode === 0) {
          dispatch({
            type: actionTypes.VERIFY_EMAIL_SUCCESS,
          });
        } else if (res.data.responseStatus.statusCode === 2) {
          dispatch({
            type: actionTypes.VERIFY_EMAIL_FAIL,
            payload: 'Somthing went wrong',
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.API_FAIL,
          payload: 'Somthing went wrong',
        });
      });
  };
};

export const forgetPassword = (email) => {
  return (dispatch) => {
    postRequest("auth/forgetPassword", email)
      .then((res) => {
        if (res.data.responseStatus.statusCode === 0) {
          dispatch({
            type: actionTypes.FORGET_PASSWORD_SUCCESS,
            payload: res.data.responseData,
          });
        } else if (
          res.data.responseStatus.statusCode === 1 ||
          res.data.responseStatus.statusCode === 2
        ) {
          dispatch({
            type: actionTypes.FORGET_PASSWORD_FAIL,
            payload: res.data.responseStatus.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.FORGET_PASSWORD_FAIL,
          payload: "Somthing went wrong",
        });
      });
  };
};

export const resetPassword = (data) => {
  return (dispatch) => {
    postRequest("auth/resetPassword", data)
      .then((res) => {
        if (res.data.responseStatus.statusCode === 0) {
          dispatch({
            type: actionTypes.RESET_PASSWORD_SUCCESS,
            // payload: res.data.responseData
          });
        } else if (
          res.data.responseStatus.statusCode === 1 ||
          res.data.responseStatus.statusCode === 2
        ) {
          dispatch({
            type: actionTypes.RESET_PASSWORD_FAIL,
            payload: 'Somthing wemt wrong',
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.RESET_PASSWORD_FAIL,
          payload: 'Somthing wemt wrong',
        });
      });
  };
};
