import React, { useEffect, useState } from "react";
import classes from "./Feedback.module.css";
import pdf from "../../../Assets/Images/pdf.png";
import docx from "../../../Assets/Images/docx.png";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../../common/Loader/Loader";
import { withRouter } from "react-router-dom";
import * as actions from "../../../Store/Actions/index";
import * as actionTypes from "../../../Store/Actions/ActionTypes";
import Toast from "../../../common/Toast/Toast";
import Utility from "../../../Utility/Utility";

const Feedback = ({ history }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [toast, setToast] = useState(false);

  let { errorMsg, candidateFeedback } = useSelector((state) => ({
    errorMsg: state.AiInterviewReducer.errorMsg,
    candidateFeedback: state.AiInterviewReducer.candidateFeedback,
  }));
  useEffect(() => {
    if (!candidateFeedback) {
      setIsLoading(true);
      dispatch(actions.getFeedback(history.location.search.split("=")[1]));
    } else {
      setIsLoading(false);
    }
  }, [candidateFeedback]);

  useEffect(() => {
    if (errorMsg) {
      setIsLoading(false);
      setToast(
        true,
        setTimeout(() => {
          setToast(false);
        }, 3000)
      );
    }
  }, [errorMsg]);

  useEffect(() => {
    return () => {
      dispatch({ type: actionTypes.CLEAR });
    };
  }, []);

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <div
        className={classes.feedback}
        style={{ borderRadius: !localStorage.getItem("Token") && "0 0 0 0" }}
      >
        {errorMsg && <Toast display={toast} type="danger" message={errorMsg} />}
        {candidateFeedback && candidateFeedback.length === 0 && (
          <div className={classes.noData}>Sorry no feedback available</div>
        )}
        {candidateFeedback && Object.keys(candidateFeedback).length > 0 && (
          <div className={classes.feedbackBox}>
            <div className={classes.jobTitle}>
              <div className={classes.jobTitleOne}>
                <div>{candidateFeedback?.position}</div>
                <div>JobID : {candidateFeedback?.alternateJobId}</div>
              </div>
              <div className={classes.img}>
                <div>
                  <a href={candidateFeedback?.resumeUrlPath}>
                    <img src={pdf} />
                  </a>
                  <br />
                  Resume
                </div>
                {candidateFeedback?.coverLetterUrl && (
                  <div>
                    <a href={candidateFeedback?.coverLetterUrl}>
                      <img src={docx} />
                    </a>
                    <br />
                    Cover Letter
                  </div>
                )}
                {!candidateFeedback?.coverLetterUrl && "No Cover Letter"}
              </div>
            </div>
            <div className={classes.data}>
              <div className={classes.dataOne}>Personal Details</div>
              <div className={classes.wrapper}>
                <div className={classes.dataText}>
                  <div>Name :</div>
                  <div>{candidateFeedback?.name}</div>
                </div>
                <div className={classes.dataText}>
                  <div>Email :</div>
                  <div style={{ wordBreak: "break-all" }}>
                    {candidateFeedback?.email}
                  </div>
                </div>
                <div className={classes.dataText}>
                  <div>Contact :</div>
                  <div>{candidateFeedback?.mobile}</div>
                </div>
                <div className={classes.dataText}>
                  <div>City :</div>
                  <div>{candidateFeedback?.city}</div>
                </div>
              </div>
              <div className={classes.progressBox}>
                <div className={classes.progress}>
                  <div
                    className={classes.innerProgress}
                    style={{ fontSize: "1em" }}
                  >
                    {parseInt(candidateFeedback?.criteriaMatches).toFixed(2)}%
                  </div>
                </div>
                iSense Score
              </div>
            </div>
            <div className={classes.progressBox1}>
              <div className={classes.progress}>
                <div
                  className={classes.innerProgress}
                  style={{ fontSize: "1em" }}
                >
                  {parseInt(
                    candidateFeedback?.findApplicationId?.isSensePercent == "NaN"
                      ? 0
                      : candidateFeedback?.findApplicationId?.isSensePercent
                  ).toFixed(2)}
                  %
                </div>
              </div>
              criteria Matches
            </div>
            <div className={classes.item}>
              <div className={classes.itemOne}>Applied Date</div>
              <div>{Utility.formatDate(candidateFeedback?.jobAppliedDate)}</div>
            </div>
            <div className={classes.data}>
              <div className={classes.dataOne}>iHire Report</div>
              <div>{candidateFeedback?.iHireReport}</div>
              <div className={classes.progressBox}>
                <div className={classes.progress}>
                  <div className={classes.innerProgress}>
                    {candidateFeedback?.totalCorrectQuestions
                      ? candidateFeedback?.totalCorrectQuestions
                      : 0}
                    /{candidateFeedback?.totalAnalyzedQuestions}
                  </div>
                </div>
                Correct / Total Question
              </div>
            </div>
            <div className={classes.skill}>
              <div className={classes.itemOne}>Skill Summary Report</div>
              <div className={classes.skillReport}>
                <div className={classes.head}>
                  <div>S.No.</div>
                  <div>Skill</div>
                  <div>Note</div>
                  {/* <div>Candidate’s Respose</div>
                  <div>Status</div> */}
                </div>
                <div
                  className={classes.div}
                  style={{ background: "lightgrey", height: "1px" }}
                ></div>
                {candidateFeedback?.skillSummaryReport?.map((item, index) => {
                  return (
                    <div className={classes.skillReportBody}>
                      <div className={classes.index}>{index + 1}</div>
                      <div className={classes.mob}>
                        <div>Skill</div>
                        <div>{item?.skillName}</div>
                      </div>
                      <div className={classes.mob}>
                        <div>Note</div>
                        <div>{item?.note}</div>
                      </div>
                      {/* <div>Candidate’s Respose</div>
                    <div>Status</div> */}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={classes.questionar}>
              <div className={classes.itemOne}>Questionnaire’s Report</div>
              <div className={classes.questionarReport}>
                <div className={classes.questionarhead}>
                  <div>S.No.</div>
                  <div>Question</div>
                  <div>Candidate’s Respose</div>
                  <div>Status</div>
                </div>
                <div
                  className={classes.div}
                  style={{ background: "lightgrey", height: "1px" }}
                ></div>
                {candidateFeedback?.questionnaireReport?.map((item, index) => {
                  return (
                    <div className={classes.questionarReportBody}>
                      <div className={classes.index}>{index + 1}</div>
                      <div className={classes.mob}>
                        <div>Question</div>
                        <div>{item?.question}</div>
                      </div>
                      <div className={classes.mob}>
                        <div>Candidate’s Respose</div>
                        <div>{item?.answer}</div>
                      </div>
                      <div className={classes.mob}>
                        <div>Status</div>
                        <div
                          style={{
                            color:
                              item?.answerStatus === "incorrect"
                                ? "red"
                                : "green",
                          }}
                        >
                          {item?.answerStatus}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={classes.videoBox} style={{ marginTop: "1em" }}>
              <div className={classes.itemOne}>Candidate’s Pitch</div>
              <div>
                {candidateFeedback?.candidatePitch === "" && (
                  <span>Sorry no pitch provided by candidate</span>
                )}
                {candidateFeedback?.candidatePitch != "" && (
                  <video className={classes.video} controls>
                    <source
                      src={candidateFeedback?.candidatePitch}
                      type="video/mp4"
                    />
                  </video>
                )}
              </div>
            </div>
            <div className={classes.videoBox} style={{ marginTop: "1em" }}>
              <div className={classes.itemOne}>Interview Video</div>
              <div>
                <video className={classes.video} controls>
                  <source
                    src={candidateFeedback?.candidateAnswerVideo}
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
};

export default withRouter(Feedback);
