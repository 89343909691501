import React from "react";
import classes from "./Mcq.module.css";
import pencil from "../../../../Assets/Images/pencil.png";
import trash from "../../../../Assets/Images/trash.png";

const McqCustome = ({
  list,
  index,
  masterSkill,
  saveCustomeMcqQuestion,
  editCustomeMcqQuestion,
  removeCustomeMcqQuestion,
}) => {

  const getSkill = (skillId) => {
    let skillName = "";
    masterSkill.map((skill) => {
      if (skill._id === skillId) {
        skillName = skill.value;
      }
    });

    if (skillName != "") {
      return skillName;
    } else {
      return skillId;
    }
  };
  const cleanInputSkill=(e)=>{
    document.getElementById("inputSelectCus").value="";
    document.getElementById("inputQuestionCus").value="";
    document.getElementById("inputAnswerCus1").value="";
    document.getElementById("inputAnswerCus2").value="";
    document.getElementById("inputAnswerCus3").value="";
    document.getElementById("inputAnswerCus4").value="";

    document.getElementById("inputAnsRadioCus1").checked=false
    document.getElementById("inputAnsRadioCus2").checked=false
    document.getElementById("inputAnsRadioCus3").checked=false
    document.getElementById("inputAnsRadioCus4").checked=false

      }
  return (
    <form onSubmit={(e) => saveCustomeMcqQuestion(e, index)} autocomplete="off">
      <div className={classes.mcqStrip}>
        <div className={classes.index}>{index}.</div>
        <div className={classes.select}>
          <div className={list.isEdit ? classes.mobNoEd : classes.mobView}>
            {!list.isEdit && <div className={classes.mob}>Skill:</div>}

            <div>
              {list.isEdit && (
                <select required defaultValue={list.skillId} id="inputSelectCus">
                  <option disabled selected value="">
                    Select Skill
                  </option>
                  {masterSkill &&
                    masterSkill.map((item) => {
                      return <option value={item._id}>{item.value}</option>;
                    })}
                </select>
              )}
              {!list.isEdit && (
                <span className={classes.text}>{getSkill(list.skillId)}</span>
              )}
            </div>
          </div>
        </div>
        <div className={classes.select}>
          <div className={list.isEdit ? classes.mobNoEd : classes.mobView}>
            {!list.isEdit && <div className={classes.mob}>Question:</div>}

            <div>
              {list.isEdit && (
                <input
                  type="text"
                  required
                  // pattern="^[ A-Za-z?.]*$"
                  // title="only alphabets,dot and ? allowed"
                  placeholder="Enter question"
                  id="inputQuestionCus"
                  defaultValue={list.question}
                />
              )}
              {!list.isEdit && (
                <span className={classes.text}>{list.question}</span>
              )}
            </div>
          </div>
        </div>
        <div className={classes.options}>
          <div className={list.isEdit ? classes.mobNoEd : classes.mobView}>
            {!list.isEdit && <div className={classes.mob}>Options:</div>}

            <div>
              {list.isEdit && (
                <React.Fragment>
                  <div className={classes.optionItem}>
                    <input
                      type="radio"
                      name="answer"
                      required
                      defaultChecked={list.answer === list.options[0]}
                      id="inputAnsRadioCus1"
                      
                    />
                    &nbsp;
                    <input
                      type="text"
                      required
                      defaultValue={list.options[0]}
                      minLength='1'
                      placeholder="Enter Answer"
                      id="inputAnswerCus1"
                    />
                  </div>
                  <div className={classes.optionItem}>
                    <input
                      type="radio"
                      name="answer"
                      required
                      defaultChecked={list.answer === list.options[1]}
                      id="inputAnsRadioCus2"
                    />
                    &nbsp;
                    <input
                      type="text"
                      required
                      defaultValue={list.options[1]}
                      minLength='1'
                      placeholder="Enter Answer"
                      id="inputAnswerCus2"
                    />
                  </div>
                  <div className={classes.optionItem}>
                    <input
                      type="radio"
                      name="answer"
                      required
                      defaultChecked={list.answer === list.options[2]}
                      id="inputAnsRadioCus3"
                    />
                    &nbsp;
                    <input
                      type="text"
                      required
                      defaultValue={list.options[2]}
                      minLength='1'
                      placeholder="Enter Answer"
                      id="inputAnswerCus3"
                    />
                  </div>
                  <div className={classes.optionItem}>
                    <input
                      type="radio"
                      name="answer"
                      required
                      defaultChecked={list.answer === list.options[3]}
                      id="inputAnsRadioCus4"
                    />
                    &nbsp;
                    <input
                      type="text"
                      required
                      defaultValue={list.options[3]}
                      minLength='1'
                      placeholder="Enter Answer"
                      id="inputAnswerCus4"
                    />
                  </div>
                </React.Fragment>
              )}
              {!list.isEdit &&
                list.options.map((item) => {
                  return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      
                      
                      <span className={classes.text}>{item}</span>&nbsp;
                      <i
                        class="fa fa-check-circle-o"
                        aria-hidden="true"
                        style={{
                          color: "green",
                          opacity: list.answer === item ? "1" : "0",
                        }}
                      ></i>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <div>
          {list.isEdit && (
            <div className={classes.btnSave}>
            <button>Add</button>&nbsp;&nbsp;&nbsp;
            <i class="fa fa-window-close"
              title="Clear Question"
              onClick={cleanInputSkill}
              style={{color: "#9675e4",cursor:"pointer",fontSize: "20px",verticalAlign: "middle"}} aria-hidden="true"></i>
            
            </div>
          )}
          {!list.isEdit && (
            <div className={classes.edit}>
              <img src={pencil} onClick={() => editCustomeMcqQuestion(index)} />
              <img
                src={trash}
                onClick={() => removeCustomeMcqQuestion(index)}
              />
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

export default McqCustome;
