import * as actionTypes from "./ActionTypes";
import { getRequest, postRequest } from "../../Helper/apiHit";

export const submitJobDetails = (inputData) => {
  return (dispatch) => {
    postRequest("jobs/submitJobDetails", inputData)
      .then((res) => {
        if (res.data.responseStatus.statusCode === 0) {
          dispatch({
            type: actionTypes.SUBMIT_JOB_DETAIL_SUCCESS,
            payload: res.data.responseData,
          });
        } else if (res.data.responseStatus.statusCode === 2) {
          dispatch({
            type: actionTypes.SUBMIT_JOB_DETAIL_FAIL,
            payload: "Somthing went wrong",
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.SUBMIT_JOB_DETAIL_FAIL,
          payload: "Somthing went wrong",
        });
      });
  };
};

export const submitSkillsMatrix = (skillMatrixData) => {
  return (dispatch) => {
    postRequest("jobs/submitSkillMatrix", skillMatrixData)
      .then((res) => {
        if (res.data.responseStatus.statusCode === 0) {
          dispatch({
            type: actionTypes.SUBMIT_SKILLS_MATRIX_SUCCESS,
          });
        } else {
          dispatch({
            type: actionTypes.SUBMIT_SKILLS_MATRIX_FAIL,
            payload: "Somthing went wrong",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.SUBMIT_SKILLS_MATRIX_FAIL,
          payload: "Somthing went wrong",
        });
      });
  };
};

export const submitQualifyingQuestions = (questions) => {
  return (dispatch) => {
    postRequest("jobs/submitQualifyingQuestions", questions)
      .then((res) => {
        if (res.data.responseStatus.statusCode === 0) {
          dispatch({
            type: actionTypes.SUBMIT_QALIFYING_QUESTION,
          });
        } else {
          dispatch({
            type: actionTypes.SUBMIT_QALIFYING_QUESTION_FAIL,
            payload: "Somthing went wrong",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.SUBMIT_QALIFYING_QUESTION_FAIL,
          payload: "Somthing went wrong",
        });
      });
  };
};

export const submitEducation = (body) => {
  return (dispatch) => {
    postRequest("jobs/submitEducation", body)
      .then((res) => {
        if (res.data.responseStatus.statusCode === 0) {
          dispatch({
            type: actionTypes.SUBMIT_EDUCATION_SUCCESS,
          });
        } else {
          dispatch({
            type: actionTypes.SUBMIT_EDUCATION_FAIL,
            payload: "Somthing went wrong",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.SUBMIT_EDUCATION_FAIL,
          payload: "Somthing went wrong",
        });
      });
  };
};

export const submitInterview = (body) => {
  return (dispatch) => {
    postRequest("jobs/submitInterviewProcess", body)
      .then((res) => {
        if (res.data.responseStatus.statusCode === 0) {
          dispatch({
            type: actionTypes.SUBMIT_INTERVIEW_SUCCESS,
          });
        } else {
          dispatch({
            type: actionTypes.SUBMIT_INTERVIEW_FAIL,
            payload: "Somthing went wrong",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.SUBMIT_INTERVIEW_FAIL,
          payload: "Somthing went wrong",
        });
      });
  };
};

export const submitMiscInfo = (body) => {
  return (dispatch) => {
    postRequest("jobs/submitMiscInfo", body)
      .then((res) => {
        if (res.data.responseStatus.statusCode === 0) {
          dispatch({
            type: actionTypes.SUBMIT_MISC_INFO_SUCCESS,
          });
        } else {
          dispatch({
            type: actionTypes.SUBMIT_MISC_INFO_FAIL,
            payload: "Somthing went wrong",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.SUBMIT_MISC_INFO_FAIL,
          payload: "Somthing went wrong",
        });
      });
  };
};

export const submitWorkLocation = (body) => {
  return (dispatch) => {
    postRequest("jobs/submitWorkLocation", body)
      .then((res) => {
        if (res.data.responseStatus.statusCode === 0) {
          dispatch({
            type: actionTypes.WORK_LOCATION_SUBMIT_SUCCESS,
          });
        } else {
          dispatch({
            type: actionTypes.WORK_LOCATION_SUBMIT_FAIL,
            payload: "Somthing went wrong",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.SUBMIT_SKILLS_MATRIX_FAIL,
          payload: "Somthing went wrong",
        });
      });
  };
};

export const submitOnBoardingRequirement = (body) => {
  return (dispatch) => {
    postRequest("jobs/submitOnBoardingDetails", body)
      .then((res) => {
        if (res.data.responseStatus.statusCode === 0) {
          dispatch({
            type: actionTypes.ONBOARDING_SUBMIT_SUCCESS,
          });
        } else {
          dispatch({
            type: actionTypes.ONBOARDING_SUBMIT_FAIL,
            payload: "Somthing went wrong",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.SUBMIT_SKILLS_MATRIX_FAIL,
          payload: "Somthing went wrong",
        });
      });
  };
};

export const uploadVideo = (file, jobId) => {
  return (dispatch) => {
    postRequest(`jobs/uploadVideo?refJobId=${jobId}`, file)
      .then((res) => {
        if (res.data.responseStatus.statusCode === 0) {
          dispatch({
            type: actionTypes.SUBMIT_PERSONAL_MESSAGE,
          });
        } else {
          dispatch({
            type: actionTypes.SUBMIT_PERSONAL_MESSAGE_FAIL,
            payload: "Somthing went wrong",
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.SUBMIT_PERSONAL_MESSAGE_FAIL,
          payload: "Somthing went wrong",
        });
      });
  };
};

export const getCreateJobPreview = (refJobId) => {
  return (dispatch) => {
    getRequest(`jobs/getCreateJobPreview?refJobId=${refJobId}`)
      .then((res) => {
        if (res.data.responseStatus.statusCode === 0) {
          let url = [];
          let count = 1;
          let originalCount = res.data.responseData.uploadedData.length;
          if (originalCount > 0) {
            res.data.responseData.uploadedData.map((item) => {
              new Promise((resolve, reject) => {
                getRequest(`jobs/getUploadedVideo/${item.name}`)
                  .then((res) => {
                    url.push(res.data.responseData);
                    if (count === originalCount) {
                      resolve(1);
                    }
                    count++;
                  })
                  .catch((err) => {});
              }).then((re) => {
                dispatch({
                  type: actionTypes.GET_CREATE_JOB_PREVIEW,
                  payload: { ...res.data.responseData, uploadedData: url },
                  refJobId: res.data.responseData._id,
                });
              });
            });
          } else {
            dispatch({
              type: actionTypes.GET_CREATE_JOB_PREVIEW,
              payload: res.data.responseData,
              refJobId: res.data.responseData._id,
            });
          }
        } else {
          dispatch({
            type: actionTypes.GET_CREATE_JOB_PREVIEW_FAIL,
            payload: "Somthing went wrong",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_CREATE_JOB_PREVIEW_FAIL,
          payload: "Somthing went wrong",
        });
      });
  };
};

export const saveAsDraft = (body) => {
  return (dispatch) => {
    postRequest("jobs/saveAsDraft", body)
      .then((res) => {
        if (res.data.responseStatus.statusCode === 0) {
          dispatch({
            type: actionTypes.SAVE_AS_DRAFT_SUCCESS,
          });
        } else {
          dispatch({
            type: actionTypes.SAVE_AS_DRAFT_FAIL,
            payload: "Somthing went wrong",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.SAVE_AS_DRAFT_FAIL,
          payload: "Somthing went wrong",
        });
      });
  };
};

export const publishJob = (body,update) => {
  return (dispatch) => {
    postRequest("jobs/publishJob", body).then((res) => {
      if (res.data.responseStatus.statusCode === 0) {
        if(update){
          dispatch({
            type: actionTypes.UPDATE_JOB,
          });
        }else{
          dispatch({
            type: actionTypes.PUBLISH_JOB_SUCCESS,
          });
        }
        
      } else {
        dispatch({
          type: actionTypes.PUBLISH_JOB_FAIL,
          payload: "Somthing went wrong",
        });
      }
    }).catch((err) => {
      dispatch({
        type: actionTypes.SAVE_AS_DRAFT_FAIL,
        payload: "Somthing went wrong",
      });
    });
  };
};