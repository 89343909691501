import React from "react";
import classes from "./Skill.module.css";
import SkillEdit from "./SkillEdit";
import SkillCustome from "./SkillCustome";
import SkillInitial from "./SkillInitial";

const Skill = ({
  skillQuestion,
  skillCustomeQuestion,
  masterSkill,
  masterSkillBasedQuestions,
  addNewSkillQuestion,
  removeSkillQuestion,
  editSkillQuestion,
  saveSkillQuestion,
  handleSkillQuestion,
  addCustomeSkillQuestion,
  editCustomeSkillQuestion,
  removeCustomeSkillQuestion,
}) => {

  return (
    <div className={classes.skill}>
      <div className={classes.head}>
        <div className={classes.index}>S.No.</div>
        <div>Skill</div>
        <div>Question</div>
        <div>Answer</div>
        <div>Action</div>
      </div>
      {skillQuestion.map((item, index) => {
        return (
          <SkillEdit
            index={index}
            data={item}
            masterSkill={masterSkill}
            masterSkillBasedQuestions={masterSkillBasedQuestions}
            removeSkillQuestion={removeSkillQuestion}
            editSkillQuestion={editSkillQuestion}
            saveSkillQuestion={saveSkillQuestion}
            handleSkillQuestion={handleSkillQuestion}
          />
        );
      })}

      
      {masterSkillBasedQuestions.length !== 0 && (
        <SkillInitial
          lastIndex={skillQuestion.length}
          masterSkill={masterSkill}
          masterSkillBasedQuestions={masterSkillBasedQuestions}
          addNewSkillQuestion={addNewSkillQuestion}
        />
      )}
     
      {skillQuestion.length === 0 &&
        // skillCustomeQuestion.length === 1 &&
        masterSkillBasedQuestions.length === 0 && (
          <div style={{ textAlign: "center" }}>
            Sorry no master skill based questions found
          </div>
        )}
    </div>
  );
};

export default Skill;
