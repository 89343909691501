import React from "react";
import classes from "./UpcomingInterviews.module.css";
import UpcomingInterview from "./UpcomingInterview";
import { withRouter } from "react-router-dom";

const UpcomingInterviews = ({ history, upcomingInterviews = [] }) => {
  const sendToInterviewScheduled = () => {
    history.push("/interview-scheduled");
  };

  let upInterviews = upcomingInterviews.slice(0, 5);

  return (
    <div className={classes.upcomingInterviews}>
      <div className={classes.top}>
        <span>Upcoming Interview</span>
        <span onClick={sendToInterviewScheduled}>See All</span>
      </div>
      {upcomingInterviews && upcomingInterviews.length === 0 && (
        <div className={classes.noData}>No Upcoming Interviews.</div>
      )}
      {upcomingInterviews && upcomingInterviews.length !== 0 && (
        <>
          <div className={classes.titles}>
            <span>Date</span>
            {/* <span>Time</span> */}
            <span>Name</span>
            <span>Position</span>
          </div>
          {/* <div className={classes.scrollBar} > */}
          <div
            className={classes.body}
            style={{ overflow: "auto", height: "100px" }}
          >
            {upInterviews.map((item) => {
              return (
                <UpcomingInterview
                  date={item.interviewScheduleId.date}
                  // time={item.interviewScheduleId.time}
                  name={item.candidateId.userId.name}
                  position={item.jobId.role}
                />
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default withRouter(UpcomingInterviews);
