import React, { useState, useEffect, useRef } from "react";
import classes from "./MiscInformation.module.css";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../Store/Actions/index";
import * as actionTypes from "../../../Store/Actions/ActionTypes";
import Toast from "../../../common/Toast/Toast";
import Loader from "../../../common/Loader/Loader";
import ErrorAlert from "../../../common/ErrorAlert/ErrorAlert";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import $ from "jquery";

const MiscInformation = ({
  setPreviousPage,
  handleMiscInfo,
  miscInfo,
  handleDisplay,
  handleFieldChanges,
  handleExpDate,
  setNextPage,
}) => {
  let dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const {
    refJobId,
    errorMsg,
    masterOfferedBenifitsList,
    masterRelocationBenifitsList,
    miscInfoSubmitted,
  } = useSelector((state) => ({
    masterOfferedBenifitsList: state.MasterReducer.masterOfferedBenifitsList,
    masterRelocationBenifitsList:
      state.MasterReducer.masterRelocationBenifitsList,
    miscInfoSubmitted: state.CreateJobReducer.miscInfoSubmitted,
    errorMsg: state.CreateJobReducer.errorMsg,
    refJobId: state.CreateJobReducer.refJobId,
  }));

  const wfhRef = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const submitMiscInfo = (e) => {
    e.preventDefault();
    dispatch({ type: actionTypes.CLEAR });
    setIsLoading(true);
    dispatch(actions.submitMiscInfo({ ...miscInfo, refJobId: refJobId }));
    if (miscInfo.isWFHAvailable === "true") {
      if ("current" in wfhRef) {
        if (wfhRef.current.checked) {
          // localStorage.setItem("wfhRef", "selected")
          localStorage.setItem("wfhRef", "notSelected");
        } else {
          localStorage.setItem("wfhRef", "notSelected");
        }
      }
    } else {
      localStorage.setItem("wfhRef", "notSelected");
    }
  };

  $(function () {
    var dtToday = new Date();

    var month = dtToday.getMonth() + 1;
    var day = dtToday.getDate();
    var year = dtToday.getFullYear();
    if (month < 10) month = "0" + month.toString();
    if (day < 10) day = "0" + day.toString();

    var maxDate = year + "-" + month + "-" + day;

    // or instead:
    // var maxDate = dtToday.toISOString().substr(0, 10);

    $("#txtDate").attr("min", maxDate);
  });
  useEffect(() => {
    if (miscInfoSubmitted) {
      setIsLoading(false);
      dispatch({ type: actionTypes.CLEAR });
      setNextPage("interviewProcess");
    } else if (errorMsg) {
      setIsLoading(false);
      setAlert(true);
      // setToast(
      //   true,
      //   setTimeout(() => {
      //     setToast(false);
      //   }, 3000)
      // );
    }
  }, [miscInfoSubmitted, errorMsg]);

  const dateChange = (date) => {
    handleExpDate(date);
  };

  const confirm = () => {
    setAlert(false);
  };

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <div className={classes.miscInformation}>
        {
          alert && errorMsg && (
            <ErrorAlert show={true} msg={errorMsg} confirm={confirm} />
          )
          // <Toast display={toast} type="danger" message={errorMsg} />
        }
        <div className={classes.displayTo}>
          <input
            type="checkbox"
            name="allDisplayToCandidate"
            onChange={handleDisplay}
            checked={miscInfo.allDisplayToCandidate === true && true}
          />
          &nbsp; Display to Candidate &nbsp;
          <i class="fa fa-info-circle" aria-hidden="true"></i>
        </div>
        <form onSubmit={submitMiscInfo}>
          <div className={classes.miscInfoBox}>
            <div className={classes.miscInfoRate}>
              <div className={classes.textLeft}>
                <input
                  type="checkbox"
                  name="displayHourTocandidate"
                  onChange={handleDisplay}
                  checked={miscInfo.displayHourTocandidate === true && true}
                />
                <div className={classes.text}>1. Hourly rate (in USD)</div>
              </div>
              <div className={classes.inputBox}>
                <div>
                  <input
                    type="number"
                    name="hourlyRate"
                    autoComplete="off"
                    value={miscInfo.hourlyRate}
                    onChange={handleFieldChanges}
                    placeholder="Fixed Rate"
                    required={miscInfo.hourlyRateRangeStart ? false : true}
                  />
                  {/* <select
                    name="hourlyRate"
                    onChange={handleFieldChanges}
                    value={miscInfo.hourlyRate}
                    required={miscInfo.hourlyRateRangeStart ? false : true}
                  >
                    <option selected disabled value="">
                      Select Rate
                    </option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select> */}
                </div>
                Or
                <div>
                  <input
                    type="number"
                    name="hourlyRateRangeStart"
                    autoComplete="off"
                    placeholder="From"
                    value={miscInfo.hourlyRateRangeStart}
                    onChange={handleFieldChanges}
                    required={miscInfo.hourlyRate ? false : true}
                  />
                </div>
                To
                <div>
                  <input
                    type="number"
                    autoComplete="off"
                    name="hourlyRateRangeEnd"
                    placeholder="To"
                    value={miscInfo.hourlyRateRangeEnd}
                    onChange={handleFieldChanges}
                    required={miscInfo.hourlyRateRangeStart && true}
                  />
                </div>
              </div>
            </div>
            <div className={classes.positionQualOvertime}>
              <div className={classes.textLeft}>
                <input
                  type="checkbox"
                  name="displayPositionQualifiesForOvertime"
                  onChange={handleDisplay}
                  checked={
                    miscInfo.displayPositionQualifiesForOvertime === true &&
                    true
                  }
                />
                <div className={classes.text}>
                  2. Is the given position qualifies for the over time?
                </div>
              </div>
              <div className={classes.radioInput}>
                <div>
                  <input
                    type="radio"
                    name="positionQualifiesForOvertime"
                    value="true"
                    checked={
                      miscInfo.positionQualifiesForOvertime === "true" && true
                    }
                    onChange={handleFieldChanges}
                    required
                  />
                  Yes
                </div>
                <div>
                  <input
                    type="radio"
                    name="positionQualifiesForOvertime"
                    value="false"
                    checked={
                      miscInfo.positionQualifiesForOvertime === "false" && true
                    }
                    onChange={handleFieldChanges}
                    required
                  />
                  No
                </div>
              </div>
            </div>
            <div className={classes.positionQualOvertime}>
              <div className={classes.textLeft}>
                <input
                  type="checkbox"
                  name="displayWorkVisaSponcer"
                  onChange={handleDisplay}
                  checked={miscInfo.displayWorkVisaSponcer === true && true}
                />
                <div className={classes.text}>
                  3. Are you able to sponsor applicants who require work visas?
                </div>
              </div>
              <div className={classes.radioInput}>
                <div>
                  <input
                    type="radio"
                    name="workVisaSponcer"
                    value="true"
                    checked={miscInfo.workVisaSponcer === "true" && true}
                    onChange={handleFieldChanges}
                    required
                  />
                  Yes
                </div>
                <div>
                  <input
                    type="radio"
                    name="workVisaSponcer"
                    value="false"
                    checked={miscInfo.workVisaSponcer === "false" && true}
                    onChange={handleFieldChanges}
                    required
                  />
                  No
                </div>
              </div>
            </div>
            {/* <div className={classes.offeredBenifits}>
              <div className={classes.textLeft}>
                <input
                  type="checkbox"
                  name="displayOfferedBenifits"
                  onChange={handleDisplay}
                  checked={miscInfo.displayOfferedBenifits === true && true}
                />
                <div className={classes.text}>
                  4. Benefits to be offered by ConectD
                </div>
              </div>
              <div className={classes.offeredBenifitsItem}>
                {masterOfferedBenifitsList &&
                  masterOfferedBenifitsList.map((item) => {
                    return (
                      <div>
                        <input
                          type="checkbox"
                          name="offeredBenefits"
                          value={item._id}
                          onChange={handleFieldChanges}
                          checked={
                            miscInfo.offeredBenefits &&
                            miscInfo.offeredBenefits.indexOf(item._id) !== -1 &&
                            true
                          }
                          required={
                            (miscInfo.offeredBenefits &&
                              miscInfo.offeredBenefits.length === 0) ||
                            (miscInfo.offeredBenefits === undefined && true)
                          }
                        />
                        &nbsp;{item.name}
                      </div>
                    );
                  })}
              </div>
            </div> */}
            <div className={classes.relocation}>
              <div className={classes.top}>
                <div className={classes.textLeft}>
                  <input
                    type="checkbox"
                    name="displayOfferedBenifits"
                    onChange={handleDisplay}
                    checked={miscInfo.displayOfferedBenifits === true && true}
                  />
                  <div className={classes.text}>
                    4. Is Benefits offered by ConectD ?
                  </div>
                </div>
                <div className={classes.radioInputRelocation}>
                  <div>
                    <input
                      type="radio"
                      name="offeredBenifitsApplicable"
                      value="true"
                      checked={
                        miscInfo.offeredBenifitsApplicable === "true" && true
                      }
                      onChange={handleFieldChanges}
                      required
                    />
                    Yes
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="offeredBenifitsApplicable"
                      value="false"
                      checked={
                        miscInfo.offeredBenifitsApplicable === "false" && true
                      }
                      onChange={handleFieldChanges}
                      required
                    />
                    No
                  </div>
                </div>
              </div>
              <div className={classes.offeredBenifitsItem}>
                {miscInfo.offeredBenifitsApplicable === "true" &&
                  masterOfferedBenifitsList &&
                  masterOfferedBenifitsList.map((item) => {
                    return (
                      <div>
                        <input
                          type="checkbox"
                          name="offeredBenefits"
                          value={item._id}
                          onChange={handleFieldChanges}
                          checked={
                            miscInfo.offeredBenefits &&
                            miscInfo.offeredBenefits.indexOf(item._id) !== -1 &&
                            true
                          }
                          required={
                            (miscInfo.offeredBenifitsApplicable === "true" &&
                              miscInfo.offeredBenefits &&
                              miscInfo.offeredBenefits.length === 0) ||
                            (miscInfo.offeredBenefits === undefined && true)
                          }
                        />
                        &nbsp;{item.name}
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className={classes.relocation}>
              <div className={classes.top}>
                <div className={classes.textLeft}>
                  <input
                    type="checkbox"
                    name="displayRelocationBenifits"
                    onChange={handleDisplay}
                    checked={
                      miscInfo.displayRelocationBenifits === true && true
                    }
                  />
                  <div className={classes.text}>
                    5. Does the candidate need to relocate?
                  </div>
                </div>
                <div className={classes.radioInputRelocation}>
                  <div>
                    <input
                      type="radio"
                      name="isRelocationApplicable"
                      value="true"
                      checked={
                        miscInfo.isRelocationApplicable === "true" && true
                      }
                      onChange={handleFieldChanges}
                      required
                    />
                    Yes
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="isRelocationApplicable"
                      value="false"
                      checked={
                        miscInfo.isRelocationApplicable === "false" && true
                      }
                      onChange={handleFieldChanges}
                      required
                    />
                    No
                  </div>
                </div>
              </div>
              <div className={classes.relocationItem}>
                {miscInfo.isRelocationApplicable === "true" &&
                  masterRelocationBenifitsList &&
                  masterRelocationBenifitsList.map((item) => {
                    return (
                      <div>
                        <input
                          type="checkbox"
                          name="relocationBenefits"
                          onChange={handleFieldChanges}
                          checked={
                            miscInfo.relocationBenefits &&
                            miscInfo.relocationBenefits.indexOf(item._id) !==
                              -1 &&
                            true
                          }
                          value={item._id}
                          required={
                            (miscInfo.isRelocationApplicable === "true" &&
                              miscInfo.relocationBenefits &&
                              miscInfo.relocationBenefits.length === 0) ||
                            (miscInfo.relocationBenefits === undefined && true)
                          }
                        />
                        &nbsp;{item.name}
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className={classes.travelApplicable}>
              <div className={classes.top}>
                <div className={classes.textLeft}>
                  <input
                    type="checkbox"
                    name="displayTravelApplicable"
                    onChange={handleDisplay}
                    checked={miscInfo.displayTravelApplicable === true && true}
                  />
                  <div className={classes.text}>
                    6. Does the candidate need to travel for the job?
                  </div>
                </div>
                <div className={classes.radioInputRelocation}>
                  <div>
                    <input
                      type="radio"
                      name="isTravelApplicable"
                      value="true"
                      checked={miscInfo.isTravelApplicable === "true" && true}
                      onChange={handleFieldChanges}
                      required
                    />
                    Yes
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="isTravelApplicable"
                      value="false"
                      checked={miscInfo.isTravelApplicable === "false" && true}
                      onChange={handleFieldChanges}
                      required
                    />
                    No
                  </div>
                </div>
              </div>
              {miscInfo.isTravelApplicable === "true" && (
                <div className={classes.applicablePercentage}>
                  <div>Specify applicable percentage</div>
                  <div className={classes.applicablePercentageRadio}>
                    <div>
                      <input
                        type="radio"
                        name="travelPercentage"
                        value="25"
                        checked={miscInfo.travelPercentage === "25" && true}
                        onChange={handleFieldChanges}
                        required={
                          miscInfo.isTravelApplicable === "true" && true
                        }
                      />
                      <span>Upto 25%</span>
                    </div>
                    <div>
                      <input
                        type="radio"
                        name="travelPercentage"
                        value="50"
                        checked={miscInfo.travelPercentage === "50" && true}
                        onChange={handleFieldChanges}
                        required={
                          miscInfo.isTravelApplicable === "true" && true
                        }
                      />
                      <span>Upto 50%</span>
                    </div>
                    <div>
                      <input
                        type="radio"
                        name="travelPercentage"
                        value="75"
                        checked={miscInfo.travelPercentage === "75" && true}
                        onChange={handleFieldChanges}
                        required={
                          miscInfo.isTravelApplicable === "true" && true
                        }
                      />
                      <span>Upto 75%</span>
                    </div>
                    <div>
                      <input
                        type="radio"
                        name="travelPercentage"
                        value="100"
                        checked={miscInfo.travelPercentage === "100" && true}
                        onChange={handleFieldChanges}
                        required={
                          miscInfo.isTravelApplicable === "true" && true
                        }
                      />
                      <span>Upto 100%</span>
                    </div>
                  </div>
                </div>
              )}
              {miscInfo.isTravelApplicable === "true" && (
                <div className={classes.travelAppplicableNote}>
                  <div>Specify travel requirements details</div>
                  <div className={classes.travelAppplicableTextarea}>
                    <textarea
                      name="travelRequirementsDetails"
                      defaultValue={miscInfo.travelRequirementsDetails}
                      // required={miscInfo.isTravelApplicable === "true" && true}
                      onChange={handleFieldChanges}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className={classes.workFromHome}>
              <div className={classes.top}>
                <div className={classes.textLeft}>
                  <input
                    type="checkbox"
                    name="displayWorkFromHome"
                    onChange={handleDisplay}
                    checked={miscInfo.displayWorkFromHome === true && true}
                  />
                  <div className={classes.text}>
                    7. Is work from home applicable?
                  </div>
                </div>
                <div className={classes.radioInputRelocation}>
                  <div>
                    <input
                      type="radio"
                      name="isWFHAvailable"
                      value="true"
                      checked={miscInfo.isWFHAvailable === "true" && true}
                      onChange={handleFieldChanges}
                      required
                    />
                    Yes
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="isWFHAvailable"
                      value="false"
                      checked={miscInfo.isWFHAvailable === "false" && true}
                      onChange={handleFieldChanges}
                      required
                    />
                    No
                  </div>
                </div>
              </div>
              {miscInfo.isWFHAvailable === "true" && (
                <div className={classes.workFromHomeApplicable}>
                  <div>
                    <input
                      type="radio"
                      name="wfhValue"
                      value="100% Remote"
                      checked={miscInfo.wfhValue === "100% Remote" && true}
                      onChange={handleFieldChanges}
                      required={miscInfo.isWFHAvailable === "true" && true}
                      ref={wfhRef}
                    />
                    100% Remote
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="wfhValue"
                      value="4 days a week"
                      checked={miscInfo.wfhValue === "4 days a week" && true}
                      onChange={handleFieldChanges}
                      required={miscInfo.isWFHAvailable === "true" && true}
                    />
                    4 days a week
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="wfhValue"
                      value="3 days a week"
                      checked={miscInfo.wfhValue === "3 days a week" && true}
                      onChange={handleFieldChanges}
                      required={miscInfo.isWFHAvailable === "true" && true}
                    />
                    3 days a week
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="wfhValue"
                      value="2 days a week"
                      checked={miscInfo.wfhValue === "2 days a week" && true}
                      onChange={handleFieldChanges}
                      required={miscInfo.isWFHAvailable === "true" && true}
                    />
                    2 days a week
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="wfhValue"
                      value="1 day a week"
                      checked={miscInfo.wfhValue === "1 day a week" && true}
                      onChange={handleFieldChanges}
                      required={miscInfo.isWFHAvailable === "true" && true}
                    />
                    1 day a week
                  </div>
                </div>
              )}
            </div>
            <div className={classes.workFromHome}>
              <div className={classes.top}>
                <div className={classes.textLeft}>
                  <input
                    type="checkbox"
                    name="exStartDate"
                    onChange={handleDisplay}
                    checked={miscInfo.exStartDate === true && true}
                  />
                  <div className={classes.text}>8. Expected start date:</div>
                </div>
                <div className={classes.radioInputRelocation}>
                  <div className={classes.expectedDate}>
                    <input
                      type="date"
                      name="startDate"
                      value={miscInfo.startDate}
                      onChange={handleFieldChanges}
                      required
                      id="txtDate"
                      style={{
                        width: "200px",
                        height: "32px",
                        borderRadius: "4px",
                      }}
                    />
                  </div>
                  {/* <div style={{position:"relative"}}>
                    {localStorage.getItem("isUpdate") === "YES" ? 
                    <DatePicker value={new Date(miscInfo.startDate)} selected={new Date(miscInfo.startDate)} onChange={(date) => dateChange(date)}
                      dateFormat="dd/MM/yyyy" name="startDate" minDate={new Date()} placeholderText="DD / MM / YYYY" className={classes.exp_date} />
:
                  <DatePicker value={miscInfo.startDate} selected={miscInfo.startDate} onChange={(date) => dateChange(date)}
                  minDate={new Date()} dateFormat="dd/MM/yyyy" name="startDate" placeholderText="DD / MM / YYYY" className={classes.exp_date} />
              }
                      <span className={classes.dateIcon}><i class="fa fa-calendar" aria-hidden="true" ></i></span>

                  </div> */}
                </div>
              </div>
            </div>
            {/* <div className={classes.expectedDate}>
                Expected start date:
                <input
                  type="date"
                  name="startDate"
                  value={miscInfo.startDate}
                  onChange={handleFieldChanges}
                  required
                />
              </div> */}
            <div className={classes.additionalNotes}>
              <div className={classes.textBottom}>
                <input
                  type="checkbox"
                  name="displayAdditionNotes"
                  onChange={handleDisplay}
                  checked={miscInfo.displayAdditionNotes === true && true}
                />
                <div className={classes.text}>
                  9. Additional Notes for the given job
                </div>
              </div>
              <div>
                <textarea
                  name="additionalNotes"
                  defaultValue={miscInfo.additionalNotes}
                  onChange={handleFieldChanges}
                />
              </div>
            </div>
            <div className={classes.travelApplicable}>
              <div className={classes.top}>
                <div className={classes.textLeft}>
                  <input
                    type="checkbox"
                    name="displayPositionQualifiesForOvertime"
                    // style={{ opacity: "0" }}
                    onChange={handleDisplay}
                    checked={
                      miscInfo.displayPositionQualifiesForOvertime === true &&
                      true
                    }
                  />
                  <div className={classes.text}>
                    10. Is hire pitch required?
                  </div>
                </div>
                <div className={classes.radioInput}>
                  <div>
                    <input
                      type="radio"
                      name="whyShouldHire"
                      value="true"
                      checked={miscInfo.whyShouldHire === "true" && true}
                      onChange={handleFieldChanges}
                      required
                    />
                    Yes
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="whyShouldHire"
                      value="false"
                      checked={miscInfo.whyShouldHire === "false" && true}
                      onChange={handleFieldChanges}
                      required
                    />
                    No
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={classes.skillFooter}>
            <div onClick={() => setPreviousPage("education")}>Back</div>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <button>Next</button>
          </div>
        </form>
      </div>
    );
  }
};

export default MiscInformation;
