import React, { useEffect, useState } from "react";
import classes from "./ScheduledInterviews.module.css";
import { withRouter } from "react-router-dom";
import TableWrapper from "../../../common/TableWrapper/TableWrapper";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../Store/Actions/index";
import * as actionTypes from "../../../Store/Actions/ActionTypes";
import Loader from "../../../common/Loader/Loader";
import Utility from "../../../Utility/Utility";

const ScheduledInterviews = ({ history }) => {
  const dispatch = useDispatch();

  const [mySchedule,setMySchedule] = useState([]);

  const { errorMsg, myScheduledInterviews } = useSelector((state) => ({
    errorMsg: state.CandidateReducer.errorMsg,
    myScheduledInterviews: state.CandidateReducer.myScheduledInterviews,
  }));

  useEffect(() => {
    window.scrollTo(0,0);
    dispatch(actions.getMyScheduleInterviews());
  }, []);

  function compareTime(str1, str2){
    if(str1 === str2){
        return 0;
    }
    var time1 = str1.split(':');
    var time2 = str2.split(':');

    if(eval(time1[0]) > eval(time2[0])){
      if(eval(time1[1]) >= eval(time2[1])){
        return 1;
      }
        
    }  else {
        return -1;
    }
}

  const redyForInterview = (date, time) => {
    let currentDate = new Date();
    // let InterviewDate = `${currentDate.getFullYear()}-${
    //   currentDate.getMonth() + 1
    // }-${currentDate.getDate() < 10 ? `0${currentDate.getDate()}` : currentDate.getDate()}`;
    let InterviewDate = new Date(date)
    if(Date.parse(date) <= Date.parse(currentDate)){
      return true;
    }else{
      return false;
    }
    
    // let InterviewTime = `${currentDate.getHours()}:${currentDate.getMinutes() < 10 ? `0${currentDate.getMinutes()}` : currentDate.getMinutes() }`;
    // console.log(Date.parse(InterviewDate) >= Date.parse(date))
    // if (Date.parse(InterviewDate) <= Date.parse(date) && parseInt(InterviewTime.split(':').join('')) >= parseInt(time.split(':').join(''))) {
    //   return true;
    // } else {
    //   return false;
    // }
  };

  const startInterview = (jobId,candidateId,applicationId) => {
    history.push('/start-interview',{JobId:jobId,CandidateId:candidateId,ApplicationId:applicationId});
  };

  const getFeedback = (id) => {
    history.push(`/feedback?id=${id}`)
  };

  useEffect(() => {
    return () => {
      dispatch({ type: actionTypes.TRASH });
    };
  }, []);

  useEffect(() => {
    if(myScheduledInterviews && myScheduledInterviews.length > 0){
      let arr = myScheduledInterviews.sort((a,b)=>new Date(b.date).getTime()- new Date(a.date).getTime());
      setMySchedule(arr)
    }
   }, [myScheduledInterviews])

  if (!myScheduledInterviews) {
    return <Loader />;
  } else {
    return (
      <div className={classes.scheduledInterviews}>
        <TableWrapper
          text="Interviews"
          count={myScheduledInterviews.length}
        >
          {myScheduledInterviews.length === 0 && (
            <div className={classes.noData}>Sorry No scheduled interviews</div>
          )}
          {myScheduledInterviews.length > 0 && (
            <div className={classes.body}>
              <div className={classes.head}>
                <div>Employer</div>
                <div>Date</div>
                <div>Applied Date</div>
                <div>Designation</div>
                <div style={{ textAlign: "center" }}>Attend AI Interview</div>
                <div>Status</div>
                <div>Feedback</div>
              </div>
              <div className={classes.scrollBar} style={{overflow:"auto",height:"300px"}}>
              {mySchedule.map((item) => {
                // For testing
                // item.date = "2021-05-02"
                // item.applicationStatus = "SCHEDULED"
                // item.applicationStatus = "COMPLETED"
                return (
                  <div className={classes.data}>
                    <div className={classes.mob}>
                      <div className={classes.title}>Employer</div>
                      <div>{item.employerName}</div>
                    </div>
                    <div className={classes.mob}>
                      <div className={classes.title}>
                        Date
                      </div>
                      <div>{`${Utility.formatDate(
                        item.date
                      )}`}</div>
                    </div>
                    <div className={classes.mob}>
                      <div className={classes.title}>Applied Date</div>
                      <div>{Utility.formatDate(item.appliedDate)}</div>
                    </div>
                    <div className={classes.mob}>
                      <div className={classes.title}>Designation</div>
                      <div>{item.role}</div>
                    </div>
                    <div
                      className={`${classes.mob} ${classes.btn}`}
                    >
                      <div className={classes.title}>Attend AI Interview</div>
                      <div>
                        {
                        // new Date(item.date).toDateString() === new Date().toDateString() || new Date(item.date) > new Date() ?
                        new Date(item.date).toDateString() === new Date(Date.now() - 3 * 24 * 60 * 60 * 1000).toDateString()
                        || new Date(item.date) > new Date(Date.now() - 3 * 24 * 60 * 60 * 1000) ?
                          <button
                          className={
                            redyForInterview(item.date, item.time) && item.applicationStatus === "SCHEDULED"
                              ? classes.enable
                              : classes.disable
                          }
                          onClick={() =>
                            redyForInterview(item.date, item.time) && item.applicationStatus === "SCHEDULED" &&
                            startInterview(item.appliedJobId,item.candidateId,item.applicationId)
                          }
                        >
                          Start
                        </button>:
                        // new Date(item.date) < new Date() ? <span>Expired</span> : null
                        new Date(item.date) < new Date(Date.now() - 3 * 24 * 60 * 60 * 1000) && item.applicationStatus !== "COMPLETED"
                        ? <span><button className={classes.disable}>Start</button></span> : 
                        <button className={classes.disable}>Start</button>
                        }
                      </div>
                    </div>
               
                    <div className={classes.mob}>
                      <div className={classes.title}>Designation</div>
                      {item.applicationStatus == "COMPLETED" && <div>COMPLETED</div>}
                      {new Date(item.date) < new Date(Date.now() - 3 * 24 * 60 * 60 * 1000) && item.applicationStatus !== "COMPLETED" && <div>Expired</div>}
                      {new Date(item.date) > new Date(Date.now() - 3 * 24 * 60 * 60 * 1000) && item.applicationStatus == "SCHEDULED" && <div>SCHEDULED</div>}
                    </div>
                 
                    {item.applicationStatus === "SCHEDULED" ? (
                      <div className={classes.mob}>
                        <div className={classes.title}>Feedback</div>
                        <div>-</div>
                      </div>
                    ) : (
                      <div
                        className={`${classes.feedback} ${classes.mob}`}
                        onClick={() => getFeedback(item.applicationId)}
                      >
                        <div className={classes.title}>Feedback</div>
                        <div>Feedback</div>
                      </div>
                    )}
                  </div>
                );
              })}
              </div>
            </div>
          )}
        </TableWrapper>
      </div>
    );
  }
};

export default withRouter(ScheduledInterviews);
