import React, { useEffect,useState } from "react";
import classes from "./DeletedJobs.module.css";
import JobCard from "../../../components/JobCard/JobCard";
import Loader from "../../../common/Loader/Loader";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../Store/Actions/index";
import * as actionTypes from "../../../Store/Actions/ActionTypes";
import Toast from "../../../common/Toast/Toast";
import TopCountShowStrip from '../../../common/TopShowCountStrip/TopCountShowStrip';
import ErrorAlert from '../../../common/ErrorAlert/ErrorAlert';
import SuccessAlert from '../../../common/SuccessAlert/SuccessAlert';

const DeletedJobs = () => {
  const dispatch = useDispatch();
  const [alert, setAlert] = useState(false);
  const [inLoader,setInLoader] = useState(false);

  const { loader, deletedJobs, error, errorMsg, jobReposted } = useSelector(
    (state) => ({
      loader: state.EmployerReducer.loader,
      deletedJobs: state.EmployerReducer.deletedJobs,
      error: state.EmployerReducer.error,
      errorMsg: state.EmployerReducer.errorMsg,
      jobReposted: state.EmployerReducer.jobReposted,
    })
  );

  const repostJob = (id) => {
    setInLoader(true)
    dispatch(actions.repostJob({ refJobId: id }));
  };

  useEffect(() => {
    window.scrollTo(0,0);
    dispatch(actions.deletedJobs());
  }, []);

  useEffect(() => {
    if (errorMsg || jobReposted) {
      setInLoader(false);
      setAlert(true);
      // setToast(
      //   true,
      //   setTimeout(() => {
      //     setToast(false);
      //     dispatch({ type: actionTypes.CLEAR });
      //   }, 3000)
      // );
    }
  }, [errorMsg,jobReposted]);

  const confirm = () => {
    setAlert(false);
    dispatch({ type: actionTypes.CLEAR });
  }

  if (loader || inLoader) {
    return <Loader />;
  } else if (deletedJobs) {
    return (
      <div className={classes.deletedJobs}>
        <TopCountShowStrip 
          text='Total Deleted Jobs'
          count={deletedJobs.length}
        />
        {deletedJobs.length === 0 && (
          <div className={classes.noData}>There are no deleted jobs till now.</div>
        )}
        {deletedJobs.length !== 0 && (
          <div className={classes.deletedJobList}>
            {deletedJobs.map((item) => {
              return (
                <JobCard
                  jobId={item._id}
                  jobTitle={item.role}
                  jobType={item.type}
                  postedDate={item.publishedDate}
                  alternateJobId={item.alternateJobId}
                  btnText="Repost"
                  btnEvent={repostJob}
                  style={{ border: "1px solid #42BF42", color: "#42BF42" }}
                />
              );
            })}
          </div>
        )}
        {
          alert && error && errorMsg && <ErrorAlert show={true} msg={errorMsg} confirm={confirm}/>
          // <Toast display={toast} type="danger" message={errorMsg} />
        }
        {alert && jobReposted && (
          <SuccessAlert show={true} msg={"Job Reposted Successfully."} confirm={confirm}/>
          // <Toast
          //   display={toast}
          //   type="success"
          //   message={"Job Reposted Successfully"}
          // />
        )}
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default DeletedJobs;
