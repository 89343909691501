import React from "react";
import classes from "./ForgetPasswordModal.module.css";
import ModalWrapper from "../ModalWrapper/ModalWrapper";

const ForgetPasswordModal = ({
  forgetFieldRef,
  sendResetLink,
  toogleForgetModal,
  error,
  isLoading
}) => {
  return (
    <ModalWrapper>
      <div className={classes.forgetPasswordBox}>
        <div className={classes.title}>
          Please enter your registered Email ID
        </div>
        <div className={classes.subTitle}>
          We will send you a link to reset your password.
        </div>
        <div className={classes.forgetInput}>
          <input
            type="email"
            ref={forgetFieldRef}
            placeholder="Please enter email address"
            required
          />
          <div className={classes.err}>{error.msg}</div>
        </div>
        
        <div className={classes.forgetButton}>
          <button type="submit" onClick={sendResetLink} className={isLoading ? classes.disable:classes.enable}>
            Submit
          </button>
          <button type="cancle" onClick={toogleForgetModal} style={{cursor:isLoading ? 'wait':'pointer'}}>
            Cancel
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default ForgetPasswordModal;
