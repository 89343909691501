import React, { useState } from "react";
import classes from "./CreateJobProgress.module.css";
import jobDetail from "../../../Assets/Images/jobDetail.png";
import skillMatrix from "../../../Assets/Images/skillMatrix.png";
import questions from "../../../Assets/Images/questionMark.png";
import education from "../../../Assets/Images/education.png";
import miscInfo from "../../../Assets/Images/miscInfo.png";
import interviewProcess from "../../../Assets/Images/interviewProcess.png";
import workLocation from "../../../Assets/Images/workLocation.png";
import onBoarding from "../../../Assets/Images/onboarding.png";
import personalMessage from "../../../Assets/Images/personalMessage.png";

const JobProgressDesktop = ({ activeIndex = 0 }) => {
  const [isActive, setIsActive] = useState(true);
  if (localStorage.getItem("wfhRef") == "selected" && activeIndex == 6) {
    activeIndex = activeIndex + 1;
  } else if (localStorage.getItem("wfhRef") == "selected" && activeIndex == 8) {
    activeIndex = activeIndex - 2;
  }
  return (
    <div className={classes.createJobProgress}>
      <div className={`${classes.element} ${activeIndex >= 1 && classes.bar}`}>
        <div
          className={`${classes.elementImg} ${
            activeIndex >= 0 && classes.active
          }`}
        >
          <img src={jobDetail} />
        </div>
        <div className={classes.elementText}>Job Details</div>
      </div>
      <div className={`${classes.element} ${activeIndex >= 2 && classes.bar}`}>
        <div
          className={`${classes.elementImg} ${
            activeIndex >= 1 && classes.active
          }`}
        >
          <img src={skillMatrix} />
        </div>
        <div className={classes.elementText}>Skill matrix</div>
      </div>
      <div className={`${classes.element} ${activeIndex >= 3 && classes.bar}`}>
        <div
          className={`${classes.elementImg} ${
            activeIndex >= 2 && classes.active
          }`}
        >
          <img src={questions} />
        </div>
        <div className={classes.elementText}>Questions</div>
      </div>
      <div className={`${classes.element} ${activeIndex >= 4 && classes.bar}`}>
        <div
          className={`${classes.elementImg} ${
            activeIndex >= 3 && classes.active
          }`}
        >
          <img src={education} />
        </div>
        <div className={classes.elementText}>Education</div>
      </div>
      <div className={`${classes.element} ${activeIndex >= 5 && classes.bar}`}>
        <div
          className={`${classes.elementImg} ${
            activeIndex >= 4 && classes.active
          }`}
        >
          <img src={miscInfo} />
        </div>
        <div className={classes.elementText}>Miscellaneous Information</div>
      </div>
      <div className={`${classes.element} ${activeIndex >= 6 && classes.bar}`}>
        <div
          className={`${classes.elementImg} ${
            activeIndex >= 5 && classes.active
          }`}
        >
          <img src={interviewProcess} />
        </div>
        <div className={classes.elementText}>Interview Process</div>
      </div>
      <div className={`${classes.element} ${activeIndex >= 7 && classes.bar}`}>
        <div
          className={`${classes.elementImg} ${
            activeIndex >= 6 && classes.active
          }`}
        >
          <img src={workLocation} />
        </div>
        <div className={classes.elementText}>Work Location</div>
      </div>
      <div className={`${classes.element} ${activeIndex >= 8 && classes.bar}`}>
        <div
          className={`${classes.elementImg} ${
            activeIndex >= 7 && classes.active
          } `}
        >
          <img src={onBoarding} />
        </div>
        <div className={classes.elementText}>Onboarding</div>
      </div>
      <div className={`${classes.element} ${activeIndex >= 9 && classes.bar}`}>
        <div
          className={`${classes.elementImg} ${
            activeIndex >= 8 && classes.active
          }`}
        >
          <img src={personalMessage} />
        </div>
        <div className={classes.elementText}>Personal Message</div>
      </div>
    </div>
  );
};

export default JobProgressDesktop;
