import React from "react";
import classes from "./Personal.module.css";
import pencil from "../../../../Assets/Images/pencil.png";
import trash from "../../../../Assets/Images/trash.png";

const PersonalCustome = ({
  customeIndex,
  customeQuestion,
  addToCustome,
  editCustomeQuestion,
  removeCustomeQuestion,
}) => {
  const textFormatter = (str) => {
    if (str.length > 80) {
      return `${str.substring(0, 80)}...`;
    } else {
      return str;
    }
  };
 const cleanInput=(e)=>{
document.getElementById("input").value=""
  }
  return (
    <form
      onSubmit={(e) => {
        addToCustome(e, customeIndex);
      }}
      autocomplete="off"
    >
      <div className={classes.strip}>
        <div className={classes.index}>{customeIndex}</div>
        <div>
          {customeQuestion.isEdit && (
            <input
              type="text"
              required
              // pattern="^[ A-Za-z?.]*$"
              // title="Only alphabets,dot and ? allowed"
              id="input"
              placeholder="Please enter your custom question here"
              defaultValue={customeQuestion.question}
            />
          )}
          {!customeQuestion.isEdit && (
            <span className={classes.text}>
              {customeQuestion.question}
            </span>
          )}
        </div>
        <div>
          {customeQuestion.isEdit && (
            <div className={classes.btnSave}>
            <span><button>Add</button>&nbsp;&nbsp;&nbsp;<i class="fa fa-window-close"
            title="Clear Question"
            onClick={cleanInput}
            style={{color: "#9675e4",cursor:"pointer",fontSize: "20px",verticalAlign: "middle"}} aria-hidden="true"></i></span>
          
              {/* <button type="" onClick={(e)=>{removeCustome(customeIndex);e.stopPropagation()}}>Remove</button> */}
            </div>
          )}
          {!customeQuestion.isEdit && (
            <div className={classes.edit}>
              <img
                src={pencil}
                onClick={() => editCustomeQuestion(customeIndex)}
              />
              <img
                src={trash}
                onClick={() => removeCustomeQuestion(customeIndex)}
              />
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

export default PersonalCustome;
