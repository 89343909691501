import React, { useEffect, useState } from "react";
import classes from "./Preview.module.css";
import pencil from "../../../Assets/Images/pencil.png";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../Store/Actions/index";
import * as actionTypes from "../../../Store/Actions/ActionTypes";
import { withRouter } from "react-router-dom";
import Toast from "../../../common/Toast/Toast";
import Loader from "../../../common/Loader/Loader";
import parse from "html-react-parser";
import Utility from "../../../Utility/Utility";
import ErrorAlert from "../../../common/ErrorAlert/ErrorAlert";
import SuccessAlert from "../../../common/SuccessAlert/SuccessAlert";

const Preview = ({ sendToPage, history, backToFlow, updateJob }) => {
  let dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState(false);

  const {
    refJobId,
    errorMsg,
    jobPreview,
    savedAsDraft,
    publishJob,
    jobUpdated,
    msg,
    masterPersonalQuestions,
    masterSkillBasedQuestions,
    masterOfferedBenifitsList,
    masterCountryList,
    masterStateList,
    masterCityList,
    questions,
    masterRelocationBenifitsList,
  } = useSelector((state) => ({
    errorMsg: state.CreateJobReducer.errorMsg,
    refJobId: state.CreateJobReducer.refJobId,
    jobPreview: state.CreateJobReducer.jobPreview,
    savedAsDraft: state.CreateJobReducer.savedAsDraft,
    publishJob: state.CreateJobReducer.publishJob,
    jobUpdated: state.CreateJobReducer.jobUpdated,
    msg: state.CreateJobReducer.msg,
    masterPersonalQuestions: state.MasterReducer.masterPersonalQuestions,
    masterSkillBasedQuestions: state.MasterReducer.masterSkillBasedQuestions,
    masterOfferedBenifitsList: state.MasterReducer.masterOfferedBenifitsList,
    masterRelocationBenifitsList:
      state.MasterReducer.masterRelocationBenifitsList,
    masterCountryList: state.MasterReducer.masterCountryList,
    masterStateList: state.MasterReducer.masterStateList,
    masterCityList: state.MasterReducer.masterCityList,
    questions: state.MasterReducer.questions,
  }));

  // const {
  //   masterOfferedBenifitsList,
  //   masterRelocationBenifitsList,
  // } = useSelector((state) => ({
  //   masterOfferedBenifitsList: state.MasterReducer.masterOfferedBenifitsList,
  //   masterRelocationBenifitsList:
  //     state.MasterReducer.masterRelocationBenifitsList,
  // }));

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsLoading(true);
    dispatch(actions.getCreateJobPreview(refJobId));
    dispatch(actions.getInterviewQuestions());
  }, []);

  useEffect(() => {
    if (jobPreview) {
      setIsLoading(false);
      dispatch({ type: actionTypes.CLEAR });
    }
  }, [jobPreview]);

  const getPersonalQuestions = (questionId) => {
    let question = "";
    if (questions) {
      questions.map((item) => {
        if (item._id === questionId) {
          question = item.name;
        }
      });
    }
    return question;
  };
  const getSkillBasedQuestions = (questionId) => {
    let question = "";
    if (questions) {
      questions.map((item) => {
        if (item._id === questionId) {
          question = item.name;
        }
      });
    }
    return question;
  };
  const getOfferedBenifits = (id) => {
    let benifit = "";
    if (masterOfferedBenifitsList) {
      masterOfferedBenifitsList.map((item) => {
        if (item._id === id) {
          benifit = item.name;
        }
      });
    }
    return benifit;
  };

  const saveAsDraft = () => {
    setIsLoading(true);
    dispatch({ type: actionTypes.CLEAR });
    dispatch(actions.saveAsDraft({ refJobId: refJobId }));
  };

  const postJob = () => {
    setIsLoading(true);
    dispatch({ type: actionTypes.CLEAR });
    dispatch(actions.publishJob({ jobId: refJobId }, updateJob));
  };

  useEffect(() => {
    if (publishJob || savedAsDraft || errorMsg || jobUpdated) {
      setIsLoading(false);
      setAlert(true);
      // setToast(
      //   true,
      //   setTimeout(() => {
      //     dispatch({ type: actionTypes.CLEAR });
      //     if (publishJob || savedAsDraft) {
      //       dispatch({ type: actionTypes.TRASH });
      //       history.push("/employer-dashboard");
      //     } else if (jobUpdated) {
      //       dispatch({ type: actionTypes.TRASH });
      //       history.push("/employer-jobs");
      //     }
      //     setToast(false);
      //   }, 3000)
      // );
    }
  }, [publishJob, savedAsDraft, jobUpdated, errorMsg]);

  const confirm = () => {
    setAlert(false);
    window.scrollTo(0, 0);
    dispatch({ type: actionTypes.CLEAR });
    if (publishJob || savedAsDraft) {
      dispatch({ type: actionTypes.TRASH });
      history.push("/employer-dashboard");
    } else if (jobUpdated) {
      dispatch({ type: actionTypes.TRASH });
      history.push("/employer-jobs");
    }
  };

  let roundName = ["First", "Second", "Third", "Fourth", "Fifth", "Sixth"];

  if (isLoading) {
    return <Loader />;
  } else if (jobPreview) {
    return (
      <React.Fragment>
        <div className={classes.preview}>
          {alert && (publishJob || savedAsDraft || jobUpdated) && (
            <SuccessAlert show={true} msg={msg} confirm={confirm} />
            // <Toast display={toast} type="success" message={msg} />
          )}

          {alert && errorMsg && (
            <ErrorAlert show={true} msg={errorMsg} confirm={confirm} />
            // <Toast display={toast} type="danger" message={errorMsg} />
          )}
          <div className={classes.previewTopText}>
            Check the given draft for the job before you post it in conectD. You
            can edit at any stage in case if required.
          </div>
          <div className={classes.previewItem}>
            <div className={classes.previewTitle}>
              <div className={classes.previewTitleItem}>
                <div>Designation</div>&nbsp;
                <div>
                  <img
                    src={pencil}
                    onClick={() => sendToPage("jobDetail", 0)}
                  />
                </div>
              </div>
            </div>
            <div>{jobPreview.role}</div>
          </div>
          <div className={classes.previewItem}>
            <div className={classes.previewTitle}>
              <div className={classes.previewTitleItem}>
                <div>Job Type</div>&nbsp;
                <div>
                  <img
                    src={pencil}
                    onClick={() => sendToPage("jobDetail", 0)}
                  />
                </div>
              </div>
            </div>
            <div>{jobPreview.type}</div>
          </div>
          <div className={classes.previewItem}>
            <div className={classes.previewTitle}>
              <div className={classes.previewTitleItem}>
                <div>Industry Type</div>&nbsp;
                <div>
                  <img
                    src={pencil}
                    onClick={() => sendToPage("jobDetail", 0)}
                  />
                </div>
              </div>
            </div>
            <div>{jobPreview.sectorId.name}</div>
          </div>
          <div className={classes.previewItem}>
            <div className={classes.previewTitle}>
              <div className={classes.previewTitleItem}>
                <div>Description</div>&nbsp;
                <div>
                  <img
                    src={pencil}
                    onClick={() => sendToPage("jobDetail", 0)}
                  />
                </div>
              </div>
            </div>
            <div>{parse(jobPreview.description)}</div>
          </div>
          <div className={classes.previewItem}>
            <div className={classes.previewTitle}>
              <div className={classes.previewTitleItem}>
                <div>Skills</div>&nbsp;
                <div>
                  <img
                    src={pencil}
                    onClick={() => sendToPage("skillMatrix", 1)}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className={classes.skillBox}>
                <div className={classes.skillBoxTitle}>
                  <div>S.No.</div>
                  <div>Skill</div>
                  <div>Preference</div>
                  <div>Experience</div>
                </div>
                {jobPreview.skillMatrixId &&
                  jobPreview.skillMatrixId.skill.map((item, index) => {
                    return (
                      <>
                        {item ? (
                          <>
                            <div className={classes.skillBoxItem}>
                              <div className={classes.index}>{index + 1}</div>
                              <div className={classes.mobile}>
                                <div className={classes.mob}>Skill</div>
                                <div>{item.value}</div>
                              </div>
                              <div className={classes.mobile}>
                                <div className={classes.mob}>Preference</div>
                                <div>{item.preference}</div>
                              </div>
                              <div className={classes.mobile}>
                                <div className={classes.mob}>Experience</div>
                                <div>{`${item.minExperience}`}</div>
                              </div>
                            </div>
                          </>
                        ) : null}
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className={classes.previewItem}>
            <div className={classes.previewTitle}>
              <div className={classes.previewTitleItem}>
                <div>Questions</div>&nbsp;
                <div>
                  <img src={pencil} onClick={() => sendToPage("question", 2)} />
                </div>
              </div>
            </div>
            <div>
              <div className={classes.title}>Personal</div>
              {jobPreview.qualifyingQuestionId &&
                jobPreview.qualifyingQuestionId.personalQuestion.length > 0 &&
                jobPreview.qualifyingQuestionId.personalQuestion.map(
                  (item, index) => {
                    return (
                      <div>{`${index + 1}. ${getPersonalQuestions(
                        jobPreview.qualifyingQuestionId.personalQuestion[
                          index
                        ] !== null
                          ? item.questionId
                          : ""
                      )}`}</div>
                    );
                  }
                )}
              {jobPreview.qualifyingQuestionId &&
                jobPreview.qualifyingQuestionId.personalQuestion.length ===
                  0 && (
                  <div style={{ color: "gray" }}>No Personal Question</div>
                )}
              <div className={classes.title}>Skills</div>
              {jobPreview.qualifyingQuestionId &&
                jobPreview.qualifyingQuestionId.skillBasedQuestion.length > 0 &&
                jobPreview.qualifyingQuestionId.skillBasedQuestion.map(
                  (item, index) => {
                    return (
                      <div>{`${index + 1}. ${getSkillBasedQuestions(
                        jobPreview.qualifyingQuestionId.skillBasedQuestion[
                          index
                        ] !== null
                          ? item.questionId
                          : ""
                      )}`}</div>
                    );
                  }
                )}
              <div className={classes.title}>Mcq Questions</div>
              {jobPreview.qualifyingQuestionId &&
                jobPreview.qualifyingQuestionId.skillBasedMcqQuestion.length >
                  0 &&
                jobPreview.qualifyingQuestionId.skillBasedMcqQuestion.map(
                  (item, index) => {
                    return (
                      <div>{`${index + 1}. ${getPersonalQuestions(
                        jobPreview.qualifyingQuestionId.skillBasedMcqQuestion[
                          index
                        ] !== null
                          ? item.questionId
                          : ""
                      )}`}</div>
                    );
                  }
                )}
              {jobPreview.qualifyingQuestionId &&
                jobPreview.qualifyingQuestionId.skillBasedMcqQuestion.length ===
                  0 && <div style={{ color: "gray" }}>No Mcq Question</div>}
            </div>
          </div>
          <div className={classes.previewItem}>
            <div className={classes.previewTitle}>
              <div className={classes.previewTitleItem}>
                <div>Qualification</div>&nbsp;
                <div>
                  <img
                    src={pencil}
                    onClick={() => sendToPage("education", 3)}
                  />
                </div>
              </div>
            </div>
            <div>{jobPreview.educationId && jobPreview.educationId.name}</div>
          </div>
          <div className={classes.previewItem}>
            <div className={classes.previewTitle}>
              <div className={classes.previewTitleItem}>
                <div>Miscellaneous Information</div>&nbsp;
                <div>
                  <img src={pencil} onClick={() => sendToPage("miscInfo", 4)} />
                </div>
              </div>
            </div>
            <div>
              <div className={classes.salary}>
                <span>Salary:&nbsp; </span>
                {/* {!jobPreview.miscInfoId.hourlyRate && !jobPreview.miscInfoId.hourlyRateRangeStart && !jobPreview.miscInfoId.hourlyRateRangeEnd && <span>Not disclosed</span>} */}
                {jobPreview.miscInfoId.hourlyRate ? (
                  <span>
                    {"USD " + jobPreview.miscInfoId.hourlyRate + "/hour"}
                  </span>
                ) : (
                  !jobPreview.miscInfoId.hourlyRate && (
                    <span>
                      {"USD " +
                        jobPreview.miscInfoId.hourlyRateRangeStart +
                        "-" +
                        jobPreview.miscInfoId.hourlyRateRangeEnd +
                        "/hour"}
                    </span>
                  )
                )}

                {/* <span> {jobPreview.miscInfoId.hourlyRate? "USD " +jobPreview.miscInfoId.hourlyRate + " P.A" : "Not disclosed"} </span> */}
              </div>
              <div className={classes.benifitsToBeOffered}>
                <div className={classes.benifitsTitle}>
                  Benefits to be offered by ConectD : &nbsp;
                  {jobPreview.miscInfoId &&
                  jobPreview.miscInfoId.offeredBenefits.length == 0 ? (
                    <span style={{ color: "black", fontWeight: "100" }}>
                      N/A
                    </span>
                  ) : null}
                </div>
                <div className={classes.benifits}>
                  {jobPreview.miscInfoId &&
                    jobPreview.miscInfoId.offeredBenefits.map((item, index) => {
                      return (
                        <div>{`${index + 1}. ${getOfferedBenifits(item)}`}</div>
                      );
                    })}
                </div>
              </div>
              <div className={classes.date}>
                <span className={classes.benifitsTitle}>
                  Work from home (Expected Start Date) :&nbsp;
                </span>
                <span>
                  {Utility.formatDate(jobPreview.miscInfoId.startDate)}
                </span>
              </div>
              <div className={classes.date}>
                <span className={classes.benifitsTitle}>
                  Given position qualifies for the over time :&nbsp;
                </span>
                <span>
                  {jobPreview.miscInfoId.positionQualifiesForOvertime === "true"
                    ? "Yes"
                    : "No"}
                </span>
              </div>
              <div className={classes.date}>
                <span className={classes.benifitsTitle}>
                  Are you able to sponsor applicants who require work visas
                  :&nbsp;
                </span>
                <span>
                  {jobPreview.miscInfoId.workVisaSponcer === "true"
                    ? "Yes"
                    : "No"}
                </span>
              </div>
              <div className={classes.date}>
                <span className={classes.benifitsTitle}>
                  Is relocation applicable :&nbsp;
                </span>
                <span>
                  {jobPreview.miscInfoId.isRelocationApplicable === "true"
                    ? "Yes"
                    : "No"}
                </span>
              </div>
              <div className={classes.date}>
                <span className={classes.benifitsTitle}>
                  Relocation benefits :&nbsp;
                </span>
                <span>
                  <div>
                    {jobPreview.miscInfoId.relocationBenefits.length > 0 &&
                      jobPreview.miscInfoId.relocationBenefits.map(
                        (item, index) => {
                          return (
                            <div className={classes.subText}>
                              <i class="fa fa-circle" aria-hidden="true"></i>{" "}
                              {Utility.getQuestionName(
                                masterRelocationBenifitsList,
                                item
                              )}
                            </div>
                          );
                        }
                      )}
                    {jobPreview.miscInfoId.relocationBenefits.length === 0 && (
                      <div className={classes.subText}>
                        Relocation Benefits not available
                      </div>
                    )}
                  </div>
                </span>
              </div>
              <div className={classes.date}>
                <span className={classes.benifitsTitle}>
                  Travel applicable for the job :&nbsp;
                </span>
                <span>
                  {jobPreview.miscInfoId.isTravelApplicable === "true"
                    ? "Yes"
                    : "No"}
                </span>
              </div>
              <div

              // className={roleType !== "candidate" ? classes.data : classes.dataOne}
              >
                <div className={classes.benifitsTitle}>
                  Travel Requirement Details:
                </div>
                <span>
                  {jobPreview.miscInfoId.travelRequirementsDetails
                    ? jobPreview.miscInfoId.travelRequirementsDetails
                    : "No Requirement Detail"}
                </span>
              </div>
              <div className={classes.additonalNotes}>
                <div className={classes.benifitsTitle}>
                  Additional Note for the given job :&nbsp;
                </div>
                <div>
                  {jobPreview.miscInfoId &&
                  jobPreview.miscInfoId.additionalNotes
                    ? jobPreview.miscInfoId.additionalNotes
                    : "No additonal notes"}
                </div>
              </div>
            </div>
          </div>
          <div className={classes.previewItem}>
            <div className={classes.previewTitle}>
              <div className={classes.previewTitleItem}>
                <div>Interview Process</div>&nbsp;
                <div>
                  <img
                    src={pencil}
                    onClick={() => sendToPage("interviewProcess", 5)}
                  />
                </div>
              </div>
            </div>
            <div className={classes.interviewProcess}>
              <div className={classes.interviewItem}>
                Mode of communication for candidate’s interview
              </div>
              <div>
                {jobPreview.interviewProcessId &&
                  jobPreview.interviewProcessId.communicationMode &&
                  Object.values(jobPreview.interviewProcessId.communicationMode)
                    .length > 0 &&
                  Object.values(
                    jobPreview.interviewProcessId.communicationMode
                  ).map((item, index) => {
                    return (
                      <div className={classes.mode}>
                        <span style={{ fontWeight: "bold" }}>
                          {`${roundName[index]} round`}:
                        </span>{" "}
                        {item}
                      </div>
                    );
                  })}
                {/* {jobPreview.interviewProcessId.communicationMode.length ===
                  0 && (
                  <div className={classes.mode}>
                    1. {jobPreview.interviewProcessId.otherCommunicationMode}
                  </div>
                )} */}
              </div>
              <div className={classes.salary}>
                <span>Rounds of interview :</span>&nbsp;
                <span>
                  {jobPreview.interviewProcessId &&
                    jobPreview.interviewProcessId.rounds}
                </span>
              </div>
              <div className={classes.benifitsTitle}>
                <span>
                  Tips for candidates to be successful in the interview process
                  :
                </span>
                &nbsp;
                <br />
                <span style={{ fontWeight: "normal" }}>
                  {jobPreview.interviewProcessId &&
                    jobPreview.interviewProcessId.tips.tipText}
                </span>
              </div>
              <div>
                {jobPreview.interviewProcessId &&
                jobPreview.interviewProcessId.tips.tip === "true"
                  ? jobPreview.interviewProcessId.tipText
                  : "No tips for candidate"}
              </div>
            </div>
          </div>
          <div className={classes.previewItem}>
            <div className={classes.previewTitle}>
              <div className={classes.previewTitleItem}>
                <div>Work Location</div>&nbsp;
                <div>
                  <img
                    src={pencil}
                    onClick={() => sendToPage("workLocation", 6)}
                  />
                </div>
              </div>
            </div>
            <div>
              {jobPreview.workLocation &&
                Utility.getLocation(
                  masterCountryList,
                  masterStateList,
                  masterCityList,
                  jobPreview.workLocation
                )}
            </div>
          </div>
          <div className={classes.previewItem}>
            <div className={classes.previewTitle}>
              <div className={classes.previewTitleItem}>
                <div>Onboarding Requirements</div>&nbsp;
                <div>
                  <img
                    src={pencil}
                    onClick={() => sendToPage("onBoarding", 7)}
                  />
                </div>
              </div>
            </div>
            <div>
              {jobPreview.boardingId &&
                jobPreview.boardingId.map((item, index) => {
                  return <div>{`${index + 1}. ${item.name}`}</div>;
                })}
            </div>
          </div>
          <div className={classes.personalSection}>
            <div className={classes.benifitsTitle}>
             {` Message regarding ${jobPreview.role} posting :`}
            </div>
            {jobPreview.uploadedData &&
              jobPreview.uploadedData.map((item) => {
                return (
                  <div>
                    <video className={classes.video} controls>
                      <source src={item} type="video/mp4" />
                    </video>
                  </div>
                );
              })}
          </div>
        </div>
        <div className={classes.skillFooter}>
          <div onClick={backToFlow}>Back</div>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <div onClick={saveAsDraft}>Save as Draft</div>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <div onClick={postJob}>{updateJob ? "Update Job" : "Post Job"}</div>
        </div>
      </React.Fragment>
    );
  } else {
    return <div></div>;
  }
};

export default withRouter(Preview);
