import React, { useEffect, useState } from "react";
import classes from "./VideoPreviewModal.module.css";
import ModalWrapper from "../../common/ModalWrapper/ModalWrapper";
import cross from "../../Assets/Images/cross.png";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../common/Loader/Loader";
import { postRequest,getRequest } from "../../Helper/apiHit";

const VideoPreviewModal = ({ close, candidatePitch }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");

  useEffect(() => {
    setIsLoading(true);
    if(candidatePitch){
      getRequest(`jobs/getUploadedVideo/${candidatePitch}`).then((res) => {
        setVideoUrl(res.data.responseData);
        setIsLoading(false);
      });
    }else{
      setIsLoading(false);
    }
    
  }, []);

  if (isLoading) {
    return (
      <ModalWrapper>
        <div className={classes.videoPreviewLoader}>
          <Loader />
        </div>
      </ModalWrapper>
    );
  } else {
    return (
      <ModalWrapper>
        <div className={classes.videoPreviewModal}>
          <div>
            <i
              className="fa fa-times"
              aria-hidden="true"
              onClick={() => close("")}
            />
          </div>
          <div>
            {videoUrl !== "" && (
              <video className={classes.frame} controls>
                <source src={videoUrl} type="video/mp4" />
              </video>
            )}
            {videoUrl === "" && candidatePitch != '' && (
              <div className={classes.noVideoText}>Somthing went wrong</div>
            )}
            {
              candidatePitch === '' && videoUrl === "" && 
              <div className={classes.noVideo}>Sorry no pitch provided by candidate</div>
            }
          </div>
        </div>
      </ModalWrapper>
    );
  }
};

export default VideoPreviewModal;
