import React, { useEffect, useState } from "react";
import classes from "./ApplicationReceived.module.css";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import Loader from "../../../common/Loader/Loader";
import Toast from "../../../common/Toast/Toast";
import JobCard from "../../../components/JobCard/JobCard";
import * as actions from "../../../Store/Actions/index";
import * as actionTypes from "../../../Store/Actions/ActionTypes";
import Utility from "../../../Utility/Utility";
import ScheduleInterviewModal from "../../../components/ScheduleInterviewModal/ScheduleInterviewModal";
import TopCountShowStrip from "../../../common/TopShowCountStrip/TopCountShowStrip";
import TableWrapper from "../../../common/TableWrapper/TableWrapper";
import ConfirmModal from "../../../components/ConfirmModal/ConfirmModal";
import VideoPreviewModal from "../../../components/VideoPreviewModal/VideoPreviewModal";
import Slider from "react-slick";
import SuccessAlert from "../../../common/SuccessAlert/SuccessAlert";
import ErrorAlert from "../../../common/ErrorAlert/ErrorAlert";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ApplicationReceived = ({ history }) => {
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    className: `${classes.carousel}`,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
    ],
  };
  const dispatch = useDispatch();

  const [activeIndex, setActiveIndex] = useState(0);
  const [alert, setAlert] = useState(false);
  const [isScheduleInterviewActive, setIsScheduleInterviewActive] =
    useState(false);
  const [isRejectModalActive, setIsRejectModalActive] = useState(false);
  const [applicationData, setApplicationData] = useState("");
  const [isVideoPreviewActive, setIsVideoPreviewActive] = useState(false);
  const [whyHireVideo, setWhyHireVideo] = useState("");
  const {
    loader,
    application,
    error,
    errorMsg,
    smallLoader,
    applicationDetail,
    applicationRejected,
    interviewScheduled,
    msg,
  } = useSelector((state) => ({
    loader: state.EmployerReducer.loader,
    application: state.EmployerReducer.application,
    error: state.EmployerReducer.error,
    errorMsg: state.EmployerReducer.errorMsg,
    smallLoader: state.EmployerReducer.smallLoader,
    applicationDetail: state.EmployerReducer.applicationDetail,
    applicationRejected: state.EmployerReducer.applicationRejected,
    interviewScheduled: state.EmployerReducer.interviewScheduled,
    msg: state.EmployerReducer.msg,
  }));

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch({ type: actionTypes.CLEAR });
    dispatch(actions.getApplications("PENDING"));
  }, []);

  useEffect(() => {
    if (
      application &&
      application.length > 0 &&
      activeIndex === 0 &&
      !applicationDetail
    ) {
      dispatch(actions.getApplicationByJobId(application[0].jobId, "PENDING"));
    }
  }, [application]);

  useEffect(() => {
    if (interviewScheduled || applicationRejected) {
      dispatch({
        type: actionTypes.UPDATE_APPLICATION,
        payload: application[activeIndex].jobId,
      });
    }
    setAlert(true);
    // setToast(
    //   true,
    //   setTimeout(() => {
    //     setToast(false);
    //     if(interviewScheduled ||applicationRejected){
    //       dispatch({ type: actionTypes.TEST});
    //     }

    //   }, 3000)
    // );
  }, [applicationRejected, interviewScheduled, errorMsg]);

  const getApplicationDetail = (jobId, index) => {
    setActiveIndex(index);
    dispatch(actions.getApplicationByJobId(jobId, "PENDING"));
  };

  const rejectApplication = () => {
    setIsRejectModalActive(false);
    setApplicationData("");
    dispatch(
      actions.rejectApplication({
        applicationId: applicationData.applicationId,
      })
    );
  };

  const toogleApplicationModal = (applicationData) => {
    setIsScheduleInterviewActive(!isScheduleInterviewActive);
    setApplicationData(applicationData);
  };

  const toogleRejectModal = (applicationData) => {
    setIsRejectModalActive(!isRejectModalActive);
    setApplicationData(applicationData);
  };

  const toggleVideoPreview = (url) => {
    setIsVideoPreviewActive(!isVideoPreviewActive);
    setWhyHireVideo(url);
  };

  const sendInvite = (e, date) => {
    e.preventDefault();
    setIsScheduleInterviewActive(false);
    setApplicationData("");
    dispatch(
      actions.scheduleInterview({
        applicationId: applicationData.applicationId,
        date: date,
      })
    );
  };

  const getTotalReceivedApplication = (application) => {
    let count = 0;
    application.map((item) => {
      count = count + parseInt(item.applicationReceived);
    });
    return count;
  };

  // useEffect(()=>{
  //   return () => {
  //     dispatch({type:actionTypes.CLEAR})
  //   }
  // },[])

  const confirm = () => {
    setAlert(false);
    if (interviewScheduled || applicationRejected) {
      dispatch({ type: actionTypes.TEST });
    }
  };

  if (loader) {
    return <Loader />;
  } else if (application) {
    return (
      <div className={classes.applicationReceived}>
        <TopCountShowStrip
          text="Total Received Application"
          count={
            application.length !== 0
              ? getTotalReceivedApplication(application)
              : 0
          }
        />
        {application.length === 0 && (
          <div className={classes.noData}>
            There are no application received till now.
          </div>
        )}
        {application.length !== 0 && (
          <Slider {...settings}>
            {application.map((application, index) => {
              return (
                <JobCard
                  width={
                    window.innerWidth < 800
                      ? window.innerWidth < 600
                        ? ""
                        : "17.8em"
                      : "22em"
                  }
                  indexing={true}
                  activeIndex={activeIndex}
                  index={index}
                  jobId={application.jobId}
                  alternateJobId={application.alternateJobId}
                  jobTitle={application.role}
                  jobType={application.type}
                  postedDate={application.publishedDate}
                  totalCount={application.applicationReceived}
                  getApplicationDetail={getApplicationDetail}
                />
              );
            })}
          </Slider>
        )}
        <div className={classes.bottom}>
          {smallLoader && (
            <div className={classes.noSubData}>Please wait...</div>
          )}
          {/* {application.length !== 0 &&
            !smallLoader &&
            applicationDetail &&
            applicationDetail.length === 0 && (
              <div className={classes.noSubData}>
                Sorry No Interview Scheduled For this Job.
              </div>
            )} */}
          {!smallLoader && applicationDetail && applicationDetail.length !== 0 && (
            <TableWrapper
              text={application[activeIndex].role}
              count={application[activeIndex].applicationReceived}
            >
              {/* <div className={classes.applicationDetailHead}>
                <div>S.No.</div>
                <div>Applicant</div>
                <div>Email ID</div>
                <div>Profile</div>
                <div>Date of Application</div>
                <div>Video</div>
                <div>iSense</div>
                <div>Action</div>
              </div> */}

              <div className={classes.applicationDetailHead}>
                <div>S.No.</div>
                <div>Date of Application</div>
                <div>Position Title</div>
                <div>Job ID</div>
                <div>Applicant</div>
                <div>Profile</div>
                <div>Criteria Matches</div>
                <div>Action</div>
              </div>

              <div
                className={classes.scrollBar}
                style={{ overflow: "auto", height: "150px" }}
              >
                {applicationDetail.map((detail, index) => {
                  return (
                    <div className={classes.applicationDetailData}>
                      <div className={classes.index}>{index + 1}</div>
                      <div className={classes.mob}>
                        <div className={classes.item}>Date of Application</div>
                        <div>{Utility.formatDate(detail.appliedDate)}</div>
                      </div>
                      <div className={classes.mob}>
                        <div className={classes.item}>Position Title</div>
                        <div>{detail.role}</div>
                      </div>
                      <div className={classes.mob}>
                        <div className={classes.item}>Job ID</div>
                        <div>{detail.alternateJobId}</div>
                      </div>
                      <div className={classes.mob}>
                        <div className={classes.item}>Applicant</div>
                        <div style={{ paddingLeft: "10px" }}>
                          {detail.candidateName}
                        </div>
                      </div>
                      <div className={classes.mob}>
                        <div className={classes.item}>Profile</div>
                        <div
                          onClick={() =>
                            history.push(
                              `/candidateDetailProfile?id=${detail.candidateId}`
                            )
                          }
                        >
                          View Profile
                        </div>
                      </div>

                      {/* <div className={classes.mob}>
                      <div className={classes.item}>Video</div>
                      {detail.candidatePitch ?
                      <div onClick={()=>toggleVideoPreview(detail.candidatePitch)} style={{paddingLeft:"10px"}}>Preview</div> : <span style={{paddingLeft:"10px"}}>-</span>}
                      
                    </div> */}
                      <div className={classes.mob}>
                        <div className={classes.item}>Criteria Matches</div>
                        <div>
                          {detail.iSensePercent.includes(
                            "503 Service Unavailable"
                          ) ||
                          detail.iSensePercent.includes("error") ||
                          detail.iSensePercent == "0"
                            ? "-"
                            : parseInt(
                                detail.iSensePercent == "NaN"
                                  ? 0
                                  : detail.iSensePercent
                              ).toFixed(2) + "%"}
                        </div>
                      </div>
                      {/* <div className={classes.mob}>
                      <div className={classes.item}>iSense</div>
                      <div style={{paddingLeft:"17px"}}>{detail.iSensePercent.includes("503 Service Unavailable") || detail.iSensePercent.includes("error") || detail.iSensePercent == "0" ? "-" : parseInt(detail.iSensePercent).toFixed(2) +"%"}</div>
                    </div> */}
                      <div className={classes.mob}>
                        <div className={classes.item}>Action</div>
                        <div className={classes.lastDiv}>
                          <span
                            onClick={() => toogleRejectModal(detail)}
                            title="Decline application"
                          >
                            <button className={classes.desctBtn}>
                              Decline
                            </button>
                          </span>{" "}
                          <span
                            onClick={() => toogleApplicationModal(detail)}
                            title="Accept application"
                            // style={{paddingRight:"4px"}}
                          >
                            <button className={classes.aceptBtn}>Accept</button>
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </TableWrapper>
          )}
        </div>
        {isScheduleInterviewActive && (
          <ScheduleInterviewModal
            toogleApplicationModal={toogleApplicationModal}
            role={application[activeIndex].role}
            applicationDetail={applicationData}
            sendInvite={sendInvite}
          />
        )}
        {isRejectModalActive && (
          <ConfirmModal
            text="Are you sure you want to reject this application?"
            onCancel={toogleRejectModal}
            onConfirm={rejectApplication}
          />
        )}
        {isVideoPreviewActive && (
          <VideoPreviewModal
            close={toggleVideoPreview}
            candidatePitch={whyHireVideo}
          />
        )}
        {alert && (applicationRejected || interviewScheduled) && (
          <SuccessAlert show={true} msg={msg} confirm={confirm} />
          // <Toast display={toast} type="success" message={msg} />
        )}
        {alert && errorMsg && (
          <ErrorAlert show={true} msg={errorMsg} confirm={confirm} />
        )}
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default withRouter(ApplicationReceived);
