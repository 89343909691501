import React from "react";
import classes from "./PersonalMessage.module.css";

const PersonalMessage = ({ personalMessage }) => {
  return (
    <div className={classes.personalMessage}>
      <div className={classes.titleOne}>Personal Message</div>
      {personalMessage.map((item) => {
        return (
          <video className={classes.video} controls>
            <source src={item} type="video/mp4" />
          </video>
        );
      })}
    </div>
  );
};

export default PersonalMessage;
