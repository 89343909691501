import React, { useEffect, useState } from "react";
import classes from "./DraftedJobs.module.css";
import JobCard from "../../../components/JobCard/JobCard";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../Store/Actions/index";
import * as actionTypes from "../../../Store/Actions/ActionTypes";
import { withRouter } from "react-router-dom";
import Loader from "../../../common/Loader/Loader";
import Toast from "../../../common/Toast/Toast";
import TopCountShowStrip from "../../../common/TopShowCountStrip/TopCountShowStrip";
import ErrorAlert from "../../../common/ErrorAlert/ErrorAlert";

const DraftedJobs = ({ history }) => {
  const dispatch = useDispatch();
  const [alert, setAlert] = useState(false);

  const { loader, draftedJobs, error, errorMsg } = useSelector((state) => ({
    loader: state.EmployerReducer.loader,
    draftedJobs: state.EmployerReducer.draftedJobs,
    error: state.EmployerReducer.error,
    errorMsg: state.EmployerReducer.errorMsg,
  }));

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch({ type: actionTypes.TRASH });
    dispatch(actions.draftedJobs());
  }, []);

  useEffect(() => {
    if (errorMsg) {
      setAlert(true);
      // setToast(
      //   true,
      //   setTimeout(() => {
      //     setToast(false);
      //     dispatch({ type: actionTypes.CLEAR });
      //   }, 3000)
      // );
    }
  }, [errorMsg]);

  const sendToCreateJob = (jobId) => {
    localStorage.setItem("isUpdate", "YES");
    history.push("/create-job", { jobId: jobId });
  };

  const confirm = () => {
    setAlert(false);
    dispatch({ type: actionTypes.CLEAR });
  };

  if (loader) {
    return <Loader />;
  } else if (draftedJobs) {
    return (
      <div className={classes.draftedJobs}>
        <TopCountShowStrip
          text="Total Drafted Jobs"
          count={draftedJobs.length}
        />
        {draftedJobs.length === 0 && (
          <div className={classes.noData}>
            There are no draft jobs till now.
          </div>
        )}
        {draftedJobs.length !== 0 && (
          <div className={classes.draftJobList}>
            {draftedJobs.map((item) => {
              return (
                <JobCard
                  jobId={item._id}
                  alternateJobId={item.alternateJobId}
                  jobTitle={item.role}
                  jobType={item.type}
                  postedDate={item.publishedDate}
                  btnText="Continue"
                  btnEvent={sendToCreateJob}
                  style={{ border: "1px solid #AE95E8", color: "#AE95E8" }}
                />
              );
            })}
          </div>
        )}
        {
          alert && error && errorMsg && (
            <ErrorAlert show={true} msg={errorMsg} confirm={confirm} />
          )
          // <Toast display={toast} type="danger" message={errorMsg} />
        }
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default withRouter(DraftedJobs);
