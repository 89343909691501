import * as actionTypes from "../Actions/ActionTypes";

const initialState = {};

const MasterReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.MASTER_COMPANY_SECTOR_LIST_SUCCESS:
      return {
        ...state,
        masterCompanySectorList: action.payload,
      };
    case actionTypes.MASTER_COMPANY_SECTOR_LIST_FAIL:
      return {
        ...state,
        masterCompanySectorList: [],
        error: true,
        errorMsg: action.payload,
      };
    case actionTypes.MASTER_COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        masterCountryList: action.payload,
      };
    case actionTypes.MASTER_COUNTRY_LIST_FAIL:
      return {
        ...state,
        masterCountryList: [],
        error: true,
        errorMsg: action.payload,
      };
    case actionTypes.MASTER_STATE_LIST_SUCCESS:
      return {
        ...state,
        masterStateList: action.payload,
      };
    case actionTypes.MASTER_STATE_LIST_FAIL:
      return {
        ...state,
        masterStateList: [],
        error: true,
        errorMsg: action.payload,
      };
    case actionTypes.MASTER_CITY_LIST_SUCCESS:
      return {
        ...state,
        masterCityList: action.payload,
      };
    case actionTypes.MASTER_CITY_LIST_FAIL:
      return {
        ...state,
        masterCityList: [],
        error: true,
        errorMsg: action.payload,
      };
    case actionTypes.MASTER_EDUCATION_LIST_SUCCESS:
      return {
        ...state,
        masterEducationList: action.payload,
      };
    case actionTypes.MASTER_EDUCATION_LIST_FAIL:
      return {
        ...state,
        masterEducationList: [],
        error: true,
        errorMsg: action.payload,
      };
    case actionTypes.MASTER_BOARDING_REQUIREMENT_LIST_SUCCESS:
      return {
        ...state,
        masterBoardingRequirementList: action.payload,
      };
    case actionTypes.MASTER_BOARDING_REQUIREMENT_LIST_FAIL:
      return {
        ...state,
        masterBoardingRequirementList: [],
        error: true,
        errorMsg: action.payload,
      };
    case actionTypes.MASTER_COMMUNICATION_MODE_LIST_SUCCESS:
      return {
        ...state,
        masterCommunicationModeList: action.payload,
      };
    case actionTypes.MASTER_COMMUNICATION_MODE_LIST_FAIL:
      return {
        ...state,
        masterCommunicationModeList: [],
        error: true,
        errorMsg: action.payload,
      };
    case actionTypes.MASTER_OFFERED_BENIFITS_LIST_SUCCESS:
      return {
        ...state,
        masterOfferedBenifitsList: action.payload,
      };
    case actionTypes.MASTER_OFFERED_BENIFITS_LIST_FAIL:
      return {
        ...state,
        masterOfferedBenifitsList: [],
        error: true,
        errorMsg: action.payload,
      };
    case actionTypes.MASTER_RELOCATION_BENIFITS_LIST_SUCCESS:
      return {
        ...state,
        masterRelocationBenifitsList: action.payload,
      };
    case actionTypes.MASTER_RELOCATION_BENIFITS_LIST_FAIL:
      return {
        ...state,
        masterRelocationBenifitsList: [],
        error: true,
        errorMsg: action.payload,
      };
    case actionTypes.GET_MASTER_SKILLS:
      return {
        ...state,
        masterSkill: action.payload,
      }
    case actionTypes.GET_MASTER_SKILLS_FAIL:
      return {
        ...state,
        masterSkill: [],
        errorMsg: action.payload,
      } 
    case actionTypes.GET_INTERVIEW_QUESTIONS:
      return {
        ...state,
        masterPersonalQuestions:action.masterPersonalQuestions,
        masterSkillBasedQuestions:action.masterSkillBasedQuestions,
        masterSkillBasedMcqQuestion:action.masterSkillBasedMcqQuestion,
        questions:action.questions
      } 
    case actionTypes.GET_INTERVIEW_QUESTIONS_FAIL:
      return {
        ...state,
        masterPersonalQuestions:[],
        masterSkillBasedQuestions:[],
        masterSkillBasedMcqQuestion:[],
        questions:[]
      }  
    default:
      return state;
  }
};

export default MasterReducer;
