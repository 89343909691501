import React, { useEffect, useState } from "react";
import classes from "./Toast.module.css";

const Toast = ({ type, message, toastIcon, display }) => {
  let toastTypeClasses;

  if (type === "success") {
    toastTypeClasses = classes.success;
  } else if (type === "danger") {
    toastTypeClasses = classes.danger;
  } else {
    toastTypeClasses = classes.warning;
  }

  return (
    <div
      className={`${classes.toastWrapper} ${toastTypeClasses}  ${
        display && classes.visible
      }`}
    >
      <p>{message}</p>
    </div>
  );
};

export default Toast;
