import React from 'react';
import classes from './Questions.modules.css';
import { useSelector, useDispatch } from "react-redux";
import Utility from '../../../Utility/Utility';

const Question = ({index,question}) => {

    const { questions } = useSelector(
        (state) => ({
          questions: state.MasterReducer.questions,
        })
      );

    return(
        <div className={classes.question} style={{fontSize:'.9em'}}>
           {index + 1}. {Utility.getQuestionName(questions,question.questionId)}
        </div>
    )
}

export default Question;