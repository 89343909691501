import React from "react";
import classes from "./TotalJobsCard.module.css";
import brifcase from "../../Assets/Images/brifcase.png";
import ProgressBar from "../ProgressBar/ProgressBar";
import { withRouter } from "react-router";
import Utility from '../../Utility/Utility';

const TotalJobsCard = ({history,totalJobs = 0,openJobs = 0, closeJobs = 0}) => {
  const goToTotalJobs = () => {
    history.push("/employer-jobs");
  };

  return (
    <div className={classes.totalJobsCard} onClick={() => goToTotalJobs()}>
      <div className={classes.content}>
        <div className={classes.top}>
          <img src={brifcase} />
          &nbsp;&nbsp;&nbsp;
          <div className={classes.text}>
            <span>{totalJobs}</span>
            <br />
            <span>Total Jobs</span>
          </div>
          <div className={classes.progressRes}>
            <ProgressBar percentage={Utility.getPerecentage(totalJobs,openJobs)} text='Open/Total'/>
          </div>
        </div>
        <div className={classes.divider}></div>
        <div className={classes.bottom}>
          <div className={classes.bottomItem}>
            <span>{openJobs}</span>&nbsp;&nbsp;&nbsp;<span>Open Jobs</span>
          </div>
          <div className={classes.bottomItem}>
            <span>{closeJobs}</span>&nbsp;&nbsp;&nbsp;<span>Close Jobs</span>
          </div>
        </div>
      </div>
      <div className={classes.progress}>
        <ProgressBar percentage={Utility.getPerecentage(totalJobs,openJobs)} text='Open/Total'/>
      </div>
    </div>
  );
};

export default withRouter(TotalJobsCard);
