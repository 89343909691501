import React, { useEffect, useState, useRef } from "react";
import classes from "./CandidateStartInterview.module.css";
import RecordRTC from "recordrtc";
import * as actions from "../../../Store/Actions/index";
import * as actionTypes from "../../../Store/Actions/ActionTypes";
import { useSelector, useDispatch } from "react-redux";
import ErrorAlert from "../../../common/ErrorAlert/ErrorAlert";
import Loader from "../../../common/Loader/Loader";
import { withRouter } from "react-router-dom";
import Mcq from "./McqComp";
import Hark from "hark";
import SuccessAlert from "../../../common/SuccessAlert/SuccessAlert";
import MediaStreamRecorder from "msr";

var recorder;
var tts = window.speechSynthesis;
var toSpeak;
var audioAnswer = [];
var audioRecorder;
var timeInterval;
var maxTimeInterval;
var submittedMcq = [];
var speachEvent;
var mcqQuestionNumber = 0;
var totalQuestionNumber = 0;
var mcqCompleted = false;
var mediaRecorder;

const InterviewComp = ({ history }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [warningAlert, setWarningAlert] = useState(false);
  const [interviewStarted, setInterviewStarted] = useState(false);
  const [interviewCompleated, setInterviewCompleted] = useState(false);
  //   const [mcqCompleted, setMcqCompleted] = useState(false);
  const [question, setQuestion] = useState();
  let [timer, setTimer] = useState(10);
  let [maxTime, setMaxTime] = useState(120);
  let [questionNumber, setQuestionNumber] = useState(0);
  const [isMaxTimeReached, setIsMaxTimeReached] = useState(false);
  const [activeComp, setActiveComp] = useState("mcq");
  //   const [mcqQuestionNumber, setMcqQuestionNumber] = useState(0);
  const [recordingVideo, setRecordingVideo] = useState();
  const [totalQuestion, setTotalQuestion] = useState([]);
  //   let [totalQuestionNumber, setTotalQuestionNumber] = useState(0);
  const [answer, setAnswer] = useState("");
  const [alert, setAlert] = useState(false);
  const videoRef = useRef();
  const radioRefOne = useRef();
  const radioRefTwo = useRef();
  const radioRefThree = useRef();
  const radioRefFour = useRef();

  let {
    errorMsg,
    questionsForInterview,
    interviewSubmited,
    msg,
    audioUploaded,
    audioPath,
  } = useSelector((state) => ({
    errorMsg: state.AiInterviewReducer.errorMsg,
    questionsForInterview: state.AiInterviewReducer.questionsForInterview,
    interviewSubmited: state.AiInterviewReducer.interviewSubmited,
    msg: state.AiInterviewReducer.msg,
    audioUploaded: state.AiInterviewReducer.audioUploaded,
    audioPath: state.AiInterviewReducer.audioPath,
  }));

  useEffect(() => {
    setIsLoading(true);
    dispatch(actions.getQuestionsForInterview(history.location.state.JobId));
  }, []);

  useEffect(() => {
    if (questionsForInterview || interviewSubmited || errorMsg) {
      mergeQuestion();
      setIsLoading(false);
      if (errorMsg || interviewSubmited) {
        setAlert(true);
      }
    }
  }, [questionsForInterview, errorMsg, interviewSubmited]);

  const confirmAlert = () => {
    dispatch({ type: actionTypes.CLEAR });
    setAlert(false);
    if (interviewSubmited) {
      history.push("/scheduled-interviews");
    }
  };

  const mergeQuestion = () => {
    setTotalQuestion([
      ...questionsForInterview.skillBasedQuestions,
      ...questionsForInterview.personalQuestions,
      ...questionsForInterview.mcqQuestion,
    ]);
  };

  const getVoices = () => {
    let voices = [];
    voices = tts.getVoices();
    voices = voices.filter((item, index) => {
      if (index === 4) {
        return item;
      }
    });
    return voices;
  };

  const checkMcq = () => {
    if (questionsForInterview.mcqQuestion.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const startInterview = () => {
    if (checkMcq()) {
      setInterviewStarted(true);
      setActiveComp("mcq");
      startMaxTimer();
    } else {
      setIsLoading(true);
      gettingVideoReady()
        .then((res) => {
          setActiveComp("video");
          mcqCompleted = true;
          //   setMcqCompleted(true);
          setInterviewStarted(true);
          setIsLoading(false);
          startVideoRecording(res);
          startListening(res);

          handleQuestion(formatInterviewQuestions(), questionNumber);
        })
        .catch((err) => {
          setWarningAlert(true);
        });
      gettingAudioReady()
        .then((res) => {
          startAudioRecording(res);
        })
        .catch((err) => {
          setWarningAlert(true);
        });
    }
  };

  const gettingVideoReady = () => {
    if (window.innerWidth < 800) {
      return navigator.mediaDevices.getUserMedia({
        audio: true,
        video: { width: 400, height: 250 },
      });
    } else if (window.innerWidth < 600) {
      return navigator.mediaDevices.getUserMedia({
        audio: true,
        video: { width: 400, height: 200 },
      });
    } else if (window.innerWidth < 500) {
      return navigator.mediaDevices.getUserMedia({
        audio: true,
        video: { width: 250, height: 200 },
      });
    } else {
      return navigator.mediaDevices.getUserMedia({
        audio: true,
        video: { width: 600, height: 250 },
      });
    }
  };
  const gettingAudioReady = () => {
    return navigator.mediaDevices.getUserMedia({
      audio: true,
      mimeType: "audio/wav;codecs=pcm",
      recorderType: "StereoAudioRecorder",
    });
  };

  // starting video recording
  const startVideoRecording = (camera) => {
    if (videoRef.current !== null) {
      videoRef.current.srcObject = camera;
      videoRef.current.setAttribute("muted", true);
      videoRef.current.setAttribute("autoplay", false);
      videoRef.current.setAttribute("controls", false);
    }

    recorder = RecordRTC(camera, {
      type: "video",
    });

    // recognition.start()
    recorder.startRecording();
    recorder.camera = camera;
  };

  const stopVideoRecordingCallback = () => {
    if (videoRef.current !== null) {
      videoRef.current.src = URL.createObjectURL(recorder.getBlob());
      videoRef.current.srcObject = null;
      videoRef.current.setAttribute("muted", true);
      videoRef.current.setAttribute("autoplay", true);
      videoRef.current.setAttribute("controls", true);
    }
    let file = new File([recorder.getBlob()], "video.mp4", {
      type: "video/mp4",
    });
    setRecordingVideo(file);
    setInterviewCompleted(true);
    setInterviewStarted(false);
    // setAnswerText(answerText);
    clearInterval(timeInterval);
    clearInterval(maxTimeInterval);
    recorder.camera.stop();
    recorder.destroy();
    speachEvent.stop();

    recorder = null;
  };

  const stopVideoRecording = () => {
    recorder.stopRecording(stopVideoRecordingCallback);
  };

  // start audio recording
  const startAudioRecording = (audio) => {
    audioRecorder = RecordRTC(audio, {
      type: "audio/wav;codecs=pcm",
    });
    audioRecorder.startRecording();
  };

  const stopAudioRecorderCallback = () => {
    let file = new File(
      [audioRecorder.getBlob()],
      `answer${questionNumber}.wav`,
      {
        type: "audio/wav",
      }
    );
    console.log("file", audioRecorder.getBlob());
    audioAnswer.push(file);
    // console.log("file",answerAudio)
    // setAnswerAudio([...answerAudio, `answer${questionNumber}.mp3`]);
    // console.log("checking", file);
    audioRecorder = null;
  };
  const stopAudioReording = () => {
    if (audioRecorder) {
      audioRecorder.stopRecording(stopAudioRecorderCallback);
    }
  };

  const speak = (voice, dataToSpeak) => {
    setQuestion(dataToSpeak);
    toSpeak = new SpeechSynthesisUtterance(dataToSpeak);
    toSpeak.pitch = 0.5;
    toSpeak.rate = 1;
    toSpeak.voice = voice;
    toSpeak.onstart = (e) => {};
    toSpeak.onend = (e) => {
      if (recorder.getState() !== "stopped") {
        console.log("calling mini timer and max timer on after question speak");
        if (timeInterval || maxTimeInterval) {
          clearInterval(timeInterval);
          clearInterval(maxTimeInterval);
        }
        startTimer();
        startMaxTimer();
      }
    };
    tts.speak(toSpeak);
  };

  const startTimer = () => {
    if (history.location.pathname === "/start-interview") {
      timeInterval = setInterval(() => {
        setTimer(--timer);
        if (timer === 0) {
          stopAudioReording();
          moveToNext("minitimer");
        }
      }, 1000);
    } else {
      toSpeak.volume = 0;
      if (recorder) {
        // stopAudioRecording();
        stopVideoRecording();
      }
    }
  };

  const startMaxTimer = () => {
    maxTimeInterval = setInterval(() => {
      setMaxTime(--maxTime);
      if (maxTime === 0) {
        setIsMaxTimeReached(true);
        clearInterval(maxTimeInterval);
        setMaxTime(120);
        maxTime = 120;
        setTimeout(() => {
          if (mcqCompleted) {
            stopAudioReording();
            setIsMaxTimeReached(false);
            moveToNext("maxtimer");
          } else {
            submitAnswer(
              questionsForInterview.mcqQuestion[mcqQuestionNumber].name,
              answer
            );
          }
        }, 2000);
      }
    }, 1000);
  };

  const moveToNext = (a) => {
    setTimer(10);
    timer = 10;
    clearInterval(timeInterval);
    setMaxTime(120);
    maxTime = 120;
    clearInterval(maxTimeInterval);
    questionNumber = questionNumber + 1;
    setQuestionNumber(questionNumber);
    totalQuestionNumber = totalQuestionNumber + 1;
    // setTotalQuestionNumber(totalQuestionNumber + 1);
    mergeQuestion();
    handleQuestion(formatInterviewQuestions(), questionNumber);
  };

  const handleQuestion = (questions, questionNumber) => {
    if (questions.length - 1 >= questionNumber) {
      gettingAudioReady().then((res) => {
        startAudioRecording(res);
        let question = questionNumber;
        let voice = getVoices();
        speak(voice[0], questions[question]);
      });
    } else {
      if (recorder) {
        stopVideoRecording();
        tts.cancel();
      }
    }
  };

  const formatInterviewQuestions = () => {
    if (questionsForInterview) {
      let combineedQuestion = [];
      if (
        questionsForInterview.skillBasedQuestions &&
        questionsForInterview.skillBasedQuestions.length > 0
      ) {
        combineedQuestion = [
          ...combineedQuestion,
          ...questionsForInterview.skillBasedQuestions.map((item) => {
            return item.name;
          }),
        ];
      }
      if (
        questionsForInterview.personalQuestions &&
        questionsForInterview.personalQuestions.length > 0
      ) {
        combineedQuestion = [
          ...combineedQuestion,
          ...questionsForInterview.personalQuestions.map((item) => {
            return item.name;
          }),
        ];
      }
      return combineedQuestion;
    }
  };

  const startListening = (camera) => {
    speachEvent = Hark(camera, {});
    speachEvent.on("speaking", () => {
      clearInterval(timeInterval);
      setTimer(10);
      timer = 10;
    });
    speachEvent.on("stopped_speaking", () => {
      if (timeInterval) {
        clearInterval(timeInterval);
        setTimer(10);
        timer = 10;
      }
      startTimer();
    });
  };

  const submitAnswer = (name, answer) => {
    submittedMcq.push({ question: name, answer: answer });
    clearInterval(maxTimeInterval);
    setMaxTime(120);
    maxTime = 120;
    radioRefOne.current.checked = false;
    radioRefTwo.current.checked = false;
    radioRefThree.current.checked = false;
    radioRefFour.current.checked = false;
    if (mcqQuestionNumber === questionsForInterview.mcqQuestion.length - 1) {
      setIsLoading(true);
      setActiveComp("video");
      mcqCompleted = true;
      //   setMcqCompleted(true);
      gettingVideoReady()
        .then((res) => {
          setInterviewStarted(true);
          setIsLoading(false);
          startVideoRecording(res);
          startListening(res);
          totalQuestionNumber = totalQuestionNumber + 1;
          //   setTotalQuestionNumber(totalQuestionNumber + 1);
          mergeQuestion();
          handleQuestion(formatInterviewQuestions(), questionNumber);
        })
        .catch((err) => {
          setWarningAlert(true);
        });
      gettingAudioReady()
        .then((res) => {
          setIsLoading(false);
          startAudioRecording(res);
        })
        .catch((err) => {
          setWarningAlert(true);
        });
    } else if (
      mcqQuestionNumber <
      questionsForInterview.mcqQuestion.length - 1
    ) {
      startMaxTimer();
      totalQuestionNumber = totalQuestionNumber + 1;
      //   setTotalQuestionNumber(totalQuestionNumber + 1);
      mergeQuestion();

      mcqQuestionNumber = mcqQuestionNumber + 1;

      //   setMcqQuestionNumber(mcqQuestionNumber + 1);
    }
  };

  const skipQuestion = () => {
    stopAudioReording();
    moveToNext();
  };

  const submitData = () => {
    let formData = new FormData();
    formData.append("file", recordingVideo);
    setIsLoading(true);
    dispatch(
      actions.submitAnswerVideo(formData, history.location.state.ApplicationId)
    );
    let question = formatInterviewQuestions();
    let dataToUpload = audioAnswer.map((item, i) => {
      let audioFile = new FormData();
      audioFile.append("audio", item);
      return {
        name: `${history.location.state.ApplicationId}answer${i + 1}`,
        file: audioFile,
      };
    });
    dispatch(actions.candidateAnswerAudio(dataToUpload));
    // let questionsWithAnswer = question.map((item, i) => {
    //   return {
    //     question: item,
    //     answer: audioAnswer[i],
    //   };
    // });
    // dispatch(
    //   actions.submitCandidateInterview({
    //     answers: questionsWithAnswer,
    //     applicationId: history.location.state.ApplicationId,
    //   })
    // );
  };

  useEffect(() => {
    if (audioUploaded) {
      let question = formatInterviewQuestions();
      let questionsWithAnswer = question.map((item, i) => {
        return {
          question: item,
          answer: audioPath[i],
        };
      });
      questionsWithAnswer = [...questionsWithAnswer, ...submittedMcq];
      dispatch(
        actions.submitCandidateInterview({
          answers: questionsWithAnswer,
          applicationId: history.location.state.ApplicationId,
        })
      );
    }
  }, [audioUploaded]);

  useEffect(() => {
    return () => {
      tts.cancel();
    };
  }, []);

  let indexQuestion = (
    <React.Fragment>
      {totalQuestion.map((item, index) => {
        return (
          <div
            className={classes.index}
            style={{
              background: index <= totalQuestionNumber && "#6a68d2",
              color: index <= totalQuestionNumber && "#fff",
            }}
          >
            {index + 1}
          </div>
        );
      })}
    </React.Fragment>
  );

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <div className={classes.left}>
        <div className={classes.title}>
          Welcome {localStorage.getItem("User")}
        </div>

        {alert && interviewSubmited && (
          <SuccessAlert show={true} msg={msg} confirm={confirmAlert} />
        )}

        {alert && errorMsg && (
          <ErrorAlert show={true} msg={errorMsg} confirm={confirmAlert} />
        )}

        {warningAlert && (
          <ErrorAlert
            show={true}
            msg={"Please Enable Microphone & Camera Permissions"}
            confirm={() => setWarningAlert(false)}
          />
        )}
        {interviewStarted && !interviewCompleated && (
          <div
            className={classes.maxTimerOuter}
            style={{
              color: maxTime < 10 && "red",
              border: maxTime < 10 && "2px solid red",
            }}
          >
            <div>
              <i
                class="fa fa-clock-o"
                aria-hidden="true"
                style={{ color: "yellow", fontSize: "2em" }}
              ></i>
            </div>
            <div> Time Remaining</div>
            <div>{maxTime}</div>
          </div>
        )}

        <div className={classes.extraWrapper}>
          {(interviewStarted || interviewCompleated) && (
            <div>
              <div className={classes.questionIndexs}>
                <div className={classes.questionText}>Questions:</div>
                <div className={classes.questionIndex}>{indexQuestion}</div>
              </div>
              {activeComp === "mcq" && interviewStarted && (
                <div className={classes.left}>
                  <div className={classes.testInterviewWrapper}>
                    <div className={classes.question}>
                      {
                        questionsForInterview.mcqQuestion[mcqQuestionNumber]
                          .name
                      }
                    </div>
                    <div className={classes.options}>
                      <div className={classes.option}>
                        <input
                          ref={radioRefOne}
                          type="radio"
                          name="option"
                          value={
                            questionsForInterview.mcqQuestion[mcqQuestionNumber]
                              .options[0]
                          }
                          onChange={() =>
                            setAnswer(
                              questionsForInterview.mcqQuestion[
                                mcqQuestionNumber
                              ].options[0]
                            )
                          }
                        />{" "}
                        &nbsp;{" "}
                        {
                          questionsForInterview.mcqQuestion[mcqQuestionNumber]
                            .options[0]
                        }
                      </div>
                      <div className={classes.option}>
                        <input
                          ref={radioRefTwo}
                          type="radio"
                          name="option"
                          value={
                            questionsForInterview.mcqQuestion[mcqQuestionNumber]
                              .options[1]
                          }
                          onChange={() =>
                            setAnswer(
                              questionsForInterview.mcqQuestion[
                                mcqQuestionNumber
                              ].options[1]
                            )
                          }
                        />{" "}
                        &nbsp;{" "}
                        {
                          questionsForInterview.mcqQuestion[mcqQuestionNumber]
                            .options[1]
                        }
                      </div>
                      <div className={classes.option}>
                        <input
                          ref={radioRefThree}
                          type="radio"
                          name="option"
                          value={
                            questionsForInterview.mcqQuestion[mcqQuestionNumber]
                              .options[2]
                          }
                          onChange={() =>
                            setAnswer(
                              questionsForInterview.mcqQuestion[
                                mcqQuestionNumber
                              ].options[2]
                            )
                          }
                        />{" "}
                        &nbsp;{" "}
                        {
                          questionsForInterview.mcqQuestion[mcqQuestionNumber]
                            .options[2]
                        }
                      </div>
                      <div className={classes.option}>
                        <input
                          ref={radioRefFour}
                          type="radio"
                          name="option"
                          value={
                            questionsForInterview.mcqQuestion[mcqQuestionNumber]
                              .options[3]
                          }
                          onChange={() =>
                            setAnswer(
                              questionsForInterview.mcqQuestion[
                                mcqQuestionNumber
                              ].options[3]
                            )
                          }
                        />{" "}
                        &nbsp;{" "}
                        {
                          questionsForInterview.mcqQuestion[mcqQuestionNumber]
                            .options[3]
                        }
                      </div>
                    </div>
                    <div
                      className={classes.submitBtn}
                      style={{ marginTop: "5vh", marginBottom: "5vh" }}
                    >
                      <button
                        onClick={() =>
                          submitAnswer(
                            questionsForInterview.mcqQuestion[mcqQuestionNumber]
                              .name,
                            answer
                          )
                        }
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
                // <Mcq
                //   questions={
                //     questionsForInterview &&
                //     questionsForInterview.mcqQuestion[mcqQuestionNumber]
                //   }
                //   submitMcq={submitMcq}
                // />
              )}
              {(interviewStarted || interviewCompleated) &&
                mcqCompleted &&
                activeComp === "video" && (
                  <div className={classes.videoShow}>
                    <div className={classes.testInterviewWrapper}>
                      {interviewStarted && !interviewCompleated && (
                        <div className={classes.question}>{question}</div>
                      )}
                      {/* {interviewStarted && !interviewCompleated && (
                  <div className={classes.timer}>{timer}</div>
                )} */}
                      <div className={classes.videoBox}>
                        <video
                          className={classes.video}
                          ref={videoRef}
                          muted
                          playsinline
                        ></video>
                      </div>
                      {interviewCompleated && (
                        <div
                          className={classes.submitBtn}
                          style={{ marginBottom: "5vh" }}
                        >
                          <button onClick={submitData}>Submit</button>
                        </div>
                      )}
                      {interviewStarted && mcqCompleted && (
                        <div
                          className={classes.submitBtn}
                          style={{ marginBottom: "5vh" }}
                        >
                          <button
                            onClick={skipQuestion}
                            // style={{ pointerEvents: timer > 7 && "none" }}
                          >
                            Next
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                )}
            </div>
          )}
          {interviewStarted && !interviewCompleated && (
            <div
              className={classes.maxTimer}
              style={{
                color: maxTime < 10 && "red",
                border: maxTime < 10 && "2px solid red",
              }}
            >
              <div>
                <i
                  class="fa fa-clock-o"
                  aria-hidden="true"
                  style={{ color: "yellow", fontSize: "2em" }}
                ></i>
              </div>
              <div> Time Remaining</div>
              <div>{maxTime}</div>
            </div>
          )}
        </div>

        {/* {interviewStarted && mcqCompleted && !interviewCompleated && (
          <div
            className={classes.maxTimer}
            style={{
              color: maxTime < 10 && "red",
              border: maxTime < 10 && "2px solid red",
            }}
          >
            <div> Time Remaining</div>
            <div>{maxTime}</div>
          </div>
        )} */}

        {!interviewStarted && !interviewCompleated && (
          <div className={classes.instruction}>
            To Start the Interview Please click on below button
          </div>
        )}

        {!interviewStarted && !interviewCompleated && (
          <div className={classes.submitBtn} style={{ marginTop: "20vh" }}>
            <button onClick={startInterview}>Start Interview</button>
          </div>
        )}
      </div>
    );
  }
};

export default withRouter(InterviewComp);
