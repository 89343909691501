import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actions from "../../../Store/Actions/index";
import * as actionTypes from "../../../Store/Actions/ActionTypes";
import Loader from "../../../common/Loader/Loader";
import Description from "../Description/Description";
import Skills from "../Skills/Skills";
import Questions from "../Questions/Questions";
import PersonalMessage from "../PersonalMessage/PersonalMessage";
import OnBoardingRequirements from "../OnBoardingRequirements/OnBoardingRequirements";
import MiscInformation from "../MiscInformation/MiscInformation";
import classes from "./JobDescription.module.css";
import Utility from "../../../Utility/Utility";

const JobDescription = ({ history }) => {
  const dispatch = useDispatch();

  const {
    jobPreview,
    masterCountryList,
    masterStateList,
    masterCityList,
    questions,
  } = useSelector((state) => ({
    jobPreview: state.CreateJobReducer.jobPreview,
    masterCountryList: state.MasterReducer.masterCountryList,
    masterStateList: state.MasterReducer.masterStateList,
    masterCityList: state.MasterReducer.masterCityList,
    questions: state.MasterReducer.questions,
  }));

  useEffect(() => {
    dispatch({ type: actionTypes.TRASH });
    if (history.location.state.jobId) {
      dispatch(actions.getCreateJobPreview(history.location.state.jobId));
      if (!masterCountryList) {
        dispatch(actions.getMasterCountryList());
      }
      if (!masterStateList) {
        dispatch(actions.getMasterStateList());
      }
      if (!masterCityList) {
        dispatch(actions.getMasterCityList());
      }
      if(!questions){
        dispatch(actions.getInterviewQuestions());
      }
    }
  }, []);

  if (!jobPreview) {
    return <Loader />;
  } else {
    return (
      <div className={classes.jobDescription}>
        <div className={classes.descTop}>
          <div className={classes.top}>
            <span>{jobPreview.role && jobPreview.role}</span>
            <br />
            <span>
              Posted On: {Utility.formatDate(jobPreview.publishedDate)}
            </span>
          </div>
          <div className={classes.middle}>
            <i className="fa fa-map-marker" aria-hidden="true"></i>&nbsp;&nbsp;
            <span>
              {jobPreview.workLocation &&
                Utility.getLocation(
                  masterCountryList,
                  masterStateList,
                  masterCityList,
                  jobPreview.workLocation
                )}
            </span>
          </div>
          <div className={classes.bottom}>
            <i className="fa fa-clock-o" aria-hidden="true"></i>&nbsp;&nbsp;
            <span>
              Contract Period:{" "}
              {jobPreview.startDate === null && jobPreview.endDate === null
                ? "Not applicable"
                : `${jobPreview.startDate} ${
                    jobPreview.startDate > 1 ? "yrs" : "yr"
                  } ${jobPreview.endDate} ${
                    jobPreview.endDate > 1 ? "months" : "month"
                  }`}
            </span>
          </div>
        </div>
        <div className={classes.description}>
          <Description
            id={jobPreview._id}
            type={jobPreview.type}
            description={jobPreview.description}
            education={jobPreview.educationId.name}
            interviewProcess={jobPreview.interviewProcessId}
            companyType={jobPreview.sectorId.name}
          />
        </div>
        <div className={classes.description}>
          <Skills skill={jobPreview.skillMatrixId.skill} />
        </div>
        <div className={classes.description}>
          <MiscInformation miscInfo={jobPreview.miscInfoId} />
        </div>
        <div className={classes.description}>
          <Questions questions={jobPreview.qualifyingQuestionId} />
        </div>
        <div className={classes.description}>
          <PersonalMessage personalMessage={jobPreview.uploadedData} />
        </div>
        <div className={classes.description}>
          <OnBoardingRequirements boardingRequirement={jobPreview.boardingId} />
        </div>
      </div>
    );
  }
};

export default withRouter(JobDescription);
