import * as actionTypes from "../Actions/ActionTypes";

const initialState = {};

const EmployerReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.API_INITIATE:
      return {
        ...state,
        loader: true,
        error: undefined,
        errorMsg: undefined,
        msg: undefined,
        profileSubmitted: undefined,
        logoUploaded: undefined,
      };
    case actionTypes.SUB_API_INITIATE:
      return {
        ...state,
        smallLoader: true,
        error: undefined,
        errorMsg: undefined,
        msg: undefined,
      };
    case actionTypes.CLEAR:
      return {
        ...state,
        error: undefined,
        errorMsg: undefined,
        jobReposted: undefined,
        application: undefined,
        applicationDetail: undefined,
        jobClosed: undefined,
        msg: undefined,
      };
    case actionTypes.TRASH:
      return {
        ...state,
        employerDetail: undefined,
      };
    case actionTypes.UPDATE_APPLICATION:
      return {
        ...state,
        application: state.application.map((item) => {
          let returnItem = { ...item };
          if (item.jobId === action.payload) {
            returnItem.applicationReceived = returnItem.applicationReceived - 1;
          }
          return returnItem;
        }),
      };
    case actionTypes.TEST:
      return {
        ...state,
        interviewScheduled: undefined,
        applicationRejected: undefined,
      };
    case actionTypes.UPLOAD_COMPANY_LOGO_SUCCESS:
      return {
        ...state,
        logoUploaded: true,
      };
    case actionTypes.UPLOAD_COMPANY_LOGO_FAIL:
      return {
        ...state,
        loader: false,
        error: true,
        errorMsg: action.payload,
      };
    case actionTypes.SUBMIT_PROFILE_SUCCESS:
      return {
        ...state,
        loader: false,
        getEmployerProfile: undefined,
        profileSubmitted: "Your company profile has been created successfully.",
      };
    case actionTypes.SUBMIT_PROFILE_FAIL:
      return {
        ...state,
        loader: false,
        error: true,
        errorMsg: action.payload,
      };
    case actionTypes.GET_EMPLOYER_PROFILE:
      return {
        ...state,
        loader: false,
        employerDetail: action.payload,
      };
    case actionTypes.GET_EMPLOYER_PROFILE_FAIL:
      return {
        ...state,
        loader: false,
        errorMsg: action.payload,
      };
    case actionTypes.UPDATE_EMPLOYER_PROFILE_SUCCESS:
      return {
        ...state,
        loader: false,
        profileUploaded: undefined,
        profileSubmitted: "Your company profile has been updated successfully.",
      };
    case actionTypes.UPDATE_EMPLOYER_PROFILE_FAIL:
      return {
        ...state,
        loader: false,
        profileUploaded: undefined,
        error: true,
        errorMsg: action.payload,
      };
    case actionTypes.GET_DRAFTED_JOB_SUCCESS:
      return {
        ...state,
        loader: false,
        draftedJobs: action.payload,
      };
    case actionTypes.GET_DRAFTED_JOB_FAIL:
      return {
        ...state,
        loader: false,
        error: true,
        errorMsg: action.payload,
        draftedJobs: [],
      };
    case actionTypes.GET_DELETED_JOB_SUCCESS:
      return {
        ...state,
        loader: false,
        deletedJobs: action.payload,
      };
    case actionTypes.GET_DELETED_JOB_FAIL:
      return {
        ...state,
        loader: false,
        error: true,
        errorMsg: action.payload,
        deletedJobs: [],
      };
    case actionTypes.REPOST_JOB_SUCCESS:
      return {
        ...state,
        loader: false,
        jobReposted: true,
        deletedJobs: state.deletedJobs.filter((item) => {
          if (item._id !== action.payload) {
            return item;
          }
        }),
      };
    case actionTypes.REPOST_JOB_FAIL:
      return {
        ...state,
        loader: false,
        error: true,
        errorMsg: action.payload,
      };
    case actionTypes.GET_APPLICATION_SUCCESS:
      return {
        ...state,
        loader: false,
        application: action.payload,
      };
    case actionTypes.GET_APPLICATION_FAIL:
      return {
        ...state,
        loader: false,
        application: [],
        error: true,
        errorMsg: action.payload,
      };
    case actionTypes.GET_APPLICATION_BY_ID_SUCCESS:
      return {
        ...state,
        smallLoader: false,
        applicationDetail: action.payload,
      };
    case actionTypes.GET_APPLICATION_BY_ID_FAIL:
      return {
        ...state,
        smallLoader: false,
        applicationDetail: undefined,
        error: true,
        errorMsg: action.payload,
      };
    case actionTypes.REJECT_JOB_APPLICATION_SUCCESS:
      return {
        ...state,
        smallLoader: false,
        msg: "Application rejected successfully.",
        applicationDetail: state.applicationDetail.filter((item) => {
          if (item.applicationId !== action.payload) {
            return item;
          }
        }),
        applicationRejected: true,
      };
    case actionTypes.REJECT_JOB_APPLICATION_FAIL:
      return {
        ...state,
        smallLoader: false,
        error: true,
        errorMsg: action.payload,
      };
    case actionTypes.SCHEDULE_INTERVIEW_SUCCESS:
      return {
        ...state,
        interviewScheduled: true,
        applicationDetail: state.applicationDetail.filter((item) => {
          if (item.applicationId !== action.payload) {
            return item;
          }
        }),
        smallLoader: false,
        msg: "Interview scheduled successfully.",
      };
    case actionTypes.SCHEDULE_INTERVIEW_FAIL:
      return {
        ...state,
        smallLoader: false,
        errorMsg: action.payload,
      };
    case actionTypes.GET_COMPLETED_JOBS:
      return {
        ...state,
        completedJobs: action.payload,
      };
    case actionTypes.GET_COMPLETED_JOBS_FAIL:
      return {
        ...state,
        completedJobs: [],
      };

    case actionTypes.GET_REJECTED_JOBS:
      return {
        ...state,
        rejectedJobs: action.payload,
      };
    case actionTypes.GET_REJECTED_JOBS_FAIL:
      return {
        ...state,
        rejectedJobs: [],
      };

    default:
      return state;
  }
};

export default EmployerReducer;
