import React from "react";
import classes from "./CandidateStartInterview.module.css";

const Instruction = ({ changeActiveComp }) => {
  return (
    <div className={classes.left}>
      <div className={classes.title}> Instructions </div>
      <div className={classes.instructions}>
        <div>1.</div>
        <div>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy
        </div>
      </div>
      <div className={classes.instructions}>
        <div>2.</div>
        <div>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy
        </div>
      </div>
      <div className={classes.instructions}>
        <div>3.</div>
        <div>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy
        </div>
      </div>
      <div className={classes.instructions}>
        <div>4.</div>
        <div>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy
        </div>
      </div>
      <div className={classes.instructions}>
        <div>5.</div>
        <div>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy
        </div>
      </div>
      <div className={classes.submitBtn} style={{marginTop:'20vh'}}>
        <button onClick={()=>changeActiveComp('test')}>Next</button>
      </div>
    </div>
  );
};

export default Instruction;
