import React from "react";
import classes from "./JobProgressMobile.module.css";
import SectionOne from "./SectionOne";
import SectionTwo from "./SectionTwo";

const JobProgressMobile = ({ activeIndex = 0 }) => {
  return (
    <div className={classes.jobProgressMobile}>
      {activeIndex <= 5 ? (
        <SectionOne activeIndex={activeIndex} />
      ) : (
        <SectionTwo  activeIndex={activeIndex}/>
      )}
    </div>
  );
};
export default JobProgressMobile;
