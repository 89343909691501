import React from "react";
import classes from "./Skill.module.css";
import pencil from "../../../../Assets/Images/pencil.png";
import trash from "../../../../Assets/Images/trash.png";

const SkillCustome = ({
  customeIndex,
  customeQuestion,
  masterSkill,
  addCustomeSkillQuestion,
  editCustomeSkillQuestion,
  removeCustomeSkillQuestion,
}) => {
  const getSkill = (skillId) => {
    let skillName = "";
    masterSkill.map((skill) => {
      if (skill._id === skillId) {
        skillName = skill.value;
      }
    });

    if (skillName != "") {
      return skillName;
    } else {
      return skillId;
    }
  };

  const textFormatter = (str) => {
    if (str.length > 50) {
      return `${str.substring(0, 50)}...`;
    } else {
      return str;
    }
  };

 const cleanInputSkill=()=>{
  document.getElementById("inputAnswer").value="";
  document.getElementById("inputQuestion").value=""
  document.getElementById("inputSelect").selected=true;
 }

  return (
    <form onSubmit={(e) => addCustomeSkillQuestion(e, customeIndex)} autocomplete="off">
      <div className={classes.skillStrip}>
        <div className={classes.index}>{customeIndex }</div>
        <div className={classes.select}>
          {customeQuestion.isEdit && (
            <select required defaultValue={customeQuestion.skillId}>
              <option disabled id="inputSelect" selected value="">
                Select Skill
              </option>
              {masterSkill.map((skill) => {
                return <option value={skill._id}>{skill.value}</option>;
              })}
            </select>
          )}
          {!customeQuestion.isEdit && (
            <span className={classes.text}>
              {getSkill(customeQuestion.skillId)}
            </span>
          )}
        </div>
        <div className={classes.input}>
          {customeQuestion.isEdit && (
            <input
              type="text"
              placeholder="Enter question"
              required
              // pattern="^[ A-Za-z?.]*$"
              id="inputQuestion"
              // title="only alphabets,dot and ? allowed"
              defaultValue={customeQuestion.question}
            />
          )}
          {!customeQuestion.isEdit && (
            <span className={classes.text}>
              {customeQuestion.question}
            </span>
          )}
        </div>
        <div className={classes.input}>
          {customeQuestion.isEdit && (
            <input
              type="text"
              placeholder="Enter answer"
              minLength='1'
              required
              // pattern="^[ A-Za-z.,']*$"
              // title="only alphabets and . ' , allowed"
              id="inputAnswer"
              defaultValue={customeQuestion.answer}
            />
          )}
          {!customeQuestion.isEdit && (
            <span className={classes.text}>
              {customeQuestion.answer}
            </span>
          )}
        </div>
        <div>
          {customeQuestion.isEdit && (
            <div className={classes.btnSave}>
              <span><button>Add</button>&nbsp;&nbsp;&nbsp;<i class="fa fa-window-close"
              title="Clear Question"
              onClick={cleanInputSkill}
              style={{color: "#9675e4",cursor:"pointer",fontSize: "20px",verticalAlign: "middle"}} aria-hidden="true"></i></span>
            
            </div>
          )}
          {!customeQuestion.isEdit && (
            <div className={classes.edit}>
              <img
                src={pencil}
                onClick={() => editCustomeSkillQuestion(customeIndex)}
              />
              <img
                src={trash}
                onClick={() => removeCustomeSkillQuestion(customeIndex)}
              />
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

export default SkillCustome;
