import React, { useEffect, useState, useRef } from "react";
import * as actions from "../../../Store/Actions/index";
import NavigationPrompt from "./NavigationPrompt";
import Modal from "react-modal";
import ErrorAlert from "../../../common/ErrorAlert/ErrorAlert";
import classes from "./StartInterview.module.css";
import * as actionTypes from "../../../Store/Actions/ActionTypes";
import { useSelector, useDispatch } from "react-redux";
import MCQ from "./interviewBySadhvi/mcq";
import { useHistory } from "react-router";
import SkillsBased from "./interviewBySadhvi/skillsBased";
import VideoPreview from "./interviewBySadhvi/videoPreview";
import SuccessAlert from "../../../common/SuccessAlert/SuccessAlert";
import Loader from "../../../common/Loader/Loader";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const MainInterview = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  let {
    errorMsg,
    questionsForInterview,
    interviewSubmited,
    msg,
    audioUploaded,
    audioPath,
    interviewVideoSubmitted,
  } = useSelector((state) => ({
    errorMsg: state.AiInterviewReducer.errorMsg,
    questionsForInterview: state.AiInterviewReducer.questionsForInterview,
    interviewSubmited: state.AiInterviewReducer.interviewSubmited,
    msg: state.AiInterviewReducer.msg,
    audioUploaded: state.AiInterviewReducer.audioUploaded,
    audioPath: state.AiInterviewReducer.audioPath,
    interviewVideoSubmitted: state.AiInterviewReducer.interviewVideoSubmitted,
  }));

  const [isLoading, setIsLoading] = useState(false);
  const [mcqQuestions, setMCQQuestions] = useState(null);
  const [skillBasedQuestions, setSkillBasedQuestions] = useState(null);
  const [allQuestions, setAllQuestions] = useState(null);
  const [mcqEnable, setMCQEnable] = useState(false);
  const [speechEnable, setSpeechEnable] = useState(false);
  const [mcqCompleteAnswer, setMCQCompleteAnswer] = useState([]);
  const [skillCompleteAnswer, setSkillCompleteAnswer] = useState([]);
  const [totalQuestionNumber, settotalQuestionNumber] = useState(0);
  const [showPreview, setShowPreview] = useState(false);
  let [maxTime, setMaxTime] = useState(120);
  const [recordedVideo, setRecordedVideo] = useState(null);
  const [interviewStarted, setInterviewStarted] = useState(false);
  const [interviewCompleated, setInterviewCompleted] = useState(false);
  const [alert, setAlert] = useState(false);
  const [warningAlert, setWarningAlert] = useState(false);

  useEffect(() => {
    if (questionsForInterview) {
      // const allMCQQuestions = questionsForInterview?.mcqQuestion;
      // const allSkillQuestions = [
      //   ...questionsForInterview?.personalQuestions,
      //   ...questionsForInterview?.skillBasedQuestions,
      // ];

      // if (
      //   questionsForInterview?.mcqQuestion.length === 0 ||
      //   questionsForInterview?.mcqQuestion === undefined
      // ) {
      //   setMCQEnable(false);
      // }

      // const skillQuestions = [
      //   ...questionsForInterview?.personalQuestions,
      //   ...questionsForInterview?.skillBasedQuestions,
      // ];
      // if (skillQuestions.length === 0 || skillQuestions === undefined) {
      //   setSpeechEnable(false);
      // }
      if (questionsForInterview?.mcqQuestion.length > 0) {
        setMCQEnable(true);
      } else {
        setSpeechEnable(true);
      }
      setIsLoading(false);
      setMCQQuestions(questionsForInterview?.mcqQuestion);
      setSkillBasedQuestions([
        ...questionsForInterview?.personalQuestions,
        ...questionsForInterview?.skillBasedQuestions,
      ]);
      setAllQuestions([
        ...questionsForInterview?.mcqQuestion,
        ...questionsForInterview?.personalQuestions,
        ...questionsForInterview?.skillBasedQuestions,
      ]);
    }
  }, [questionsForInterview]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(actions.getQuestionsForInterview(history.location.state.JobId));
  }, []);

  useEffect(() => {
    if (errorMsg || interviewSubmited) {
      setAlert(true);
    }
  }, [errorMsg, interviewSubmited]);

  const finalMCQHandler = (MCQAnswers) => {
    const transformedCurrentIndex = totalQuestionNumber + 1;
    settotalQuestionNumber(transformedCurrentIndex);
    setMCQCompleteAnswer(MCQAnswers);
    setMCQEnable(false);
    setSpeechEnable(true);
  };

  const finalSpeechHandler = (speechAnswers) => {
    setSkillCompleteAnswer(speechAnswers);
    console.log("complete answer", [...mcqCompleteAnswer, ...speechAnswers]);
    setShowPreview(true);
  };

  window.onbeforeunload = (event) => {
    localStorage.setItem("pageReloaded", true);
    submitApiHandler();
  };

  let indexQuestion = (
    <React.Fragment>
      {allQuestions !== null &&
        [...mcqQuestions, ...skillBasedQuestions].map((item, index) => {
          return (
            <div
              className={classes.index}
              style={{
                background: index <= totalQuestionNumber && "#6a68d2",
                color: index <= totalQuestionNumber && "#fff",
              }}
            >
              {index + 1}
            </div>
          );
        })}
    </React.Fragment>
  );

  const nextQuestionNumberHandler = (index) => {
    settotalQuestionNumber(totalQuestionNumber + 1);
  };

  const recordingVideoHandler = (recordedVideo) => { };

  const submitApiHandler = () => {
    let file;
    console.log({ recordedVideo })
    if (recordedVideo) {
      file = new File([recordedVideo.getBlob()], "video.mp4", {
        type: "video/mp4",
      });
    }

    let formData = new FormData();
    formData.append("file", file);
    setIsLoading(true);
    dispatch(
      actions.submitAnswerVideo(formData, history.location.state.ApplicationId)
    );
  };

  const finalSubmission = () => {
    let questionsWithAnswer = [...mcqCompleteAnswer, ...skillCompleteAnswer];
    console.log("checking final answer", questionsWithAnswer);
    dispatch(
      actions.submitCandidateInterview({
        answers: questionsWithAnswer,
        applicationId: history.location.state.ApplicationId,
      })
    );

    setInterviewStarted(false);

    let x = JSON.parse(localStorage.getItem("InterviewCompleted"));
    x.push(history.location.state.ApplicationId);
    localStorage.setItem("InterviewCompleted", JSON.stringify(x));
  };

  useEffect(() => {
    if (interviewVideoSubmitted) {
      finalSubmission();
      setIsLoading(false);
    }
  }, [interviewVideoSubmitted]);

  const videoRef = useRef();
  const videoDataHandler = (recorder) => {
    setRecordedVideo(recorder);
    videoRef.current.src = URL.createObjectURL(recorder.getBlob());
  };

  const startInterview = () => {
    localStorage.setItem("userStartedInterview", true);
    setInterviewStarted(true);
  };

  const pauseInterview = () => {
    // console.log("pause", pause);
    // setPause(!pause);
    // if (!pause) {
    //   clearInterval(timeInterval);
    //   clearInterval(maxTimeInterval);
    // } else {
    //   if (history.location.pathname === "/start-interview") {
    //     if (activeComp === "video") {
    //       startTimer("pause");
    //       startMaxTimer("pause");
    //     } else {
    //       startMaxTimer("pause");
    //     }
    //   }
    // }
  };

  const confirmAlert = () => {
    dispatch({ type: actionTypes.CLEAR });
    setAlert(false);

    if (interviewSubmited) {
      history.push("/candidate-dashboard");
    }

    window.location.reload();
    localStorage.setItem("reloadmodal", false);
  };

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <>
        <div className={classes.left}>
          <div className={classes.title}>
            Welcome {localStorage.getItem("User")}
          </div>

          <NavigationPrompt
            when={interviewStarted}
            submit={finalSubmission}
            pauseInterview={pauseInterview}
          >
            {(isOpen, onConfirm, onCancel, isLeave) => (
              <React.Fragment>
                <Modal isOpen={isOpen} style={customStyles}>
                  <div>
                    <p>
                      {isLeave
                        ? "Suspicious activity detected. Your interview will be submitted. "
                        : "Switching tabs is not permitted."}
                    </p>
                    {isLeave && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          borderRadius: "10px",
                          width: "80%",
                          margin: "auto",
                        }}
                      >
                        <button
                          onClick={onConfirm}
                          style={{
                            background: "#5f5dbd",
                            borderRadius: "50px",
                            border: "none",
                            padding: "10px 20px",
                            color: "#fff",
                            cursor: "pointer",
                          }}
                        >
                          Submit
                        </button>
                      </div>
                    )}
                    {!isLeave && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          borderRadius: "10px",
                          width: "80%",
                          margin: "auto",
                        }}
                      >
                        <button
                          onClick={onCancel}
                          style={{
                            background: "#5f5dbd",
                            borderRadius: "50px",
                            border: "none",
                            padding: "10px",
                            color: "#fff",
                            cursor: "pointer",
                          }}
                        >
                          Ok
                        </button>
                        <button
                          onClick={onConfirm}
                          style={{
                            background: "#5f5dbd",
                            borderRadius: "50px",
                            border: "none",
                            padding: "10px 20px",
                            color: "#fff",
                            cursor: "pointer",
                          }}
                        >
                          leave
                        </button>
                      </div>
                    )}
                  </div>
                </Modal>
              </React.Fragment>
            )}
          </NavigationPrompt>

          {alert && interviewSubmited && (
            <SuccessAlert show={true} msg={msg} confirm={confirmAlert} />
          )}

          {alert && errorMsg && (
            <ErrorAlert show={true} msg={errorMsg} confirm={confirmAlert} />
          )}

          {warningAlert && (
            <ErrorAlert
              show={true}
              msg={"Please Enable Microphone & Camera Permissions"}
              confirm={() => setWarningAlert(false)}
            />
          )}

          {interviewStarted && (
            <div className={classes.questionIndexs}>
              {totalQuestionNumber <
                mcqQuestions?.length + skillBasedQuestions?.length &&
                !showPreview ? (
                <div>
                  <div className={classes.questionText}>Questions:</div>
                  <div className={classes.questionIndex}>{indexQuestion}</div>
                </div>
              ) : (
                <div className={classes.questionText}>Preview Video</div>
              )}
            </div>
          )}

          {mcqEnable && interviewStarted && (
            <MCQ
              questions={mcqQuestions}
              submitted={finalMCQHandler}
              currentQuestionNumber={nextQuestionNumberHandler}
            />
          )}

          {speechEnable && !showPreview && interviewStarted && (
            <SkillsBased
              questions={skillBasedQuestions}
              submitted={finalSpeechHandler}
              currentQuestionNumber={nextQuestionNumberHandler}
              recordedVideo={recordingVideoHandler}
              videoData={videoDataHandler}
            />
          )}

          {showPreview && interviewStarted && (
            <VideoPreview
              submitApi={submitApiHandler}
              recordedVideo={videoRef}
            />
          )}

          {!interviewStarted && !interviewCompleated && (
            <div className={classes.instruction}>
              To Start the Interview Please click on below button
            </div>
          )}

          {!interviewStarted && !interviewCompleated && (
            <div className={classes.submitBtn} style={{ marginTop: "20vh" }}>
              <button onClick={startInterview}>Start Interview</button>
            </div>
          )}
        </div>
      </>
    );
  }
};

export default MainInterview;
