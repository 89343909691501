import React, { useEffect, useState } from "react";
import classes from "./AppliedJobs.module.css";
import { withRouter } from "react-router-dom";
import TableWrapper from "../../../common/TableWrapper/TableWrapper";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../Store/Actions/index";
import * as actionTypes from "../../../Store/Actions/ActionTypes";
import Loader from "../../../common/Loader/Loader";
import Utility from "../../../Utility/Utility";
import CandidateAppDetailBox from "../../../components/CandidateAppDetailBox/CandidateAppDetailBox";


const AppliedJobs = ({ history }) => {
  const dispatch = useDispatch();
  const [activeDetailBoxId, setActiveDetailBoxId] = useState("");
  const [detailBoxActive, setDetailBoxActive] = useState(false);

  const { errorMsg, appliedJobs } = useSelector((state) => ({
    errorMsg: state.CandidateReducer.errorMsg,
    appliedJobs: state.CandidateReducer.appliedJobs,
  }));

  useEffect(() => {
    window.scrollTo(0,0);
    dispatch(actions.getCandidateApplications());
  }, []);

  useEffect(() => {
    return () => {
      dispatch({ type: actionTypes.TRASH });
    };
  }, []);

  const viewDetails = (id) => {
    setActiveDetailBoxId(id);
    setDetailBoxActive(true);
  };

  const closeDetailBox = () => {
    setActiveDetailBoxId("");
    setDetailBoxActive(false);
    dispatch({ type: actionTypes.CLEAR });
  };

  if (!appliedJobs) {
    return <Loader />;
  } else {
    return (
      <div className={classes.appliedJobs}>
        <TableWrapper text="Applied Jobs" count={appliedJobs.length}>
          {detailBoxActive && (
            <CandidateAppDetailBox
              applicationId={activeDetailBoxId}
              closeDetailBox={closeDetailBox}
            />
          )}
          {appliedJobs.length === 0 && (
            <div className={classes.noData}>Sorry No applied jobs</div>
          )}
          {appliedJobs.length > 0 && (
            <div className={classes.body}>
              <div className={classes.head}>
                {/* <div>Employer</div> */}
                <div>Designation</div>
                <div>Applied Date</div>
                <div>Job Type</div>
                <div>Details</div>
                <div>Application Status</div>
              </div>
              <div className={classes.scrollBar} style={{overflow:"auto",height:"300px"}}>
              {appliedJobs.map((item) => {
                return (
                  <div className={classes.data}>
                    {/* <div>Employer</div> */}
                    <div className={classes.mob}>
                      <div className={classes.title}>Designation</div>
                      <div>{item.role}</div>
                    </div>
                    <div className={classes.mob}>
                      <div className={classes.title}>Applied Date</div>
                      <div>{Utility.formatDate(item.appliedDate)}</div>
                    </div>
                    <div className={classes.mob}>
                      <div className={classes.title}>Job Type</div>
                      <div>{item.type}</div>
                    </div>
                    <div
                      className={`${classes.viewDetaill} ${classes.mob}`}
                      onClick={() => viewDetails(item.applicationId)}
                    >
                      <div className={classes.title}>Details</div>
                      <div>View Details</div>
                    </div>
                    <div className={classes.mob}>
                      <div className={classes.title}>Application Status</div>
                      <div>
                        {item.applicationStatus === "PENDING" ? (
                          <span className={classes.pending}>Pending</span>
                        ) : item.applicationStatus === "SCHEDULED" ? (
                          <span className={classes.accecpt}>AI Interview Scheduled</span>
                        ) :item.applicationStatus === "REJECTED"?<span className={classes.reject}>Rejected</span> :(
                          <span className={classes.accecpt}>COMPLETED</span>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}</div>
            </div>
          )}
        </TableWrapper>
      </div>
    );
  }
};

export default withRouter(AppliedJobs);
