import React, { useEffect, useState } from "react";
import classes from "./JobDescriptionCard.module.css";
import Description from "./Description/Description";
import Skills from "./Skills/Skills";
import Questions from "./Questions/Questions";
import PersonalMessage from "./PersonalMessage/PersonalMessage";
import OnBoardingRequirements from "./OnBoardingRequirements/OnBoardingRequirements";
import MiscInformation from "./MiscInformation/MiscInformation";
import Utility from "../../Utility/Utility";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../Store/Actions/index";
import Loader from "../../common/Loader/Loader";
import { getRequest } from "../../Helper/apiHit";
import { useHistory } from "react-router-dom";

const JobDescriptionCard = ({ jobDetail, roleType, openApplyJobBox }) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("jobDescription");
  const [image, setImage] = useState("");
  let history = useHistory();

  const { masterCountryList, masterStateList, masterCityList } = useSelector(
    (state) => ({
      masterCountryList: state.MasterReducer.masterCountryList,
      masterStateList: state.MasterReducer.masterStateList,
      masterCityList: state.MasterReducer.masterCityList,
      questions: state.MasterReducer.questions,
    })
  );

  if (jobDetail) {
    getRequest(
      `users/getAvatar?avatarPath=${jobDetail.employerId.userId.avatarPath}`
    )
      .then(function (res) {
        if (res.data.responseStatus.statusCode === 0) {
          setImage(res.data.responseData);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  if (!jobDetail) {
    return <Loader />;
  } else {
    return (
      <div className={classes.jobDescriptionCard}>
        <div className={classes.descTop}>
          <div className={classes.top}>
            <span>{jobDetail.role && jobDetail.role}</span>&nbsp;|&nbsp;
            <span>
              Posted On: {Utility.formatDate(jobDetail.publishedDate)}
            </span>
          </div>
          <div className={classes.middle}>
            <i className="fa fa-map-marker" aria-hidden="true"></i>&nbsp;&nbsp;
            <span>
              {jobDetail.workLocation &&
                Utility.getLocation(
                  masterCountryList,
                  masterStateList,
                  masterCityList,
                  jobDetail.workLocation
                )}
            </span>
          </div>
          <div className={classes.bottom}>
            <i className="fa fa-clock-o" aria-hidden="true"></i>&nbsp;&nbsp;
            <span>
              Contract Period:{" "}
              {jobDetail.startDate === null && jobDetail.endDate === null
                ? "Not applicable"
                : jobDetail.startDate == 0
                ? `${jobDetail.endDate} ${
                    jobDetail.endDate > 1 ? "months" : "month"
                  }`
                : `${jobDetail.startDate} ${
                    jobDetail.startDate > 1 ? "yrs" : "yr"
                  } 
                  ${jobDetail.endDate} ${
                    jobDetail.endDate > 1 ? "months" : "month"
                  }`}
            </span>
          </div>
          <div style={{ textAlign: "right", marginTop: "-50px" }}>
            {history.location.pathname === "/open-jobs" ? (
              <>
                {!jobDetail.isApplied && (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      openApplyJobBox(
                        image,
                        jobDetail.role,
                        Utility.getLocation(
                          masterCountryList,
                          masterStateList,
                          masterCityList,
                          jobDetail.workLocation
                        ),
                        jobDetail._id,
                        jobDetail.miscInfoId.whyShouldHire
                      );
                    }}
                  >
                    Apply
                  </button>
                )}
                {jobDetail.isApplied && "Applied"}
              </>
            ) : null}
          </div>
        </div>
        <div className={classes.descDetail}>
          <div className={classes.tabs}>
            <div
              onClick={(e) => {
                setActiveTab("jobDescription");
              }}
            >
              Job Description
              {activeTab === "jobDescription" && <div></div>}
            </div>
            <div onClick={() => setActiveTab("skills")}>
              Skills
              {activeTab === "skills" && <div></div>}
            </div>
            <div onClick={() => setActiveTab("miscInfo")}>
              Misc Information
              {activeTab === "miscInfo" && <div></div>}
            </div>
            {roleType !== "candidate" && (
              <div onClick={() => setActiveTab("questions")}>
                Questions
                {activeTab === "questions" && <div></div>}
              </div>
            )}

            {/* <div onClick={() => setActiveTab("location")}>
              Location
              {activeTab === "location" && <div></div>}
            </div> */}
            <div onClick={() => setActiveTab("personalMessage")}>
              Personal Message
              {activeTab === "personalMessage" && <div></div>}
            </div>
            <div onClick={() => setActiveTab("onboarding")}>
              Onboarding
              {activeTab === "onboarding" && <div></div>}
            </div>
          </div>
          <div className={classes.details}>
            {activeTab === "jobDescription" && (
              <Description
                id={jobDetail.alternateJobId}
                type={jobDetail.type}
                description={jobDetail.description}
                education={jobDetail.educationId.name}
                interviewProcess={jobDetail.interviewProcessId}
                companyType={jobDetail.sectorId.name}
              />
            )}
            {activeTab === "skills" && (
              <Skills skill={jobDetail.skillMatrixId.skill} />
            )}
            {activeTab === "miscInfo" && (
              <MiscInformation
                miscInfo={jobDetail.miscInfoId}
                roleType={roleType}
              />
            )}
            {activeTab === "questions" && (
              <Questions questions={jobDetail.qualifyingQuestionId} />
            )}
            {activeTab === "personalMessage" && (
              <PersonalMessage personalMessage={jobDetail.uploadedData} />
            )}
            {activeTab === "onboarding" && (
              <OnBoardingRequirements
                boardingRequirement={jobDetail.boardingId}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
};

export default JobDescriptionCard;
