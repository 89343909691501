import * as actionTypes from "../Actions/ActionTypes";

const initialState = {};

const AiInterviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CLEAR:
      return {
        ...state,
        errorMsg: undefined,
        interviewVideoSubmitted: undefined,
        interviewSubmited: undefined,
        msg: undefined,
        candidateFeedback:undefined
      };
    case actionTypes.GET_QUESTIONS_FOR_INTERVIEW:
      return {
        ...state,
        questionsForInterview: action.payload,
      };
    case actionTypes.GET_QUESTIONS_FOR_INTERVIEW_FAIL:
      return {
        ...state,
        errorMsg: action.payload,
      };
    case actionTypes.SUBMIT_ANSWER_VIDEO:
      return {
        ...state,
        interviewVideoSubmitted: true,
      };
    case actionTypes.AUDIO_UPLOADED:
      return {
        ...state,
        audioUploaded: true,
        audioPath: action.payload,
      };
    case actionTypes.AUDIO_UPLOADED_FAIL:
      return {
        ...state,
        errorMsg: action.payload,
      };
    case actionTypes.SUBMIT_CANDIDATE_INTERVIEW:
      return {
        ...state,
        interviewSubmited: true,
        msg: "Interview submitted successfully",
      };
    case actionTypes.SUBMIT_CANDIDATE_INTERVIEW_FAIL:
      return {
        ...state,
        errorMsg: action.payload,
      };
    case actionTypes.GET_CANDIDATE_FEEDBACK_SUCCESS:
      return {
        ...state,
        candidateFeedback: action.payload,
      };
    case actionTypes.GET_CANDIDATE_FEEDBACK_FAIL:
      return {
        ...state,
        candidateFeedback: [],
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default AiInterviewReducer;
