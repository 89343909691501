import React, { useEffect, useRef, useState } from "react";
import classes from "./ApplyJobCard.module.css";
import ModalWrapper from "../../common/ModalWrapper/ModalWrapper";
import * as actions from "../../Store/Actions/index";
import * as actionTypes from "../../Store/Actions/ActionTypes";
import { useSelector, useDispatch } from "react-redux";
import upload from "../../Assets/Images/upload.png";
import Toast from "../../common/Toast/Toast";
import resumeImg from "../../Assets/Images/cv.png";
import SuccessAlert from "../../common/SuccessAlert/SuccessAlert";
import ErrorAlert from "../../common/ErrorAlert/ErrorAlert";

const ApplyJobCard = ({ closeBox, employerDetail }) => {
  const dispatch = useDispatch();
  const videoRef = useRef();
  const fileRef = useRef();
  const [videoUrl, setVideoUrl] = useState("");
  const [videoObject, setVideoObject] = useState("");
  const [error, setError] = useState(false);
  const [fileName, setFileName] = useState("");
  const [resumeObject, setResumeObject] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const [alert, setAlert] = useState(false);
  const {
    masterCountryList,
    masterStateList,
    masterCityList,
    candidateProfile,
    isProfileAvailable,
    videoSubmittedUrl,
    errorMsg,
    jobApplied,
    jobId,
  } = useSelector((state) => ({
    masterCountryList: state.MasterReducer.masterCountryList,
    masterStateList: state.MasterReducer.masterStateList,
    masterCityList: state.MasterReducer.masterCityList,
    candidateProfile: state.CandidateReducer.candidateProfile,
    isProfileAvailable: state.CandidateReducer.isProfileAvailable,
    videoSubmittedUrl: state.CandidateReducer.videoSubmittedUrl,
    errorMsg: state.CandidateReducer.errorMsg,
    jobApplied: state.CandidateReducer.jobApplied,
    jobId: state.CandidateReducer.jobId,
  }));

  useEffect(() => {
    if (!candidateProfile) {
      dispatch(actions.getCandidateProfile());
    }
  }, []);

  useEffect(() => {
    if (videoSubmittedUrl || videoSubmittedUrl === "") {
      dispatch({ type: actionTypes.CLEAR });
      dispatch(
        actions.sendCandidateApplication({
          jobId: employerDetail.jobId,
          isTravelPreferred: data.willingToTravel === "Yes" ? true : false,
          isReloactionPreferred:
            data.willingToRelocate === "Yes" ? true : false,
          candidatePitch: videoSubmittedUrl,
        })
      );
    }
  }, [videoSubmittedUrl]);

  useEffect(() => {
    if (jobApplied || errorMsg) {
      setIsLoading(false);
      setAlert(true);
      // if(jobApplied){
      //   closeBox();
      // }
      // setToast(
      //   true,
      //   setTimeout(() => {
      //     dispatch({ type: actionTypes.CLEAR });
      //     if (jobApplied) {
      //       dispatch({ type: actionTypes.CHNAGE_STATE, payload: jobId });
      //     }
      //     closeBox();
      //     setToast(false);
      //   }, 3000)
      // );
    }
  }, [jobApplied, errorMsg]);

  const handleVideo = (e) => {
    if (e.target.files) {
      if (
        e.target.files[0].type === "video/mp4" ||
        e.target.files[0].type === "video/quicktime" ||
        e.target.files[0].type === "video/x-matroska"
      ) {
        setError(false);
        setVideoUrl(URL.createObjectURL(e.target.files[0]));
        setVideoObject(e.target.files[0]);
      } else {
        alert("File format not supported");
      }
    } else {
      videoRef.current.value = "";
    }
  };

  const textFormatter = (str) => {
    if (str.length > 30) {
      return `${str.substring(0, 20)}...`;
    } else {
      return str;
    }
  };

  const handleResume = (e) => {
    if (e.target.files) {
      setFileName(URL.createObjectURL(e.target.files[0]));
      setResumeObject(e.target.files[0]);
    }
  };

  const handleFieldChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const removeFile = () => {
    setVideoUrl("");
    setVideoObject("");
    if (videoRef.current !== null) {
      videoRef.current.value = "";
    }
  };

  const submitApplication = (e) => {
    e.preventDefault();
    // if (!videoObject) {
    //   setError(true);
    // } else {
    setIsLoading(true);
    let video = null;
    let resume = null;
    if (resumeObject) {
      resume = new FormData();
      resume.append("productImage", resumeObject);
    }
    if (videoObject) {
      video = new FormData();
      video.append("pitch", videoObject);
    }
    dispatch(actions.uploadApplyJobsData({ resume, video }));
    // }
  };

  const clearImage = () => {
    setFileName("");
    setResumeObject("");
    if (fileRef.current != null) {
      fileRef.current.value = "";
    }
  };

  useEffect(() => {
    if (candidateProfile) {
      setFileName(candidateProfile.filesUrl[1]);
    }
  }, [candidateProfile]);

  const confirm = () => {
    setAlert(false);
    dispatch({ type: actionTypes.CLEAR });
    if (jobApplied) {
      closeBox();
      dispatch({ type: actionTypes.CHNAGE_STATE, payload: jobId });
    } else if (errorMsg) {
      closeBox();
    }
  };

  return (
    <ModalWrapper>
      {
        alert && errorMsg && (
          <ErrorAlert show={true} msg={errorMsg} confirm={confirm} />
        )
        // <Toast display={toast} type="danger" message={errorMsg} />
      }
      {alert && jobApplied && (
        <SuccessAlert
          show={true}
          msg="Job application applied successfully."
          confirm={confirm}
        />
        // <Toast
        //   display={toast}
        //   type="success"
        //   message="job applied successfully"
        // />
      )}
      <div
        className={classes.applyBox}
        style={{ opacity: (jobApplied || errorMsg) && "0" }}
      >
        {(!candidateProfile || isLoading) && (
          <div className={classes.boxLoader}>Please wait...</div>
        )}
        {candidateProfile && !isLoading && (
          <React.Fragment>
            <div className={classes.head}>
              <div>Schedule Interview</div>
              <div>
                <i className="fa fa-times" onClick={closeBox}></i>
              </div>
            </div>
            <form onSubmit={(e) => submitApplication(e)}>
              <div className={classes.body}>
                <div className={classes.applyBoxTop}>
                  <div className={classes.img}>
                    <img
                      src={employerDetail.employerImg}
                      alt="emeployer-logo"
                    />
                  </div>
                  <div className={classes.applyBoxTopDetail}>
                    <div className={classes.name}>
                      {employerDetail.jobTitile}
                    </div>
                    <div className={classes.location}>
                      <div>
                        <i className="fa fa-map-marker" aria-hidden="true"></i>
                      </div>
                      &nbsp;
                      <div>{employerDetail.jobLocation}</div>
                    </div>
                  </div>
                </div>
                <div className={classes.data}>
                  <div>Name</div>
                  <div>{candidateProfile.profileDetail.name}</div>
                </div>
                <div className={classes.data}>
                  <div>Email</div>
                  <div>{candidateProfile.profileDetail.email}</div>
                </div>
                <div className={classes.data}>
                  <div>Contact No.</div>
                  <div>{candidateProfile.profileDetail.mobile}</div>
                </div>
                <div className={classes.data}>
                  <div>CV/Resume</div>
                  <div className={classes.resume}>
                    {fileName && (
                      <div className={classes.resumeBox}>
                        <i className="fa fa-times" onClick={clearImage}></i>
                        <img src={resumeImg} />
                      </div>
                    )}

                    {!fileName && (
                      <div>
                        Update Resume
                        <input
                          type="file"
                          required={fileName ? false : true}
                          ref={fileRef}
                          accept="application/pdf,application/msword,.doc, .docx"
                          onChange={handleResume}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className={classes.radioData}>
                  <div>Are you willing to travel?</div>
                  <div className={classes.radio}>
                    <div>
                      <input
                        type="radio"
                        value="yes"
                        name="willingToTravel"
                        required
                        onChange={handleFieldChange}
                      />
                      &nbsp;Yes
                    </div>
                    <div>
                      <input
                        type="radio"
                        value="no"
                        name="willingToTravel"
                        required
                        onChange={handleFieldChange}
                      />
                      &nbsp;No
                    </div>
                  </div>
                </div>
                <div className={classes.radioData}>
                  <div>Are you willing to relocate?</div>
                  <div className={classes.radio}>
                    <div>
                      <input
                        type="radio"
                        value="yes"
                        name="willingToRelocate"
                        required
                        onChange={handleFieldChange}
                      />
                      &nbsp;Yes
                    </div>
                    <div>
                      <input
                        type="radio"
                        value="no"
                        name="willingToRelocate"
                        required
                        onChange={handleFieldChange}
                      />
                      &nbsp;No
                    </div>
                  </div>
                </div>
                {employerDetail.whyShouldHire === "true" && (
                  <div className={classes.videoBox}>
                    <div>Why must we hire you?</div>
                    {!videoUrl && (
                      <div
                        className={classes.video}
                        style={{ border: error && "2px dashed red" }}
                      >
                        <div>
                          <img src={upload} />
                        </div>
                        <div className={classes.videoText}>
                          Drag & Drop your Video here or Browse.
                        </div>
                        <input
                          type="file"
                          name="whyHire"
                          accept="video/mp4,video/quicktime,video/x-matroska"
                          ref={videoRef}
                          onChange={handleVideo}
                        />
                      </div>
                    )}
                    {videoUrl && (
                      <div className={classes.showVideo}>
                        <i
                          class="fa fa-times"
                          aria-hidden="true"
                          onClick={removeFile}
                        ></i>
                        <video className={classes.videoSource} controls>
                          <source src={videoUrl} type="video/mp4" />
                        </video>
                      </div>
                    )}
                    <div
                      style={{
                        opacity: ".6",
                        fontSize: ".9em",
                        width: "95%",
                        margin: "auto",
                        boxSizing: "border-box",
                      }}
                    >
                      Only mp4, mov and mkv files are supported
                    </div>
                  </div>
                )}
                <div className={classes.termsAndConditions}>
                  <div>
                    <input type="checkbox" required />
                  </div>
                  <div>
                    I agree to <span>Terms & Conditions</span>
                  </div>
                </div>
                <div className={classes.submitButton}>
                  <button>Apply Job</button>
                </div>
              </div>
            </form>
          </React.Fragment>
        )}
      </div>
    </ModalWrapper>
  );
};

export default ApplyJobCard;
