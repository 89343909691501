export default {
  // isMobile
  isMobile: function () {
    let check = false;
    (function (a) {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
          a
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  },
  parseJwt: (token) => {
    if (token === null) {
      return;
    }
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  },
  getPerecentage: (total, newValue) => {
    let percentage = 0;
    if (total !== 0 && newValue !== 0) {
      percentage = (newValue / total) * 100;
    }
    return percentage;
  },
  formatDate: function (datStr) {
    const monthNames = [
      "Jan",
      "Feb",
      "March",
      "Apr",
      "May",
      "June",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const d = new Date(datStr);
    return (
      d.getDate() + " " + monthNames[d.getMonth()] + " " + " " + d.getFullYear()
    );
  },
  formatDateMilisecond: function (datStr) {
    const monthNames = [
      "Jan",
      "Feb",
      "March",
      "Apr",
      "May",
      "June",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const d = new Date(parseInt(datStr));
    return (
      d.getDate() + " " + monthNames[d.getMonth()] + " " + " " + d.getFullYear()
    );
  },
  formatTime: function (date) {
    if (date) {
      let d = date.split(":");
      let median = "";
      let h;
      let m;
      if (parseInt(d[0]) > 12) {
        median = "PM";
      } else {
        median = "AM";
      }
      if (parseInt(d[0]) > 12) {
        h = parseInt(d[0]) - 12;
      } else {
        h = parseInt(d[0]);
      }
      if (parseInt(d[0]) < 10) {
        h = "0" + parseInt(d[0]);
      }
      if (parseInt(d[1]) < 10) {
        m = "0" + parseInt(d[1]);
      } else {
        m = parseInt(d[1]);
      }

      return h + ":" + m + " " + median;
    }
  },
  setMinDate: function (date) {
    let d = new Date(date);

    return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
  },
  getLocation: (masterCountryList, masterStateList, masterCityList, data) => {
    let city = "";
    let state = "";
    let country = "";
    if (data !== null) {
      if (masterCountryList) {
        masterCountryList.map((item) => {
          if (data.countryId === item._id) {
            country = item.name;
          }
        });
      }
      if (masterStateList) {
        masterStateList.map((item) => {
          if (data.stateId === item._id) {
            state = item.name;
          }
        });
      }
      if (masterCityList) {
        masterCityList.map((item) => {
          if (data.cityId === item._id) {
            city = item.name;
          }
        });
      }
    }

    return `${city}, ${state}, ${country}`;
  },
  getQuestionName: (masterQuestion, questionId) => {
    let questionName = "";
    if (masterQuestion) {
      masterQuestion.map((item) => {
        if (item._id === questionId) {
          questionName = item.name;
        }
      });
    }
    return questionName;
  },
};
