import React from "react";
import classes from "./DeleteDraftCard.module.css";
import draft from "../../Assets/Images/draft.png";
import post from "../../Assets/Images/postjob.png";
import deleteJob from "../../Assets/Images/delete.png";
import { withRouter } from "react-router-dom";

const DeleteDraftCard = ({ history, deletedJobs = 0, draftedJobs = 0 }) => {
  const sendToDeletedJobs = () => {
    history.push("/deleted-jobs");
  };

  const sendToDraftedJobs = () => {
    history.push("/drafted-jobs");
  };

  const sendToCreateJob = () => {
    localStorage.setItem("isUpdate","NO")
    history.push("/create-job")
  };

  return (
    <div className={classes.deleteDraft}>
      <div className={classes.deleteDraftItem} onClick={sendToDeletedJobs}>
        <img src={deleteJob} />
        <br />
        <span>{deletedJobs}</span>
        <br />
        <span>Deleted Jobs</span>
      </div>
      <div className={classes.deleteDraftItem} onClick={sendToDraftedJobs}>
        <img src={draft} />
        <br />
        <span>{draftedJobs}</span>
        <br />
        <span>Draft Jobs</span>
      </div>
      <div className={classes.deleteDraftItem}>
        <img src={post} />
        <br />
        <button onClick={sendToCreateJob}>Post A Job</button>
      </div>
    </div>
  );
};

export default withRouter(DeleteDraftCard);
