import React from 'react';
import classes from './Skills.module.css';
import Skill from './Skill';

const Skills = ({skill}) => {
    return(
        <div className={classes.skills}>
           <div className={classes.title}>Skills</div>
            {
                skill && skill.map(item=>{
                    return <Skill skillData={item}/>
                })
            }
        </div>
    )
}

export default Skills;