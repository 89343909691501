export default {
    mothOptions:[
        // {value:'0',name:'0 Month'},
        {value:'1',name:'1 Month'},
        {value:'2',name:'2 Months'},
        {value:'3',name:'3 Months'},
        {value:'4',name:'4 Months'},
        {value:'5',name:'5 Months'},
        {value:'6',name:'6 Months'},
        {value:'7',name:'7 Months'},
        {value:'8',name:'8 Months'},
        {value:'9',name:'9 Months'},
        {value:'10',name:'10 Months'},
        {value:'11',name:'11 Months'}
    ],
    yearOptions:[
        {value:'0',name:'0 Year'},
        {value:'1',name:'1 Year'},
        {value:'2',name:'2 Years'},
        {value:'3',name:'3 Years'},
        {value:'4',name:'4 Years'},
        {value:'5',name:'5 Years'},
        {value:'6',name:'6 Years'},
        {value:'7',name:'7 Years'},
        {value:'8',name:'8 Years'},
        {value:'9',name:'9 Years'},
        {value:'10',name:'10 Years & Above'}
    ],
    startSalary:[
        {value:'10,000',name:'10,000'},
        {value:'11,000',name:'11,000'},
        {value:'12,000',name:'12,000'},
        {value:'13,000',name:'13,000'},
        {value:'14,000',name:'14,000'},
        {value:'15,000',name:'15,000'},
        {value:'16,000',name:'16,000'},
        {value:'17,000',name:'17,000'},
        {value:'18,000',name:'18,000'},
        {value:'19,000',name:'19,000'}
    ],
    endSalary:[
        {value:'10,000',name:'10,000'},
        {value:'11,000',name:'11,000'},
        {value:'12,000',name:'12,000'},
        {value:'13,000',name:'13,000'},
        {value:'14,000',name:'14,000'},
        {value:'15,000',name:'15,000'},
        {value:'16,000',name:'16,000'},
        {value:'17,000',name:'17,000'},
        {value:'18,000',name:'18,000'},
        {value:'19,000',name:'19,000'},
        {value:'20,000',name:'20,000'},
        {value:'21,000',name:'21,000'},
        {value:'22,000',name:'22,000'},
        {value:'23,000',name:'23,000'},
        {value:'24,000',name:'24,000'},
        {value:'25,000',name:'25,000'},
        {value:'26,000',name:'26,000'},
        {value:'27,000',name:'27,000'},
        {value:'28,000',name:'28,000'},
        {value:'29,000',name:'29,000'},
        {value:'30,000',name:'30,000'},
    ],
    personalQuestion:[
        {id:'1',value:'Tell me about yourself?'},
        {id:'2',value:'Tell me about your previous experience?'}
    ]
}