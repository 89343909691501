import React, { useEffect, useState, useRef } from "react";
import classes from "./PersonalMessage.module.css";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../Store/Actions/index";
import * as actionTypes from "../../../Store/Actions/ActionTypes";
import Toast from "../../../common/Toast/Toast";
import Loader from "../../../common/Loader/Loader";
import ErrorAlert from "../../../common/ErrorAlert/ErrorAlert";

const PersonalMessage = ({
  personalMessageUrl,
  handlePersonalMessage,
  personalMessageToSend,
  setPreviousPage,
  role,
  setNextPage,
  remove,
}) => {
  let dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [error, setError] = useState(false);
  const fileRef = useRef();

  const { refJobId, errorMsg, personalMessageSubmitted } = useSelector(
    (state) => ({
      errorMsg: state.CreateJobReducer.errorMsg,
      refJobId: state.CreateJobReducer.refJobId,
      personalMessageSubmitted: state.CreateJobReducer.personalMessageSubmitted,
    })
  );

  useEffect(() => {
    window.scrollTo(0,0);
  },[])

  const submitPersonalMessage = () => {
    if (!personalMessageUrl || personalMessageUrl === "") {
      setError(true);
    } else if (!personalMessageToSend || personalMessageToSend === "") {
      setNextPage("preview");
    } else {
      setIsLoading(true);
      setError(false);
      dispatch({ type: actionTypes.CLEAR });
      let formData = new FormData();
      formData.append("file", personalMessageToSend);
      dispatch(actions.uploadVideo(formData, refJobId));
    }
  };

  useEffect(() => {
    if (personalMessageSubmitted) {
      setIsLoading(false);
      dispatch({ type: actionTypes.CLEAR });
      setNextPage("preview");
    } else if (errorMsg) {
      setIsLoading(false);
      setAlert(true);
      // setToast(
      //   true,
      //   setTimeout(() => {
      //     setToast(false);
      //   }, 3000)
      // );
    }
  }, [errorMsg, personalMessageSubmitted]);

  useEffect(() => {
    if (personalMessageUrl) {
      setError(false);
    }
  }, [personalMessageUrl]);

  const removeFile = () => {
    if(fileRef.current != null){
      fileRef.current.value = "";
      
    }
    remove();
  };

  const confirm = () => {
    setAlert(false);
  };

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <React.Fragment>
        <div className={classes.personalMessage}>
          {
            alert && errorMsg && (
              <ErrorAlert show={true} msg={errorMsg} confirm={confirm} />
            )
            // <Toast display={toast} type="danger" message={errorMsg} />
          }
          <div className={classes.personalMessageTop}>
            <div>
              {`A message from the company's hiring manager regarding ${role} posting.`}
            </div>
          </div>
          
          {personalMessageUrl && (
            <div className={classes.personalMessageBody}>
              <i
                class="fa fa-times"
                aria-hidden="true"
                onClick={removeFile}
              ></i>
              <video className={classes.video} controls>
                <source src={personalMessageUrl} type="video/mp4" />
              </video>
            </div>
          )}
          {!personalMessageUrl && (
            <div className={classes.personalText}>
              <i class="fa fa-file-video-o" aria-hidden="true"></i>
            </div>
          )}
          {
            !personalMessageUrl && 
             <div
          className={classes.personalBottom}>
            <div className={classes.bottomText}>Only mp4, mov and mkv files are supported</div>
            
            <div
              className={classes.uplaod}
              style={{ pointerEvents: personalMessageUrl && "none" }}
            >
              <div className={classes.uploadText}>Upload video</div>
              <input
                ref={fileRef}
                type="file"
                // accept="video/mp4,video/quicktime,video/x-matroska"
                onChange={handlePersonalMessage}
              />
            </div>
          </div>
          }
         
          <div className={classes.error}>
            {error && "Please select a video"}
          </div>
        </div>
        <div className={classes.skillFooter}>
          <div onClick={() => setPreviousPage("onBoarding")}>Back</div>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <button onClick={submitPersonalMessage}>Next</button>
        </div>
      </React.Fragment>
    );
  }
};

export default PersonalMessage;
