import React from "react";
import classes from "./OnBoardingRequrements.module.css";



const OnBoardingRequirement = ({index , boardingRequirement}) => {

  return (
    <div className={classes.onBoardingRequirement}>
      {index + 1}. {boardingRequirement.name}
    </div>
  );
};

export default OnBoardingRequirement;
