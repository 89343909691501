import React, { useState, useEffect } from "react";
import classes from "./OnBoarding.module.css";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../Store/Actions/index";
import * as actionTypes from "../../../Store/Actions/ActionTypes";
import Toast from "../../../common/Toast/Toast";
import Loader from "../../../common/Loader/Loader";
import ErrorAlert from "../../../common/ErrorAlert/ErrorAlert";

const OnBoarding = ({
  setPreviousPage,
  handleOnboarding,
  setNextPage,
  onBoarding,
  allOnBoardingId,
}) => {
  let dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState(false);

  const {
    refJobId,
    errorMsg,
    masterBoardingRequirementList,
    onBoardingSubmitted,
  } = useSelector((state) => ({
    errorMsg: state.CreateJobReducer.errorMsg,
    refJobId: state.CreateJobReducer.refJobId,
    masterBoardingRequirementList:
      state.MasterReducer.masterBoardingRequirementList,
    onBoardingSubmitted: state.CreateJobReducer.onBoardingSubmitted,
  }));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const submitOnBoarding = (e) => {
    e.preventDefault();
    dispatch({ type: actionTypes.CLEAR });
    setIsLoading(true);
    dispatch(
      actions.submitOnBoardingRequirement({
        refJobId: refJobId,
        boardingListId: onBoarding,
      })
    );
  };

  useEffect(() => {
    if (onBoardingSubmitted) {
      setIsLoading(false);
      dispatch({ type: actionTypes.CLEAR });
      setNextPage("personalMessage");
    } else if (errorMsg) {
      setIsLoading(false);
      setAlert(true);
      // setToast(
      //   true,
      //   setTimeout(() => {
      //     setToast(false);
      //   }, 3000)
      // );
    }
  }, [errorMsg, onBoardingSubmitted]);

  const confirm = () => {
    setAlert(false);
  };
  let onBoardingId = [];
  const checkAll = () => {
    var checkboxes = document.getElementsByTagName("input");

    if (document.getElementById("headingCheckBox").checked == true) {
      for (var i = 0; i < checkboxes.length; i++) {
        if (
          checkboxes[i].type == "checkbox" &&
          checkboxes[i].id !== "headingCheckBox"
        ) {
          checkboxes[i].checked = true;
          onBoardingId.push(checkboxes[i].id);
        }
      }
    } else {
      for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].type == "checkbox") {
          checkboxes[i].checked = false;
        }
      }
      onBoardingId = [];
    }
    allOnBoardingId(onBoardingId);
  };

  const uncheckAll = () => {
    if(document.getElementById("headingCheckBox").checked){
      let check = document.getElementById("headingCheckBox");
      check.checked = false;
    }
  };

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <React.Fragment>
        <form onSubmit={submitOnBoarding}>
          <div className={classes.onBoarding}>
            {
              alert && errorMsg && (
                <ErrorAlert show={true} msg={errorMsg} confirm={confirm} />
              )
              // <Toast display={toast} type="danger" message={errorMsg} />
            }
            <div className={classes.onBoardingText}>
              <span>
                <input
                  type="checkbox"
                  id="headingCheckBox"
                  onChange={checkAll}
                />
                &nbsp;ConectD will comply with the following On-boarding
                requirements
              </span>
            </div>
            {masterBoardingRequirementList &&
              masterBoardingRequirementList.map((item, index) => {
                return (
                  <div className={classes.onBoardingItem}>
                    <div>
                      <input
                        type="checkbox"
                        value={item._id}
                        id={item._id}
                        onChange={(e) => {
                          handleOnboarding(e);
                          uncheckAll();
                        }}
                        required={
                          (!onBoarding || onBoarding.length === 0) && true
                        }
                        checked={
                          onBoarding &&
                          onBoarding.indexOf(item._id) !== -1 &&
                          true
                        }
                      />
                    </div>
                    &nbsp;
                    <div>{item.name}</div>
                  </div>
                );
              })}
          </div>
          <div className={classes.skillFooter}>
            <div onClick={() => setPreviousPage(localStorage.getItem("wfhRef") !== "selected" ? "workLocation" : "interviewProcess")}>Back</div>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <button>Next</button>
          </div>
        </form>
      </React.Fragment>
    );
  }
};

export default OnBoarding;
