import React from "react";
import classes from "./ConfirmModal.module.css";
import ModalWrapper from "../../common/ModalWrapper/ModalWrapper";

const ConfirmModal = ({ text, onConfirm, onCancel }) => {
  return (
    <ModalWrapper>
      <div className={classes.confirmModal}>
        <div>{text}</div>
        <div>
          <button onClick={onConfirm}>Yes</button>
          <button onClick={onCancel}>No</button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default ConfirmModal;
