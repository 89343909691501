import React, { useEffect, useState } from "react";
import classes from "./CandidateAppDetailBox.module.css";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../Store/Actions/index";
import * as actionsTypes from "../../Store/Actions/ActionTypes";
import ModalWrapper from "../../common/ModalWrapper/ModalWrapper";
import Loader from "../../common/Loader/Loader";
import Toast from "../../common/Toast/Toast";
import Utility from "../../Utility/Utility";
import parse from 'html-react-parser';
import SweetAlert from 'react-bootstrap-sweetalert';
import useVisible from '../../common/Header/useVisible';

const CandidateAppDetailBox = ({ applicationId, closeDetailBox }) => {
  const dispatch = useDispatch();

  const [toast, setToast] = useState(false);
  const { ref, isVisible, setIsVisible } = useVisible(true);

  const {
    errorMsg,
    applicationDetail,
    masterCountryList,
    masterStateList,
    masterCityList,
  } = useSelector((state) => ({
    errorMsg: state.CandidateReducer.errorMsg,
    applicationDetail: state.CandidateReducer.applicationDetail,
    masterCountryList: state.MasterReducer.masterCountryList,
    masterStateList: state.MasterReducer.masterStateList,
    masterCityList: state.MasterReducer.masterCityList,
  }));

  useEffect(() => {
    if (!masterCountryList) {
      dispatch(actions.getMasterCountryList());
    }
    if (!masterStateList) {
      dispatch(actions.getMasterStateList());
    }
    if (!masterCityList) {
      dispatch(actions.getMasterCityList());
    }

    dispatch(actions.getApplicationDetailsCandidate(applicationId));
  }, []);

  useEffect(() => {
    // setIsVisible(true)
  },[])

  useEffect(() => {
    if(!isVisible){
      closeDetailBox()
      // dispatch({ type: actionsTypes.CLEAR });
    }
  },[isVisible])

  useEffect(() => {
    if (errorMsg) {
      setToast(
        true,
        setTimeout(() => {
          setToast(false);
          dispatch({ type: actionsTypes.CLEAR });
        }, 3000)
      );
    }
  }, [errorMsg]);

  return (
    <ModalWrapper>
      {!applicationDetail && (
        <div className={classes.candidateAppDetailBox}>
          {" "}
          <Loader />
        </div>
      )}
      {errorMsg && <Toast display={toast} type="danger" message={errorMsg} />}
      {applicationDetail && isVisible && (
       
        <div className={classes.candidateAppDetailBox} ref={ref}>
          <div className={classes.data}>
            <div>
              <span className={classes.title}>Job Role:</span>&nbsp;&nbsp;
              <span>{applicationDetail.role}</span>
            </div>
            <div>
              <span className={classes.title}>Applied Date:</span>&nbsp;&nbsp;
              <span>{Utility.formatDate(applicationDetail.appliedDate)}</span>
            </div>
          </div>
          <div className={classes.dataOne}>
            <span className={classes.title}>Job Type:</span>&nbsp;&nbsp;
            <span>{applicationDetail.type}</span>
          </div>
          <div className={classes.dataOne}>
            <div className={classes.title}>Job Description:</div>
            <div>{parse(applicationDetail.description)}</div>
          </div>
          <div className={classes.dataOne}>
            <div className={classes.title}>Skills:</div>
            {applicationDetail?.skillMatrix?.skill?.map((item) => {
              return (
                <div>
                  <span>{item?.value}</span>
                  <span className={classes.skill}> ({item?.preference})</span>
                  :&nbsp;&nbsp;
                  <span>
                    Exp.
                    {item?.minExperience}
                  </span>
                </div>
              );
            })}
          </div>
          <div className={classes.dataOne}>
            <span className={classes.title}>Interview Mode:</span>&nbsp;&nbsp;
            <span>
              {
                Object.values(applicationDetail?.interviewProcess?.communicationMode).map((item,index)=>{
                  return <div>
                    <span>{index + 1}.</span> <span>{item}</span>
                  </div>
                })
              }
            </span>
          </div>
          <div className={classes.dataOne}>
            <span className={classes.title}>No of Interview Rounds:</span>
            &nbsp;&nbsp;<span>{applicationDetail?.interviewProcess?.rounds}</span>
          </div>
          <div className={classes.dataOne}>
            <span className={classes.title}>Interview Tips:</span>&nbsp;&nbsp;
            {applicationDetail?.interviewProcess?.tips?.tip === 'false' &&
              <span>Not Applicable</span>
            }
            {applicationDetail?.interviewProcess?.tips?.tip === 'true' &&
              <span>{applicationDetail?.interviewProcess?.tips?.tipText}</span>
            }
          </div>
          <div className={classes.dataOne}>
            <span className={classes.title}>Work Location:</span>&nbsp;&nbsp;
            <span>
              {Utility.getLocation(
                masterCountryList,
                masterStateList,
                masterCityList,
                applicationDetail.workLocation
              )}
            </span>
          </div>
          <div className={classes.submit}>
            <button onClick={closeDetailBox}>Ok</button>
          </div>
        </div>
        
      )}
    </ModalWrapper>
  );
};

export default CandidateAppDetailBox;
