import React from "react";
import classes from "./JobInfoCard.module.css";
import pencil from "../../Assets/Images/pencil.png";
import trash from "../../Assets/Images/trash.png";
import Utility from "../../Utility/Utility";
import { getEmployerDetails } from "../../Store/Actions";

const JobInfoCard = ({
  deleteJob,
  closeJob,
  repostJob,
  jobTitle,
  isActive,
  postedDate,
  openJob,
  id,
  cardActive,
  index,
  getJobDetails,
  sendToUpdateJob,
  roleType,
  image,
  openApplyJobBox,
  jobLocation,
  isApplied,
  hire
}) => {
  return (
    <div
      className={`${classes.jobInfoCard} ${
        cardActive ? classes.activeCard : ""
      }`}
      onClick={() => getJobDetails(index, id)}
    >
      {roleType !== "candidate" && (
        <div className={classes.infoTop}>
          <span>{jobTitle}</span>
          <button
            className={isActive ? classes.openStatus : classes.closeStatus}
          >
            {isActive ? "Open" : "Close"}
          </button>
        </div>
      )}
      {roleType === "candidate" && (
        <div className={classes.imgApply}>
          <div>
            <img src={image} alt="logo" />
          </div>
          <div>
            {
              !isApplied &&<button
              onClick={(e) => {
                e.stopPropagation();
                openApplyJobBox(image, jobTitle,jobLocation, id,hire);
              }}
            >
              Apply
            </button>
            }
            {
              isApplied && 'Applied'
            }
            
          </div>
        </div>
      )}
      {roleType === "candidate" && (
        <div className={classes.infoTop}>
          <span>{jobTitle}</span>
        </div>
      )}
      {roleType !== "candidate" && (
        <div className={classes.infoBetween}>
          Posted On: {Utility.formatDate(postedDate)}
        </div>
      )}
      {
        roleType === "candidate" &&
        <div className={classes.infoBetween}>
          Location : {jobLocation}
        </div>
      }

      {roleType !== "candidate" && <div className={classes.infoDevider}></div>}

      {roleType !== "candidate" && (
        <div className={classes.infoBottom}>
          <div className={classes.btn}>
            <button
              className={classes.btnColor}
              onClick={(e) => {
                isActive ? closeJob(id) : repostJob(id);
                e.stopPropagation();
              }}
            >
              {isActive ? "Close" : "Open"}
            </button>
          </div>
          <div className={classes.editDelete}>
            <img
              src={pencil}
              onClick={(e) => {
                sendToUpdateJob(id);
                e.stopPropagation();
              }}
            />
            <img
              src={trash}
              onClick={(e) => {
                deleteJob(id);
                e.stopPropagation();
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default JobInfoCard;
