import React from "react";
import classes from "./NewApplications.module.css";
import NewApplication from "./NewApplication";
import { withRouter } from "react-router-dom";

const NewApplications = ({ history, newApplicants = [] }) => {
  const sendToReceivedApplication = () => {
    history.push("/application-received");
  };

  return (
    <div className={classes.newApplications}>
      <div className={classes.top}>
        <span>New Applicants</span>
        <span onClick={sendToReceivedApplication} style={{color:"white"}}>See All</span>
      </div>
      {newApplicants.length === 0 && <div className={classes.noData}>No New Applicants.</div>}
      {newApplicants.length !== 0 && (
        <div className={classes.body}>
          {
            newApplicants.map(item=>{
              return <NewApplication  applicant={item} sendTo={sendToReceivedApplication}/>
            })
          }
        </div>
      )}
    </div>
  );
};

export default withRouter(NewApplications);
