import { combineReducers } from 'redux';
import AuthReducer from './AuthReducer';
import EmployerReducer from './EmployerReducer';
import CommonReducer from './CommonReducer';
import MasterReducer from './MasterReducer';
import CreateJobReducer from './CreateJobReducer';
import CandidateReducer from './CandidateReducer';
import AiInterviewReducer from './AiInterviewReducer';

const rootReducer = combineReducers({
    AuthReducer,
    EmployerReducer,
    CommonReducer,
    MasterReducer,
    CreateJobReducer,
    CandidateReducer,
    AiInterviewReducer
})



export default rootReducer;