import * as actionTypes from "./ActionTypes";
import { getRequest, postRequest } from "../../Helper/apiHit";

export const getDashboard = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.API_INITIATE });

    getRequest("employer/getEmployerDashboard")
      .then((res) => {
        if (res.data.responseStatus.statusCode === 0) {
          dispatch({
            type: actionTypes.GET_DASHBOARD_SUCCESS,
            payload: res.data.responseData,
          });
        } else {
          dispatch({
            type: actionTypes.GET_DASHBOARD_FAIL,
            payload: "Somthing went wrong",
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.GET_DASHBOARD_FAIL,
          payload: "Somthing went wrong",
        });
      });
  };
};

export const checkForProfile = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.API_INITIATE });
    getRequest("employer/checkForProfile")
      .then((res) => {
        dispatch({
          type: actionTypes.CHECK_PROFILE_SUCCESS,
          payload: res.data.responseData,
        });
      })
      .catch((e) => {});
  };
};
export const getPostedJobs = (roleType) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.API_INITIATE });
    getRequest("jobs/getPostedJobs")
      .then((res) => {
        if (res.data.responseStatus.statusCode === 0) {
          if (roleType === "candidate") {
            let imagesUrl = [];
            let failedPath = [];
            let promises = [];
            if (res.data.responseData) {
              res.data.responseData.map((item) => {
                item.employerId.userId.avatarPath &&
                  promises.push(
                    getRequest(
                      `users/getAvatar?avatarPath=${item.employerId.userId.avatarPath}`
                    )
                  );
              });
              Promise.allSettled(promises).then(async (results) => {
                await results.forEach((result) => {
                  if (result.status === "fulfilled") {
                    imagesUrl.push(result.value.data.responseData);
                  } else {
                    failedPath.push(result.value.data.responseData);
                  }
                });

                let newData = res.data.responseData.map((item, index) => {
                  let returnItem = { ...item };
                  returnItem.image = imagesUrl[index];
                  return returnItem;
                });
                dispatch({
                  type: actionTypes.GET_POSTEDJOBS_SUCCESS,
                  payload: newData,
                  images: imagesUrl,
                });
              });
            }
          } else if (roleType === "employer") {
            dispatch({
              type: actionTypes.GET_POSTEDJOBS_SUCCESS,
              payload: res.data.responseData,
            });
          }
        } else {
          dispatch({
            type: actionTypes.GET_POSTEDJOBS_FAIL,
            payload: "Somthing went wrong",
          });
        }
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.GET_POSTEDJOBS_FAIL,
          payload: "Somthing went wrong",
        });
      });
  };
};

export const closeJob = (body) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.API_INITIATE });
    postRequest("jobs/closeJob", body)
      .then((res) => {
        if (res.data.responseStatus.statusCode === 0) {
          dispatch({
            type: actionTypes.CLOSE_JOB_SUCCESS,
            jobId: body.refJobId,
          });
        } else {
          dispatch({
            type: actionTypes.CLOSE_JOB_FAIL,
            payload: "Somthing went wrong",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.CLOSE_JOB_FAIL,
          payload: "Somthing went wrong",
        });
      });
  };
};

export const repostedJob = (body) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.API_INITIATE });
    postRequest("jobs/repostJob", body)
      .then((res) => {
        if (res.data.responseStatus.statusCode === 0) {
          dispatch({
            type: actionTypes.REPOSTED_JOB_SUCCESS,
            jobId: body.refJobId,
          });
        } else {
          dispatch({
            type: actionTypes.REPOSTED_JOB_FAIL,
            payload: "Somthing went wrong",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.REPOSTED_JOB_FAIL,
          payload: "Somthing went wrong",
        });
      });
  };
};

//DELETE JOB FROM JOB LISTING
export const deleteJob = (body) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.API_INITIATE });
    postRequest("jobs/deleteJob", body)
      .then((res) => {
        if (res.data.responseStatus.statusCode === 0) {
          dispatch({
            type: actionTypes.DELETE_JOB_SUCCESS,
          });
        } else {
          dispatch({
            type: actionTypes.DELETE_JOB_FAIL,
            payload: "Somthing went wrong",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.DELETE_JOB_FAIL,
          payload: "Somthing went wrong",
        });
      });
  };
};
