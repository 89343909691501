import React, { useState, useEffect, useRef } from "react";
import classes from "./JobDetails.module.css";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../Store/Actions/index";
import * as actionTypes from "../../../Store/Actions/ActionTypes";
import { withRouter } from "react-router-dom";
import Tab from "./Tab";
// import Editer from "./Editor";
import upload from "../../../Assets/Images/upload.png";
import Data from "../../../Utility/DataGenerator";
import JoditEditor from "jodit-react";
import Loader from "../../../common/Loader/Loader";
import Toast from "../../../common/Toast/Toast";
import ErrorAlert from "../../../common/ErrorAlert/ErrorAlert";
import JoditReact from "jodit-react-ts";
import "jodit/build/jodit.min.css";

const JobDetails = ({
  jobDetail,
  setJobdetail,
  description,
  descriptionRef,
  handleDescriptionBox,
  setTextFileToJd,
  setToDescription,
  next,
  history,
}) => {
  const config = {
    style: {
      whiteSpace: "pre",
      fontFamily: "Arial",
      borderRadius: "6px",
      background: "#FAFBFC;",
    },
    readonly: false,
  };

  let dispatch = useDispatch();
  const edit = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [contractD, setContractD] = useState("");

  const { masterCompanySectorList, jobDetailSubmitted, errorMsg, refJobId } =
    useSelector((state) => ({
      masterCompanySectorList: state.MasterReducer.masterCompanySectorList,
      jobDetailSubmitted: state.CreateJobReducer.jobDetailSubmitted,
      errorMsg: state.CreateJobReducer.errorMsg,
      refJobId: state.CreateJobReducer.refJobId,
    }));

  useEffect(() => {
    // if(history.location.state && history.location.state.jobId){

    // }else{
    //   dispatch({type:actionTypes.TRASH})
    // }
    if (!masterCompanySectorList) {
      dispatch(actions.getMasterCompanySectorList());
    }
  }, []);

  const submitJobDetail = (e, jobDetail) => {
    e.preventDefault();
    let reg = /( |<([^>]+)>)/gi;
    let newDesc = description.replace(reg, "");
    if (newDesc === "") {
      edit.current.style.border = "1px solid red";
    }
    // if(jobDetail.startDate === "0" && jobDetail.endDate === "0"){
    //   setContractD("Error")
    // }
    else if (jobDetail.initialSalary) {
      setIsLoading(true);
      dispatch(
        actions.submitJobDetails({
          ...jobDetail,
          description: description,
          refJobId: refJobId,
          annualSalaryRange: `${jobDetail.initialSalary}-${jobDetail.finalSalary}`,
        })
      );
    } else {
      dispatch({ type: actionTypes.CLEAR });
      setIsLoading(true);
      dispatch(
        actions.submitJobDetails({
          ...jobDetail,
          refJobId: refJobId,
          description: description,
        })
      );
    }
  };

  useEffect(() => {
    if (jobDetailSubmitted) {
      setIsLoading(false);
      dispatch({ type: actionTypes.CLEAR });
      next("skillMatrix");
    } else if (errorMsg) {
      setIsLoading(false);
      setAlert(true);
      // setToast(
      //   true,
      //   setTimeout(() => {
      //     setToast(false);
      //   }, 3000)
      // );
    }
  }, [jobDetailSubmitted, errorMsg]);

  const confirm = () => {
    setAlert(false);
  };

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <form onSubmit={(e) => submitJobDetail(e, jobDetail)}>
        {
          alert && errorMsg && (
            <ErrorAlert show={true} msg={errorMsg} confirm={confirm} />
          )
          // <Toast display={toast} type="danger" message={errorMsg} />
        }
        <div className={classes.note}>
          <span>Note:- </span>All your jobs will be saved to draft, if you leave
          anywhere in the middle.
        </div>
        <div className={classes.jobDetails}>
          <Tab type={jobDetail.type} setJobType={setJobdetail} />
          <div className={classes.divider}></div>
          {jobDetail.type === "Direct Hire" && (
            <div className={classes.element}>
              <div className={classes.text}>1. Annual Salary Range</div>
              <div className={classes.inputField}>
                <div className={classes.field}>
                  <input
                    type="text"
                    name="salary"
                    required={!jobDetail.initialSalary && true}
                    value={jobDetail.salary}
                    onChange={setJobdetail}
                    placeholder="Fixed Rate"
                  />
                  {/* <select
                    name="initialSalary"
                    onChange={setJobdetail}
                    required
                    value={jobDetail.initialSalary}
                  >
                    <option selected disabled value="">
                      Select
                    </option>
                    {Data.startSalary.map((item) => {
                      return <option value={item.value}>{item.name}</option>;
                    })}
                  </select> */}
                </div>
                <div className={classes.divid}>Or</div>
                <div className={classes.field}>
                  <input
                    type="number"
                    name="initialSalary"
                    required={jobDetail.finalSalary && true}
                    value={jobDetail.initialSalary}
                    onChange={setJobdetail}
                    placeholder="From"
                  />
                  {/* <select
                    name="initialSalary"
                    onChange={setJobdetail}
                    required
                    value={jobDetail.initialSalary}
                  >
                    <option selected disabled value="">
                      Select
                    </option>
                    {Data.startSalary.map((item) => {
                      return <option value={item.value}>{item.name}</option>;
                    })}
                  </select> */}
                </div>
                <div className={classes.divid}>To</div>
                <div className={classes.field}>
                  <input
                    type="number"
                    name="finalSalary"
                    required={jobDetail.initialSalary && true}
                    value={jobDetail.finalSalary}
                    onChange={setJobdetail}
                    placeholder="To"
                  />
                  {/* <select
                    name="finalSalary"
                    onChange={setJobdetail}
                    required
                    value={jobDetail.finalSalary}
                  >
                    <option selected disabled value="">
                      Select
                    </option>
                    {Data.endSalary.map((item) => {
                      return <option value={item.value}>{item.name}</option>;
                    })}
                  </select> */}
                </div>
              </div>
            </div>
          )}
          {jobDetail.type !== "Direct Hire" && (
            <div className={classes.element}>
              <div className={classes.text}>1. Contract Duration</div>
              <div className={classes.inputField}>
                <div className={classes.field}>
                  <select
                    name="startDate"
                    onChange={setJobdetail}
                    required
                    value={jobDetail.startDate}
                  >
                    {Data.yearOptions.map((item) => {
                      return <option value={item.value}>{item.name}</option>;
                    })}
                  </select>
                </div>
                <div className={classes.divid}></div>
                <div className={classes.field}>
                  <select
                    name="endDate"
                    onChange={setJobdetail}
                    required
                    value={jobDetail.endDate}
                  >
                    {jobDetail.startDate !== "0" ? (
                      <option value="0">0 Month</option>
                    ) : null}
                    {Data.mothOptions.map((item) => {
                      return <option value={item.value}>{item.name}</option>;
                    })}
                  </select>
                </div>
              </div>
            </div>
          )}

          <div className={classes.element}>
            <div className={classes.text}>2. Designation</div>
            <div className={classes.inputField}>
              <input
                type="text"
                name="role"
                onChange={setJobdetail}
                required
                defaultValue={jobDetail.role}
                // pattern="^[a-zA-Z ]*$"
                title="Only alphabets allowed"
                placeholder="Enter Designation"
              />
            </div>
          </div>
          <div className={classes.element}>
            <div className={classes.text}>3. Industry Type</div>
            <div className={classes.inputField}>
              <select
                name="sectorId"
                onChange={setJobdetail}
                required
                value={jobDetail.sectorId}
              >
                <option selected disabled value="">
                  Select Industry Type
                </option>
                {!masterCompanySectorList && (
                  <option value="">Please wait...</option>
                )}
                {masterCompanySectorList &&
                  masterCompanySectorList.length === 0 && (
                    <option value="">Sorry No Company Sector Found</option>
                  )}
                {masterCompanySectorList &&
                  masterCompanySectorList.length !== 0 &&
                  masterCompanySectorList.map((item) => {
                    return <option value={item._id}>{item.name}</option>;
                  })}
              </select>
            </div>
          </div>
          <div className={classes.jd}>4. Job Description</div>
          <div className={classes.editor} ref={edit}>
            <JoditEditor
              ref={descriptionRef}
              value={description}
              config={config}
              onChange={(newContent) => handleDescriptionBox(newContent)}
              onBlur={setToDescription}
              tabIndex={1}
            />
            {/* <JoditReact defaultValue={description} onBlur={setToDescription}  onChange={(content) => handleDescriptionBox(content)}  /> */}
          </div>
          <div className={classes.sectionDevide}>Or</div>
          <div className={classes.browseSection}>
            <div>
              <img src={upload} />
            </div>
            <div>
              Drag & Drop a Job Description file Or Browse (Text file only).
            </div>
            {/* <div>Or</div> */}
            <input type="file" onChange={setTextFileToJd} accept="text/plain" />
            {/* <div className={classes.selectBox}>
              <span>Browse</span>
              
            </div> */}
          </div>
        </div>
        <div className={classes.sectionSubmit}>
          <button type="submit">Next</button>
        </div>
      </form>
    );
  }
};

export default withRouter(JobDetails);
