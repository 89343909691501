import React from "react";
import classes from "./OnBoardingRequrements.module.css";
import OnBoardingRequirement from "./OnBoardingRequirement";

const OnBoardingRequirements = ({boardingRequirement}) => {
  return (
    <div className={classes.onBoardingRequirements}>
      <div className={classes.title} style={{fontWeight:"bold"}}>On Boarding Requirements:</div>
      <div className={classes.data}>
        {
          boardingRequirement.map((item,index)=>{
            return <OnBoardingRequirement index={index} boardingRequirement={item}/>
          })
        }
      </div>
    </div>
  );
};

export default OnBoardingRequirements;
