import React from "react";
import classes from "./Skills.module.css";

const Skill = ({ skillData }) => {
  return (
    <div className={classes.skill}>
      <div className={classes.skillData}>
        <div>Skill Name:</div>&nbsp;
        <div>{skillData.value}</div>
      </div>
      <div className={classes.skillData}>
        <div>Preference:</div>&nbsp;
        <div>{skillData.preference}</div>
      </div>
      <div className={classes.skillData}>
        <div>Experience:</div>&nbsp;
        <div>{`${skillData.minExperience}`}</div>
      </div>
    </div>
  );
};

export default Skill;
