import React from "react";
import classes from "./JobCard.module.css";
import Utility from "../../Utility/Utility";

const JobCard = ({
  btnText,
  style,
  jobId,
  jobTitle,
  jobType,
  postedDate,
  btnEvent,
  totalCount,
  activeIndex,
  index,
  indexing,
  getApplicationDetail,
  alternateJobId,
  width,
}) => {
  return (
    <div
      className={`${classes.jobCard} ${
        indexing && activeIndex === index && classes.active
      }`}
      style={{
        width: width,
        pointerEvents:
          indexing === undefined ? "auto" : totalCount > 0 ? "auto" : "none",
      }}
      key={jobId}
      onClick={() => indexing && getApplicationDetail(jobId, index)}
    >
      <div className={classes.jobTitle}>{jobTitle}</div>
      {totalCount >= 0 && (
        <div className={classes.showCount}>
          <div>
            <div>{totalCount}</div>
          </div>
        </div>
      )}
      <div className={classes.jobCardContent}>
        <span>Job Id:</span>&nbsp;&nbsp;<span>{alternateJobId}</span>
      </div>
      <div className={classes.jobCardContent}>
        <span>Job Type:</span>&nbsp;&nbsp;<span>{jobType}</span>
      </div>
      <div className={classes.jobCardContent}>
        {/* <span>Job Posted On :</span>&nbsp;&nbsp; */}
        <span>Job Created On :</span>&nbsp;&nbsp;
        <span>{Utility.formatDate(postedDate)}</span>
      </div>
      {btnText && (
        <div className={classes.jobBtn}>
          <button style={style} onClick={() => btnEvent(jobId)}>
            {btnText}
          </button>
        </div>
      )}
    </div>
  );
};

export default JobCard;
