import React from "react";
import classes from "./UpcomingInterviews.module.css";
import Utility from '../../Utility/Utility';

const UpcomingInterview = ({date,time,name,position}) => {
  return (
    <div className={classes.upcomingInterview}>
      <span>{Utility.formatDate(date)}</span>
      {/* <span>{Utility.formatTime(time)}</span> */}
      <span>{name}</span>
      <span>{position}</span>
    </div>
  );
};

export default UpcomingInterview;
