import React from "react";
import classes from "./Personal.module.css";
import PersonalCustome from "./PersonalCustom";
import PersonalEdit from "./PersonalEdit";
import PersonalInitial from "./PersonalInitial";

const Personal = ({
  personalQuestions,
  personalCustomeQuestions,
  editPersonal,
  removePersonal,
  savePersonal,
  handlePersonalChange,
  addNewPersonal,
  addToCustome,
  editCustomeQuestion,
  removeCustomeQuestion,
  masterPersonalQuestions,
  clearError
}) => {
  return (
    <div className={classes.personal}>
      <div className={classes.head}>
        <div className={classes.index}>S.No.</div>
        <div>Question</div>
        <div>Action</div>
      </div>
      {personalQuestions.map((item, index) => {
        return (
          <PersonalEdit
            personalIndex={index}
            personalQuestions={item}
            editPersonal={editPersonal}
            removePersonal={removePersonal}
            savePersonal={savePersonal}
            handlePersonalChange={handlePersonalChange}
            masterPersonalQuestion={masterPersonalQuestions}
          />
        );
      })}
      <PersonalInitial
        lastIndex={personalQuestions.length}
        addNewPersonal={addNewPersonal}
        masterPersonalQuestion={masterPersonalQuestions}
        clearError={clearError}
      />
     
    </div>
  );
};

export default Personal;
