import React, { useEffect, useState, useRef } from "react";
import classes from "./OpenJobs.module.css";
import JobsInfoCard from "../../../components/JobInfoCard/JobInfoCard";
import JobDescriptionCard from "../../../components/JobDescriptionCard/JobDescriptionCard";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../Store/Actions/index";
import * as actionTypes from "../../../Store/Actions/ActionTypes";
import Loader from "../../../common/Loader/Loader";
import Toast from "../../../common/Toast/Toast";
import Utility from "../../../Utility/Utility";
import { withRouter } from "react-router-dom";
import ApplyJobCard from "../../../components/ApplyJobCard/ApplyJobCard";
import ModalFilter from "../../../common/ModalFIlter/ModalFilter";
import SearchSuggestion from "./Suggestions";
import { getRequest } from "../../../Helper/apiHit";
// import WarningAlert from "../../../common/WarningAlert/WarningAlert";
import ErrorAlert from "../../../common/ErrorAlert/ErrorAlert";

const OpenJobs = ({ history }) => {
  const roleType = Utility.parseJwt(localStorage.getItem("Token")).roleType;
  const locationRef = useRef();
  const desiginationRef = useRef();
  let dispatch = useDispatch();
  const [toast, setToast] = useState(false);
  const [activeIndex, setIsActiveIndex] = useState(0);
  const [isApplyJobActive, setIsApplyJobActive] = useState(false);
  const [activeApplyBoxDetail, setActiveApplyBoxDetail] = useState({});
  const [positionFilterActive, setPositionFilterActive] = useState(false);
  const [locationFilterActive, setLocationFilterActive] = useState(false);
  const [suggestion, setSuggestion] = useState([]);
  const [suggestionId, setSuggestionId] = useState("");
  const [warnAlert, setWarnAlert] = useState(false);

  const {
    postedJobs,
    error,
    errorMsg,
    masterPostedJobs,
    msg,
    jobPreview,
    loader,
    images,
    masterCountryList,
    masterStateList,
    masterCityList,
  } = useSelector((state) => ({
    loader: state.CommonReducer.loader,
    postedJobs: state.CommonReducer.postedJobs,
    masterPostedJobs: state.CommonReducer.masterPostedJobs,
    error: state.CommonReducer.error,
    errorMsg: state.CommonReducer.errorMsg,
    msg: state.CommonReducer.msg,
    jobPreview: state.CreateJobReducer.jobPreview,
    images: state.CommonReducer.images,
    masterCountryList: state.MasterReducer.masterCountryList,
    masterStateList: state.MasterReducer.masterStateList,
    masterCityList: state.MasterReducer.masterCityList,
  }));

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch({ type: actionTypes.TRASH });
    dispatch(actions.getPostedJobs(roleType));
    dispatch(actions.getMasterSkills());
    dispatch(actions.getMasterCommunicationModeList());
    dispatch(actions.getMasterOfferedBenefitsList());
    dispatch(actions.getMasterRelocationBenefitsList());
    dispatch(actions.getMasterCountryList());
    dispatch(actions.getMasterStateList());
    dispatch(actions.getMasterCityList());
    dispatch(actions.getEmployerDetails());
    dispatch(actions.getMasterBoardingRequirementsList());
    dispatch(actions.getInterviewQuestions());
  }, []);

  useEffect(() => {
    if (postedJobs && postedJobs.length >= 0 && window.innerWidth > 800) {
      if (activeIndex === 0 && postedJobs.length > 0) {
        dispatch(actions.getCreateJobPreview(postedJobs[0]._id));
      }
    }
  }, [postedJobs]);

  const getJobDetails = (index, jobId) => {
    if (window.innerWidth > 800) {
      dispatch({ type: actionTypes.TRASH });
      dispatch(actions.getCreateJobPreview(jobId));
      setIsActiveIndex(index);
    } else {
      history.push("/job-description", { jobId: jobId });
    }
  };

  // useEffect(() => {
  //   console.log("jobPreview",jobPreview)
  //   if(jobPreview !== undefined){
  //     console.log("jobPreview",jobPreview)
  //     let res = getRequest(`users/getAvatar?avatarPath=${jobPreview.employerId.userId.avatarPath}`)
  //     console.log("imageData",res)
  //   }
  // }, [getRequest]);

  const openApplyJobBox = (img, title, location, id, hire) => {
    setActiveApplyBoxDetail({
      employerImg: img,
      jobTitile: title,
      jobLocation: location,
      jobId: id,
      whyShouldHire: hire,
    });
    setIsApplyJobActive(true);
  };

  // const filterJobs = (e)  => {
  //   if(e.target.value === 'position'){
  //     setPositionFilterActive(true);
  //   }else if(e.target.value === 'location'){
  //     setLocationFilterActive(true)
  //   }
  // }

  // const confirm = (type,value) => {
  //   if(type === 'position'){
  //     dispatch({type:actionTypes.POSITION_FILTER,payload:value})
  //   }else if(type === 'location'){
  //     dispatch({type:actionTypes.LOCATION_FILTER,payload:value})
  //   }
  //   setPositionFilterActive(false);
  //   setLocationFilterActive(false);
  // }

  // const cancel = () => {
  //   setPositionFilterActive(false);
  //   setLocationFilterActive(false);
  // }

  const filteredSuggestion = (e) => {
    if (masterStateList) {
      if (e.target.value !== "") {
        setSuggestion(
          masterStateList.filter((item) => {
            if (
              item.name.toLowerCase().includes(e.target.value.toLowerCase())
            ) {
              return item;
            }
          })
        );
      } else {
        setSuggestion([]);
      }
    }
  };

  const addNewValue = (e, outerIndex, value, id) => {
    locationRef.current.value = value;
    setSuggestionId(id);
  };

  const clearFilters = () => {
    locationRef.current.value = "";
    desiginationRef.current.value = "";
    setSuggestionId("");
    dispatch({
      type: actionTypes.CANDIDATE_JOB_FILTER_CLEAR,
      payload: masterPostedJobs,
    });
  };

  const applyFilters = () => {
    let desigination = desiginationRef.current.value;
    let locationValue = locationRef.current.value;

    let suggestion = suggestionId;
    if (desigination != "" && locationValue != "") {
      let filteredJobs = masterPostedJobs.filter((item) => {
        if (item.workLocation) {
          if (
            item.workLocation.stateId === suggestion &&
            item.role.toLowerCase().includes(desigination.toLowerCase())
          ) {
            return item;
          }
        }
      });

      // let filteredJobs2 = masterPostedJobs.filter((item) => {
      //   if(item.workLocation){
      //     if (item.workLocation.stateId === suggestion) {
      //       return item;
      //     }
      //   }
      //   // if(item.role.toLowerCase().includes(desigination.toLowerCase())){
      //   //   return item;
      //   // }
      // });

      // let finalArr = filteredJobs.concat(filteredJobs2);

      // if(filteredJobs.length === 0){
      //   setWarnAlert(true)
      // }

      dispatch({
        type: actionTypes.CANDIDATE_JOB_FILTER,
        payload: filteredJobs,
      });
    } else if (desigination != "") {
      let filteredJobs = masterPostedJobs.filter((item) => {
        if (item.role.toLowerCase().includes(desigination.toLowerCase())) {
          return item;
        }
      });
      // if(filteredJobs.length === 0){
      //   setWarnAlert(true)
      // }
      dispatch({
        type: actionTypes.CANDIDATE_JOB_FILTER,
        payload: filteredJobs,
      });
    } else if (locationValue != "") {
      let filteredJobs = masterPostedJobs.filter((item) => {
        if (item.workLocation) {
          if (item.workLocation.stateId === suggestion) {
            console.log("Else If 2");
            return item;
          }
        }
        // if(item.workLocation.address.includes(locationValue)){
        //   return item;
        // }
      });
      console.log("filteredJobs", filteredJobs);
      // if(filteredJobs.length === 0){
      //   setWarnAlert(true)
      // }
      dispatch({
        type: actionTypes.CANDIDATE_JOB_FILTER,
        payload: filteredJobs,
      });
    }
  };

  const confirmAndCancel = () => {
    setWarnAlert(false);
    clearFilters();
  };

  if (loader) {
    return <Loader />;
  } else if (postedJobs) {
    return (
      <div className={classes.jobs}>
        <div className={classes.filters}>
          <div className={classes.filterItem}>Search Job :</div>
          <div className={classes.filterItem}>
            {/* <form> */}
            <input
              type="text"
              required
              placeholder="Enter Desigination"
              pattern="^[a-zA-Z ]*$"
              title="Only alphabets allowed"
              ref={desiginationRef}
            />
            {/* <button>
                <i class="fa fa-search" aria-hidden="true"></i>
                </button> */}
            {/* </form> */}
          </div>
          <div className={classes.filterItem}>
            <input
              type="text"
              required
              onChange={(e) => filteredSuggestion(e)}
              ref={locationRef}
              placeholder="Enter State"
            />
            <i class="fa fa-map-marker" aria-hidden="true"></i>
            <SearchSuggestion
              suggestion={suggestion}
              addNewValue={addNewValue}
              clearSuggestion={() => setSuggestion([])}
            />
          </div>
          <div className={classes.filterItem}>
            <button onClick={applyFilters}>Search</button>
            <button onClick={clearFilters}>Clear</button>
          </div>
        </div>
        {/* {
          positionFilterActive &&
          <ModalFilter show={true} filterType='position' cancle={cancel} confirm={confirm}/>
        }

        {
          locationFilterActive &&
          <ModalFilter show={true} filterType='location' cancle={cancel} confirm={confirm}/>
        } */}

        {isApplyJobActive && (
          <ApplyJobCard
            employerDetail={activeApplyBoxDetail}
            closeBox={() => {
              setIsApplyJobActive(!isApplyJobActive);
              setActiveApplyBoxDetail({});
            }}
          />
        )}
        {postedJobs.length === 0 && (
          <div className={classes.noData}>Sorry No Jobs Available.</div>
        )}
        {postedJobs.length !== 0 && (
          <div className={classes.allJobs}>
            <div className={classes.jobCard}>
              <div>
                <div className={classes.filter}>
                  <span>Total Jobs</span>&nbsp;&nbsp;
                  <span>{postedJobs.length}</span>
                  {/* <select onChange={filterJobs}>
                    <option selected disabled>
                      Filter By
                    </option>
                    <option value="position">Position</option>
                    <option value="location">Location</option>
                  </select> */}
                </div>
              </div>
              <div className={classes.card}>
                {postedJobs.map((job, index) => {
                  return (
                    <JobsInfoCard
                      key={index}
                      roleType="candidate"
                      id={job._id}
                      openApplyJobBox={openApplyJobBox}
                      image=""
                      image={job.image}
                      index={index}
                      jobTitle={job.role}
                      jobLocation={Utility.getLocation(
                        masterCountryList,
                        masterStateList,
                        masterCityList,
                        job.workLocation
                      )}
                      hire={job.miscInfoId.whyShouldHire}
                      isApplied={job.isApplied}
                      isActive={job.isActive}
                      postedDate={job.publishedDate}
                      deleteJob={console.log("")}
                      closeJob={console.log("")}
                      repostJob={console.log("")}
                      getJobDetails={getJobDetails}
                      cardActive={activeIndex === index ? true : false}
                      sendToUpdateJob={console.log("")}
                    />
                  );
                })}
              </div>
            </div>
            <div className={classes.jobDescription}>
              <JobDescriptionCard
                jobDetail={jobPreview}
                openApplyJobBox={openApplyJobBox}
                roleType="candidate"
              />
            </div>
          </div>
        )}
        {warnAlert && (
          // <WarningAlert show={true} msg="Work location not found" confirm={confirmAndCancel} cancel={confirmAndCancel} />
          <ErrorAlert
            show={true}
            msg="State not found"
            confirm={confirmAndCancel}
          />
        )}
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default withRouter(OpenJobs);
